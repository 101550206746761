/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
import { isDebugEnv } from 'config';

Array.prototype.remove = function (index) {
	return this.splice(index, 1);
};

Array.prototype.sortDescending = function (index) {
	return this.sort((a, b) => a > b ? -1 : b > a ? 1 : 0);
};

function noop() { }

if (!isDebugEnv) {
	console.log = noop;
	console.warn = noop;
	console.error = noop;
}

