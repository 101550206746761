import React, { forwardRef, useEffect, useState, useCallback } from 'react';
import { Box, Button, TextField, Select, MenuItem, InputAdornment } from '@material-ui/core';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import * as personService from 'services/person';
import { Autocomplete } from '@material-ui/lab';
import { Edit } from "@material-ui/icons";
import debounce from 'random/debounce';
import { TablePagination } from '@material-ui/core';
import { useTenant } from 'contexts/tenant';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { TableContainer } from 'containers/TableContainer';

const SearchPersonFields = ({ onRowDataChange, rowData, related }) => {

	const [searchField, setSearchField] = useState(undefined);
	const [options, setOptions] = useState([]);
	const { selectedTenant } = useTenant();

	const fieldName = related ? 'relatedTo' : 'person';
	const discriminatorField = related ? 'relatedToDiscriminator' : 'discriminator';

	const verify = useCallback(
		debounce(name => {
			fetchPersons(name);
		}, 500),
		[]
	);

	useEffect(() => {
		fetchPersons();
	}, []);

	useEffect(() => {
		verify(searchField);
	}, [searchField]);

	const fetchPersons = (name) => {
		personService.getPersonsList({ page: 0, size: 10, searchString: name }, selectedTenant).then(resp => {
			setOptions(resp?.data.map(n => {
				return { name: n.name, value: n.id, discriminator: n.discriminator };
			}));
		});
	};

	return (

		<Autocomplete
			fullWidth
			noOptionsText={'Nenhum registro foi encontrado.'}
			defaultValue={{ value: rowData[fieldName + 'Id'], name: rowData[fieldName + 'IdDisplay'] ?? rowData[fieldName]?.name }}
			options={options}
			getOptionLabel={(option) => option.name}
			filterOptions={(options, _) => options}
			renderInput={(params) => <TextField {...params} onChange={(event) => setSearchField(event?.target?.value)} shrink />}
			onFocus={() => setSearchField('')}
			onInputChange={(_, __, reason) => { if (reason === 'clear') setSearchField(''); }}
			onChange={(_, person) => {
				{
					onRowDataChange({
						...rowData,
						[fieldName + 'Id']: person?.value,
						[fieldName + 'IdDisplay']: person?.name,
						[discriminatorField]: person?.discriminator
					});
				}
			}
			}

		/>);

};

const typeOfRelationships = [
	{ name: 'Procurador', value: 'Proxy' },
	{ name: 'Sócio', value: 'Partner' },
	{ name: 'Cônjuge', value: 'Spouse' },
	{ name: 'Testemunha', value: 'Witness' },
	{ name: 'Credor', value: 'Creditor' },
	{ name: 'Agente de cobrança', value: 'BillingAgent' },
	{ name: 'Usuário aprovador', value: 'BillingAgentApproverUser' }
];

function InvolvedPersonsTab(props) {

	const { involvedPersons, setInvolvedPersons, readOnly, envWithProdAccount = [] } = props;
	const [isEditingTable, setIsEditingTable] = React.useState(false);
	const addPersonButton = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);

	useEffect(() => {
		if (involvedPersons.length < 1 && !readOnly) {
			addPersonButton.current.click();
			setIsEditingTable(true);
		}
	}, []);

	useEffect(() => {
		if (envWithProdAccount.length > 0) {
			setInvolvedPersons([...envWithProdAccount]);
		}
	}, []);

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const deletePerson = function (oldData) {
		let index = involvedPersons?.map(x => x.id).indexOf(oldData.id);
		let newPersons = [...involvedPersons];
		newPersons.splice(index, 1);
		setInvolvedPersons(newPersons);
	};

	const handleLink = (personId, discriminator) => {
		if (discriminator === "LegalPerson") {
			return `/pessoa-juridica/editar?id=${personId}`;
		} else return `/pessoa-fisica/editar?id=${personId}`;
	};

	const table = <MaterialTable
		title={
			<Button onClick={() => {
				addPersonButton.current.click();
				setIsEditingTable(true);
			}} variant="contained" color="primary" disabled={isEditingTable || readOnly}> + envolvido</Button>}
		icons={{
			Add: _ => (
				<div ref={addPersonButton} />
			),
			Edit: forwardRef((props, ref) => {
				return (
					<Edit {...props} onClick={() => {
						setTimeout(() => {
							setIsEditingTable(!!isEditingTable);
						}, 10);
					}} ref={ref} />
				);
			}),

		}}
		columns={[
			{
				title: 'Pessoa',
				field: 'person.name',
				render: ({ person, personIdDisplay, discriminator, personId }) => {
					return (
						<React.Fragment>
							{personIdDisplay || person?.name}
							<Link to={handleLink(personId, discriminator || person?.discriminator)}>
								<SearchOutlinedIcon style={{ float: 'right' }} position="end" />
							</Link>
						</React.Fragment>);
				},
				editComponent: props => (
					<SearchPersonFields onRowDataChange={props.onRowDataChange} rowData={props.rowData} />
				)
			},
			{
				title: 'Tipo de relação',
				field: 'typeOfRelationship',
				render: ({ typeOfRelationship }) => (typeOfRelationships?.find(asset => asset.value == typeOfRelationship))?.name,
				editComponent:
					props => {
						const findedItem = typeOfRelationships.find(item => item.value === props.value);
						return (
							<Select
								value={props.value || ''}
								fullWidth
								onChange={(event) => props.onChange(event.target.value)}
								component={TextField}
							>
								<MenuItem value={props.value}>{findedItem?.name}</MenuItem>
								{typeOfRelationships && typeOfRelationships.map(field => (
									!involvedPersons.find(f => f.typeOfRelationship === field.value && f.personId === props?.rowData?.personId) &&
									<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
								))}
							</Select>
						);
					}
			},
			{
				title: 'Signatário',
				field: 'isSigner',
				render: (props) => {
					const { isSigner } = props;
					if (envWithProdAccount?.length > 0) {
						envWithProdAccount?.forEach((item) => {
							if (item.discriminator === "NaturalPerson") {
								var signer = item.isSigner = "Sim";
								return isSigner && signer;
							};
						});
					}

					const discriminator = props?.discriminator || props?.person?.discriminator;
					return isSigner && discriminator == "NaturalPerson" ? 'Sim' : 'Não';
				},
				editComponent: props => {
					const selected = [{ name: 'Sim', value: true }, { name: 'Não', value: false }];
					const discriminator = props.rowData?.discriminator || props.rowData?.person?.discriminator;
					return (
						<Select
							required={true}
							value={props.value}
							fullWidth
							disabled={discriminator != "NaturalPerson"}
							onChange={(event) => props.onChange(event.target.value)}
							component={TextField}
							options={selected}
						>
							{selected && selected.map(field => (
								<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
							))}
						</Select>
					);
				}
			},
			{
				title: 'Participação na Empresa',
				field: 'participationPercentage',
				render: ({ participationPercentage }) => participationPercentage ? `${participationPercentage.toString().replace(".", ",")}%` : `${0}%`,
				editComponent: (props) => {
					const discriminator = props.rowData?.discriminator || props.rowData?.person?.discriminator;
					return (
						<NumberFormat
							id='participationPercentageId'
							name='participationPercentage'
							defaultValue={0}
							fullWidth
							value={props.value}
							onChange={(event) => props.onChange(event.target.value)}
							customInput={TextField}
							fixedDecimalScale={true}
							disabled={discriminator != "NaturalPerson"}
							decimalSeparator=","
							InputProps={{
								endAdornment: <InputAdornment position="end">{"%"} </InputAdornment>,
							}}
						/>
					);
				}
			},
			{
				title: 'Relacionada a ',
				field: 'relatedToIdDisplay',
				render: ({ relatedToIdDisplay }) => relatedToIdDisplay,
				editComponent: props => (
					<SearchPersonFields onRowDataChange={props.onRowDataChange} rowData={props.rowData} related={true} />
				)
			}
		]}
		editable={{
			onRowAdd: !readOnly ? newData =>
				new Promise((resolve, reject) => {
					setIsEditingTable(false);
					setInvolvedPersons(involveds => [...involveds, newData]);
					resolve();
				}) : null,
			onRowUpdate: !readOnly ? (newData, oldData) => {
				return new Promise(resolve => {
					let index = involvedPersons.map(x => x.id ? x.id : x.tableData.id).indexOf(oldData.id ? oldData.id : oldData.tableData.id);
					let newInvolvedPersons = [...involvedPersons];
					newInvolvedPersons[index] = newData;
					setIsEditingTable(false);
					setInvolvedPersons(newInvolvedPersons);
					resolve();
				});
			} : null,
			onRowAddCancelled: () => {
				return new Promise(resolve => {
					setIsEditingTable(false);
					resolve();
				});
			},
			onRowUpdateCancelled: () => {
				setIsEditingTable(false);
			},
			onRowDelete: oldData =>
				new Promise(resolve => {
					deletePerson(oldData).finally(() => resolve());
				})
		}}
		data={involvedPersons}
		onChangeRowsPerPage={onRowsPerPageChange}
		onRowsPerPageChange={onRowsPerPageChange}
		localization={localizationOptions}
		components={{
			Pagination: props => (
				<TablePagination
					{...props}
					rowsPerPageOptions={[5, 10, 20, 50]}
				/>
			),
		}}
		options={{
			search: false,
			actionsColumnIndex: -1,
			pageSize: rowsPerPage,
			debounceInterval: 500,
			searchAutoFocus: true,
			toolbarButtonAlignment: 'left',
			searchFieldAlignment: 'right'
		}}
	/>;

	return (
		<>
			<Box p={1}>
				<TableContainer>
					{table}
				</TableContainer>
			</Box>
		</>
	);
}

export default InvolvedPersonsTab;