
const bankCodeFields =
	[
		{ name: '001 | Banco do Brasil S.A.', value: 1 },
		{ name: '237 | Banco Bradesco S.A.', value: 237 },
		{ name: '003 | Banco da Amazônia S.A.', value: 3 },
		{ name: '117 | Advanced Cc Ltda', value: 117 },
		{ name: '172 | Albatross Ccv S.A.', value: 172 },
		{ name: '188 | Ativa Investimentos S.A', value: 188 },
		{ name: '280 | Avista S.A. Crédito, Financiamento e Investimento', value: 280 },
		{ name: '080 | B&T Cc Ltda', value: 80 },
		{ name: '654 | Banco A.J.Renner S.A.', value: 654 },
		{ name: '246 | Banco ABC Brasil S.A.', value: 246 },
		{ name: '075 | Banco ABN AMRO S.A.', value: 75 },
		{ name: '121 | Banco Agibank S.A.', value: 121 },
		{ name: '025 | Banco Alfa S.A.', value: 25 },
		{ name: '641 | Banco Alvorada S.A.', value:641},
		{ name: '065 | Banco Andbank (Brasil) S.A.', value: 65 },
		{ name: '213 | Banco Arbi S.A.', value: 213 },
		{ name: '096 | Banco B3 S.A.', value: 96 },
		{ name: '024 | Banco BANDEPE S.A.', value: 24 },
		{ name: '318 | Banco BMG S.A.', value: 318 },
		{ name: '752 | Banco BNP Paribas Brasil S.A.', value: 752 },
		{ name: '107 | Banco BOCOM BBM S.A.', value: 107 },
		{ name: '063 | Banco Bradescard S.A.', value: 63  },
		{ name: '036 | Banco Bradesco BBI S.A.', value: 36  },
		{ name: '122 | Banco Bradesco BERJ S.A.', value: 122  },
		{ name: '204 | Banco Bradesco Cartões S.A.', value: 204  },
		{ name: '394 | Banco Bradesco Financiamentos S.A.', value: 394  },
		{ name: '218 | Banco BS2 S.A.', value: 218  },
		{ name: '208 | Banco BTG Pactual S.A.', value: 208  },
		{ name: '336 | Banco C6 S.A – C6 Bank',  value: 336 },
		{ name: '363 | SINGULARE CTVM',  value: 363 },
		{ name: '473 | Banco Caixa Geral – Brasil S.A.', value: 473  },
		{ name: '412 | Banco Capital S.A.', value: 412  },
		{ name: '040 | Banco Cargill S.A.', value: 40  },
		{ name: '368 | Banco Carrefour.', value: 368  },
		{ name: '266 | Banco Cédula S.A.', value: 266  },
		{ name: '739 | Banco Cetelem S.A.', value: 739  },
		{ name: '233 | Banco Cifra S.A.', value: 233  },
		{ name: '745 | Banco Citibank S.A.', value: 745  },
		{ name: '241 | Banco Clássico S.A.', value: 241  },
		{ name: '756 | Banco Cooperativo SICOOB S/A. – BANCO SICOOB', value: 756 },
		{ name: '222 | Banco Credit Agricole Brasil S.A.', value: 222  },
		{ name: '505 | Banco Credit Suisse (Brasil) S.A.', value: 505 },
		{ name: '069 | Banco Crefisa S.A.', value: 69 },
		{ name: '083 | Banco da China Brasil S.A.', value: 83 },
		{ name: '707 | Banco Daycoval S.A.', value: 707 },
		{ name: '051 | Banco de Desenvolvimento do Espírito Santo S.A.', value: 51 },
		{ name: '300 | Banco de La Nacion Argentina', value: 300 },
		{ name: '495 | Banco de La Provincia de Buenos Aires', value: 495 },
		{ name: '494 | Banco de La Republica Oriental del Uruguay', value: 494 },
		{ name: '047 | Banco do Estado de Sergipe S.A.', value: 47 },
		{ name: '037 | Banco do Estado do Pará S.A.', value: 37 },
		{ name: '041 | Banco do Estado do Rio Grande do Sul S.A.', value: 41 },
		{ name: '004 | Banco do Nordeste do Brasil S.A.', value: 4 },
		{ name: '196 | Banco Fair Corretora de Câmbio S.A', value: 196 },
		{ name: '265 | Banco Fator S.A.', value: 265 },
		{ name: '224 | Banco Fibra S.A.', value: 224 },
		{ name: '626 | Banco Ficsa S.A.', value: 626 },
		{ name: '094 | Banco Finaxis S.A.', value: 94 },
		{ name: '612 | Banco Guanabara S.A.', value: 612 },
		{ name: '012 | Banco Inbursa S.A.', value: 12 },
		{ name: '604 | Banco Industrial do Brasil S.A.', value: 604 },
		{ name: '653 | Banco Indusval S.A.', value: 653 },
		{ name: '077 | Banco Inter S.A.', value: 77 },
		{ name: '249 | Banco Investcred Unibanco S.A.', value: 249 },
		{ name: '184 | Banco Itaú BBA S.A.', value: 184 },
		{ name: '029 | Banco Itaú Consignado S.A.', value: 29 },
		{ name: '479 | Banco ItauBank S.A', value: 479 },
		{ name: '376 | Banco J. P. Morgan S.A.', value: 376 },
		{ name: '074 | Banco J. Safra S.A.', value: 74 },
		{ name: '217 | Banco John Deere S.A.', value: 217 },
		{ name: '076 | Banco KDB S.A.', value: 76 },
		{ name: '757 | Banco KEB HANA do Brasil S.A.', value: 757 },
		{ name: '600 | Banco Luso Brasileiro S.A.', value: 600 },
		{ name: '243 | Banco Máxima S.A.', value: 243 },
		{ name: '720 | Banco Maxinvest S.A.', value: 720 },
		{ name: '389 | Banco Mercantil do Brasil S.A.', value: 389 },
		{ name: '370 | Banco Mizuho do Brasil S.A.', value: 370 },
		{ name: '746 | Banco Modal S.A.', value: 746 },
		{ name: '066 | Banco Morgan Stanley S.A.', value: 66 },
		{ name: '456 | Banco MUFG Brasil S.A.', value: 456 },
		{ name: '007 | Banco Nacional de Desenvolvimento Econômico e Social – BNDES', value: 7 },
		{ name: '169 | Banco Olé Bonsucesso Consignado S.A.', value: 169 },
		{ name: '111 | Banco Oliveira Trust Dtvm S.A.', value: 111 },
		{ name: '079 | Banco Original do Agronegócio S.A.', value: 79 },
		{ name: '212 | Banco Original S.A.', value: 212 },
		{ name: '712 | Banco Ourinvest S.A.', value: 712 },
		{ name: '623 | Banco PAN S.A.', value: 623 },
		{ name: '611 | Banco Paulista S.A.', value: 611 },
		{ name: '643 | Banco Pine S.A.', value: 643 },
		{ name: '658 | Banco Porto Real de Investimentos S.A.', value: 658 },
		{ name: '747 | Banco Rabobank International Brasil S.A.', value: 747 },
		{ name: '633 | Banco Rendimento S.A.', value: 633 },
		{ name: '741 | Banco Ribeirão Preto S.A.', value: 741 },
		{ name: '120 | Banco Rodobens S.A.', value: 120 },
		{ name: '422 | Banco Safra S.A.', value: 422 },
		{ name: '033 | Banco Santander (Brasil) S.A.', value: 33 },
		{ name: '743 | Banco Semear S.A.', value: 743 },
		{ name: '754 | Banco Sistema S.A.', value: 754 },
		{ name: '630 | Banco Smartbank S.A.', value: 630 },
		{ name: '366 | Banco Société Générale Brasil S.A.', value: 366 },
		{ name: '637 | Banco Sofisa S.A.', value: 637 },
		{ name: '464 | Banco Sumitomo Mitsui Brasileiro S.A.', value: 464 },
		{ name: '082 | Banco Topázio S.A.', value: 82 },
		{ name: '634 | Banco Triângulo S.A.', value: 634 },
		{ name: '018 | Banco Tricury S.A.', value: 18 },
		{ name: '655 | Banco Votorantim S.A.', value: 655 },
		{ name: '610 | Banco VR S.A.', value: 610 },
		{ name: '119 | Banco Western Union do Brasil S.A.', value: 119 },
		{ name: '124 | Banco Woori Bank do Brasil S.A.', value: 124 },
		{ name: '348 | Banco Xp S/A', value: 348 },
		{ name: '081 | BancoSeguro S.A.', value: 81 },
		{ name: '021 | BANESTES S.A. Banco do Estado do Espírito Santo', value: 21 },
		{ name: '755 | Bank of America Merrill Lynch Banco Múltiplo S.A.', value: 755 },
		{ name: '268 | Barigui Companhia Hipotecária', value: 268 },
		{ name: '250 | BCV – Banco de Crédito e Varejo S.A.', value: 250 },
		{ name: '144 | BEXS Banco de Câmbio S.A.', value: 144 },
		{ name: '253 | Bexs Corretora de Câmbio S/A', value: 253 },
		{ name: '134 | Bgc Liquidez Dtvm Ltda', value: 134 },
		{ name: '136 | CONF NAC COOP CENTRAIS UNICRED', value: 136 },
		{ name: '017 | BNY Mellon Banco S.A.', value: 17 },
		{ name: '301 | Bpp Instituição De Pagamentos S.A.', value: 301 },
		{ name: '126 | BR Partners Banco de Investimento S.A.', value: 126 },
		{ name: '070 | BRB – Banco de Brasília S.A.', value: 70 },
		{ name: '092 | Brickell S.A. Crédito, Financiamento e Investimento.', value: 92 },
		{ name: '173 | BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.', value: 173 },
		{ name: '142 | Broker Brasil Cc Ltda', value: 142 },
		{ name: '292 | BS2 Distribuidora de Títulos e Valores Mobiliários S.A.', value: 292 },
		{ name: '011 | C.Suisse Hedging-Griffo Cv S.A. (Credit Suisse)', value: 11 },
		{ name: '104 | Caixa Econômica Federal.', value: 104 },
		{ name: '288 | Carol Distribuidora de Títulos e Valor Mobiliários Ltda', value: 288 },
		{ name: '130 | Caruana Scfi', value: 130 },
		{ name: '159 | Casa Credito S.A.', value: 159 },
		{ name: '016 | Ccm Desp Trâns Sc E Rs', value: 16 },
		{ name: '089 | Ccr Reg Mogiana', value: 89 },
		{ name: '114 | Central Cooperativa De Crédito No Estado Do Espírito Santo.', value: 114 },
		{ name: '320 | China Construction Bank (Brasil) Banco Múltiplo S.A.', value: 320 },
		{ name: '477 | Citibank N.A.', value: 477 },
		{ name: '180 | Cm Capital Markets Cctvm Ltda', value: 180 },
		{ name: '127 | Codepe Cvc S.A.', value: 127 },
		{ name: '163 | Commerzbank Brasil S.A. – Banco Múltiplo', value: 163 },
		{ name: '060 | Confidence Cc S.A.', value: 60 },
		{ name: '085 | Coop Central Ailos', value: 85 },
		{ name: '097 | Cooperativa Central de Crédito Noroeste Brasileiro Ltda', value: 97 },
		{ name: '085 | Cooperativa Central de Crédito Urbano-CECRED', value: 85 },
		{ name: '286 | Cooperativa de Crédito Rural De Ouro', value: 286 },
		{ name: '279 | Cooperativa de Crédito Rural de Primavera Do Leste', value: 279 },
		{ name: '273 | Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel', value: 273 },
		{ name: '098 | Credialiança Ccr', value: 98 },
		{ name: '010 | Credicoamo', value: 10 },
		{ name: '133 | Cresol Confederação', value: 133 },
		{ name: '182 | Dacasa Financeira S/A', value: 182 },
		{ name: '487 | Deutsche Bank S.A. – Banco Alemão', value: 487 },
		{ name: '140 | Easynvest – Título Cv S.A.', value: 140 },
		{ name: '149 | Facta S.A. Cfi', value: 149 },
		{ name: '285 | Frente Corretora de Câmbio Ltda', value: 285 },
		{ name: '278 | Genial Investimentos Corretora de Valores Mobiliários S.A.', value: 278 },
		{ name: '138 | Get Money Cc Ltda', value: 138 },
		{ name: '064 | Goldman Sachs do Brasil Banco Múltiplo S.A.', value: 64 },
		{ name: '177 | Guide Investimentos S.A. Corretora de Valores', value: 177 },
		{ name: '146 | Guitta Corretora de Câmbio Ltda', value: 146 },
		{ name: '078 | Haitong Banco de Investimento do Brasil S.A.', value: 78 },
		{ name: '062 | Hipercard Banco Múltiplo S.A.', value: 62 },
		{ name: '189 | HS Financeira S/A Crédito, Financiamento e Investimentos', value: 189 },
		{ name: '269 | HSBC Brasil S.A. – Banco de Investimento', value: 269 },
		{ name: '271 | IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.', value: 271 },
		{ name: '274 | BMP MONEY PLUS', value: 274 },
		{ name: '439 | ID CORRETORA DE TITULOS E VALORES MOBILIARIOS S.A.', value: 439 },
		{ name: '157 | Icap Do Brasil Ctvm Ltda', value: 157 },
		{ name: '132 | ICBC do Brasil Banco Múltiplo S.A.', value: 132 },
		{ name: '492 | ING Bank N.V.', value: 492 },
		{ name: '139 | Intesa Sanpaolo Brasil S.A. – Banco Múltiplo', value: 139 },
		{ name: '652 | Itaú Unibanco Holding S.A.', value: 652 },
		{ name: '341 | Itaú Unibanco S.A.', value: 341 },
		{ name: '488 | JPMorgan Chase Bank, National Association', value: 488 },
		{ name: '399 | Kirton Bank S.A. – Banco Múltiplo', value: 399 },
		{ name: '293 | Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda', value: 293 },
		{ name: '105 | Lecca Crédito, Financiamento e Investimento S/A', value: 105 },
		{ name: '145 | Levycam Ccv Ltda', value: 145 },
		{ name: '113 | Magliano S.A.', value: 113 },
		{ name: '323 | Mercado Pago – Conta Do Mercado Livre', value: 323 },
		{ name: '128 | MS Bank S.A. Banco de Câmbio', value: 128 },
		{ name: '137 | Multimoney Cc Ltda', value: 137 },
		{ name: '014 | Natixis Brasil S.A. Banco Múltiplo', value: 14 },
		{ name: '191 | Nova Futura Corretora de Títulos e Valores Mobiliários Ltda', value: 191 },
		{ name: '753 | Novo Banco Continental S.A. – Banco Múltiplo', value: 753 },
		{ name: '260 | Nu Pagamentos S.A. (Nubank)', value: 260 },
		{ name: '613 | Omni Banco S.A.', value: 613 },
		{ name: '290 | Pagseguro Internet S.A.', value: 290 },
		{ name: '254 | Paraná Banco S.A.', value: 254 },
		{ name: '326 | Parati – Crédito Financiamento e Investimento S.A.', value: 326 },
		{ name: '194 | Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda', value: 194 },
		{ name: '174 | Pernambucanas Financ S.A.', value: 174 },
		{ name: '100 | Planner Corretora De Valores S.A.', value: 100 },
		{ name: '125 | Plural S.A. – Banco Múltiplo', value: 125 },
		{ name: '093 | Pólocred Scmepp Ltda', value: 93 },
		{ name: '108 | Portocred S.A.', value: 108 },
		{ name: '283 | Rb Capital Investimentos Dtvm Ltda', value: 283 },
		{ name: '101 | Renascenca Dtvm Ltda', value: 101 },
		{ name: '270 | Sagitur Corretora de Câmbio Ltda', value: 270 },
		{ name: '751 | Scotiabank Brasil S.A. Banco Múltiplo', value: 751 },
		{ name: '276 | Senff S.A. – Crédito, Financiamento e Investimento', value: 276 },
		{ name: '545 | Senso Ccvm S.A.', value: 545 },
		{ name: '190 | Servicoop', value: 190 },
		{ name: '183 | Socred S.A.', value: 183 },
		{ name: '299 | Sorocred Crédito, Financiamento e Investimento S.A.', value: 299 },
		{ name: '118 | Standard Chartered Bank (Brasil) S/A–Bco Invest.', value: 118 },
		{ name: '197 | Stone Pagamentos S.A.', value: 197 },
		{ name: '340 | Super Pagamentos e Administração de Meios Eletrônicos S.A.', value: 340 },
		{ name: '095 | Travelex Banco de Câmbio S.A.', value: 95 },
		{ name: '143 | Treviso Corretora de Câmbio S.A.', value: 143 },
		{ name: '131 | Tullett Prebon Brasil Cvc Ltda', value: 131 },
		{ name: '129 | UBS Brasil Banco de Investimento S.A.', value: 129 },
		{ name: '914 | Unicred Central do Rio Grande do Sul', value: 914 },
		{ name: '091 | Unicred Central Rs', value: 91 },
		{ name: '099 | UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda', value: 99 },
		{ name: '084 | Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências', value: 84 },
		{ name: '457 | UY3', value: 457 },
		{ name: '298 | Vips Cc Ltda', value: 298 },
		{ name: '310 | Vortx Distribuidora de Títulos e Valores Mobiliários Ltda', value: 310 },
		{ name: '102 | Xp Investimentos S.A.', value: 102 },
		{ name: '1147 | Central das Cooperativas de Economia e Crédito Mútuo do Estado do Espírito Santo Ltda', value: 1147 },
		{ name: '0876 | Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná', value: 876 },
		{ name: '0892 | Cooperativa de Crédito Rural da Região da Mogiana', value: 892 },
		{ name: '0981 | CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL', value: 981 },
		{ name: '403 | Cora SCD SA', value: 403 },
		{ name: '380 | PicPay', value: 380 },
		{ name: '438 | Planner', value: 438 },
		{ name: '748 | Bco Cooperativo Sicredi S.A', value: 748 },
		{ name: '450 | Fitbank Pagamentos Eletronicos S.A.', value: 450 },
		{ name: '413 | BCO BV S.A.', value: 413 },
		{ name: '335 | Banco Digio S.A.', value: 335 },
		{ name: '329 | QI SCD S.A.', value: 329 },
		{ name: '332 | ACESSO SOLUCOES PAGAMENTO SA', value: 332 },
		{ name: '536 | NEON PAGAMENTOS S.A. IP', value: 536 },
	];

export default bankCodeFields;
