import { object, string, addMethod, number } from "yup";
import Helper from 'helpers/schema.helpers';

function formatBrazilianRegistrationNumber(value: string) {
	if (value.length == 14) {
		return Helper.cnpjValidate(value);
	} else {
		return Helper.cpfValidate(value);
	}
}

addMethod(string, 'customCPFCNPJValidator', function () {
	return this.test({
		name: 'cpfCnpjValidation',
		message: 'CPF/CNPJ: inválido.',
		test: (value) => {

			return value ? formatBrazilianRegistrationNumber(value) : true;
		}
	});
});

export const CreateBeneficiarySchema = object().shape({
	name: string().required('Nome do favorecido: Precisa ser preenchido.'),
	sessionId: string().nullable(),
	code: string().nullable(),
	// @ts-ignore
	registrationNumber: string().typeError('Valor precisa ser um número.').required('Esse campo é obrigatório.').customCPFCNPJValidator() as any,
	operationTypeValue: string().typeError('Tipo de operação: inválido.').required('Tipo de operação: obrigatório.'),
	bank: string().when("operationTypeValue", {
		is: "Transfer",
		then: string().required('Banco: Precisa ser preenchido.').typeError('Banco: Precisa ser preenchido.'),
		otherwise: string().nullable()
	}),
	accountType: string().when("operationTypeValue", {
		is: "Transfer",
		then: string().required('Tipo de conta: Precisa ser preenchido.').typeError('Tipo de conta: Precisa ser preenchido.'),
		otherwise: string().nullable()
	}),
	agency: string().when("operationTypeValue", {
		is: "Transfer",
		then: string().required('Agência : Precisa ser preenchido.').typeError('Valor precisa ser um número.').max(4, 'Agência: Máximo de 4 caracteres.'),
		otherwise: string().nullable()
	}),
	accountNumber: number().when("operationTypeValue", {
		is: "Transfer",
		then: number().typeError('Valor precisa ser um número.').required('Conta : Precisa ser preenchido.'),
		otherwise: number().nullable()
	}),
	pixKeyTypeValue: string().when("operationTypeValue", {
		is: "Pix",
		then: string()
			.typeError('Tipo de chave: Precisa ser preenchido.')
			.required('Tipo de chave: Precisa ser preenchido.'),
		otherwise: string().nullable()
	}),
	keyPix: string().when("pixKeyTypeValue", pixKeyTypeValue => {
		return pixKeyTypeValue == "Email" || pixKeyTypeValue == "Phone" || pixKeyTypeValue == "Automatic" ?
			string().typeError('Chave Pix: Precisa ser preenchido.')
				.required('Chave Pix: Precisa ser preenchido.') :
			string().nullable();
	}),
});

export const defaultValues = {
	name: '',
	agency: null,
	accountNumber: null,
	accountType: null,
	registrationNumber: "",
	bank: null,
	sessionId: '',
	code: '',
	operationTypeValue: null,
	pixKeyTypeValue: null,
	keyPix: "",
};