import {
	makeStyles,
	createStyles,
	Grid,
	Paper,
	Typography,
	ButtonGroup,
	Button,
	Box
} from "@material-ui/core";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LabelList } from 'recharts';
import { RightArrowIcon, LeftArrowIcon } from "../../../assets";
import CustomTooltip from '../AgeRangeChart/tooltip';

const SalesChart = ({ data, x_axis, y_series }) => {
	const useStyles = makeStyles(() =>
		createStyles({
			root: {
				flexGrow: 1,
				margin: "10px 10px",
			},
			filtersContainer: {
				flexGrow: 1,
				padding: "20px",
				textAlign: "center",
				height: "320px",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				borderRadius: "24px"
			},
		})
	);
	const classes = useStyles();
	const formatter = e => e.toFixed(0) + '';
	const formatterY = e => e + '';
	var ticks = data.map(({ key, }, index) => { return index; });	
	function truncate(input) {
		if (input.length > 50) {
		   return input.substring(0, 50) + '...';
		}
		return input;
	 };

	return (
		<Grid item xs={12}>
			<Paper className={classes.filtersContainer} elevation={0}>
				<div className="card-item-row-line">
					<Typography>
						Operações por status
					</Typography>					
				</div>
				<Box m={1}>
				</Box>
				<ResponsiveContainer height={250}>
					<BarChart data={data} heigh={250} width={900}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey='key'
							ticks={data.map(e => e.key)}
							tick={{ fontSize: '10px', wordWrap: 'break-word' }}
							type={'category'}
							tickFormatter={truncate} />
						<YAxis />
						<Tooltip formatter={formatter} content={<CustomTooltip />} />
						<Bar dataKey='value' fill='#077e9f' />
					</BarChart>
				</ResponsiveContainer>
			</Paper>
		</Grid>
	);
};

export default SalesChart;
