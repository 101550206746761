import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentContainer, Toast, UploadTab } from 'components';
import { Box, AppBar, Tabs, Tab, Divider, Grid, } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import getFormFunctions from 'helpers/form-helpers';
import { validationSchema, defaultValues } from 'pages/Fund/CreateEdit/validation';
import * as fundService from 'services/fund';
import { useLoading } from 'contexts/loading';
import InvolvedPersons from 'pages/Fund/Tabs/involvedPersons';
import { useDropzone } from 'react-dropzone';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import ModalAssign from 'components/Assign/ModalAssign';
import InformationsFund from '../FieldsForm/informationsFund';

function FundEdit(props) {

	const { setTitle } = useHeader();
	const { selectedTenant } = useTenant();
	const history = useHistory();
	const { loading, addLoader, completeLoader } = useLoading();
	const [fund, setFund] = useState();
	const [files, setFiles] = useState([]);
	const [tab, setTab] = useState('Informações');
	const [involvedPersons, setInvolvedPersons] = useState([]);
	const [modalAssign, setModalAssign] = useState(false);
	const { getRootProps, getInputProps } = useDropzone({});
	const { handleSubmit, setValue, watch, getValues, control, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});

	const { errors } = useFormState({ control });

	const { isFieldRequired, inputBind, inputBindNumber } = getFormFunctions({ validationSchema, setValue, control, errors });

	const getFundId = () => props?.location?.search?.replace('?id=', '');

	const isEditing = () => {
		return !!getFundId();
	};

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const fetchFundsById = () => {
		addLoader("fetchFundsById");
		fundService.getFundById(getFundId())
			.then(result => {
				setTitle(`Fundo - ${result.name}`);
				completeLoader("fetchFundsById");
				setFund(result);
				setInvolvedPersons(result?.relatedPersonFund);
				setFiles(result?.relatedDocs || []);
			}).catch(err => completeLoader("fetchFundsById"));
	};

	useEffect(() => {
		if (isEditing()) {
			fetchFundsById();
		} else {
			setTitle("Fundo - novo fundo");
		}
	}, []);

	useEffect(() => {
		if (fund) {
			reset(fund);
		}
	}, [fund]);

	const saveFund = (values) => {

		const option = isEditing() ? 'edit' : 'create';
		let record = { ...values };
		record.relatedDocs = files;
		record.relatedPersonFund = involvedPersons;

		addLoader("saveFund");
		fundService.editOrCreate(record, option, selectedTenant)
			.then(result => {
				completeLoader("saveFund");
				if (isEditing()) {
					fetchFundsById();
				}
				else {
					history.push(`/fundo/editar?id=${result.data}`);
					completeLoader("saveFund");
				}
			})
			.catch(error => {
				completeLoader("saveFund");
				console.log(error);
			});
	};

	const handleModalAssign = () => {
		setModalAssign(true);
	};
	const handleCloseModalAssign = () => {
		setModalAssign(false);
	};

	const actions = isEditing() ? {
		onAssign: handleModalAssign,
		disabledAll: loading
	} : {
	};

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsFund {...{ getFundId, isEditing, fund, inputBind, inputBindNumber, control, setValue, getValues, watch }} />
		},
		{
			name: 'Envolvidos',
			show: true,
			children: <InvolvedPersons {...{ inputBindNumber, isFieldRequired, inputBind, involvedPersons, setInvolvedPersons }} />
		},
		{
			name: 'Documentos',
			show: true,
			children: <UploadTab files={files} setFiles={setFiles} getRootProps={getRootProps} getInputProps={getInputProps} />
		},
	];


	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const onSubmit = (values) => {
		saveFund(values);
	};

	const style = { borderTopLeftRadius: 10, borderTopRightRadius: 7, borderBottomLeftRadius: 10, borderBottomRightRadius: 7, backgroundColor: 'white', padding: 10, margin: 1 };

	return (
		<ContentContainer>
			{modalAssign &&
				<ModalAssign recordType={"Fund"} recordId={getFundId()} onCloseModal={handleCloseModalAssign} />
			}

			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<Grid container direction="row" spacing={4}>
					<Grid item xs={12} lg={6}>
						<ActionBar {...actions} />
					</Grid>
					{isEditing() &&
						<Grid item xs={12} lg={6} className="disablePrint">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{fund?.createdBy && <small style={style}><strong>Criado em:</strong>&nbsp;{fund?.createdAt && new Date(fund?.createdAt).toLocaleDateString('pt-BR')}</small>}
								{fund?.ownerUser && <small style={style}><strong>Operador:</strong>&nbsp;{fund?.ownerUser?.userIdDisplay}</small>}
								{fund?.ownerGroup && <small style={style}><strong>Grupo:</strong>&nbsp;{fund?.ownerGroup?.groupDisplay}</small>}
							</div>
						</Grid>}
				</Grid>

				<AppBar position="relative" color="transparent" elevation={0}>
					<Tabs
						centered={false}
						value={tab}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabs.map((item, index) => (
							item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
						))}
					</Tabs>
				</AppBar>
				{
					tabs.map((item, index) =>
						<TabPanel key={index} value={tab} index={item.name}>
							{item.children}
						</TabPanel>
					)
				}
			</form>
		</ContentContainer>
	);
}

export default FundEdit;
