import axios from 'axios';
import qs from 'query-string';
import { ApiConfig } from 'config';
import { signOut, getToken } from 'repository/cognito';
import { format } from 'date-fns';

const commonAxiosOptions = {
	baseURL: ApiConfig.baseURL,
	paramsSerializer: params => {
		const filteredParams = {};
		Object.keys(params).forEach(key => {
			const value = params[key];
			if (value === null || value === undefined) {
				return;
			}

			if (typeof value.format === 'function' && typeof value.isValid === 'function' && value.isValid()) {
				filteredParams[key] = value.format('YYYY-MM-DD HH:mm:ss');
			} else if (typeof value.getMonth === 'function') {
				filteredParams[key] = format(value, 'yyyy-MM-dd HH:mm:ss');
			} else {
				filteredParams[key] = value;
			}
		});
		return qs.stringify(filteredParams);
	}
};

class ApiClient {
	constructor() {
		this.currentAuthenticatedToken = null;
		this.clientInstances = {
			authenticated: null,
			notAuthenticated: null
		};
	}

	get = (url, params, authenticated = true) => this.executeRequest(url, 'get', params, authenticated)
	postV2 = ({url, params = {}, authenticated = true}) => this.executeRequest(url, 'post', params, authenticated)
	post = (url, params = {}, authenticated = true) => this.executeRequest(url, 'post', params, authenticated)
	put = (url, params = {}) => this.executeRequest(url, 'put', params)
	delete = (url, params = {}) => this.executeRequest(url, 'delete', params)

	executeRequest(url, method, parameters, authenticated = true) {
		const axiosParameters = {
			url,
			method,
			...parameters
		};

		return this.getClient(authenticated)
			.then(client => client(axiosParameters))
			.catch(error => {
				console.error(`An error occurred on ${url}`, error);
				if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
					const errorCode = url === '/user/profile/sale' && method === 'get' ? '1' : '0';
					signOut();
					window.location.href = `/login/${errorCode}`;
				}
				return Promise.reject(error);
			});
	}

	uploadImage(imageFile) {
		var formData = new FormData();
		formData.append('files', imageFile, imageFile.name);
		var instance = axios.create({
			headers: {
				'Content-Type' : 'application/x-www-form-urlencoded'
			},
			...commonAxiosOptions
		});
	 
		return instance.post('/upload/file', formData);
	}

	openCall(url, params, authenticated = true, method = 'get'){
		const axiosParameters = {
			baseURL: ApiConfig.baseURL.replace("v2", "open") + url,
			method,
			...params
		};

		return this.getClient(authenticated)
			.then(client => client(axiosParameters))
			.catch(error => {
				console.error(`An error occurred on ${url}`, error);
				if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
					const errorCode = url === '/user/profile/sale' && method === 'get' ? '1' : '0';
					signOut();
					window.location.href = `/login/${errorCode}`;
				}
				return Promise.reject(error);
			});
	} 
	
	getClient(authenticated) {
		if (authenticated) {
			return getToken()
				.then(token => {
					if (!this.clientInstances.authenticated || token !== this.currentAuthenticatedToken) {
						const authenticatedClientOptions = {
							headers: {
								Authorization: `Bearer ${token}`
							},
							...commonAxiosOptions
						};
						this.clientInstances.authenticated = axios.create(authenticatedClientOptions);
						this.currentAuthenticatedToken = token;
					}
					return this.clientInstances.authenticated;
				});
		} else {
			if (!this.clientInstances.notAuthenticated) {
				this.clientInstances.notAuthenticated = axios.create(commonAxiosOptions);
			}
			return Promise.resolve(this.clientInstances.notAuthenticated);
		}
	}

	extractErrorMessageFromException(exception) {
		return exception && exception.response && exception.response.data && exception.response.data.errors;
	}
}

export default new ApiClient();