import React from 'react';

const BillIcon = () => (
	<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="32" height="32" fill="url(#pattern0)"/>
		<defs>
			<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
				<use href="#image0_55_76" transform="scale(0.03125)"/>
			</pattern>
			<image id="image0_55_76" width="32" height="32" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAQAAACJ4248AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmBwYSASXWP0PrAAAENklEQVRYw61WW0iUQRT+zr+hYVtsV7tIGFtGuSHdqHApSiOinuzBXnbJCLpoED3ZS1CR9SBBN+whKjV6WKICQ8HC2KSwyBbLekjLNQpEMLysRK3N18OUv7/u6v6rB4b/n5lz5vvmnDNzBpxyqamBDZmmP0ND4NevdgzHiKSkABkZds3+Efj2TQy3ezL4VB4P5P37JAn8W4QuF7hhQ3IUMjP1IvPmUeXnm55pbxcJh+MzJ0l2dOhdeL1TnxOlpQl7wJTOTvD168mEBOJ2A+vW2QqBKY2NYvh8k8EnjxwBKiqSJDB6scWLwdxcHc9QaHhcZWVBCgqsym/filFfnyjRhAiA69dDAgHg6lXAJADxeIALFyy6UlEBTCEBcu1aE6SwkGxoEHn0SPdDIeDoUatBa2ui4BMSIKdPB2pqgCVLgE+fALcbCATIVatEPn8W6egAbtywAzhajPG3n5OjwWtrRVauBE+eBN69A3JyNMEtW8jHj61tlEcmF4KuLv1dvZpq505IVZXIlSvm/KJFwJ49Vht7V/q4BMTo7CRv3waKiiD19UA0SpaWily6pD309CmwZo3VqKdnCj0AAIcOgc+eQbZvBwoKgPJyMhgUaW4Wo78fsJd0o2XcHCDnzgX37YO0tYkcPAiePw+IAF4vAFDt3Uv29lqaKi+fQg+kpEDu3QP6+siyMsDv1+O6dsQlrlJTwRUrIAC4YwdVTw/kxQuRjx9j7DJWMaquNufPnrXUFlVbSxpGPGDy4kXyx4+YdUk1NZG5ubYI6PGSEr1AIBAfPCODbGnR9gMDZGUlVUkJld9Pnjljzg0NkceP2yPAzZupnjwhi4tj50paGtnc/P9JRpWePlZHhMrnIwcHSaXI/fsTJjCRUJWVDYPT4RhXl3l5ZDSqwzR79qQJUDmderGBgVg7N4ELC8m0NP1/7drwY2XiJOzuTuzlU1lpkpo50wp+7pzWuX9f97Ozdf/ly8TKMUgwztGTOXMAlwt49UqDezyQujry9Gmgqgq4fh04fBjo7QUvXwYAkQ8fyEgEyMpKzAMqEolP7dQpbXPggO7v3k3+/q1bMKjnvn+nsl7ZZFcX+fNnYh4Qh8Py0rXIwoX6u2yZ3l1dnSZTXQ1s3Qp++QLs2iVGe7s1RPPng+Gw9oCKRPQZb2hIPgdaWkgR0664mHzzhmrBgrGJ6/Npm1u3hCTH7uruXRH9KCVv3gRnzRrfQxs3ApmZoN8vxgjyKjVVjF+/rOBOJyQUApYvB7dtA1V+/nDjiRPJ3QObNpF//uhLJi8vvp7Tqa9yknzwIIZCcheR9tSxY9o2GtXnPDt7ZMy129vatE5rK+lyAYBYFlFeL6SxEYhEwO5uuyQgS5cC08zE5uAgpL8fSE8H/teQhw/BoiIx+vqAuOXY6YQ4nbYJjCE0YwbgcIDhMCQYBO/cEeP585EqfwFXATtrNcIB0wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNy0wNlQxODowMTozNyswMDowMFiFLZUAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDctMDZUMTg6MDE6MzcrMDA6MDAp2JUpAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTA3LTA2VDE4OjAxOjM3KzAwOjAwfs209gAAAABJRU5ErkJggg=="/>
		</defs>
	</svg>
);

export default BillIcon;