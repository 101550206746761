import ApiClient from 'services/apiClient';
import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';

export function getDataByDataset({ data }) {
	const { id, dataset, query, resolve, reject } = data;
	var url = `/risk/dataset/${id}?dataset=${dataset}`;
	if (query !== null && query !== undefined) {
		url += `&q=${query}`;
	}
	try {
		const result = ApiClient.get(url);
		resolve(result.data);
	} catch (exception) {
		reject(exception);
	}
}

export async function addDataSet(entity, id, dataset) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/${entity}/${id}/Dataset/${dataset}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config);
		Toast.showSuccessMessage("Dataset gerado");

		Promise.resolve(result);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err?.response?.data?.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter os dados", err);
		}
		Promise.reject(err);
	}
	return result;
}

export async function getDataSetReport(entity, id, dataset) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/${entity}/${id}/Dataset/${dataset}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		return await axios.get(url, config);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err?.response?.data?.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter os dados", err);
		}
		Promise.reject(err);
	}
	return result;
}

export async function fetchDataSetReport(entity, id, dataset) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/${entity}/${id}/Dataset/${dataset}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config);
		return result;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err?.response?.data?.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter os dados", err);
		}
		throw err;
	}
}

export async function getDatasetsByUserId(entity, id) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/${entity}/${id}/Dataset`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		Promise.resolve(result);
	}
	catch (err) {
		console.log(err);
		if (err?.response?.errors) {
			Toast.showErrorMessage(err.response.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter os dados", err);
		}

		Promise.reject(err);
	}
	return result;
}
