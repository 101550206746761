import { Box } from '@material-ui/core';
import React from 'react';
import CreditDatassetPdf from '../../../components/Datasets/CreditDatassetPdf';
import ScrDataset from '../../../components/Datasets/ScrDataset';
import RequestCreditStatus from '../../../components/RequestCreditStatus/RequestCreditComponent';

function LegalCreditDatasets({ setLoading, personId, loading, person }) {

	const useSCR = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCR_DATASET;
	const usePDF = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_PDF_DATASET;
	const useRequestCredit = !!+window.__RUNTIME_CONFIG__.REACT_APP_REQUEST_CREDIT;

	return (
		<Box py={2}>
			{useRequestCredit && <RequestCreditStatus person={person} />}
			{usePDF && <CreditDatassetPdf setLoading={setLoading} legalPersonId={personId} />}
			{useSCR && <ScrDataset loading={loading} setLoading={setLoading} idPerson={personId} typePerson={'LegalPerson'} />}
		</Box>
	);
}

export default LegalCreditDatasets;