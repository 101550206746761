import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { ContentContainer } from 'components';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { Button, TablePagination, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { removeUserGroupById, getUsersInGroupList, postGroupInsertUser } from 'services/group';
import { useTenant } from 'contexts/tenant';
import { Edit } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import { getOperatorList } from 'services/operator';

const OperatorFields = ({ onRowDataChange, rowData }) => {
	const [searchField, setSearchField] = useState('');
	const [options, setOptions] = useState([]);
	const { selectedTenant } = useTenant();

	const verify = useCallback(
		debounce(name => {
			fetchOperator(name);
		}, 500),
		[]
	);

	useEffect(() => {
		fetchOperator();
	}, []);

	useEffect(() => {
		verify(searchField);
	}, [searchField]);

	const fetchOperator = (name) => {
		getOperatorList({ page: 0, size: 10, name, selectedTenant }).then(resp => {
			setOptions(resp?.data);
		});
	};

	return (
		<Autocomplete
			fullWidth
			noOptionsText={'Nenhum registro foi encontrado.'}
			defaultValue={{ value: rowData.id, name: rowData.name }}
			options={options}
			getOptionLabel={(option) => option.name}
			filterOptions={(options, _) => options}
			renderInput={(params) => <TextField {...params} onChange={(event) => setSearchField(event?.target?.value)} shrink />}
			onFocus={() => setSearchField('')}
			onInputChange={(_, __, reason) => { if (reason === 'clear') setSearchField(''); }}
			onChange={(event, user) => {
				console.log("onChange", event, user);
				onRowDataChange({
					...rowData,
					id: user?.id,
					name: user?.name
				});
			}
			}
		/>);
};

const UsersInGroupList = (props) => {

	const { selectedTenant } = useTenant();
	const { groupNameId } = props;
	const history = useHistory();
	const tableRef = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const [paginationState, setPaginationState] = useState({ 0: null });
	const addOperatorButton = React.useRef(null);
	const [isEditingTable, setIsEditingTable] = React.useState(false);

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		setPaginationState({ 0: null });
		localStorage.setItem('rowsPerPage', page);
	};

	return (
		<ContentContainer>
			<MaterialTable
				tableRef={tableRef}
				title={
					<Button onClick={
						() => {
							addOperatorButton.current.click();
							setIsEditingTable(true);
						}
					} variant="contained" color="primary" disabled={isEditingTable}>
						<Typography component="span" style={{ color: "white", textTransform: 'none', }} >+ operador</Typography>
					</Button>}
				icons={{
					Add: _ => (
						<div ref={addOperatorButton} >
						</div>
					),
					Edit: forwardRef((props, ref) => {
						return (
							<Edit {...props} onClick={() => {
								setTimeout(() => {
									setIsEditingTable(!!isEditingTable);
								}, 10);
							}} ref={ref} />
						);
					}),
				}}
				columns={[
					{
						title: 'Nome', field: 'name',
						editComponent: props => (
							<OperatorFields onRowDataChange={props.onRowDataChange} rowData={props.rowData} />
						)
					},
					{
						title: 'Status', field: 'userStatus',
						render: ({ userStatus }) => <strong className={`uk-text-${userStatus !== 'CONFIRMED' ? 'danger' : 'success'}`}>{userStatus == 'CONFIRMED' ? "Confirmado" : 'Alteração de Senha'}
						</strong>, draggable: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200 },
						editComponent: () => false
					},
					{
						title: 'Data de Criaçao', field: 'userCreateDate',
						render: ({ userCreateDate }) => userCreateDate && new Date(userCreateDate).toLocaleDateString('pt-BR'),
						editComponent: () => false
					}
				].filter(x => x !== undefined)}
				actions={[
					{
						icon: 'search',
						tooltip: 'Editar',
						position: 'row',
						onClick: (_, rowData) => history.push(`/operador/editar?id=${rowData.id}`)
					},
				]}
				editable={{
					onRowAdd: newData =>
						new Promise((resolve, reject) => {
							postGroupInsertUser(groupNameId, newData.id, selectedTenant).then(_ => {
								resolve();
							}).catch(err => { console.log(err); reject(); });
							setIsEditingTable(false);
							resolve();
						}),
					onRowAddCancelled: () => {
						return new Promise(resolve => {
							setIsEditingTable(false);
							resolve();
						});
					},
					onRowDelete: oldData =>
						new Promise(resolve => {
							removeUserGroupById(groupNameId, oldData.id, selectedTenant).then(_ => {
								resolve();
							}).catch(_ => {
								resolve();
							});
						})
				}}
				data={({ page, pageSize }) =>
					new Promise((resolve, reject) => {

						const filters = {
							page,
							size: pageSize,
							nextToken: paginationState[page],
							groupNameId: groupNameId,
							selectedTenant
						};

						getUsersInGroupList(filters)
							.then(result => {
								if (result?.data)
									resolve({
										data: result.data,
										page: result.page,
										totalCount: result.totalItems,
									});
								var paginationToken = result?.paginationToken;
								setPaginationState({ ...paginationState, [page + 1]: paginationToken });

							}).catch(err => reject(err));
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					search: false,
					showFirstLastPageButtons: false
				}}
			/>
		</ContentContainer>
	);
};

export default UsersInGroupList;
