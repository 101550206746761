import React, { useMemo, useState } from 'react';
import { format, subDays, isValid } from "date-fns";
import { Grid, Radio, FormGroup, FormControlLabel } from '@material-ui/core';
import { Row } from "assets/style/styled.components";
import { DatePickerInput } from "components/Input";
import ModalComponent from 'components/Modal/ModalComponent';
import Toast from 'components/Toast/Toast';
import { PeriodModalProps } from './types';
import BankAccountForm from '../../BankAccountForm/bankAccountForm';
import { usePdfOrCsv } from 'contexts/filterPdfAndCsv';

const PeriodFilter = ({ open, onClose, onClick }: PeriodModalProps) => {
	const [initialDate, setInitialDate] = useState<Date>(subDays(new Date(), 15));
	const [finalDate, setFinalDate] = useState<Date>(new Date());
	const [period, setPeriod] = useState<'15' | '30' | '60' | 'custom'>('15');

	const { getSelectedFilter } = usePdfOrCsv();

	const periodFilter = useMemo(() => {

		const filters = {
			15: { initialDate: subDays(new Date(), 15), finalDate: new Date(), period: 'Últimos 15 dias' },
			30: { initialDate: subDays(new Date(), 30), finalDate: new Date(), period: 'Últimos 30 dias' },
			60: { initialDate: subDays(new Date(), 60), finalDate: new Date(), period: 'Últimos 60 dias' },
			custom: { initialDate, finalDate, period: `de ${format(initialDate, 'dd/MM/yyyy')} á ${format(finalDate, 'dd/MM/yyyy')}` },
		};
		return filters[period];
	}, [period, finalDate, initialDate]);

	if (periodFilter.initialDate && periodFilter.finalDate) getSelectedFilter(periodFilter);

	const isInvalidDate = useMemo(() => {
		return period !== 'custom' && !isValid(initialDate) && !isValid(finalDate);
	}, [initialDate, finalDate]);

	function HandleFilter() {
		return (
			<>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormGroup>
							<FormControlLabel
								control={
									<Radio id='radio15' checked={period === '15'} onClick={() => setPeriod('15')} />}
								label="Últimos 15 dias" />
						</FormGroup>
					</Grid>
					<Grid item xs={12}>
						<FormGroup>
							<FormControlLabel
								control={
									<Radio id='radio30' checked={period === '30'} onClick={() => setPeriod('30')} />}
								label="Últimos 30 dias" />
						</FormGroup>
					</Grid>
					<Grid item xs={12}>
						<FormGroup>
							<FormControlLabel
								control={
									<Radio id='radio60' checked={period === '60'} onClick={() => setPeriod('60')} />}
								label="Últimos 60 dias" />
						</FormGroup>
					</Grid>
					<Grid item xs={12}>
						<FormGroup>
							<FormControlLabel
								control={
									<Row>
										<Radio id='radioCustom' checked={period === 'custom'} onClick={() => setPeriod('custom')} />
									</Row>}
								label="Período" />
						</FormGroup>
					</Grid>

					{period === 'custom' &&
						<div className='uk-grid-small uk-child-width-1-2@s' data-uk-grid>
							<div>
								<div className='uk-form-controls'>
									<Grid item xs={12}>
										<DatePickerInput
											disabled={period !== 'custom'}
											label='Data Inicial'
											placeholder='dd/mm/aaaa'
											value={initialDate}
											type='dd/MM/yyyy'
											onChange={(date: Date) => setInitialDate(date)}
										/>
									</Grid>
								</div>
							</div>
							<div>
								<div className='uk-form-controls'>
									<Grid item xs={12}>
										<DatePickerInput
											disabled={period !== 'custom'}
											label='Data Final'
											placeholder='dd/mm/aaaa'
											value={finalDate}
											type='dd/MM/yyyy'
											onChange={(date: Date) => setFinalDate(date)}
										/>
									</Grid>
								</div>
							</div>
						</div>}
				</Grid>
				<br />
				<div style={{
					borderBottom: '3px solid #e9ecef',
					borderTopLeftRadius: '0.3rem',
					borderTopRightRadius: '0.3rem',
					padding: '0.75rem 1.25rem',
				}} />
			</>
		);
	}

	return (
		<ModalComponent
			disabled={isInvalidDate}
			open={open}
			onClose={() => onClose()}
			onClick={() => { Toast.showSuccessMessage("Período de extrato atualizado com sucesso."); onClick(periodFilter); onClose(); }}
			title="Filtrar Resultados"
			subtitle="Preencha um dos parâmetros abaixo para filtrar"
			children={<HandleFilter />}
			buttonText="Filtrar"
		/>
	);
};

export default PeriodFilter;
