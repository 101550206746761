import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';
import { initial, pickBy } from 'lodash';
import Helper from 'helpers/message.helpers';

export async function getBankAccountRequestList(filters, tenant) {

	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));

	delete params.orderByField;
	delete params.orderByDirection;

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountRequest`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de solicitações de abertura de conta", err);
		}
		throw err;
	}
}

export async function getBankAccountRequestById(id) {

	if (!id) {
		throw new Error('id not provided');
	}

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountRequest/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {

		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter as informações de abertura de conta digital criada");
		}
		throw err;
	}
}

export async function editOrCreate(data, option) {

	var relatedPersonData = data.relatedPerson;
	let newRelaredPerson = relatedPersonData;
	newRelaredPerson = newRelaredPerson?.map(x => {
		if (x?.participationPercentage) {
			x.participationPercentage = x.participationPercentage.toString().replace(",", ".");
		}
		if (x?.isSigner) {x.isSigner = x.isSigner === "Sim" ? true : x.isSigner === "Não" ? false : x.isSigner;}
		return x;
	});

	var payload = {
		...data,
		relatedPerson: data?.relatedPerson ? newRelaredPerson : null,
	};

	var url = payload.id ?
		ApiConfig.baseURL + `/BankAccountRequest/${data.id}` :
		ApiConfig.baseURL + `/BankAccountRequest`;

	var token = await getToken();
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(url, payload, config),
			successMessage: 'Conta bancaria cadastrada com sucesso',
			errorMessage: 'Ocorreu um erro ao cadastrar a solicitação de conta digital'
		},
		edit: {
			function: () => axios.put(url, payload, config),
			successMessage: 'Conta bancaria atualizada com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar a solicitação de conta digital'
		}
	};

	let result;

	try {

		result = await request[option].function();
		Toast.showSuccessMessage(request[option].successMessage);
		return result;

	}
	catch (err) {
		if (err.response) {
			if (err.response?.data.message) {
				Toast.showErrorMessage(err?.response.data?.errors[0]?.message);
			} else if (err.response?.data?.errors) {
				Toast.showErrorMessage(err?.response.data?.errors);
			} else if (err.response?.data) {
				Toast.showErrorMessage(err?.response.data);
			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}
		throw err;
	}
}

export async function approveBankAccountById(id, message) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountRequest/${id}/approve`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, { message }, config),
		Toast.showSuccessMessage("Conta digital aprovada com sucesso.");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao aprovar a conta digital");
		}
		throw err;
	}
}


export async function disapprovedBankAccountById(id, params) {


	var { message, reasonDisapproval, blockType, blockUntil } = params;

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountRequest/${id}/disapprove`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, { message, reasonDisapproval, blockType, blockUntil }, config);
		Toast.showSuccessMessage("Conta digital reprovada com sucesso.");
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao reprovar a conta digital");
		}
		throw err;
	}
}

export async function sumbitApprovalById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountRequest/${id}/submitapproval`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config),
		Toast.showSuccessMessage("Conta digital enviada para aprovação.");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao enviar a conta digital para aprovação.");
		}
		throw err;
	}
}

export async function approvalRevisionById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountRequest/${id}/approvalRevision`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config),
		Toast.showSuccessMessage("Revisão de solicitação de conta concluída");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao concluir revisão de solicitação de conta.");
		}
		throw err;
	}
}

export async function getReasonList() {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountRequestBlockReason`;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de Motivos para reprovação", err);
		}
		throw err;
	}
}

export async function manualUpdateBankAccount(id, data, tenant) {
	const token = await getToken();
	const url = ApiConfig.baseURL + `/BankAccountRequest/${id}/manualUpdate`;
	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	let result;

	try {
		result = await axios.post(urlWithTenant, data, config);
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage([err, "Não foi possível atualizar a operação"]);
		}
		console.log(err);
		throw err;
	}
}

export async function deleteBankAccount(id){
	
	const token = await getToken();
	const url = ApiConfig.baseURL + `/BankAccountRequest/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	let result;

	try {
		result = await axios.delete(url, config);
		return result.data;

	}
	catch (err) {
		Helper.parseErrors("Não foi possível deletar a conta digital", err);
		throw err;
	}
}

export async function bankAccountPostCNAB240(bankAccountId, initialDate, finalDate) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Cnab/Cnab240?bankAccountId=${bankAccountId}&initialDate=${initialDate}&finalDate=${finalDate}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let result;
	try {
		result = await axios.post(url, {}, config);
		Toast.showSuccessMessage('Arquivo CNAB 240 exportado com sucesso');
		return result; 
	} catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage('Não foi possível exportar o arquivo CNAB 240', err);
		}
		throw err;
	}
}

