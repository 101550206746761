import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { MenuItem, Select, SelectProps, TablePagination } from "@material-ui/core";
import * as bankAccountOperatorService from "services/digitalBankAccount";
import * as servicesOperator from "services/digitalBankAccount";
import { BeneficiaryType, ParamsDelete, ParamsApproveOrReject, ParamsCancel } from "global/types/digitalAccount.types";
import { localizationOptions } from "helpers/table.helpers";
import { ContentContainer, Toast } from 'components';
import { useParams } from 'react-router-dom';
import { ModalProps, RouteWithIdParamsType } from 'global/types/types';
import HelperStatus from 'helpers/message.helpers';
import HasPassCode from '../../Code/codeForm';
import { useUser } from 'contexts/user';
import Button from 'components/Button/Button';
import FilterListIcon from "@material-ui/icons/FilterList";
import { optionsStatusFiltering } from '../../OptionsFields/options.status';
import { optionsLevelPermission } from '../../OptionsFields/options.level';
import { IMFAState } from 'global/interface/types';
import ApproversDetails from 'components/ApproversDetails/ApproversDetails';


//@ts-ignore
const BankAccountOperatorList = (props) => {
	const { setModalProps, handleOpen, handleClose, tableRef } = props;
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const { user } = useUser();
	const [username, setUsername] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState<number>(Number(localStorage.getItem('rowsPerPage')) || 5);
	const [enableFilter, setEnableFilter] = useState(false);
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	const stateRef = React.useRef<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	stateRef.current = modalFormData;
	const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
	const cognitoClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID;

	useEffect(() => {
		//@ts-ignore
		if (user?.authenticated == true) setUsername(user['payload']['cognito:username']);
	}, [user]);

	const onRowsPerPageChange = (page: number) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', String(page));
	};

	const defaultModalProps = {
		title: 'Atualizar nível de autorização do operador',
		subtitle: '',
		onClick: () => { },
		buttonText: "Confirmar",
		children: <HasPassCode setFormData={(data: IMFAState) => { setModalFormData(data); }} />
	} as ModalProps;

	const defaultDetailsModalProps = {
		enableButton: false,
	} as ModalProps; 

	const handleDelete = async (userId: string) => {
		let modalData = stateRef.current;
		await bankAccountOperatorService.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				userId,
				sessionId,
				passCode: modalData.code,
				path: "Operator"
			} as ParamsDelete;
			bankAccountOperatorService.deleteList(data).then(() => {
				Toast.showSuccessMessage("Operador excluído com sucesso!");
				tableRef.current.onQueryChange();
			}).finally(() => handleClose());
		});
	};

	const handleApprove = async (paymentId: string) => {
		let modalData = stateRef.current;
		await servicesOperator.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				payload: {
					action: "Approve", sessionId: sessionId, code: modalData.code
				},
				paymentId,
				path: "Operator"
			} as ParamsApproveOrReject;

			servicesOperator.approveOrReject(data).then(() => Toast.showSuccessMessage("Operador aprovado com sucesso!"))
				.finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	const handleReject = async (paymentId: string) => {
		let modalData = stateRef.current;
		await servicesOperator.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				payload: {
					action: "Reject", sessionId: sessionId, code: modalData.code
				},
				paymentId,
				path: "Operator"
			} as ParamsApproveOrReject;

			servicesOperator.approveOrReject(data).then(() => Toast.showSuccessMessage("Operador reprovado com sucesso!"))
				.finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	const handleCancel = async (beneficiaryId: string) => {
		let modalData = stateRef.current;
		await servicesOperator.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				beneficiaryId,
				sessionId,
				passCode: modalData.code,
				path: "Operator"
			} as ParamsCancel;

			servicesOperator.cancel(data).then(() => {
				Toast.showSuccessMessage("Operador cancelado com sucesso!");
				servicesOperator.getBankAccountOperatorList(bankAccountId, { page: 0, size: 10, isDeleted: false });
			}).finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	return (
		<ContentContainer>
			<MaterialTable
				style={{ paddingLeft: 20, paddingRight: 20, maxWidth: '91vw' }}
				tableRef={tableRef}
				title={
					<Button
						variant="outlined"
						onClick={() => setEnableFilter(!enableFilter)}
						children={"Operadores"}
						color="default"
						startIcon={<FilterListIcon />}
					/>
				}
				//@ts-ignore
				columns={[
					{ title: "Nome", field: "user.userIdDisplay" },
					{
						title: 'Nível',
						field: 'levelDisplay',
						render: ({ levelDisplay }: { user: any; levelDisplay: string }) => levelDisplay,
						filterComponent: (props: JSX.IntrinsicAttributes & SelectProps) => <Select
							{...props}
							fullWidth
							//@ts-ignore
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
						>
							<MenuItem value={""}>Todos</MenuItem>
							{optionsLevelPermission && optionsLevelPermission.map(field => (
								<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
							))}
						</Select>
					},
					{
						title: 'Status',
						field: 'statusDisplay',
						render: ({ statusDisplay }: BeneficiaryType) => statusDisplay,
						//@ts-ignore
						cellStyle: (_, { statusDisplay }: BeneficiaryType) => ({ color: HelperStatus.statusTheme(statusDisplay) }),
						filterComponent: (props: JSX.IntrinsicAttributes & SelectProps) => <Select
							{...props}
							fullWidth
							//@ts-ignore
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
						>
							<MenuItem value={""}>Todos</MenuItem>
							{optionsStatusFiltering && optionsStatusFiltering.map(field => (
								<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
							))}
						</Select>
					},
				].filter(item => item != undefined)}
				actions={[
					(rowData:any) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "loupe",
							tooltip: 'Detalhes',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: async () => {
								setModalProps(
									{
										...defaultDetailsModalProps,
										title: 'Detalhes da Aprovação',
										subtitle: "",
										children: <ApproversDetails data={rowData} />
									}
								);
								handleOpen();
							}
						};
					},
					(rowData:any) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "thumb_up",
							tooltip: 'Aprovar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: async (_, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										onClick: () => handleApprove(rowData.id),
									}
								);
							}
						};
					},
					(rowData:any) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "thumb_down",
							tooltip: 'Reprovar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: async (_, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										title: 'Reprovar Operador',
										subtitle: 'Tem certeza que deseja  reprovar o operador?',
										onClick: () => handleReject(rowData.id),
									}
								);

							}
						};
					},
					(rowData:any) => {
						return {
							iconProps: { style: { color: '#ff0000' } },
							icon: "cancel",
							tooltip: 'Cancelar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: (_, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										title: 'Cancelar Operador',
										subtitle: 'Tem certeza que deseja cancelar o operador?',
										onClick: () => handleCancel(rowData.id),
									}
								);
							}
						};
					}
				]}
				editable={{
					onRowDelete: oldData =>
						new Promise(async resolve => {
							handleOpen();
							setModalProps(
								{
									...defaultModalProps,
									title: "Remover operador",
									subtitle: 'Tem certeza que deseja remover o operador da conta?',
									//@ts-ignore
									onClick: () => handleDelete(oldData?.id)
								}
							);
							resolve('');
						})
				}}
				data={(allParams) =>
					new Promise((resolve, reject) => {
						const { page, pageSize, search, filters, orderBy, orderDirection } = allParams;

						const filtersValues = {
							f_userUserId: enableFilter && filters.find(f => f.column.field === 'user.userIdDisplay')?.value,
							f_level: enableFilter && filters.find(f => f.column.field === 'levelDisplay')?.value,
							f_status: enableFilter && enableFilter && filters.find(f => f.column.field === 'statusDisplay')?.value,
							page,
							size: pageSize,
							searchString: search,
							orderByField: orderBy?.field,
							orderByDirection: orderDirection,
							isDeleted: false
						};

						bankAccountOperatorService.getBankAccountOperatorList(bankAccountId, filtersValues)
							.then(result => {
								if (result?.data)
									resolve({
										data: result.data,
										page: result.page,
										totalCount: result.totalItems,
									});
							}).catch(err => reject(err));
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				//@ts-ignore
				onRowsPerPageChange={onRowsPerPageChange}
				localization={{
					...localizationOptions,
					header: {
						actions: 'Ações'
					}
				}}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					filtering: enableFilter
				}}
			/>
		</ContentContainer>
	);
};

export default BankAccountOperatorList;
