import React, { useState } from 'react';
import { DatePicker, ContentContainer } from 'components';
import { Box, Grid, Button } from '@material-ui/core';
import { getValidationSchema, defaultValues } from './validation';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from 'helpers/form-helpers';
import { Uploads } from './uploads';
import { useDropzone } from 'react-dropzone';
import ActionBar from 'components/ActionBar/ActionBar';
import { postCNAB } from 'services/cteBatch';
import { useHeader } from 'contexts/header';
import SelectComponent from 'components/Select/Select';

function CTE(props) {

	const [loading, setLoading] = useState(false);
	const { setTitle } = useHeader();
	const { getRootProps, getInputProps } = useDropzone({});
	const { setValue, control, handleSubmit, getValues } = useForm({
		resolver: yupResolver(getValidationSchema),
		defaultValues: defaultValues
	});
	const { errors } = useFormState({ control });
	const { inputBind } = getFormFunctions({ validationSchema: getValidationSchema, setValue, control, errors });
	const files = getValues('uploads');
	setTitle("Documentos CT-e");

	return (
		<ContentContainer>
			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form style={{ margin: "10px" }}
				onSubmit={handleSubmit(values => {
					setLoading(true);
					postCNAB(values).then(result => {
						setLoading(false);
						return window.open(result.data.tempUrl, "_blank");
					});
				})}
			>
				<ActionBar />
				<br />
				<Grid container spacing={2} style={{ margin: "15px" }}>
					<Grid item xs={3}>
						<DatePicker
							id="dueDate"
							label="Data de vencimento"
							variant="inline"
							name="dueDate"
							fullWidth
							InputLabelProps={{ shrink: true, required: true }}
							{...inputBind("dueDate")}
							disablePast={true} />						
					</Grid>
					<Grid item xs={3}>
						<SelectComponent
							id="operation"
							label="Tipo de arquivo"							
							name="operation"
							fullWidth
							fields={[{ name: "Remessa", value: "0"}, { name: "Baixa", value: "1"}]}
							{...inputBind("operation")} />						
					</Grid>
				</Grid><Box style={{ marginTop: "22px", padding: "9px" }}>
					<Grid>
						<Uploads
							files={files}
							errors={errors['uploads']}
							accept=".xml"
							title="Documentos CT-e"
							setFiles={(files) => {
								setValue('uploads', files);
							}}
							getRootProps={getRootProps}
							getInputProps={getInputProps} />
					</Grid>
				</Box>
			</form>
		</ContentContainer>
	);
}

export default CTE;