import { IProps } from 'global/interface/types';
import { ThemeProps } from 'global/types/theme.types';
import React, { createContext, useContext, useState } from 'react';

type HexDigit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
type HexColor<T extends string> =
	T extends `#${HexDigit}${HexDigit}${HexDigit}${infer Rest1}`
	? (Rest1 extends ``
		? T // three-digit hex color
		: (
			Rest1 extends `${HexDigit}${HexDigit}${HexDigit}`
			? T  // six-digit hex color
			: never
		)
	)
	: never;

function hex<T extends string>(s: HexColor<T>): T {
	return s;
}

const defaultTheme: ThemeProps = {
	palette: {
		primary: {
			lightest: hex('#E9D4C4'),
			light: hex('#ECA973'),
			medium: hex('#F18B3A'),
			dark: hex('#F46C00'),
			darkest: hex('#D04D27'),
		},
		neutral: {
			lightest: hex('#FFFFFF'),
			light: hex("#BABFD0"),
			medium: hex('#666666'),
			dark: hex('#373737'),
			darkest: hex('#000000')
		},
		support: {
			highlight: {
				lightest: hex('#ACABF0'),
				medium: hex('#7471FD'),
				light: hex('#FFB547'),
				dark: hex('#FF9800'),
				darkest: hex('#C77700')
			},
			danger: {
				light: hex('#F88078'),
				dark: hex('#F44336')
			},
			success: {
				light: hex('#7BC67E'),
				dark: hex('#4CAF50')
			}
		}
	},
	colors: {
		primary: hex('#F18B3A'),
		secondary: hex('#F46C00')
	},
	typography: {
		fontWeight: {
			bold: 700,
			regular: 300,
			medium: 500

		},
		fontSize: {
			xxxs: "12px",
			xxs: "16px",
			xs: "20px",
			sm: "24px",
			md: "28px",
			lg: "32px",
			xl: "36px",
			xxl: "48px",
		},
		fontFamily: {
			base: 'Roboto',
			highlight: 'Roboto'
		}
	},
	spacing: {
		spacingInline: {
			xxxs: "4px",
			xxs: "8px",
			xs: "16px",
			sm: "24px",
			md: "32px",
			lg: "48px",
			xl: "64px",
		},
		spacingStack: {
			xxxs: "4px",
			xxs: "8px",
			xs: "16px",
			sm: "24px",
			md: "32px",
			lg: "48px",
			xl: "64px",
			xxl: "96px",
			xxxl: "128px",
		},
		spacingInset: {
			xxs: "4px",
			xs: "8px",
			sm: "16px",
			md: "24px",
			lg: "32px",
			xl: "48px"
		}
	},
};

type GlobalContent = {
	theme: ThemeProps
	setTheme: () => void
};

const ThemeContext = createContext<GlobalContent>({ theme: defaultTheme, setTheme: () => { } });

export const ThemeProvider = ({ children }: IProps) => {
	const [theme, setTheme] = useState<ThemeProps>(defaultTheme);

	return (
		<ThemeContext.Provider value={{
			theme: theme,
			setTheme: () => setTheme
		}}>
			{children}
		</ThemeContext.Provider>
	);
};

export function useTheme() {
	const context = useContext(ThemeContext);
	const {
		theme,
		setTheme
	} = context;
	return {
		theme,
		setTheme
	};
}
