import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentContainer, Toast } from 'components';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import getFormFunctions from 'helpers/form-helpers';
import { validationSchema, defaultValues } from './validationSchema';
import { useLoading } from 'contexts/loading';
import { useHeader } from 'contexts/header';
import InformationsGroup from '../FieldsForm/informationsGroup';
import * as groupServices from 'services/group';
import UsersInGroupList from '../UsersInGroup';
import PermissionsList from '../Permissions';
import { useTenant } from 'contexts/tenant';
import * as permissionServices from 'services/permission';

function GroupEdit(props) {

	const history = useHistory();
	const { selectedTenant } = useTenant();
	const { loading, addLoader, completeLoader } = useLoading();
	const [group, setGroup] = useState();
	const [tab, setTab] = useState('Informações');
	const { setTitle } = useHeader();
	const { handleSubmit, setValue, control, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});
	const [permissionData, setPermissionData] = useState([]);
	const getOperatorId = () => window?.location?.search?.replace('?id=', '');
	const getGroupId = () => props?.location?.search?.replace('?id=', '');

	const isEditing = () => {
		return !!getGroupId();
	};

	const { errors } = useFormState({ control });
	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema, setValue, control, errors });

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const fetchGroupById = () => {
		addLoader("fetchGroupById");
		groupServices.getGroupById(getGroupId(), selectedTenant).then(resp => {
			setTitle(`Grupo - ${resp?.groupName}`);
			setGroup(resp);
			completeLoader("fetchGroupById");
		}).catch(err => { console.log('Erro:', err), completeLoader("fetchGroupById"); });
	};

	useEffect(() => {
		if (isEditing()) {
			fetchGroupById();
		} else {
			setTitle("Grupo - novo grupo");
		}
	}, []);

	useEffect(() => {
		if (group) {
			reset(group);
		}
	}, [group]);

	const saveGroup = (values) => {

		const option = isEditing() ? 'edit' : 'create';
		addLoader("saveGroup");
		groupServices.editOrCreate(values, option, getGroupId(), selectedTenant)
			.then(result => {
				if (result.data.groupName) {
					completeLoader("saveGroup");
					history.push(`/grupo/editar?id=${result.data.groupName}`);
				} else {
					completeLoader("saveGroup");
					fetchGroupById();
				}
			})
			.catch(error => {
				console.log(error);
				completeLoader("saveGroup");
			});
	};

	const handleSavePermission = () => {
		addLoader("handleSavePermission");
		permissionServices.postPermissionList(permissionData, getGroupId(), selectedTenant, "Group").then(_ => {
			addLoader("handleSavePermission");
			permissionServices.getPermissionList(getGroupId(), selectedTenant, "Group").then((permissions) => {
				setPermissionData(permissions);
				completeLoader("handleSavePermission");
			});
		}).catch(err => { console.log(err); completeLoader("handleSavePermission"); });
	};


	const actions = isEditing() ? {
		disabledAll: loading,
		onSavePermission: handleSavePermission,
	} : {
	};

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsGroup {...{ isEditing, group, inputBind, inputBindNumber }} />
		},
		isEditing() ? {
			name: 'Membros',
			show: true,
			children: <UsersInGroupList {...{ isEditing, groupNameId: getGroupId(), group }} />
		} : null,
		isEditing() ? {
			name: 'Permissões',
			show: true,
			children: <PermissionsList  {...{ userId: getOperatorId(), permissionData, setPermissionData }} />
		} : null,
	].filter(tab => tab != null);

	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const onSubmit = (values) => {
		saveGroup(values);
	};

	return (
		<ContentContainer>

			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<ActionBar {...actions} />
				<AppBar position="relative" color="transparent" elevation={0}>
					<Tabs
						centered={false}
						value={tab}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabs.map((item, index) => (
							item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
						))}
					</Tabs>
				</AppBar>
				{
					tabs.map((item, index) =>
						<TabPanel key={index} value={tab} index={item.name}>
							{item.children}
						</TabPanel>
					)
				}
			</form>
		</ContentContainer>
	);
}

export default GroupEdit;
