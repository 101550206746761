export const optionsInsertBlock = [
	{
		name: 'Não',
		value: null
	},
	{
		name: 'Tomador',
		value: "Person"
	},
	{
		name: 'Operação',
		value: "Operation"
	}
];