import 'uikit/dist/js/uikit.min';
import 'uikit/dist/css/uikit.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.css';
import 'assets/css/style.css';
import './components/ModalScan3/style.scss';
import Provider from 'provider/provider';

export default function AppRouter() {

	return (<Provider />);
}