import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';

export async function postCNAB(data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CTEBatch`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, { ...data, uploads: data.uploads.map(file => file.fileName) }, config),
		Toast.showSuccessMessage("Arquivo CNAB enviado com sucesso");
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo CNAB");
		}
		throw err;

	}
}