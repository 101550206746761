import { isEmpty, get } from 'lodash';

const isFieldRequired = (validationSchema: any) => (field: string) => {
	var hierarchy = field.split(".");
	let fieldSchema = validationSchema.describe();
	for (let index = 0; index < hierarchy.length; index++) {
		const element = hierarchy[index];
		fieldSchema = fieldSchema?.fields[element];
	}
	return fieldSchema?.tests.findIndex(({ name }: { name: string }) => name === 'required') >= 0;
};

const inputBind = (control: any, errors: any, validationSchema: string, readOnly: any) => (name: string) => {
	return { name, control, errors: get(errors, name), required: isFieldRequired(validationSchema)(name), disabled: readOnly };
};

const inputBindNumber = (control: any, errors: any, validationSchema: string, setValue: any, readOnly: any) => (name: string) => {
	return { control, errors: get(errors, name), required: isFieldRequired(validationSchema)(name), disabled: readOnly, onValueChange: (event: any) => { onChangeNumber(setValue)(name, event); } };
};

const onChangeNumber = (setValue: (arg0: any, arg1: number) => void) => (name: any, event: { floatValue: any; }) => {
	setValue(name, Number(event.floatValue));
};

export default function getFormFunctions(props: { validationSchema: any; setValue: any; control: any; errors: any; readOnly?: any; }) {

	const { validationSchema, control, errors, setValue, readOnly } = props;
	return {
		isFieldRequired: isFieldRequired(validationSchema),
		inputBind: inputBind(control, errors, validationSchema, readOnly),
		inputBindNumber: inputBindNumber(control, errors, validationSchema, setValue, readOnly),
		onChangeNumber: onChangeNumber(setValue),
	};
};