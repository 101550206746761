import React,{ useState } from 'react';
import { ContentContainer } from 'components';
import ModalComponent from 'components/Modal/ModalComponent';
import InformationsFavored from './FieldsForm/informationsFavored';
import FavoredList from './List/favoredList';

const AccountFavoreds = () => {
	const [open, setOpen] = useState(false);
	const tableRef = React.useRef(null);
	const [modalProps, setModalProps] = useState({
		title: "",
		subtitle: "",
		onClick: () => { },
		buttonText: "",
		children: <></>
	});

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<ContentContainer>
			<ModalComponent
				open={open}
				onClose={handleClose}
				{...modalProps}
			/>
			<InformationsFavored tableRef={tableRef}/>
			<FavoredList setModalProps={setModalProps} handleOpen={handleOpen} handleClose={handleClose} tableRef={tableRef}/>
		</ContentContainer>
	);
};

export default AccountFavoreds;
