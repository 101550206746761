import React, { useState } from 'react';
import { ContentContainer } from 'components';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { TablePagination, Button, Typography } from '@material-ui/core';
import { deleteProductById, getProductsList } from 'services/product';
import { Link, useHistory } from 'react-router-dom';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import Helper from 'helpers/format.helpers';
import ActionBar from 'components/ActionBar/ActionBar';
import FilterListIcon from '@material-ui/icons/FilterList';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { TableContainer } from 'containers/TableContainer';

const ProductList = (props) => {

	const { hideActions } = props;
	const history = useHistory();
	const tableRef = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const [enableFilter, setEnableFilter] = useState(false);
	const { setTitle } = useHeader();
	const [selectedRows, setSelectedRows] = useState([]);
	const { selectedTenant } = useTenant();

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const handleDelete = async () => {
		selectedRows.map(row => {
			deleteProductById(row?.id)
				.then(_ => {
					tableRef.current.onQueryChange();
				})
				.catch(err => {
					tableRef.current.onQueryChange();
				});
		});
		setSelectedRows([]);
	};

	const actions = {
		onRefresh: () => tableRef?.current?.onQueryChange(),
		onDelete: selectedRows.length > 0 && handleDelete,
	};

	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';

	return (
		<ContentContainer>
			{<ActionBar {...actions} hideSubmit={true} />}
			{!hideActions && <>
				<div className='uk-width-auto@m uk-width-1-1'>
					<div className='uk-width-auto@m uk-width-1-1'>
						<Link to='/produto/novo' style={{ backgroundColor: '#3f51b5', textDecoration: "none" }} className='wt-button wt-button-lowercase uk-button'>
							<i className='fa fa-plus uk-margin-small-right' style={{ color: "white" }}></i>
							<Typography component="span" style={{ color: "white", textTransform: 'none', }}>Adicionar novo produto</Typography>
						</Link>
						<Button style={{ marginLeft: 10 }} onClick={() => setEnableFilter(!enableFilter)} >
							<FilterListIcon /> Filtrar
						</Button>
					</div>
				</div>
				<br />
			</>
			}
			<TableContainer>
				<MaterialTable
					tableRef={tableRef}
					title='Produtos'
					columns={[
						isRootTenancy ? { title: 'Correspondente', field: 'tenantDisplay', filtering: false } : undefined,
						{ title: 'Nome', field: 'name' },
						{ title: 'Sistema de amortização', field: 'amortizationType', render: ({ amortizationTypeDisplay }) => amortizationTypeDisplay },
						{
							title: 'Custo de Emissao', field: 'bankComission', filtering: false,
							render: ({ bankComission }) => { return bankComission?.type == 0 ? Helper.formatCurrencyAsIs(bankComission?.amount) : Helper.formatPercentageAsIs(bankComission?.amount); }
						},
						{
							title: 'Criado em', field: 'createdAt',
							render: ({ createdAt }) => createdAt && new Date(createdAt).toLocaleDateString('pt-BR'),
							filterComponent: (props) => <KeyboardDatePicker
								{...props}
								format="dd/MM/yyyy"
								InputLabelProps={{ shrink: true }}
								placeholder="dd/mm/aaaa"
								variant="inline"
								value={props?.columnDef?.tableData?.filterValue || null}
								disableFuture={true}
								onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e)}
								helperText={false}
							/>
						}
					].filter(x => x !== undefined)}
					actions={[
						{
							icon: 'search',
							tooltip: 'Editar',
							position: 'row',
							onClick: (_, rowData) => history.push(`/produto/editar?id=${rowData.id}`)
						},
					]}
					editable={{
						onRowDelete: oldData =>
							new Promise(resolve => {
								deleteProductById(oldData.id).then(_ => {
									resolve();
								}).catch(err => {
									resolve();
								});
							})
					}}
					data={(allParams) =>
						new Promise((resolve, reject) => {
							const { page, pageSize, search, filters } = allParams;

							const createdAt = filters.find(f => f.column.field === 'createdAt')?.value;

							const filterFinalDate = enableFilter &&
								createdAt &&
								moment(createdAt).isValid() &&
								moment(createdAt).set("hour", "23").set("minute", "59")?._d;

							const filterInitialDate =
								enableFilter &&
								createdAt &&
								moment(createdAt).isValid() &&
								moment(createdAt).set("hour", "0").set("minute", "0")?._d;

							const filtersValues = {
								name: enableFilter && filters.find(f => f.column.field === 'name')?.value,
								amortizationType: enableFilter && filters.find(f => f.column.field === 'amortizationType')?.value,
								initialDate: filterInitialDate,
								finalDate: filterFinalDate,
								page,
								size: pageSize,
								searchString: search
							};

							getProductsList(filtersValues, selectedTenant)
								.then(result => {
									setTitle("Produtos");
									if (result?.data)
										resolve({
											data: result.data,
											page: result.page,
											totalCount: result.totalItems,
										});
									else {
										resolve({
											data: [],
											page: 0,
											totalCount: 0,
										});
									}
								}).catch(err => reject(err));
						})
					}
					onChangeRowsPerPage={onRowsPerPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
					localization={localizationOptions}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								rowsPerPageOptions={[5, 10, 20, 50]}
							/>
						),
					}}
					options={{
						search: false,
						actionsColumnIndex: -1,
						pageSize: rowsPerPage,
						debounceInterval: 500,
						searchAutoFocus: true,
						filtering: enableFilter
					}}
				/>
			</TableContainer>
		</ContentContainer>
	);
};

export default ProductList;

