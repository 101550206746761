import React, { useState } from "react";
import { ContentContainer } from "components";
import InformationsTransfer from "./FieldsForm/informationTransfer";
import ModalComponent from "components/Modal/ModalComponent";
import TransferList from "./List/transferList";

//@ts-ignore
const AccountTransfers = () => {
	const [open, setOpen] = useState(false);
	const tableRef = React.useRef(null);
	const [modalProps, setModalProps] = useState({
		title: "",
		subtitle: "",
		onClick: () => { },
		buttonText: "",
		children: <></>
	});

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	return (
		<ContentContainer>
			<ModalComponent
				open={open}
				onClose={handleClose}
				{...modalProps}
			/>
			<InformationsTransfer tableRef={tableRef} />
			<TransferList setModalProps={setModalProps} handleOpen={handleOpen} handleClose={handleClose} tableRef={tableRef} />
		</ContentContainer >
	);
};

export default AccountTransfers;