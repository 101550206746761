import React from 'react';
import {
	Grid, Box, Paper, makeStyles,
	createStyles
} from "@material-ui/core";
import { CardIcon } from 'components';
import Helper from 'helpers/format.helpers';
import { ChartLineUpIcon, ChartLineDownIcon } from "../../../assets";


const message = `Truncation should be conditionally applicable on this long line of text
 as this is a much longer line than what the container can support. `;

const lineUpIcon = <ChartLineUpIcon className="chart-line-up chart-line-up_increase" />;
const lineDownIcon = <ChartLineDownIcon className="chart-line-down chart-line-down_decrease" />;

const colorIncrease = "#6EC202";
const colorDecrease = "#FF8049";

const DashboardHeader = ({ revenue = 0.0, saleCount = 0, mediumTicket = 0.0, reversal = 0 }) => {	
	const useStyles = makeStyles(() =>
		createStyles({
			root: {
				flexGrow: 1,
				margin: "10px 10px",
			},
			cardContainer: {
				height: "100px",
				borderRadius: "24px",
				top: "120px",
			},
		})
	);
	const classes = useStyles();

	return (
		<Box>
			<Grid container wrap="nowrap" spacing={2}>
				<Grid item xs={4}>
					<Paper className={classes.cardContainer} elevation={0}>
						<CardIcon symbol="R$" label="Valor operado" icon={lineUpIcon} color={colorIncrease} value={Helper.formatCurrency(revenue).replace("R$", "")} />
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper className={classes.cardContainer} elevation={0}>
						<CardIcon symbol="" label="Número de operações" icon={lineDownIcon} color={colorDecrease} value={saleCount} />
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper className={classes.cardContainer} elevation={0}>
						<CardIcon symbol="R$" label="Ticket Médio" icon={lineUpIcon} color={colorIncrease} value={Helper.formatCurrency(mediumTicket).replace("R$", "")} />
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper className={classes.cardContainer} elevation={0}>
						<CardIcon symbol="" label="Canceladas" icon={lineDownIcon} value={reversal} color={colorDecrease} />
					</Paper>
				</Grid>
			</Grid>
		</Box >
	);
};

export default DashboardHeader;