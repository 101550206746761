import qs from 'query-string';
import moment from 'moment';

const QueryParameterType = {
	NUMBER: 0,
	DATE_TIME: 1,
	STRING: 2
};

function extractQueryParamsFromSearch(search, fields) {
	const result = {};
	const queryParams = qs.parse(search);
	Object.keys(fields).forEach(fieldName => {
		const queryValue = queryParams[fieldName];
		if (queryValue === undefined || queryValue === null || queryValue.length === 0) {
			return;
		}

		const fieldType = fields[fieldName];
		if (fieldType === QueryParameterType.STRING || fieldType === QueryParameterType.NUMBER_ARRAY) {
			result[fieldName] = queryValue;
		} else {
			if (fieldType === QueryParameterType.NUMBER) {
				const numericResult = queryValue * 1;
				if (isNaN(numericResult)) {
					return;
				}
				result[fieldName] = numericResult;
			} else {
				const dateValue = new Date(queryValue);
				if (!isNaN(dateValue.getTime())) {
					result[fieldName] = moment(dateValue);
				}
			}
		}
	});

	return result;
}

function saveQueryParamsToSearch(history, location, fields, values) {
	const queryToSave = {};
	Object.keys(fields).forEach(fieldName => {
		const value = values[fieldName];
		if (value === undefined || value === null || value.length === 0) {
			return;
		}

		const fieldType = fields[fieldName];
		if (fieldType === QueryParameterType.DATE_TIME && typeof value.format === 'function') {
			queryToSave[fieldName] = value.toISOString();
		} else {
			queryToSave[fieldName] = value;
		}
	});


	const searchResult = qs.stringify(queryToSave);
	history.replace({
		pathName: location.pathname,
		search: `?${searchResult}`
	});
}

export {
	QueryParameterType,
	extractQueryParamsFromSearch,
	saveQueryParamsToSearch
};