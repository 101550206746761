import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';
import { pickBy } from 'lodash';

const COMMISSION_PERCENTAGE = 1;
const COMMISSION_ABSOLUTE = 0;

export async function getCreditNoteById(id) {

	if (!id) {
		throw new Error('id not provided');
	}

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return axios.get(url, config).then(({ data }) => {
		if (data) {
			data.amortization.unitPrice = data.amortization?.unitPrice / 100;
			data.amortization.administrationFeeIncents = data.amortization?.administrationFeeIncents / 100;
			data.amortization.realStatevalue = data.amortization?.realStatevalue / 100;
			data.amortization.physicalDamageInsurance = data.amortization?.physicalDamageInsurance * 100;
			data.amortization.deathOrPermanentIncapabilityInsurance = data.amortization?.deathOrPermanentIncapabilityInsurance * 100;
			data.invoiceValue = data.invoiceValue / 100;
			data.amortization.requestedAmount = data.amortization?.requestedAmount / 100;
			data.amortization.indexerValue = data?.amortization?.indexerValue * 100;
			data.amortization.apr = data.amortization?.apr * 100;
			data.amortization.absAmortizationInMonths = data.amortization?.absAmortizationInMonths;
			data.amortization.absInterestInMonths = data.amortization?.absInterestInMonths;
			data.amortization.fixedPaymentDuringGraduation = data.amortization?.fixedPaymentDuringGraduation / 100; // from cents do decimal			
			if (data?.amortization?.agentCommission?.amount && data?.amortization?.agentCommission?.type == COMMISSION_PERCENTAGE) {
				data.amortization.agentCommission = {
					type: "Percentage",
					amount: data?.amortization?.agentCommission?.amount * 100,
					baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
				};
			}
			if (data?.amortization?.agentCommission?.amount && data?.amortization?.agentCommission?.type == COMMISSION_ABSOLUTE) {
				data.amortization.agentCommission = {
					type: "Absolute",
					amount: data?.amortization?.agentCommission?.amount / 100,
					baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
				};
			}

			if (data.warranty) {
				data.warranty.forEach(warranty => {
					if (warranty.warrantyType === "JudiciaryProcess") warranty.valueOfCause = warranty?.valueOfCause / 100;
				});
			}
		}
		return { data };
	});

}

export async function getCreditNoteList(filters, tenant) {

	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));

	delete params.orderByField;
	delete params.orderByDirection;

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de operações", err);
		}
		throw err;
	}
}

export async function sendWebCredCreditNote(id, tenant) {
	const token = await getToken();
	const url = ApiConfig.baseURL + `/WebCred/${id}`;
	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	let result;

	try {
		result = await axios.post(urlWithTenant, {}, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage([err, "Não foi possível integrar a operação"]);
		}
		console.log(err);
		throw err;
	}
}

export async function manualUpdateCreditNote(id, data, tenant) {
	const token = await getToken();
	const url = ApiConfig.baseURL + `/CreditNote/${id}/manualUpdate`;
	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	let result;

	try {
		result = await axios.post(urlWithTenant, data, config);
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage([err, "Não foi possível atualizar a operação"]);
		}
		console.log(err);
		throw err;
	}
}

export async function sendWebCred(id) {
	const token = await getToken();
	const url = ApiConfig.baseURL + `/WebCred/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	let result;

	try {
		result = await axios.post(url, {}, config);
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage([err, "Não foi possível integrar a operação"]);
		}
		console.log(err);
		throw err;
	}
}

export async function editOrCreateCreditNote(data, option, tenant) {

	var payload = {
		...data,
		amortization: {
			...data.amortization,
			agentCommission: { ...data.amortization.agentCommission }, 
			indexerValue: data?.amortization?.indexerValue
		},
	};

	payload.paymentScheduleItems = null;
	payload.amortization.startDate = payload?.amortization?.startDate;
	if (payload?.amortization?.startDate) {
		payload?.amortization?.startDate.setHours(12);
	};

	payload.invoiceValue = payload?.invoiceValue * 100;
	payload.amortization.requestedAmount = payload?.amortization?.requestedAmount * 100;
	payload.amortization.apr = payload?.amortization?.apr / 100;
	payload.amortization.fixedPaymentDuringGraduation = payload?.amortization?.fixedPaymentDuringGraduation * 100;
	payload.amortization.unitPrice = payload?.amortization?.unitPrice * 100;
	payload.amortization.administrationFeeIncents = payload?.amortization?.administrationFeeIncents * 100;
	payload.amortization.realStatevalue = payload?.amortization?.realStatevalue * 100;
	payload.amortization.physicalDamageInsurance = payload?.amortization?.physicalDamageInsurance / 100;
	payload.amortization.deathOrPermanentIncapabilityInsurance = payload?.amortization?.deathOrPermanentIncapabilityInsurance / 100;
	payload.amortization.absAmortizationInMonths = payload?.amortization?.absAmortizationInMonths;
	payload.amortization.absInterestInMonths = payload?.amortization?.absInterestInMonths;
	if (payload?.amortization?.agentCommission?.amount && payload?.amortization?.agentCommission?.type == "Percentage") {
		payload.amortization.agentCommission.amount = (payload?.amortization?.agentCommission.amount ?? 0) / 100;
	}

	if (payload?.amortization?.agentCommission?.amount && payload?.amortization?.agentCommission?.type == "Absolute") {
		payload.amortization.agentCommission.amount = (payload?.amortization?.agentCommission.amount ?? 0) * 100;
	}

	if (payload.warranty) {
		payload.warranty.forEach(warranty => {
			if (warranty.warrantyType === "JudiciaryProcess") warranty.valueOfCause = warranty?.valueOfCause * 100;
		});
	}

	var request = {
		create: {
			successMessage: 'Operação cadastrada com sucesso',
			errorMessage: 'Ocorreu um erro ao cadastrar a operação'
		},
		edit: {
			successMessage: 'Operação atualizada com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar a operação'
		}
	};

	var token = await getToken();
	var url = payload.id ?
		ApiConfig.baseURL + `/CreditNote/${payload.id}` :
		ApiConfig.baseURL + `/CreditNote`;

	var urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	var prom = payload.id ? axios.put(urlWithTenant, payload, config) : axios.post(urlWithTenant, payload, config);

	return prom.then(data => {
		return [data, request[option].successMessage];
	}).catch(err => {
		return Promise.reject([err, request[option].errorMessage]);
	});
}

export async function cloneCreditNote(data, tenant) {

	const filter = ['status', 'paymentScheduleItems', 'timeline', 'creditNoteNo', 'id', 'uploads'];

	filter.forEach(key => delete data[key]);

	const payload = {
		...data,
		amortization: {
			...data.amortization,
			requestedAmount: data.amortization.requestedAmount * 100,
			apr: data.amortization.apr / 100,
			agentCommission: {
				amount: data?.amortization?.agentCommission?.type == 'Percentage' ? (data?.amortization?.agentCommission.amount ?? 0) / 100 : (data?.amortization?.agentCommission.amount ?? 0) * 100,
				type: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
				baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
			}
		},
	};

	const request = {
		successMessage: 'Operação duplicada com sucesso',
		errorMessage: 'Ocorreu um erro ao duplicar a operação'
	};

	const token = await getToken();
	const url = ApiConfig.baseURL + `/CreditNote`;

	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	let result;

	try {
		result = await axios.post(urlWithTenant, payload, config);
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage([err, request['errorMessage']]);
		}
		throw err;
	}
}


export async function deleteCreditNoteById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await (await axios.delete(url, config)).data;
		Toast.showSuccessMessage("Operação excluída com sucesso.");

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao excluir e empresa");
		}

		return Promise.reject(err);

	}
	return result;
}

export async function sendAssignmentEmailCreditNoteById(id, email) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/sendassignmentemail?sendOnlyTo=` + email;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config);
		Toast.showSuccessMessage("E-mail enviado com sucesso.");
		Promise.resolve(result);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao enviar o e-mail", err);
		}
		Promise.reject(err);

	}
	return result;
}

export async function draftPreviewCreditNoteById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/draftpreview?useWatermark=false`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config);
		Toast.showSuccessMessage("Contrato gerado com sucesso.");
		Promise.resolve(result);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter os dados", err);
		}
		Promise.reject(err);

	}
	return result;
}

export async function assignmentPreviewCreditNoteById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/assignmentpreview?useWatermark=false`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config);
		Toast.showSuccessMessage("Contrato de cessão gerada com sucesso.");
		Promise.resolve(result);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter os dados", err);
		}
		Promise.reject(err);

	}
	return result;
}

export async function generateBatchAssignment(data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/BatchAssignment`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Cessão em lote iniciada com sucesso.");
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Existem operações sem fundo", err);
		}
		throw err;
	}
}

export async function generateVortx(data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/vortx`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Remessa Vortx iniciada com sucesso.");
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Existem operações sem fundo", err);
		}
		throw err;
	}
}

export async function sumbitApprovalCreditNoteById(id) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/submitapproval`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}; 
	
	let result;

	try {
		result = await (await axios.post(url, {}, config)).data;
		Toast.showSuccessMessage("Operação enviada para aprovação com sucesso.");

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao enviar a operação para aprovação.");
		}
		return Promise.reject(err);

	}
	return result;
}

export async function sumbitSendNotificationCreditNoteById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/sendNotification`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await (await axios.post(url, {}, config)).data;
		Toast.showSuccessMessage("Signatário enviado com sucesso.") ;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao enviar signatário.");
		}
		return Promise.reject(err);

	}
	return result;
}

export async function sumbitCredPayOperationWebhookById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditCardOperations/${id}`; 
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await (await axios.post(url, {}, config)).data;
		Toast.showSuccessMessage("Pagamento verificado com sucesso.") ;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível verificar pagamento.");
		}
		return Promise.reject(err);

	}
	return result;
}

export async function approveCreditNoteById(id, status, message) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/approve${status.replace('Approval', '')}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await (await axios.post(url, { message }, config)).data;
		Toast.showSuccessMessage("Operação aprovada com sucesso.");

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao aprovar a operação");
		}
		return Promise.reject(err);

	}
	return result;
}

export async function disapproveCreditNoteById(id, data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/disapprove`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Operação reprovada com sucesso.");
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao reprovar a operação");
		}
		throw err;
	}
}

export async function signatureCreditNoteById(id, reject, message) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/signature`;
	if (reject) {
		url = url + `?reprove=true`;
	}
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, { message }, config);
		Toast.showSuccessMessage(`Etapa de assinatura da operação ${reject ? "reprovada" : "aprovada"} com sucesso`);
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage(`Ocorreu um erro ao ${reject ? "reprovar" : "aprovar"} assinaturas da operação`);
		}
		throw err;
	}
}

export async function cancelCreditNoteById(id, data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/cancel`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Operação cancelada com sucesso.");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao finalizar a operação");
		}
		throw err;

	}
}

export async function approveliquidCreditNoteById(id, data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/approveLiquidation`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Operação aprovada com sucesso.");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao aprovar a operação");
		}
		throw err;
	}
}

export async function paymentRevisionCreditNoteById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/donePaymentRevision`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config);
		Toast.showSuccessMessage("Revisão concluída com sucesso.");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao concluir a revisão");
		}
		throw err;
	}
}

export async function restoreCreditFromDeleted(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/restore`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config);
		Toast.showSuccessMessage("Operação restaurada com sucesso!");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao restaurar a operação.");
		}
		throw err;
	}
}

export const postBankAccountStatementExportPfd = async (accountId, initialDate, finalDate) => {
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/statement`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		responseType: 'blob',
		params: {
			initialDate, 
			finalDate
		}, 
	};

	try {
		const response = await axios.post(url, {}, config);
		const { data } = response;
		return data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao exportar PDF.");
		}
		throw err;
	}
};


export const postBankAccountStatementSpreadsheet = async (accountId, initialDate, finalDate) => {
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/statementspreadsheet`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		responseType: 'blob',
		params: {
			initialDate, 
			finalDate
		}, 
	};
	
	try {
		const response = await axios.post(url, {}, config);
		const { data } = response;
		return data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao exportar planilha.");
		}
		throw err;
	}
};

export async function updateFiles(id, files) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditNote/${id}/upload`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.put(url, { uploads: files }, config);

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo", err);
		}
		throw err;
	}
}

export async function generatePaymentLink(data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditCardOperations/generatePaymentLink`;
	
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	try {
		return await axios.post(url, data, config);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter o link", err);
		}
		throw err;
	}
}

export async function getCosifAccountTransaction() {

	var token = await getToken();
	var url = ApiConfig.baseURL + '/CosifAccount?transactionType=Transfer';
	
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	try {
		return await axios.get(url, config);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		}
		throw err;
	}
}