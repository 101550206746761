import React, { useEffect } from 'react';
import { useHeader } from 'contexts/header';
import CreditNoteListComponent from 'components/CreditNoteList';

export default () => {
	const { setTitle } = useHeader();

	useEffect(() => {
		setTitle("Crédito");
	}, []);
	return (
		<CreditNoteListComponent
			initialDate={null}
			finalDate={null}
		 />
	);	
};
