import { Box } from '@material-ui/core';
import React from 'react';
import KYCComponent from '../Tabs/KYCComponent';
import Compliance from '../Tabs/compliance';

function KYC({ bankAccountId,  isEditing, setLoading, involvedPersons, taker }) {	
	return (
		<>
			<Box py={2}>
				<Compliance  bankAccountId={bankAccountId} setLoading={setLoading} involvedPersons={involvedPersons} taker={taker} />
			</Box>
			<Box py={2}>
				<KYCComponent isEditing={isEditing} setLoading={setLoading} involvedPersons={involvedPersons} taker={taker} />
			</Box></>
	);
}

export default KYC;