import { Box, MenuItem, TextField, TablePagination } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { Select, FormHelperText, makeStyles } from '@material-ui/core';
import * as uploadService from 'services/files';
import * as Icons from "@material-ui/icons";
import Toast from 'components/Toast/Toast';

const baseStyle = {
	cursor: 'pointer',
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

const useHelperTextStyles = makeStyles(() => ({
	root: {
		color: 'red'
	}
}));


export function Uploads(props) {

	const [data, setData] = useState([]);
	const helperTextStyles = useHelperTextStyles();
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const { files, setFiles, errors, showDropzone = true, accept = "image/*,.pdf,.doc,.docx,.xlsx,.xsl" } = props;

	useEffect(() => {
		setFiles(data);
	}, [data]);
	
	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		accept,
		onDrop: async acceptedFiles => {
			acceptedFiles.map(async file => {
				Toast.showSuccessMessage("Iniciando upload");
				let responseUrlSigned = await uploadService.getSignedUrl(file.name).then(response => {
					return response?.data;
				});
				await uploadService.uploadFile(file, responseUrlSigned.putUrl).then(_ => {
					Toast.showSuccessMessage("Upload concluído");
					setData(prevData => [
						...prevData, {
							id: null,
							displayName: file.name,
							tempUrl: responseUrlSigned.getUrl,
							fileName: responseUrlSigned.filename,
						},
					]);
				});
			});
		}
	});
	
	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const table = <MaterialTable
		title={props.title || "Documentos"}
		columns={[
			{
				title: 'Nome',
				field: 'displayName',
				cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 300 }
			},
		]}
		editable={{
			onRowDelete: (rowData) =>
				new Promise(resolve => {			
					let currentFiles = [...data];
					let index = currentFiles.map(f => f.fileName).indexOf(rowData.fileName);
					currentFiles.splice(index, 1);
					var newState = [...currentFiles];
					setData([
						...newState
					]);							
					resolve();
				})
		}}
		data={data}
		localization={localizationOptions}
		onChangeRowsPerPage={onRowsPerPageChange}
		onRowsPerPageChange={onRowsPerPageChange}
		components={{
			Pagination: props => (
				<TablePagination
					{...props}
					rowsPerPageOptions={[5, 10, 20, 50]}
				/>
			),
		}}
		options={{
			actionsColumnIndex: -1,
			pageSize: rowsPerPage,
			searchAutoFocus: true,
			addRowPosition: 'row',
		}}

	/>;

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]);

	return (
		<>
			{showDropzone && <Box p={1}>
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Arraste e solte os arquivos dos CT-es para carregar, ou clique para escolher</p>
				</div>
			</Box>}
			{errors && <FormHelperText classes={{ root: helperTextStyles.root }}>{errors.message}</FormHelperText>}
			<Box p={1}>
				{table}
			</Box>
		</>
	);
}

export default function DynamicIcon({ iconName }) {
	const Icon = Icons[iconName];
	return Icon ? <><Icon /><span>{iconName}</span></> : <span>{iconName}</span>;
};
