import { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from 'contexts/user';
import { getMyPermissionList } from 'services/permission';
import { IProps } from 'global/interface/types';

type GlobalPermissionsContent = {
	permissions: [],
	setPermissions: () => void,
	getMyPermissionLevel: (resource: string, permission: string) => string | null
};

const PermissionsContext = createContext<GlobalPermissionsContent>({ permissions: [], setPermissions: () => { }, getMyPermissionLevel: () => "" });

export const MyPermissionsProvider = ({ children }: IProps) => {
	const [permissions, setPermissions] = useState<[]>([]);
	const { user } = useUser();

	useEffect(() => {
		if (!user?.authenticated) {
			setPermissions([]);
			return;
		}
		getMyPermissionList().then((response: any) => {
			return setPermissions(response);
		});
	}, [user]);

	const getMyPermissionLevel = (resource: string, permission: string) => {

		var prop = `${permission}Permission`;
		var perm = permissions?.find((p: any) => p.resource == resource);
		if (perm == null || perm[prop] == "None") return null;
		return perm[prop];
	};

	return (
		<PermissionsContext.Provider
			value={{
				permissions: permissions,
				setPermissions: () => setPermissions,
				getMyPermissionLevel: (resource: string, permission: string) => {
					return getMyPermissionLevel(resource, permission);
				}
			}}
		>
			{children}
		</PermissionsContext.Provider>
	);
};

export function useMyPermissions() {
	const context = useContext(PermissionsContext);
	const {
		permissions,
		getMyPermissionLevel
	} = context;
	return {
		permissions,
		getMyPermissionLevel
	};
}
