import { object, string } from "yup";

export const validationSchema = object().shape({

	users: object().shape({
		userId: string().typeError("Operador: Precisa ser preenchido.").required("Operador: Precisa ser preenchido."),
		level: string().typeError("Nível de Permissão: Precisa ser preenchido.").required("Nível de Permissão: Precisa ser preenchido.")
	}),
	sessionId: string().nullable(),
	code: string().nullable(),

});

export const defaultValues = {
	users: null,
	sessionId: "",
	code: ""
};