
import React, { useEffect, useState } from "react";
import {
	Box,
	Grid,
	TextField,
	FormGroup,
	FormControlLabel,
} from "@material-ui/core";
import { Select } from "components";
import TextFieldComponent from "components/TextInput/TextField";
import Helper from "helpers/format.helpers";
import { Controller } from "react-hook-form";
import CheckboxComponent from "components/CheckBox/Checkbox";
import AutocompleteWithSearch from "components/AutocompleteSearch/AutocompleteSearch";
import { useProduct } from "contexts/product";
import { useFund } from "contexts/fund";
import { usePerson } from "contexts/person";
import { Link } from "react-router-dom";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import NumberInput from "components/NumberInput/NumberInput";
import ModalComponent from "components/Modal/ModalComponent";
import { getProductById } from "services/product";
import { useTenant } from "contexts/tenant";
import SelectComponent from "components/Select/Select";
import CustomCheckbox from "components/CustomCheckBox";

function InformationsCredit({
	inputBind,
	inputBindNumber,
	creditNote,
	control,
	watch,
	setValue,
	getValues,
	readOnly,
	bankAccountPerson,
	bankAccountBeneficiary,
	setInvolvedPersons
}) {
	const product = watch("product");
	const [toggleModal, setToggleModal] = useState(false);
	const [beneficiaryModalData, setBeneficiaryModalData] = useState([]);	
	const useCDC = product?.isConsumerCreditNote;
	const isFund = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_FUND;
	const ammType = product?.amortizationType?.toLowerCase();
	const { getProductsList, productOptionsList } = useProduct();
	const { getFundsList, fundOptionsList } = useFund();
	const { getPersonsList, personOptionsList } = usePerson();
	const { selectedTenant } = useTenant();
	const [agioDesagio, setAgioDesagio] = useState();

	const hasFund = getValues("fundId");


	useEffect(() => {
		const collateral = watch("collateral");

		if (!collateral) {
			setValue(
				"collateral",
				product?.useCollateral ? product.defaultCollateralDescription : ""
			);
		}
	}, [product]);

	const getAllBeneficiaries = async () => {
		let productId = watch("productId");
		await getProductById(product?.value ?? productId, selectedTenant).then((result) => {
			const isBeneficiary = result?.relatedPerson?.filter(item => item?.typeOfRelationship === "Beneficiary");
			const isNotBeneficiary = result?.relatedPerson?.filter(item => item?.typeOfRelationship !== "Beneficiary");
			setInvolvedPersons(isNotBeneficiary);
			if (useCDC) {
				if (isBeneficiary?.length >= 2 && product?.name !== undefined) {
					setBeneficiaryModalData(isBeneficiary);
					handleToggleModal();
				} else if (isBeneficiary?.length === 1) {
					setValue("beneficiaryDiscriminator", isBeneficiary[0]?.discriminator);
					setValue("beneficiaryId", isBeneficiary[0]?.personId);
					setValue("beneficiaryDisplay", isBeneficiary[0]?.personIdDisplay);
				}
			}
		});
	};

	const changeCheckBoxValue = (event) => {
		const value = event.target.checked;
		const name = event.target.name;
		setValue(name, value);
	};

	const handlePersonLink = () => {
		const personId = watch("personId");
		if (watch("personDiscriminator") === "LegalPerson") {
			return `/pessoa-juridica/editar?id=${personId}`;
		} else return `/pessoa-fisica/editar?id=${personId}`;
	};

	const handleProductLink = () => {
		const productId = watch("productId");
		return `/produto/editar?id=${productId}`;
	};

	const handleFundLink = () => {
		const fundId = watch("fundId");
		return `/fundo/editar?id=${fundId}`;
	};

	const handleBeneficiaryLink = () => {
		const personId = watch("beneficiaryId");
		if (watch("personDiscriminator") === "LegalPerson") {
			return `/pessoa-juridica/editar?id=${personId}`;
		} else return `/pessoa-fisica/editar?id=${personId}`;
	};

	const getBankAccountFields = (array) => {
		return array?.map(item => Helper.formatBankAccountDetails(item));
	};

	const bankAccountList =
		useCDC == true && getValues("beneficiaryId")
			? bankAccountBeneficiary
			: bankAccountPerson;

	const handleToggleModal = () => {
		setToggleModal(!toggleModal);
	};

	
	const showCheckBoxValue = (event) => {
		const value = event.target.checked;
		const name = event.target.name;
		setValue(name, value);
		if(value === false) {
			setValue("assignmentCalculation.type", null);
			setValue("assignmentCalculation.amount", null);
		} 
	}; 

	const hasType = getValues("assignmentCalculation.type") !== null;
	const hasAmount = getValues("assignmentCalculation.amount") !== null;

	useEffect(() => {
		if(hasFund){
			if(hasType && hasAmount) {
				setAgioDesagio(true);
				setValue("assignmentCalculation.type", watch("assignmentCalculation.type"));
				setValue("assignmentCalculation.amount", watch("assignmentCalculation.amount"));
			} else {
				setAgioDesagio(watch("agioDesagio") ? true : false);
				setValue("assignmentCalculation.type", null);
				setValue("assignmentCalculation.amount", null);
			}
		}
		
	}, [hasFund, agioDesagio, watch("agioDesagio")]);

	return (
		<>
			<Grid>
				{toggleModal === true && (
					<ModalComponent
						open={toggleModal}
						onClose={handleToggleModal}
						title="Selecionar beneficiário"
						subtitle="Qual dos beneficiários abaixo gostaria de vincular à essa operação?"
						enableButton={false}
						children={<>
							<div>
								{beneficiaryModalData.map(item => {
									return (
										<div style={{ display: 'flex', justifyContent: "space-between", marginBottom: "18px" }}>
											<p style={{ margin: 0 }}>
												{item.personIdDisplay}
											</p>
											<button
												style={{ border: "none", backgroundColor: "transparent", cursor: "pointer", }}
												onClick={() => {
													setValue("beneficiaryDiscriminator", item?.discriminator);
													setValue("beneficiaryId", item?.personId);
													setValue("beneficiaryDisplay", item?.personIdDisplay);
													handleToggleModal();
												}}>
												Vincular
											</button>
										</div>);
								})}
							</div>
						</>}
					/>
				)}
				<Box py={3}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<AutocompleteWithSearch
								id="productId"
								name="productId"
								displayField="productIdDisplay"
								fullWidth
								label="Produtos"
								watch={watch}
								disabled={readOnly}
								setValue={setValue}
								fetch={getProductsList}
								options={productOptionsList}
								rowData={{
									productId: creditNote?.productId,
									productIdDisplay: creditNote?.productIdDisplay,
								}}
								params={{
									page: 0,
									size: 10,
								}}
								{...inputBind(`productId`)}
								endAdornment={
									<Link to={handleProductLink}>
										<SearchOutlinedIcon position="end" />
									</Link>
								}
								showEndAdornment={
									!!+window.__RUNTIME_CONFIG__.REACT_APP_PRODUCT_MENU &&
									watch("productId")
								}
								onChange={(_, options) => {

									setValue("product", options);
									setValue("productId", options?.value || "");
									setValue("productIdDisplay", options?.name || "");
									let amortizationType = options?.amortizationType;
									setValue(
										"amortization.amortizationType",
										amortizationType?.toLowerCase()
									);
									if (!useCDC) {
										setValue("sameBeneficiary", true);
										setValue("beneficiaryId", null);
										setValue("beneficiaryIdDisplay", null);
										setValue("beneficiaryDiscriminator", null);
									}
									getAllBeneficiaries();
								}}
							/>
						</Grid>
						{isFund && (
							<Grid item xs={6}>
								<AutocompleteWithSearch
									id="fundId"
									name="fundId"
									displayField="fundIdDisplay"
									fullWidth
									label="Fundo Cessionário"
									watch={watch}
									disabled={readOnly}
									setValue={setValue}
									rowData={{
										fundId: creditNote?.fundId,
										fundIdDisplay: creditNote?.fundIdDisplay,
									}}
									params={{
										page: 0,
										size: 10,
									}}
									fetch={getFundsList}
									options={fundOptionsList}
									{...inputBind(`fundId`)}
									endAdornment={
										<Link to={handleFundLink}>
											<SearchOutlinedIcon position="end" />
										</Link>
									}
									showEndAdornment={
										!!+window.__RUNTIME_CONFIG__.REACT_APP_FUND_MENU &&
										watch("fundId")
									}
									onChange={(_, options) => {
										setValue("fundId", options?.value || "");
										setValue("fundIdDisplay", options?.name || "");
									}}
								/>
							</Grid>
						)}
						<Grid item xs={6}>
							<TextFieldComponent
								id="creditNoteNo"
								fullWidth
								name="creditNoteNo"
								label="Número de Emissão"
								placeholder="0100"
								InputLabelProps={{
									shrink: false,
								}}
								component={TextField}
								value={Helper.formatDocumentNumber(
									creditNote?.creditNoteNo ?? ""
								)}
								{...inputBind(`creditNoteNo`)}
								disabled={true}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid>
				<Box py={3}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<AutocompleteWithSearch
								id="personId"
								name="personId"
								fullWidth
								displayField="personDisplay"
								label="Tomador"
								watch={watch}
								disabled={readOnly}
								setValue={setValue}
								rowData={{
									personId: creditNote?.personId,
									personIdDisplay: creditNote?.personIdDisplay,
									discriminator: creditNote?.personDiscriminator,
								}}
								params={{
									page: 0,
									size: 10,
								}}
								fetch={getPersonsList}
								options={personOptionsList}
								{...inputBind("personId")}
								endAdornment={
									<Link to={handlePersonLink}>
										<SearchOutlinedIcon position="end" />
									</Link>
								}
								showEndAdornment={watch("personId")}
								onChange={(_, options) => {
									setValue("personId", options?.value || "");
									setValue("personDisplay", options?.name);
									setValue("personDiscriminator", options?.discriminator);
									if (watch("sameBeneficiary") && !useCDC) {
										setValue("beneficiaryId", options?.value || "");
										setValue("beneficiaryDisplay", options?.name);
										setValue(
											"beneficiaryDiscriminator",
											options?.discriminator
										);
									}
								}}
							/>
						</Grid>
						{useCDC && (
							<Grid item xs={6}>
								<AutocompleteWithSearch
									id="beneficiaryId"
									displayField="beneficiaryDisplay"
									name="beneficiaryId"
									fullWidth
									label="Beneficiário"
									watch={watch}
									disabled={readOnly}
									setValue={setValue}
									rowData={{
										personId: watch("beneficiaryId"),
										personIdDisplay: watch("beneficiaryDisplay"),
										discriminator: watch("beneficiaryDiscriminator"),
									}}
									params={{
										page: 0,
										size: 10,
									}}
									endAdornment={
										<Link to={handleBeneficiaryLink}>
											<SearchOutlinedIcon position="end" />
										</Link>
									}
									showEndAdornment={watch("beneficiaryId")}
									fetch={getPersonsList}
									options={personOptionsList}
									{...inputBind("beneficiaryId")}
									fieldToClearOnChange="bankAccountId"
									onChange={(_, options) => {
										setValue("beneficiaryId", options?.value || "");
										setValue("beneficiaryDisplay", options?.name);
										setValue(
											"beneficiaryDiscriminator",
											options?.discriminator
										);
										setValue("bankAccountId", null);
									}}
								/>
							</Grid>
						)}
						<Grid item xs={6}>
							<Select
								id="liquidationType"
								name="liquidationType"
								label="Tipo de Liquidação"
								fullWidth
								defaultValue=""
								{...inputBind(`liquidationType`)}
								fields={[
									{ name: "Boleto", value: "Invoice" },
									{ name: "Transferência (TED ou PIX)", value: "EletronicTransfer" }]}
							/>
						</Grid>
						{watch("liquidationType") == "Invoice" && (
							<Grid item xs={6}>
								<TextFieldComponent
									id="invoiceBarCode"
									fullWidth
									name="invoiceBarCode"
									label="Linha Digitável"
									component={TextField}
									placeholder="00000.00000 00000.000000 00000.000000 0 00000000000000"
									{...inputBind(`invoiceBarCode`)}
									required={true}
								/>
							</Grid>
						)}
						{watch("liquidationType") != "Invoice" && (
							<Grid item xs={6}>
								<Select
									id="bankAccountId"
									name="bankAccountId"
									fullWidth
									label="Conta de liquidação"
									defaultValue=""
									loading={watch("bankAccountListLoading")}
									disabled={
										!watch("beneficiaryId") ||
										readOnly ||
										watch("bankAccountListLoading")
									}
									fields={getBankAccountFields(bankAccountList)}
									{...inputBind(`bankAccountId`)}
									required={true}
								/>
							</Grid>
						)}
					</Grid>
					<Box py={3}>
						<Grid container spacing={2}>
							{useCDC && (
								<>
									<Grid item xs={3}>
										<TextFieldComponent
											id="invoiceNumber"
											fullWidth
											name="invoiceNumber"
											label="Nº da NF/Contrato"
											type="Number"
											component={TextField}
											{...inputBind(`invoiceNumber`)}
										/>
									</Grid>
									<Grid item xs={3}>
										<NumberInput
											id="invoiceValue"
											label="Valor da NF/Contrato"
											fullWidth
											name="invoiceValue"
											adornmentText="R$"
											{...inputBindNumber(`invoiceValue`)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Select
											id="changeBankAccountId"
											name="changeBankAccountId"
											fullWidth
											label="Conta para Troco"
											loading={watch("bankAccountListLoading")}
											defaultValue=""
											disabled={readOnly}
											fields={getBankAccountFields(bankAccountPerson)}
											{...inputBind(`changeBankAccountId`)}
										/>
									</Grid>
								</>
							)}
							{ammType != "commercialpapper" && (
								<>
									<Grid item xs={2}>
										<FormGroup>
											<FormControlLabel
												control={
													<CheckboxComponent
														id="isRegistered"
														color="primary"
														name="isRegistered"
														onClick={changeCheckBoxValue}
														inputProps={{ "aria-label": "secondary checkbox" }}
														{...inputBind("isRegistered")}
													/>
												}
												label="Registradora de Recebíveis"
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={2}>
										<FormGroup>
											<FormControlLabel
												control={
													<CheckboxComponent
														id="bulkAssignment"
														color="primary"
														name="bulkAssignment"
														onClick={changeCheckBoxValue}
														inputProps={{ "aria-label": "secondary checkbox" }}
														{...inputBind("bulkAssignment")}
													/>
												}
												label="Cessão em lote"
											/>
										</FormGroup>
									</Grid>
								</>
							)}
						</Grid>
					</Box>
				</Box>

				{hasFund &&
					<Grid item xs={12} sm={3}>
						<FormControlLabel
							control={
								<CustomCheckbox
									id="agioDesagio"
									color="primary"
									name="agioDesagio"
									customOnChange={showCheckBoxValue}
									inputProps={{ "aria-label": "secondary checkbox" }}
									{...inputBind("agioDesagio")}
									bool={hasType && hasAmount}
								/>
							}
							label="Incluir Ágio/Deságio na Cessão"
						/>
					</Grid>
				}
				<br />
				{agioDesagio === true ?
					<Grid container spacing={2}>
						<Grid item xs={12} sm={3}>
							<SelectComponent
								id='assignmentCalculation.type'
								name='assignmentCalculation.type'
								label='Tipo do cálculo'
								fullWidth 
								defaultValue=''
								{...inputBind(`assignmentCalculation.type`)}
								required={true}
								fields={[
									{name: "Valor absoluto", value: 0}, 
									{name: "Percentual", value: 1}
								]}/>
						</Grid>

						<Grid item xs={12} sm={3}>
							<NumberInput
								id="assignmentCalculation.amount"
								label="Custo de cessão"
								name="assignmentCalculation.amount"
								adornmentText={ watch("assignmentCalculation.type") === 0 ? "R$" : "%"}
								{...inputBindNumber("assignmentCalculation.amount")}
								inputProps={ watch("assignmentCalculation.type") === 0 
									? null : { min: "0", max: "10", step: "1" }
								}
							/> 
						</Grid> 			
					</Grid> :
					null
				}		
				<Box py={3}>
					<Grid container spacing={3}>
						<Controller
							render={({ field }) => (
								<>
									<Grid item xs={12}>
										<TextFieldComponent
											id="observations-label"
											fullWidth
											type="text"
											name="description"
											component={TextField}
											label={"Observações adicionais"}
											margin="dense"
											multiline={true}
											rows={4}
											fieldAreaHeight={100}
											{...inputBind("observations")}
										/>
									</Grid>
								</>
							)}
							control={control}
							name="observations"
						/>
					</Grid>
				</Box>
			</Grid>
		</>
	);
}

export default InformationsCredit;
