import React, { useEffect, useState } from 'react';
import * as datasetService from '../../../services/dataset';
import Accordions from 'components/Accordion/Accordion';
import {
	AddressesExtended,
	BasicDataLegalPerson,
	Kyc,
	OnDemandPgfn,
	OnDemandQueryPesquisaProtesto,
	OnDemandSintegra,
	OwnersLawsuits,
	PhonesExtended,
	Processes,
	RelatedPeoplePhones,
	Relationships
} from 'components/Datasets';
import { Box } from '@material-ui/core';

function Datasets(props) {

	const [internalLoading, setInternalLoading] = useState(false);
	const { setLoading, loading } = props;
	const [datasets, setDatasets] = useState();

	useEffect(() => {
		getDatasetsByUserId();
	}, []);

	const generateDataset = (dataset) => {
		setInternalLoading(true);
		datasetService.addDataSet('LegalPerson', getLegalPersonId(), dataset).then(response => {
			if (response.data) {
				const datasetName = response.data[0]?.dataSet;
				const newDatasets = [...datasets];
				const index = newDatasets?.map(f => f.dataSet).indexOf(datasetName);
				if (index !== -1) newDatasets[index] = response.data[0];
				else newDatasets.push(response.data[0]);
				setDatasets(newDatasets);
				getDatasetsByUserId();
			}
			setInternalLoading(false);

		}).catch(err => {
			setInternalLoading(false);
		});
	};

	const getLegalPersonId = () => props.legalPersonId;

	const getDatasetsByUserId = () => {
		setLoading(true);
		datasetService.getDatasetsByUserId('LegalPerson', getLegalPersonId()).then(response => {
			setDatasets(response?.data);
			setLoading(false);
		});

	};

	const datasetProps = {
		disabled: loading,
		addDataset: generateDataset,
		loading: internalLoading
	};

	const useScan3 = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCAN3;
	const findProperDataset = (datasetName) => datasets?.find(dataset => dataset?.dataSet == datasetName);

	const accordionData = [
		{
			name: 'Consulta de CNPJ',
			key: 'legal_basic_data',
			value: 'LegalBasicData',
			children: <BasicDataLegalPerson data={findProperDataset('legal_basic_data')} value='LegalBasicData' {...datasetProps} />,
		},
		{
			name: 'Endereços',
			value: 'AddressesLegalPersonal',
			children: <AddressesExtended data={findProperDataset('addresses_legalpersonal')} value="AddressesLegalPersonal" {...datasetProps} />,
			key: 'addresses_legalpersonal'
		},
		{
			name: 'Protestos',
			key: 'pesquisaprotesto',
			value: 'PesquisaProtesto',
			children: <OnDemandQueryPesquisaProtesto data={findProperDataset('pesquisaprotesto')} value="PesquisaProtesto" {...datasetProps} />,
		},
		{
			name: 'KYC e Compliance',
			value: "KycData",
			children: <Kyc data={findProperDataset('kyc_data')} value="KycData" {...datasetProps} />,
			key: 'kyc_data'
		},
		{
			name: 'Processos Judiciais e Administrativos',
			value: 'JudicialProcesses',
			children: <Processes data={findProperDataset('judicial_processes')} value="JudicialProcesses" {...datasetProps} />,
			key: 'judicial_processes'
		},
		{
			name: 'Ações Judiciais relacionadas aos sócios',
			value: 'PartnersLawsuits',
			children: <OwnersLawsuits data={findProperDataset('partners_lawsuits')} value="PartnersLawsuits" {...datasetProps} />,
			key: 'partners_lawsuits'
		},
		{
			name: 'Procuradoria Geral da Fazenda Nacional',
			value: 'Pgfn',
			children: <OnDemandPgfn data={findProperDataset('pgfn')} value="Pgfn" {...datasetProps} />,
			key: 'pgfn'
		},
		{
			name: 'SINTEGRA - Sistema Integrado de Operações Interestaduais com Mercadorias e Serviços',
			value: 'Sintegra',
			children: <OnDemandSintegra data={findProperDataset('sintegra')} value="Sintegra" {...datasetProps} />,
			key: 'sintegra'
		},
		{
			name: 'Pessoas Relacionadas',
			value: 'AssociatedPeople',
			children: <Relationships data={findProperDataset('associated_people')} value="AssociatedPeople" {...datasetProps} />,
			key: 'associated_people'
		},
		{
			name: 'Telefones',
			key: 'phones_list',
			values: 'PhonesList',
			children: <PhonesExtended data={findProperDataset('phones_list')} value="PhonesList" {...datasetProps} />,
		},
		!useScan3 ? {
			name: 'Telefones de relacionados',
			key: 'related_people_phones_list',
			value: 'RelatedPeoplePhonesList',
			children: <RelatedPeoplePhones data={findProperDataset('related_people_phones_list')} value="RelatedPeoplePhonesList" {...datasetProps} />
		} : null
	].filter(i => i != null);

	return (
		<>
			<div>
				<Box py={2}>
					<Accordions accordions={accordionData} />
				</Box>
			</div>
		</>
	);
}

export default Datasets;