import React from 'react';

const EnterIcon = () => (
	<svg width="23" height="23" viewBox="0 0 512.000000 512.000000">

		<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
			fill="#FFFFFF" stroke="none">
			<path d="M2682 4187 c-58 -33 -57 -25 -60 -489 -3 -392 -4 -427 -20 -432 -9
-3 -64 -12 -122 -21 -325 -50 -705 -157 -1012 -286 -891 -372 -1381 -956
-1458 -1738 -16 -164 -14 -213 13 -252 26 -40 81 -58 125 -43 26 9 39 26 131
173 177 283 538 535 949 662 308 95 753 149 1240 149 l162 0 0 -455 c0 -305 4
-462 11 -478 14 -31 59 -57 98 -57 25 0 118 58 424 264 216 145 734 493 1152
775 493 331 768 522 783 543 30 41 25 100 -10 133 -19 19 -1879 1274 -2216
1497 -108 71 -143 81 -190 55z"/>
		</g>
	</svg>
);

export default EnterIcon;