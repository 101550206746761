import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentContainer, ModalWithLoading, Toast } from 'components';
import { Box, AppBar, Tabs, Tab, Grid, Button } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import getFormFunctions from 'helpers/form-helpers';
import InformationsProduct from 'pages/Product/FieldsForm/informationsProduct';
import { validationSchema, defaultValues } from 'pages/Product/FieldsForm/schema/validation';
import * as productService from 'services/product';
import { useLoading } from 'contexts/loading';
import InvolvedPersons from 'pages/Product/Tabs/involvedPerson';
import DocumentsRequired from 'pages/Product/Tabs/documentsRequired';
import { useTenant } from 'contexts/tenant';
import { useHeader } from 'contexts/header';
import ModalAssign from 'components/Assign/ModalAssign';

function ProductEdit(props) {

	const history = useHistory();
	const { selectedTenant } = useTenant();
	const { loading, addLoader, completeLoader } = useLoading();
	const [product, setProduct] = useState();
	const [tab, setTab] = useState('Informações');
	const { setTitle } = useHeader();
	const [documentsRequired, setDocumentsRequired] = useState([]);
	const [involvedPersons, setInvolvedPersons] = useState([]);
	const [modalAssign, setModalAssign] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const { handleSubmit, setValue, watch, getValues, control, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});

	const { errors } = useFormState({ control });

	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema, setValue, control, errors });

	const getProductId = () => props?.location?.search?.replace('?id=', '');

	const isEditing = () => {
		return !!getProductId();
	};

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const fetchProductsById = () => {
		addLoader("fetchProductsById");
		productService.getProductById(getProductId(), selectedTenant)
			.then(result => {
				setTitle(`Produto - ${result.name}`);
				completeLoader("fetchProductsById");
				setProduct(result);
				setInvolvedPersons(result?.relatedPerson);
				setDocumentsRequired(result?.requiredDoc);
			}).catch(err => completeLoader("fetchProductsById"));
	};

	useEffect(() => {
		if (isEditing()) {
			fetchProductsById();
		} else {
			setTitle("Produto - novo produto");
		}
	}, []);

	useEffect(() => {
		if (product) {
			reset(product);
		}
	}, [product]);

	const handleDeleteProduct = async () => {
		addLoader("handleDeleteProduct");
		productService.deleteProductById(getProductId())
			.then(() => {
				history.push('/produto');
				completeLoader("handleDeleteProduct");
			})
			.catch(err => {
				completeLoader("handleDeleteProduct");
			});
	};

	const saveProduct = (values) => {

		addLoader("saveProduct");
		const option = isEditing() ? 'edit' : 'create';
		let record = { ...values };

		record.relatedPerson = involvedPersons;
		record.requiredDoc = documentsRequired;
		productService.editOrCreate(record, option, selectedTenant)
			.then(result => {
				completeLoader("saveProduct");
				if (result?.data) {
					history.push(`/produto/editar?id=${result.data}`);
				} else {
					fetchProductsById();
				}
			})
			.catch(error => {
				completeLoader("saveProduct");
				console.log(error);
			});
	};

	const handleModalAssign = () => {
		setModalAssign(true);
	};
	const handleCloseModalAssign = () => {
		setModalAssign(false);
	};

	const handleCloseModalDelete = () => {
		setModalDelete(false);
	};

	const handleModalDelete = () => {
		setModalDelete(true);
	};

	const actions = isEditing() ? {
		onAssign: handleModalAssign,
		onDelete: handleModalDelete,
		disabledAll: loading
	} : {
	};

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsProduct {...{ isEditing, product, inputBind, inputBindNumber, control, setValue, getValues, watch }} />
		},
		{
			name: 'Envolvidos',
			show: true,
			children: <InvolvedPersons {...{ involvedPersons, setInvolvedPersons }} />
		},
		{
			name: 'Documentos Obrigatórios',
			show: true,
			children: <DocumentsRequired {...{ documentsRequired, setDocumentsRequired }} />
		}
	];


	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const onSubmit = (values) => {
		saveProduct(values);
	};

	const style = { borderTopLeftRadius: 10, borderTopRightRadius: 7, borderBottomLeftRadius: 10, borderBottomRightRadius: 7, backgroundColor: 'white', padding: 10, margin: 1 };

	return (
		<ContentContainer>
			{modalAssign &&
				<ModalAssign recordType={"Product"} recordId={getProductId()} onCloseModal={handleCloseModalAssign} />
			}
			{modalDelete &&
				<>
					<ModalWithLoading title='Tem certeza que deseja excluir esse Produto?' onCloseModal={handleCloseModalDelete}>
						<Grid container direction='column' justifyContent='center' spacing={2}>
							<Grid item>
								<Button color="secondary" variant='contained' onClick={() => handleDeleteProduct()}>Excluir</Button>
							</Grid>
						</Grid>
					</ModalWithLoading>
				</>
			}
			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<Grid container direction="row" spacing={4}>
					<Grid item xs={12} lg={6}>
						<ActionBar {...actions} />
					</Grid>
					{isEditing() &&
						<Grid item xs={12} lg={6} className="disablePrint">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{product?.createdBy && <small style={style}><strong>Criado em:</strong>&nbsp;{product?.createdAt && new Date(product?.createdAt).toLocaleDateString('pt-BR')}</small>}
								{product?.ownerUser && <small style={style}><strong>Operador:</strong>&nbsp;{product?.ownerUser?.userIdDisplay}</small>}
								{product?.ownerGroup && <small style={style}><strong>Grupo:</strong>&nbsp;{product?.ownerGroup?.groupDisplay}</small>}
							</div>
						</Grid>}
				</Grid>

				<AppBar position="relative" color="transparent" elevation={0}>
					<Tabs
						centered={false}
						value={tab}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabs.map((item, index) => (
							item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
						))}
					</Tabs>
				</AppBar>
				{
					tabs.map((item, index) =>
						<TabPanel key={index} value={tab} index={item.name}>
							{item.children}
						</TabPanel>
					)
				}
			</form>
		</ContentContainer>
	);
}

export default ProductEdit;
