import React, { createContext, useContext, useState, useEffect } from 'react';
import * as productServices from 'services/product';
import { useTenant } from 'contexts/tenant';
import Helper from 'helpers/format.helpers';
import { useUser } from 'contexts/user';
import { useMyPermissions } from './permissions';

const ProductContext = createContext({ productList: [], setProductList: () => { } });

export const ProductProvider = ({ children }) => {

	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';
	const [productList, setProductList] = useState([]);
	const [productOptionsList, setProductOptionsList] = useState([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: undefined });
	const { selectedTenant } = useTenant();
	const { user } = useUser();
	const { getMyPermissionLevel } = useMyPermissions();

	const hasProductPerm = !!getMyPermissionLevel("CreditProduct", "read");

	const getOptionById = (id) => {
		return new Promise((resolve, reject) => {
			var product = productOptionsList.find(p => p.value == id);
			if (product)
				resolve(product);

			productServices.getProductById(id).then(async response => {
				if (response) {
					resolve({
						name: response.name,
						value: response.id,
						amortizationType: response.amortizationType,
						maximumInterestRate: response.maximumInterestRate,
						minimumInterestRate: response.minimumInterestRate,
						maximumAmount: response.maximumAmount,
						minimumAmount: response.minimumAmount,
						maximumPrincipalAmount: response.maximumPrincipalAmount,
						minimumPrincipalAmount: response.minimumPrincipalAmount,
						maximumAutomaticLiquidationValueInCents: response.maximumAutomaticLiquidationValueInCents, 
						allowFinanceTaxExemption: response.allowFinanceTaxExemption,
						isConsumerCreditNote: response.isConsumerCreditNote,
						useCollateral: response.useCollateral,
						defaultCollateralDescription: response.defaultCollateralDescription
					});
				}
			}).catch(err => {
				resolve({
					name: "",
					value: null,
					amortizationType: "",
					maximumInterestRate: null,
					minimumInterestRate: null,
					isConsumerCreditNote: false,
					useCollateral: false,
					defaultCollateralDescription: ""
				});
			});;

		});
	};

	const searchProducts = (page, size, searchString, selectedTenant) => {
		return productServices.getProductsList({ page, size, searchString }, selectedTenant).then(response => {
			setProductList(response?.data.map(p => {
				return {
					name: p.name,
					value: p.id,
					amortizationType: p.amortizationType,
					maximumInterestRate: p.maximumInterestRate,
					minimumInterestRate: p.minimumInterestRate,
					allowFinanceTaxExemption: p.allowFinanceTaxExemption,
					isConsumerCreditNote: p.isConsumerCreditNote,
					useCollateral: p.useCollateral,
					defaultCollateralDescription: p.defaultCollateralDescription
				};
			}));
			setProductOptionsList(response?.data.map(n => {
				if (n?.registrationNumber) {
					const cpfCnpj = Helper.formatDocumentNumber(n.registrationNumber);
					return {
						name: isRootTenancy ? `${n.name || "Não informado"} (${n.tenantDisplay}) - (${cpfCnpj})` : n.name,
						value: n.id,
						maximumInterestRate: n.maximumInterestRate,
						minimumInterestRate: n.minimumInterestRate,
						allowFinanceTaxExemption: n.allowFinanceTaxExemption,
						isConsumerCreditNote: n.isConsumerCreditNote,
						useCollateral: n.useCollateral,
						defaultCollateralDescription: n.defaultCollateralDescription
					};
				} else {
					return {
						name: isRootTenancy ? `${n.name || "Não informado"} (${n.tenantDisplay})` : n.name,
						value: n.id,
						amortizationType: n.amortizationType,
						maximumInterestRate: n.maximumInterestRate,
						minimumInterestRate: n.minimumInterestRate,
						allowFinanceTaxExemption: n.allowFinanceTaxExemption,
						isConsumerCreditNote: n.isConsumerCreditNote,
						useCollateral: n.useCollateral,
						defaultCollateralDescription: n.defaultCollateralDescription
					};
				};
			}));
		});
	};

	const contextualGetProductList = (page, size, search) => {
		if (page != searchArgs?.page || size != searchArgs?.size || search != searchArgs?.search) {
			setSearchArgs({ page, size, search });
		}
	};

	useEffect(() => {		
		if (!user?.authenticated || !hasProductPerm)
			return;
		searchProducts(searchArgs.page, searchArgs.size, searchArgs.search, selectedTenant);
	}, [selectedTenant, searchArgs, user, hasProductPerm]);

	return (
		<ProductContext.Provider
			value={{
				getOptionById, productList, setProductList, getProductsList: (page, size, search) => {
					return contextualGetProductList(page, size, search);
				}, productOptionsList
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};

export function useProduct() {
	const context = useContext(ProductContext);
	const {
		productList,
		setProductList,
		getProductsList,
		productOptionsList,
		getOptionById
	} = context;
	return {
		productList,
		setProductList,
		getProductsList,
		productOptionsList,
		getOptionById
	};
}
