import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField, FormGroup, FormControlLabel, Typography, Tooltip, Modal } from '@material-ui/core';
import CheckboxComponent from "components/CheckBox/Checkbox";
import TextFieldComponent from 'components/TextInput/TextField';
import SelectComponent from 'components/Select/Select';
import AutocompleteWithSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { useFund } from 'contexts/fund';
import NumberInput from "components/NumberInput/NumberInput";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from "@material-ui/core/styles";
import UploadFile from "components/Upload/UploadFile/UploadFile";
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
	paper: {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		position: "absolute",
		width: 940,
		height: 245,
		backgroundColor: "#ffffff",
		boxShadow: theme.shadows[6],
		padding: theme.spacing(5, 6, 3),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '15px'
	}
}));

function InformationsProduct({
	isEditing,
	inputBind,
	inputBindNumber,
	setValue,
	product,
	watch,
	control
}) {

	const classes = useStyles();
	const { getFundsList, fundOptionsList } = useFund();
	const [modal, setModal] = useState(false);

	const changeCheckBoxValue = (event) => {
		const value = event.target.checked;
		const name = event.target.name;
		setValue(name, value);
	};
				
	useEffect(() => {
		watch("automaticComplianceApproval") == true ? setValue("automaticComplianceAnalysisExecution", true) : false;
	}, [watch("automaticComplianceApproval")]);

	useEffect(() => {
		watch("automaticComplianceAnalysisExecution") == false ? setValue("automaticComplianceApproval", false) : true;
	}, [watch("automaticComplianceAnalysisExecution")]);

	useEffect(() => {
		watch("automaticCreditAnalysisExecution") == false ? setValue("automaticCreditStatusApproval", false) : false;
	}, [watch("automaticCreditAnalysisExecution")]);

	useEffect(() => {
		watch("allowOwnFund") === true ? setValue("defaultFundId", null) : false;
		watch("allowOwnFund") === true ? setValue("defaultFundDisplay", null) : false;
	}, [watch("allowOwnFund")]);

	useEffect(() => {
		if (watch("signaturePortalProvider") === 0) setValue("facialBiometrics", false); setValue("selfieDocumentSignature", false);

	}, [watch("signaturePortalProvider") === 0]);	

	const closeModal = () => {
		setModal(false);
	};

	const openModal = () => {
		setModal(true);
	};

	let currentUpload = watch("templateDoc");

	return (
		<>
			{modal &&
				<Modal
					title='Atualizar Arquivo de Cessão'
					open={openModal}
					onClose={closeModal}>
					<div className={classes.paper}>
						<UploadFile currentUpload={currentUpload} fieldName={'templateDoc'} setValue={setValue} />
					</div>
				</Modal>
			}
			<Box py={3}>
				<Grid container spacing={5}>
					<Grid item xs={3}>
						<TextFieldComponent
							id="name"
							fullWidth
							label="Nome do Produto"
							name="name"
							component={TextField}
							{...inputBind("name")} />
					</Grid>
					<Grid item xs={3}>
						<SelectComponent
							id="amortizationType"
							name="amortizationType"
							label="Tipo de cálculo"
							fullWidth
							defaultValue=""
							{...inputBind(`amortizationType`)}
							fields={[
								{ name: "Price", value: "CleanPrice" },
								{ name: "Price Incentivada", value: "CleanPriceIncentivized" },
								{ name: "Price s/ repasse IOF", value: "CleanPriceNoIOF" },
								{ name: "Price com garantia fiduciária", value: "ReceivablesPrice" },
								{ name: "FGTS", value: "FGTS" },
								{ name: "Financiamento estudantil", value: "StudentLoan" },
								{ name: "Consignado", value: "Consignado" },
								{ name: "Home Equity - Price", value: "HomeEquityPrice" },
								{ name: "Home Equity - SAC", value: "HomeEquitySac" },
								{ name: "Nota Comercial", value: "CommercialPapper" }]} />
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							required
							id="minimumInterestRate"
							name="minimumInterestRate"
							label="Taxa de Juros Mínima (%)"
							type="number"
							adornmentText="%"
							fullWidth
							decimalScale={6}
							inputProps={{ min: "0", max: "160", step: "1" }}
							{...inputBindNumber("minimumInterestRate")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							required
							id="maximumInterestRate"
							name="maximumInterestRate"
							label="Taxa de Juros Máxima (%)"
							type="number"
							adornmentText="%"
							fullWidth
							decimalScale={6}
							inputProps={{ min: "0", max: "160", step: "1" }}
							{...inputBindNumber("maximumInterestRate")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="maximumPrincipalAmount"
							label="Valor máximo do principal"
							name="maximumPrincipalAmount"
							adornmentText="R$"
							{...inputBindNumber("maximumPrincipalAmount")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="minimumPrincipalAmount"
							label="Valor mínimo do principal"
							name="minimumPrincipalAmount"
							adornmentText="R$"
							{...inputBindNumber("minimumPrincipalAmount")}
						/>
					</Grid>
					<Grid item xs={3}>
						<SelectComponent
							id="code"
							name="code"
							label="Tipo de Produto"
							fullWidth
							defaultValue=""
							{...inputBind(`code`)}
							required={true}
							fields={[
								{ name: "Financiamento", value: 1 },
								{ name: "Empréstimo", value: 2 },
							]} />
					</Grid>
					<Grid item xs={3}>
						<SelectComponent
							id="viewType"
							name="viewType"
							label="Visibilidade do Produto"
							fullWidth
							defaultValue=""
							{...inputBind(`viewType`)}
							required={true}
							fields={[
								{ name: "Aberto", value: 1 },
								{ name: "Fechado", value: 0 },
							]} />
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="cosifBankAccount"
							fullWidth
							label="Conta COSIF"
							name="cosifBankAccount"
							component={TextField}
							{...inputBind("cosifBankAccount")} />
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="maximumAutomaticLiquidationValueInCents"
							label="Valor máximo para liquidação automática"
							name="maximumAutomaticLiquidationValueInCents"
							adornmentText="R$"
							{...inputBindNumber("maximumAutomaticLiquidationValueInCents")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="maximumAbsInterestPeriods"
							label="Limite máximo de carência de juros"
							name="maximumAbsInterestPeriods"
							adornmentText="R$"
							{...inputBindNumber("maximumAbsInterestPeriods")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="maximumAbsAmortizationPeriods"
							label="Limite máximo de carência de amortização"
							name="maximumAbsAmortizationPeriods"
							adornmentText="R$"
							{...inputBindNumber("maximumAbsAmortizationPeriods")}
						/>
					</Grid>
				</Grid>
				<Box p={0} mt={4}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Custo de emissão</Typography>
					<br />
					<Grid container spacing={5} >
						<Grid item xs={3}>
							<SelectComponent
								id="bankComission.type"
								name="bankComission.type"
								label="Tipo de Custo de Emissão"
								fullWidth
								defaultValue=""
								{...inputBind(`bankComission.type`)}
								required={true}
								fields={[
									{ name: "Valor Fixo", value: 0 },
									{ name: "Percentual", value: 1 },
								]} />
						</Grid>
						{watch("bankComission.type") == 0 &&
							<Grid item xs={3}>
								<NumberInput
									id="bankComission.amount"
									label="Custo de Emissão"
									name="bankComission.amount"
									adornmentText="R$"
									{...inputBindNumber("bankComission.amount")}
								/>
							</Grid>}
						{watch("bankComission.type") == 1 &&
							<Grid item xs={3}>
								<NumberInput
									required
									id="bankComission.amount"
									name="bankComission.amount"
									label="Custo de Emissão (%)"
									type="number"
									adornmentText="%"
									fullWidth
									decimalScale={6}
									inputProps={{ min: "0", max: "160", step: "1" }}
									{...inputBindNumber("bankComission.amount")}
								/>
							</Grid>}
						<Grid item xs={3}>
							<NumberInput
								id="minimumEmissionCostInCents"
								label="Custo mínimo de emissão"
								name="minimumEmissionCostInCents"
								adornmentText="R$"
								{...inputBindNumber("minimumEmissionCostInCents")}
							/>
						</Grid>
						<Grid item xs={3}>
							<NumberInput
								id="maximumEmissionCostInCents"
								label="Custo máximo de emissão"
								name="maximumEmissionCostInCents"
								adornmentText="R$"
								{...inputBindNumber("maximumEmissionCostInCents")}
							/>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="financeEmissioncost"
											color="primary"
											name="financeEmissioncost"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("financeEmissioncost")}
										/>} 
									label="Financiar custo de emissão" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="financeFinTax"
											color="primary"
											name="financeFinTax"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("financeFinTax")}
										/>}
									label="Financiar IOF" />
							</FormGroup>
						</Grid>
					</Grid>
				</Box>
				<Box p={0} mt={4}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Horário de Corte</Typography>
					<br />
					<Grid container spacing={5} >
						<Grid item xs={3}>
							<TextFieldComponent
								id="timeLimitToSubmitApproval"
								name="timeLimitToSubmitApproval"
								label="Envio para aprovação"
								type="time"
								{...inputBind("timeLimitToSubmitApproval")}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextFieldComponent
								id="timeLimitToApprove"
								name="timeLimitToApprove"
								label="Aprovação de Compliance"
								type="time"
								{...inputBind("timeLimitToApprove")}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextFieldComponent
								id="timeToSendToSignatures"
								name="timeToSendToSignatures"
								label="Início das Assinaturas"
								type="time"
								{...inputBind("timeToSendToSignatures")}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextFieldComponent
								id="timeLimitToSign"
								name="timeLimitToSign"
								label="Assinatura"
								type="time"
								{...inputBind("timeLimitToSign")}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box p={0} mt={4}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Aprovação</Typography>
					<br />
					<Grid container spacing={4}>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="requireWitness"
											color="primary"
											name="requireWitness"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("requireWitness")}
										/>}
									label="Obrigatório informar testemunhas" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="requireGuarantor"
											color="primary"
											name="requireGuarantor"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("requireGuarantor")}
										/>}
									label="Obrigatório informar avalista" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="instrumentApproval"
											color="primary"
											name="instrumentApproval"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("instrumentApproval")}
										/>}
									label="Etapa aprovação de instrumento" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="allowFinanceTaxExemption"
											color="primary"
											name="allowFinanceTaxExemption"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											disabled={isEditing() ? true : false}
											{...inputBind("allowFinanceTaxExemption")}
										/>}
									label="Permitir isenção de IOF" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="openBankAccount"
											color="primary"
											name="openBankAccount"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("openBankAccount")}
										/>}
									label="Permitir abertura de conta bancária" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="isConsumerCreditNote"
											color="primary"
											name="isConsumerCreditNote"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("isConsumerCreditNote")}
										/>}
									label="Crédito Direto ao Consumidor" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="limitOpToPerson"
											color="primary"
											name="limitOpToPerson"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("limitOpToPerson")}
										/>}
									label="Limite de operação por pessoa" />
							</FormGroup>
						</Grid>
					</Grid>
				</Box>
				<Box p={0} mt={4}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Geração de Contrato</Typography>
					<br />
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="draftGeneration"
											color="primary"
											name="draftGeneration"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("draftGeneration")}
										/>}
									label="Gerar Contrato CCB" />
							</FormGroup>
						</Grid>
						{watch("draftGeneration") == true && <Grid item xs={3}>
							<TextFieldComponent
								id="templateDoc.fileName"
								fullWidth
								label="Caminho do Arquivo Template"
								name="templateDoc.fileName"
								component={TextField}
								{...inputBind("templateDoc.fileName")}
								required={true}
								disabled={true}
								InputProps={{
									disableUnderline: true,
									endAdornment: (
										<>
											<>
												{isEditing() ? <a href={watch("templateDoc.tempGetUrl")} target="_blank">
													<Tooltip title="Download do Arquivo">
														<CloudDownloadIcon position="end" style={{ margin: '0 16', fontSize: 26 }} />
													</Tooltip>
												</a> : null}
												<div onClick={openModal}>
													<Tooltip title="Atualizar Arquivo">
														<CloudUploadIcon position="end" style={{ fontSize: 32, color: '#1e87f0', cursor: 'pointer' }} />
													</Tooltip>
												</div>
											</>
										</>)
								}}
							/>
						</Grid>}
					</Grid>
				</Box>
				<Box p={0} mt={4}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Motor de Compliance</Typography>
					<br />
					<Grid container spacing={3}>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="automaticComplianceAnalysisExecution"
											color="primary"
											name="automaticComplianceAnalysisExecution"
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("automaticComplianceAnalysisExecution")}
										/>}
									label="Execução automática de motor de compliance" />
							</FormGroup>
						</Grid>
						{watch("automaticComplianceAnalysisExecution") == true &&
							<Grid item xs={3}>
								<FormGroup>
									<FormControlLabel
										control={
											<CheckboxComponent
												id="automaticComplianceApproval"
												color="primary"
												name="automaticComplianceApproval"
												inputProps={{ 'aria-label': 'secondary checkbox' }}
												{...inputBind("automaticComplianceApproval")}
											/>}
										label="Aprovação automática de compliance" />
								</FormGroup>
							</Grid>}
						<Grid item xs={3}>
							<TextFieldComponent
								id="defaultComplianceAnalysisGroup"
								name="defaultComplianceAnalysisGroup"
								label="Grupo de Análise Compliance"
								type="number"
								fullWidth
								{...inputBindNumber("defaultComplianceAnalysisGroup")}
								required={watch("automaticComplianceAnalysisExecution") == true ? true : false}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box p={0} mt={3}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Análise de Crédito</Typography>
					<br />
					<Grid container spacing={3}>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="automaticCreditAnalysisExecution"
											color="primary"
											name="automaticCreditAnalysisExecution"
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("automaticCreditAnalysisExecution")}
										/>}
									label="Execução automática de motor de crédito" />
							</FormGroup>
						</Grid>
						{watch("automaticCreditAnalysisExecution") == true &&
							<Grid item xs={3}>
								<FormGroup>
									<FormControlLabel
										control={
											<CheckboxComponent
												id="automaticCreditApproval"
												color="primary"
												name="automaticCreditApproval"
												inputProps={{ 'aria-label': 'secondary checkbox' }}
												{...inputBind("automaticCreditApproval")}
											/>}
										label="Aprovação automática de limite de crédito" />
								</FormGroup>
							</Grid>}
						<Grid item xs={3}>
							<TextFieldComponent
								id="defaultCreditAnalysisGroup"
								name="defaultCreditAnalysisGroup"
								label="Grupo de Análise de Crédito"
								type="number"
								fullWidth
								{...inputBindNumber("defaultCreditAnalysisGroup")}
								required={watch("automaticCreditAnalysisExecution") == true ? true : false}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box p={0} mt={2}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Coleta de Assinaturas</Typography>
					<br />
					<Grid item xs={3}>
						<FormGroup>
							<FormControlLabel
								control={
									<CheckboxComponent
										id="useSignaturePortal"
										color="primary"
										name="useSignaturePortal"
										onClick={changeCheckBoxValue}
										inputProps={{ 'aria-label': 'secondary checkbox' }}
										{...inputBind("useSignaturePortal")}
									/>}
								label="Coletar assinatura" />
						</FormGroup>
					</Grid>
					<br />
					<Grid container spacing={5}>
						{watch("useSignaturePortal") == true &&
							<>
								<Grid item xs={3}>
									<SelectComponent
										id="signatureType"
										name="signatureType"
										label="Forma de envio de Assinatura"
										fullWidth
										defaultValue=""
										{...inputBind(`signatureType`)}
										required={watch("useSignaturePortal") == true ? true : false}
										fields={[
											{ name: "Email", value: 0 },
											{ name: "Sms", value: 1 },
											{ name: "Whatsapp", value: 2 }
										]} />
								</Grid>
								<Grid item xs={3}>
									<NumberInput
										id="maximumValueWithoutDigitalSignature"
										label="Valor máximo sem assinatura digital"
										name="maximumValueWithoutDigitalSignature"
										adornmentText="R$"
										{...inputBindNumber("maximumValueWithoutDigitalSignature")} />
								</Grid>
								<Grid item xs={3}>
									<SelectComponent
										id="signaturePortalProvider"
										name="signaturePortalProvider"
										label="Certificadora"
										fullWidth
										defaultValue=""
										{...inputBind(`signaturePortalProvider`)}
										required={watch("useSignaturePortal") == true ? true : false}
										fields={[
											{ name: "RBM", value: 0 },
											{ name: "ClickSign", value: 1 },
										]} />
								</Grid>
								<Grid item xs={3}>
									{watch("signaturePortalProvider") === 1 && 
										<SelectComponent
											id="signatureValidation"
											name="signatureValidation"
											label="Tipo de Assinatura"
											fullWidth
											defaultValue=''
											{...inputBind(`signatureValidation`)}
											required={watch("useSignaturePortal") === true ? true : false}
											fields={[
												{ name: "Assinatura Eletrônica", value: "EletronicSignature", },
												{ name: "Assinatura com Selfie", value: "SelfieSignature", },
												{ name: "Selfie + Documento Oficial", value: "SelfieDocumentSignature" },
												{ name: "Biometria Facial", value: "FacialBiometrics" },
												{ name: "Certificado Digital", value:"DigitalCertificate" },
											]} />
									}
									{watch("signaturePortalProvider") === 0 &&
										<SelectComponent
											id="signatureValidation"
											name="signatureValidation"
											label="Tipo de Assinatura"
											fullWidth
											defaultValue=""
											{...inputBind(`signatureValidation`)}
											required={watch("useSignaturePortal") === true ? true : false}
											fields={[
												{ name: "Assinatura Eletrônica", value: "EletronicSignature", },
												{ name: "Assinatura com Selfie", value: "SelfieSignature" },
												{ name: "Certificado Digital", value: "DigitalCertificate" },
											]} />
									}
								</Grid>
								<Grid item xs={3}>
									<FormGroup>
										<FormControlLabel
											control={
												<CheckboxComponent
													id="signatureValidationStep"
													color="primary"
													name="signatureValidationStep"
													onClick={changeCheckBoxValue}
													inputProps={{ 'aria-label': 'secondary checkbox' }}
													{...inputBind("signatureValidationStep")}
												/>}
											label="Validação de Assinatura" />
									</FormGroup>
								</Grid>
							</>}
					</Grid>
				</Box>
				<Box p={0} mt={5}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Fundo</Typography>
					<br />
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="allowOwnFund"
											color="primary"
											name="allowOwnFund"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("allowOwnFund")}
										/>}
									label="Permite Fundo Próprio UY3" />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<AutocompleteWithSearch
								id="defaultFundId"
								name="defaultFundId"
								displayField="defaultFundDisplay"
								fullWidth
								label="Fundo Padrão"
								watch={watch}
								setValue={setValue}
								rowData={{ defaultFundId: product?.defaultFundId, defaultFundDisplay: product?.defaultFundDisplay }}
								params={{
									page: 0,
									size: 10
								}}
								fetch={getFundsList}
								options={fundOptionsList}
								{...inputBind(`defaultFundId`)}
								onChange={(_, options) => {
									setValue("defaultFundId", options?.value || '');
									setValue("defaultFundDisplay", options?.name || '');
								}}
								required={watch("allowOwnFund") == false ? true : false}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box p={0} mt={4}>
					<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Garantias</Typography>
					<br />
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<FormGroup>
								<FormControlLabel
									control={
										<CheckboxComponent
											id="useCollateral"
											color="primary"
											name="useCollateral"
											onClick={changeCheckBoxValue}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											{...inputBind("useCollateral")}
										/>}
									label="Utiliza Garantia" />
							</FormGroup>
						</Grid>
						{watch("useCollateral") == true && <Grid item xs={12}>
							<Typography component="div" variant="h5" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Descrição padrão da garantia</Typography>
							<br />
							<Grid container spacing={2}>
								<Controller
									render={({ field }) => (
										<>
											<Grid item xs={12}>
												<TextFieldComponent
													id="defaultCollateralDescription"
													fullWidth
													type="text"
													name="defaultCollateralDescription"
													component={TextField}
													margin="dense"
													multiline={true}
													rows={4}
													fieldAreaHeight={100}
													{...inputBind("defaultCollateralDescription")}
												/>
											</Grid>
										</>)}
									control={control}
									name="defaultCollateralDescription" />
							</Grid>
						</Grid>}
					</Grid>
				</Box>
			</Box>
		</>
	);
}
export default InformationsProduct;
