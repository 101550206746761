import React from 'react';

export const ProtestoIcon = ({ className }) => {
	return (
		<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.25002 11.875H4.66669V20.625H7.25002V11.875Z" fill="#5F5F5F" />
			<path d="M15 11.875H12.4167V20.625H15V11.875Z" fill="#5F5F5F" />
			<path d="M26.625 6.875L13.7084 0.625L0.791687 6.875V9.375H26.625V6.875ZM6.56544 6.875L13.7084 3.425L20.8513 6.875H6.56544Z" fill="#5F5F5F" />
			<path d="M0.791687 23.125V25.625H16.8084C16.5371 24.825 16.395 23.9875 16.3434 23.125H0.791687Z" fill="#5F5F5F" />
			<path d="M22.75 14.7V11.875H20.1667V15.95L22.75 14.7Z" fill="#5F5F5F" />
			<path d="M24.0417 16.875L18.875 19.375V22.5625C18.875 25.7125 21.0838 28.6625 24.0417 29.375C26.9996 28.6625 29.2084 25.7125 29.2084 22.5625V19.375L24.0417 16.875ZM23.1117 25.625L20.4896 23.0875L21.8588 21.7625L23.1117 22.975L26.2246 20L27.5938 21.325L23.1117 25.625Z" fill="#5F5F5F" />
		</svg>

	);
};
