import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, useFormState, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from "helpers/form-helpers";
import DatePicker from "components/DatePicker/DatePicker";
import NumberInput from "components/NumberInput/NumberInput";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import EnterIcon from "assets/icons/EnterIcon";
import * as  servicesTransfers from "services/digitalBankAccount";
import { validationTransferSchema as validationSchema, defaultValues } from '../schema/validation';
import AutocompleteWithSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { getBeneficiariesList } from "services/digitalBankAccount";
import { Toast } from 'components';
import { BeneficiaryType, CreateTransferType } from 'global/types/digitalAccount.types';
import Helper from 'helpers/format.helpers';
import { useParams } from 'react-router';
import { RouteWithIdParamsType } from 'global/types/types';
import ModalComponent from 'components/Modal/ModalComponent';
import HasPassCode from '../../Code/codeForm';
import { useUser } from 'contexts/user';
import { useEnumPayPurpose } from 'contexts/enumPaymentPurpose';
import { IMFAState } from 'global/interface/types';
import { formatBankAccount } from 'helpers/bankAccount-helpers';

const BeneficiariesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const TypeTedOrPixTitle = ({ beneficiarieData }: any) => {
	return (
		<>
			{beneficiarieData?.operationTypeValueDisplay === "Transferencia" ?
				"Tem certeza que deseja realizar a transferência, via TED?" 
				: 
				"Tem certeza que deseja realizar a transferência, via PIX?"
			}
		</>
	);
};

const KeyPixOrTransfer = ({ beneficiarieData }: any) => {

	return (
		<BeneficiariesContainer>
			{beneficiarieData?.operationTypeValueDisplay === "Transferencia" && beneficiarieData?.pixKeyTypeValue != 6 &&
				<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>Agência e conta está relacionada à TED</Typography>	
			}

			{beneficiarieData?.operationTypeValueDisplay === "Transferencia" ?
				<>
					<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>CPF/CNPJ: {Helper.formatDocumentNumber(beneficiarieData.registrationNumber)}</Typography>
					<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>Banco: {`${beneficiarieData.bank} - ${beneficiarieData.bankDisplay}`}</Typography>
					<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>Agência: {beneficiarieData.agency}</Typography>
					<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>Conta: {beneficiarieData.accountNumber}</Typography>
				</> :
				<>
					<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>CPF/CNPJ: {Helper.formatDocumentNumber(beneficiarieData.registrationNumber)}</Typography>
					<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>Chave Pix: {beneficiarieData.keyPix}</Typography>
				</>
			}
		</BeneficiariesContainer>
	);
};

const InformationsTransfer = ({ tableRef }: any) => {
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const [beneficiarieData, setBeneficiarieData] = useState();
	const { getEnumPaymentPurpose, enumPayPurposeOption } = useEnumPayPurpose();
	const [open, setOpen] = useState(false);
	const [formValues, setFormValues] = useState<CreateTransferType>();
	const [beneficiariesOptionList, setBeneficiariesOptionList] = useState<[]>([]);
	const [username, setUsername] = useState("");
	const { user } = useUser();
	const { control, handleSubmit, setValue, watch, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});
	const { errors } = useFormState({ control });
	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema, control, errors, setValue });
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
	const cognitoClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID;

	const getAllBeneficiaries = async (page = 0, size = 10, searchString = "") => {
		await getBeneficiariesList(bankAccountId, { page, size, searchString }).then((result: any) => {
			let isApproved = result.data.filter((item: any) => item.statusDisplay === 'Aprovado');
			const listUsers = isApproved.map((item: any) => {
				const listDataForOptions = {
					id: item?.id,
					name: formatBankAccount(item),
					operationTypeValueDisplay: item?.operationTypeValueDisplay,
					agency: item?.agency,
					accountNumber: item?.accountNumber,
					keyPix: item?.keyPix,
					bank: item?.bank,
					bankDisplay: item?.bankDisplay,
					registrationNumber: item?.registrationNumber, 
					pixKeyTypeValue: item?.pixKeyTypeValue
				};
				return listDataForOptions;
			});
			setBeneficiariesOptionList(listUsers);
		});

	};


	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		//@ts-ignore
		if (user?.authenticated == true) setUsername(user['payload']['cognito:username']);
	}, [user]);

	const postTransfer = async () => {

		await servicesTransfers.generateSessionId(username, modalFormData.password, userPoolId, cognitoClientId).then((sessionId) => {
			let fieldValues = formValues as CreateTransferType;
			fieldValues.code = modalFormData.code;
			fieldValues.sessionId = sessionId;
			servicesTransfers.createTransfer(bankAccountId, fieldValues).then(() => {
				tableRef.current.onQueryChange();
				setBeneficiarieData(undefined);
				reset();
			}).finally(() => handleClose());
		});
	};

	//@ts-ignore
	const onSubmit = (values: FieldValues) => { handleOpen(); setFormValues(values); };

	const onError = (values: FieldValues) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	return (
		<>
			<ModalComponent
				open={open}
				onClose={handleClose}
				buttonText='Confirmar'
				onClick={() => postTransfer()}
				title='Confirmação de transferência'
				subtitle={beneficiarieData && (
					<TypeTedOrPixTitle beneficiarieData={beneficiarieData} />
				)}
				children={<HasPassCode setFormData={(data: IMFAState) => { setModalFormData(data); }} />} />

			<form onSubmit={handleSubmit(onSubmit, onError)}>
				<Box mb={2} width='50%'>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<AutocompleteWithSearch
								id="bankAccountBeneficiaryId"
								displayField="beneficiaryDisplay"
								label="Favorecido da transferência"
								watch={watch}
								setValue={setValue}
								//@ts-ignore
								rowData={{ bankAccountBeneficiaryId: watch("bankAccountBeneficiaryIdDisplay"), beneficiaryDisplay: watch("beneficiaryDisplay") }}
								params={{
									page: 0,
									size: 10
								}}
								fetch={getAllBeneficiaries}
								options={beneficiariesOptionList}
								{...inputBind('bankAccountBeneficiaryId')}
								onChange={(_: React.ChangeEvent<HTMLInputElement>, options: any) => {
									setValue("bankAccountBeneficiaryId", options?.id || '');
									//@ts-ignore
									setValue("beneficiaryDisplay", options?.name);
									setBeneficiarieData(options);
								}} />
						</Grid>
						<Grid item xs={12}>
							{beneficiarieData && (
								<KeyPixOrTransfer beneficiarieData={beneficiarieData} />
							)}
						</Grid>
						<Grid item xs={12}>
							{/*@ts-ignore*/}
							{beneficiarieData?.operationTypeValueDisplay === "Transferência" && <AutocompleteWithSearch
								id="paymentPurpose"
								displayField="name"
								label="Tipo de Pagamento"
								watch={watch}
								setValue={setValue}
								//@ts-ignore
								params={{
									page: 0,
									size: 10
								}}
								fetch={getEnumPaymentPurpose}
								options={enumPayPurposeOption}
								{...inputBind('paymentPurpose')}
								onChange={(_: React.ChangeEvent<HTMLInputElement>, options: any) => {
									setValue("paymentPurpose", options?.value || '');
									//@ts-ignore
									setValue("name", options?.name);

								}} />}
						</Grid>
						<Grid item xs={6}>
							<NumberInput
								id="amount"
								label="Valor do pagamento"
								name="amount"
								adornmentText="R$"
								{...inputBindNumber("amount")}
								disabled={false} />
						</Grid>
						<Grid item xs={6}>
							<DatePicker
								disablePast={true}
								id="transferDate"
								label="Data da transferência"
								variant="inline"
								fullWidth
								InputLabelProps={{ shrink: true, required: true }}
								{...inputBind("transferDate")}
								name="transferDate"
								disabled={false}
								disableFuture={false} />
						</Grid>
					</Grid>
					<br />
					<Grid container justifyContent='center' spacing={5}>
						<Grid item>
							<Button className="button-actionBar" type="submit" variant="contained" style={{ backgroundColor: "#5F5F5F" }}>
								<EnterIcon />
								<Typography component="span" style={{ color: "#FFFF", textTransform: 'none', }}>
									&nbsp;Enviar
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Box>
			</form>
			<br />
		</>
	);
};

export default InformationsTransfer;