import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ContentContainer } from 'components';
import { getOperatorList, deleteOperatorById } from 'services/operator';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { TablePagination, Select, MenuItem, TextField, Button, Typography } from '@material-ui/core';
import { useHeader } from 'contexts/header';
import { useUser } from 'contexts/user';
import FilterListIcon from '@material-ui/icons/FilterList';
import Helper from 'helpers/format.helpers';
import ActionBar from 'components/ActionBar/ActionBar';
import { useTenant } from 'contexts/tenant';

const statusFiltering = [
	{
		name: 'Confirmado',
		value: 'CONFIRMED'
	},
	{
		name: 'Alteração de Senha',
		value: 'FORCE_CHANGE_PASSWORD'
	}
];

const OperatorList = () => {
    
	const { user } = useUser();
	const { selectedTenant } = useTenant();
	const { setTitle } = useHeader();
	const tableRef = React.useRef(null);
	const [enableFilter, setEnableFilter] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const [paginationState, setPaginationState] = useState({ 0: null });	
	const history = useHistory();

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		setPaginationState({ 0: null });
		localStorage.setItem('rowsPerPage', page);
	};

	const actions = {
		onRefresh: () => tableRef?.current?.onQueryChange(),
	};

	return (
		<>
			<ContentContainer>
				<ActionBar {...actions} hideSubmit={true}/>
				<div className='uk-section uk-section-small uk-padding-remove-top'>
					<div className='uk-grid-small' data-uk-grid>
						<div className='uk-width-auto@m uk-width-1-1'>
							<Link to='/operador/novo' style={{ backgroundColor: '#3f51b5', textDecoration: "none" }} className='wt-button wt-button-lowercase uk-button'>
								<i className='fa fa-user-plus uk-margin-small-right' style={{ color: "white" }}></i>
								<Typography component="span" style={{ color: "white", textTransform: 'none', }}>Adicionar operador</Typography>
							</Link>
							<Button style={{ marginLeft: 10 }} onClick={() => setEnableFilter(!enableFilter)} >
								<FilterListIcon /> Filtrar
							</Button>
						</div>
					</div>
				</div>
				<MaterialTable
					tableRef={tableRef}
					title="Operadores"
					columns={[
						{ title: 'Operador', field: 'name' },
						{ title: 'CPF', field: 'document', filtering: false, render: ({ document }) => Helper.formatDocumentNumber(document) },
						{ title: 'E-mail', field: 'email' },
						{ title: 'Telefone', field: 'telephone', filtering: false, render: ({ telephone }) => Helper.formatPhoneNumber(telephone) },
						{
							title: 'Status',
							field: 'status',
							filtering: false,
							render: ({ status }) => <strong className={`uk-text-${status !== 'CONFIRMED' ? 'danger' : 'success'}`}>{status == 'CONFIRMED' ? "Confirmado" : 'Alteração de Senha'}</strong>, draggable: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200 },
							filterComponent: (props) => <Select
								{...props}
								fullWidth
								onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
								component={TextField}
							>
								<MenuItem value={""}></MenuItem>
								{statusFiltering && statusFiltering.map(field => (
									<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
								))}
							</Select>
						},
					]}
					actions={[
						{
							icon: 'edit',
							tooltip: 'Editar operador',
							onClick: (_, rowData) => history.push(`/operador/editar?id=${rowData.id}`)
						},
					]}
					editable={{
						onRowDelete: oldData =>
							new Promise(resolve => {
								deleteOperatorById(oldData.id, selectedTenant).then(_ => {
									resolve();
								}).catch(_ => {
									resolve();
								});
							})
					}}
					data={(allParams) =>
						new Promise((resolve, reject) => {
							const { page, pageSize, filters } = allParams;

							const filtersValues = {
								page,
								size: pageSize,
								email: enableFilter && filters.find(f => f.column.field === 'email')?.value,
								name: enableFilter && filters.find(f => f.column.field === 'name')?.value,
								paginationToken: paginationState[page],
								selectedTenant: selectedTenant
							};
							getOperatorList(filtersValues).then(result => {
								setTitle("Operadores");
								var returnResult = result;
								if (result)
									resolve({
										data: returnResult = returnResult?.data?.map(x => {
											return {
												id: x.id,
												telephone: x.phoneNumber,
												email: x.email,
												name: x.name,
												status: x.userStatus,
												document: x.registrationNumber
											};
										}),
										page: result.page,
										totalCount: result.totalItems,
									});
								var paginationToken = result?.paginationToken;
								setPaginationState({ ...paginationState, [page + 1]: paginationToken });

							}).catch(err => reject(err));
						})
					}
					onChangeRowsPerPage={onRowsPerPageChange}
					localization={localizationOptions}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								rowsPerPageOptions={[5, 10, 20, 30, 60]}
							/>
						),
					}}
					options={{
						search: false,
						actionsColumnIndex: -1,
						pageSize: rowsPerPage,
						debounceInterval: 500,
						searchAutoFocus: true,
						filtering: enableFilter,
						showFirstLastPageButtons: false
					}}
				/>

			</ContentContainer>
		</>
	);

};

export default OperatorList;