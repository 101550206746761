import React, { useState } from 'react';
import SelectComponent from 'components/Select/Select';
import TextFieldComponent from 'components/TextInput/TextField';
import TextCustomMask from "components/CustomMaskInput/TextCustomMask";
import Helper from 'helpers/format.helpers';
import { Grid, Box, AppBar, IconButton, TextField, FormGroup, FormControlLabel, Typography, Button, Tooltip, Modal } from '@material-ui/core';
import CheckboxComponent from "components/CheckBox/Checkbox";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link, useLocation } from 'react-router-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from "@material-ui/core/styles";
import bankCodeFields from 'components/BankDataTab/bankCodeFields';
import UploadUpdateFile from "components/Upload/UploadFile/UploadFile";
import NumberInput from "components/NumberInput/NumberInput";
import UF from 'global/enums/uf';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import AutocompleteWithSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { useLegalPersons } from 'contexts/legalPerson';
/* provider partner */
import { useUser } from "contexts/user";
import { useTenant } from "contexts/tenant";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "random/debounce";

const AutoCompleteTenant = ({
	id,
	options,
	onChange,
	fetch,
	params,
	value,
	defaultValue,
}) => {
	const [searchField, setSearchField] = React.useState(undefined);
	const { page, size } = params;

	const verify = React.useCallback(
		debounce((name) => {
			fetch(page, size, name);
		}, 500),
		[]
	);

	React.useEffect(() => {
		fetch(page, size);
	}, []);

	React.useEffect(() => {
		verify(searchField);
	}, [searchField]);

	return (
		<>
			<Autocomplete
				noOptionsText={"Nenhum registro foi encontrado."}
				options={options}
				id={id}
				fullWidth
				defaultValue={defaultValue}
				value={value}
				getOptionSelected={(props) => {
					return props.id;
				}}
				getOptionLabel={(option) => {
					return option.name ?? "";
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							disableUnderline: true,
							style: {
								backgroundColor: "#ffff",
								borderRadius: 10,
								height: 48,
								padding: 10,
								borderBottom: 0,
							},
							onChange: (event) => {
								setSearchField(event?.currentTarget?.value);
							},
						}}
						shrink
					/>
				)}
				disablePortal={true}
				onChange={onChange}
			/>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	paper: {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		position: "absolute",
		width: 940,
		height: 230,
		backgroundColor: "#ffffff",
		boxShadow: theme.shadows[6],
		padding: theme.spacing(5, 6, 3),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '15px'
	}
}));

function InformationsFund(props) {
	console.log('props', props);
	const {
		isEditing,
		inputBindNumber,
		inputBind,
		watch,
		setValue
	} = props;

	const {
		tenantList,
		getTenantList,
		setSelectedTenant,
	} = useTenant();

	const { user } = useUser();
	const classes = useStyles();
	const [showFtpPassword, setShowFtpPassword] = useState(false);
	const [modal, setModal] = useState(null);

	const changeCheckBoxValue = (event) => {
		const value = event.target.checked;
		const name = event.target.name;
		setValue(name, value);
	};

	const location = useLocation();
	const { legalPersonsOptionList, getLegalPersonsList } = useLegalPersons();
	const isLegalPerson = location.pathname.includes('juridica');

	const bankTypeFields = [
		isLegalPerson ? { name: 'Conta Escrow', value: `EscrowAccount` } : undefined,
		{ name: 'Pagamento', value: 'NaturalSimpleAccount' },
		{ name: 'Conta Corrente', value: `${isLegalPerson ? 'Legal' : 'Natural'}CheckingAccount` },
		{ name: 'Poupança', value: `${isLegalPerson ? 'Legal' : 'Natural'}SavingsAccount` }
	].filter(x => x !== undefined);

	const handleCloseModal = () => {
		setModal(null);
	};
	const handleModalTemplatePath = () => {
		setModal(false);
	};

	const handleModalTemplateDoc = () => {
		setModal(true);
	};

	let currentUploadTemplatePath = watch("templateBatchPath");
	let currentUploadTemplateDoc = watch("templateDoc");

	const isAdmin = user?.payload["cognito:groups"]?.includes("Admin");
	const isSeletorTenant = !!+window.__RUNTIME_CONFIG__.REACT_APP_SELETOR_TENANTS;
	const useScan3 = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCAN3;

	var selectedTenant =  watch("approverTenant");
	var filteredList = tenantList.filter(i => i.id === selectedTenant);
	var name = filteredList.length ? filteredList[0].name : selectedTenant;

	var selectedTenantOption = { id: selectedTenant, name: name};

	return (
		<>
			{modal != null &&
				<Modal
					title='Atualizar Arquivo de Cessão'
					open={handleModalTemplateDoc || handleModalTemplatePath}
					onClose={handleCloseModal}>
					<div className={classes.paper}>
						<UploadUpdateFile currentUpload={modal == true ? currentUploadTemplateDoc : currentUploadTemplatePath}
							fieldName={modal == true ? 'templateDoc' : 'templateBatchPath'} setValue={setValue} />
					</div>
				</Modal>
			}

			<Box py={3}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<TextFieldComponent
							id="name"
							name="name"
							fullWidth
							label="Nome"
							component={TextField}
							{...inputBind(`name`)} />
					</Grid>
					<Grid item xs={5}>
						<TextFieldComponent
							id="companyName"
							name="companyName"
							fullWidth
							label="Razão Social"
							component={TextField}
							{...inputBind(`companyName`)} />
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="registrationNumber"
							fullWidth
							name="registrationNumber"
							label="CNPJ"
							placeholder="00.000.000/0000-00"
							component={TextField}
							InputProps={{
								disableUnderline: true,
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.cnpjMask },
							}}
							{...inputBind("registrationNumber")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="email"
							fullWidth
							name="email"
							label="Email"
							component={TextField}
							{...inputBind("email")}
						/>
					</Grid>				
					<Grid item xs={5}>
						<AutocompleteWithSearch
							id="administratorPersonId"
							name="administratorPersonId"
							displayField="administratorPersonIdDisplay"
							fullWidth
							label="Administrador"
							watch={watch}
							setValue={setValue}
							params={{
								page: 0,
								size: 10
							}}
							fetch={getLegalPersonsList}
							options={legalPersonsOptionList}
							{...inputBind(`administratorPersonId`)}
							endAdornment={
								watch("administratorPersonId") ? <Link to={`/pessoa-juridica/editar?id=${watch("administratorPersonId")}`}>
									<SearchOutlinedIcon position="end" />
								</Link> : ''
							}
							showEndAdornment={watch("administratorPersonId")}
							onChange={(_, options) => {
								setValue('administratorPersonIdDisplay', options?.name);
								setValue('administratorPersonId', options?.id);
							}}
						/>
					</Grid>										
					<Grid item xs={3}>
						<SelectComponent
							id="externalId"
							name="externalId"
							label="Código da Carteira"
							fullWidth
							defaultValue=""
							{...inputBind(`externalId`)}
							required={true}
							fields={[
								{ name: "Carteira Própria", value: 1 },
								{ name: "Carteira Externa", value: 2 },
							]} />
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="signerMinimumQuorum"
							fullWidth
							label="Quórum mínimo de signatários"
							name="signerMinimumQuorum"
							component={TextField}
							{...inputBind("signerMinimumQuorum")}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box>
				{isAdmin && isSeletorTenant && !useScan3 && (
					<>
						<Typography
							variant="h6"
							noWrap
							component="span"
							className="disablePrint"
							sx={{ display: { xs: "none", sm: "block" } }}
							
						>
							Aprovação de crédito
						</Typography>
						<Grid item xs={2}>
							<Typography
								variant="h6"
								noWrap
								component="span"
								className="disablePrint"
								style={{ fontSize: 16, display: 'block', fontWeight: 'bold', paddingBottom: 10}}
							>
								Parceiro aprovador
							</Typography> 
						 <AutoCompleteTenant
								id="approverTenant"
								params={{
									page: 0,
									size: 10, 
								}}
								value={selectedTenantOption}
								fetch={getTenantList}
								options={tenantList}
								onChange={(_, option) => {
									
									setValue("approverTenant", option.id);
									setValue("approverTenantDisplay", option.name);
									
								}}
							/>
				   </Grid>
					</>
				)}
			</Box>
			<br />
			<Box>
				<Typography 
					variant="h6"
					noWrap
					component="span"
				>
					Custo de cessão
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<SelectComponent 
							id='assignmentCalculation.type'
							name='assignmentCalculation.type'
							label='Tipo do cálculo'
							fullWidth 
							defaultValue=''
							{...inputBind(`assignmentCalculation.type`)}
							required={true}
							fields={[
								{name: "Valor absoluto", value: 0}, 
								{name: "Percentual", value: 1}
							]}/>
					</Grid>
					{
						watch('assignmentCalculation.type' ) === 0 ? 
							<Grid item xs={2}>
								<NumberInput
									id="assignmentCalculation.amount"
									label="Custo de cessão"
									name="assignmentCalculation.amount"
									adornmentText="R$"
									{...inputBindNumber("assignmentCalculation.amount")}
								/> 
							</Grid> 
							:
							<Grid item xs={2}>
								<NumberInput
									required
									id="assignmentCalculation.amount"
									name="assignmentCalculation.amount"
									label="Custo de cessão (%)"
									type="number"
									adornmentText="%"
									fullWidth
									inputProps={{ min: "0", max: "10", step: "1" }}
									{...inputBindNumber("assignmentCalculation.amount")}
								/>
							</Grid> 

					}
				</Grid>
			</Box>
			<Box p={0} mt={3}>
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Relatório CNAB</Typography>
				<br />
				<Grid container spacing={3}>
					<Grid item xs={2}>
						<SelectComponent
							id="cnabType"
							name="cnabType"
							label="CNAB"
							{...inputBind("cnabType")}
							defaultValue={false}
							fields={[{ name: 'CNAB444', value: "444" }, { name: 'CNAB444 - REAG', value: "444REAG" }, { name: 'CNAB500', value: "500" },  { name: 'CNAB550', value: "550" }]} />
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="titleSpecies"
							fullWidth
							label="Espécie de Título"
							name="titleSpecies"
							component={TextField}
							{...inputBind("titleSpecies")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="assignorCode"
							fullWidth
							label="Codigo do cedente"
							name="assignorCode"
							component={TextField}
							{...inputBind("assignorCode")}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box p={0} mt={3}>
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Informações Bancárias</Typography>
				<br />
				<Grid container spacing={4}>
					<Grid item xs={2}>
						<SelectComponent
							id="bankAccount.type"
							name="bankAccount.type"
							label="Tipo da Conta"
							fullWidth
							defaultValue=""
							{...inputBind(`bankAccount.type`)}
							fields={bankTypeFields} />
					</Grid>
					<Grid item xs={2}>
						<SelectComponent
							id="bankAccount.bankCode"
							name="bankAccount.bankCode"
							label="Banco"
							fullWidth
							defaultValue=""
							{...inputBind(`bankAccount.bankCode`)}
							fields={bankCodeFields} />
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="bankAccount.agency"
							fullWidth
							label="Agência"
							name="bankAccount.agency"
							component={TextField}
							{...inputBind("bankAccount.agency")} />
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="bankAccount.agencyDigit"
							fullWidth
							label="Dígito da Agência"
							name="bankAccount.agencyDigit"
							component={TextField}
							{...inputBind("bankAccount.agencyDigit")} />
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="bankAccount.account"
							fullWidth
							label="Conta"
							name="bankAccount.account"
							component={TextField}
							{...inputBind("bankAccount.account")} />
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="bankAccount.accountDigit"
							fullWidth
							label="Dígito da Conta"
							name="bankAccount.accountDigit"
							component={TextField}
							{...inputBind("bankAccount.accountDigit")} />
					</Grid>
				</Grid>
			</Box>
			<br />

			<Box p={0} mt={3}>
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Endereço</Typography>
				<br />
				<Grid container spacing={4}>
					<Grid item xs={2}>
						<TextFieldComponent
							id="zipCode"
							fullWidth
							label="CEP"
							name="address.zipCode"
							placeholder="00000-000"
							component={TextField}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.zipCodeMask },
								onChange: (event) => {
									let value = Helper.onlyNumbers(event?.currentTarget?.value);
									if (value.length >= 8) {
										queryZipCodeAndFillForm(value, (fieldName, value) => {
											setValue(`address.${fieldName}`, value);
										});
									}
								},
							}}
							{...inputBind("address.zipCode")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="address"
							fullWidth
							label="Endereço"
							name="address.addressName"
							component={TextField}
							{...inputBind("address.addressName")}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="number"
							fullWidth
							type="number"
							label="Número"
							name="address.number"
							component={TextField}
							{...inputBind("address.number")}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="complement"
							fullWidth
							label="Complemento"
							name="address.complement"
							component={TextField}
							{...inputBind("address.complement")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="district"
							fullWidth
							label="Bairro"
							name="address.district"
							component={TextField}
							{...inputBind("address.district")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="city"
							fullWidth
							label="Cidade"
							name="address.city"
							component={TextField}
							{...inputBind("address.city")}
						/>
					</Grid>
					<Grid item xs={1}>
						<SelectComponent
							id="address-uf"
							name="address.uf"
							label="UF"
							fullWidth
							defaultValue=""
							{...inputBind(`address.uf`)}
							fields={UF} />
					</Grid>
				</Grid>
			</Box>

			<Box p={0} mt={3}>
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Fluxo de Cessão</Typography>
				<br />
				<Grid container spacing={4}>
					<Grid item xs={10}>
						<FormGroup>
							<FormControlLabel
								control={
									<CheckboxComponent
										id="assignment"
										color="primary"
										name="assignment"
										onClick={changeCheckBoxValue}
										inputProps={{ 'aria-label': 'secondary checkbox' }}
										{...inputBind("assignment")}
									/>}
								label="Executar Fluxo de Cessão" />
						</FormGroup>
					</Grid>
					{watch("assignment") == true && <>
						<Grid item xs={5}>
							<TextFieldComponent
								id="templateDoc.fileName"
								fullWidth
								label="Arquivo de Cessão Individual"
								name="templateDoc.fileName"
								component={TextField}
								placeholder="Inclua o arquivo de cessão individual"
								{...inputBind("templateDoc.fileName")}
								required={true}
								disabled={true}
								InputProps={{
									disableUnderline: true,
									endAdornment: (
										<>
											<>
												{isEditing() ? <a href={watch("templateDoc.tempGetUrl")} target="_blank">
													<Tooltip title="Download do Arquivo">
														<CloudDownloadIcon position="end" style={{ margin: '0 16', fontSize: 28 }} />
													</Tooltip>
												</a> : null}
												<div onClick={handleModalTemplateDoc}>
													<Tooltip title="Atualizar Arquivo">
														<CloudUploadIcon position="end" style={{ fontSize: 30, color: '#1e87f0', cursor: 'pointer' }} />
													</Tooltip>
												</div>
											</>
										</>)
								}} />
						</Grid>
						<Grid item xs={5}>
							<TextFieldComponent
								id="templateBatchPath.fileName"
								fullWidth
								label="Arquivo de Cessão em Lote"
								name="templateBatchPath.fileName"
								component={TextField}
								placeholder="Inclua o arquivo de cessão em lote"
								{...inputBind("templateBatchPath.fileName")}
								required={true}
								disabled={true}
								InputProps={{
									disableUnderline: true,
									endAdornment: (
										<>
											<>
												{isEditing() ? <a href={watch("templateBatchPath.tempGetUrl")} target="_blank">
													<Tooltip title="Download do Arquivo">
														<CloudDownloadIcon position="end" style={{ margin: '0 16', fontSize: 28 }} />
													</Tooltip>
												</a> : null}
												<div onClick={handleModalTemplatePath}>
													<Tooltip title="Atualizar Arquivo">
														<CloudUploadIcon position="end" style={{ fontSize: 30, color: '#1e87f0', cursor: 'pointer' }} />
													</Tooltip>
												</div>
											</>
										</>)
								}} />
						</Grid>
					</>}
					
					{watch("assignment") == true &&
						<>
							<Grid item xs={10}>
								<FormGroup>
									<FormControlLabel
										control={
											<CheckboxComponent
												id="useSignaturePortal"
												color="primary"
												name="useSignaturePortal"
												onClick={changeCheckBoxValue}
												inputProps={{ 'aria-label': 'secondary checkbox' }}
												{...inputBind("useSignaturePortal")}
											/>}
										label="Coletar assinatura" />
								</FormGroup>
							</Grid>

							{watch("useSignaturePortal") == true &&
								<>
									<Grid item xs={3}>
										<SelectComponent
											id="signatureType"
											name="signatureType"
											label="Forma de envio de Assinatura"
											fullWidth
											defaultValue=""
											{...inputBind(`signatureType`)}
											required={watch("useSignaturePortal") == true ? true : false}
											fields={[
												{ name: "Email", value: 0 },
												{ name: "Sms", value: 1 },
												{ name: "Whatsapp", value: 2 }
											]} />
									</Grid>
									<Grid item xs={3}>
										<NumberInput
											id="maximumValueWithoutDigitalSignature"
											label="Valor máximo sem assinatura digital"
											name="maximumValueWithoutDigitalSignature"
											adornmentText="R$"
											{...inputBindNumber("maximumValueWithoutDigitalSignature")} />
									</Grid>
									<Grid item xs={3}>
										<SelectComponent
											id="signaturePortalProvider"
											name="signaturePortalProvider"
											label="Certificadora"
											fullWidth
											defaultValue=""
											{...inputBind(`signaturePortalProvider`)}
											required={watch("useSignaturePortal") == true ? true : false}
											fields={[
												{ name: "RBM", value: 0 },
												{ name: "ClickSign", value: 1 },
											]} />
									</Grid>
									<Grid item xs={3}>
										{watch("signaturePortalProvider") === 1 && 
											<SelectComponent
												id="signatureValidation"
												name="signatureValidation"
												label="Tipo de Assinatura"
												fullWidth
												defaultValue=''
												{...inputBind(`signatureValidation`)}
												required={watch("useSignaturePortal") === true ? true : false}
												fields={[
													{ name: "Assinatura Eletrônica", value: "EletronicSignature", },
													{ name: "Assinatura com Selfie", value: "SelfieSignature", },
													{ name: "Selfie + Documento Oficial", value: "SelfieDocumentSignature" },
													{ name: "Biometria Facial", value: "FacialBiometrics" },
													{ name: "Certificado Digital", value:"DigitalCertificate" },
												]} />
										}
										{watch("signaturePortalProvider") === 0 &&
											<SelectComponent
												id="signatureValidation"
												name="signatureValidation"
												label="Tipo de Assinatura"
												fullWidth
												defaultValue=""
												{...inputBind(`signatureValidation`)}
												required={watch("useSignaturePortal") === true ? true : false}
												fields={[
													{ name: "Assinatura Eletrônica", value: "EletronicSignature", },
													{ name: "Assinatura com Selfie", value: "SelfieSignature" },
													{ name: "Certificado Digital", value: "DigitalCertificate" },
												]} />
										}
									</Grid>
								</>}
						</>}
					{watch("assignment") == true &&
						<>
							<Grid item xs={3}>
								<SelectComponent
									id="assignmentType"
									name="assignmentType"
									label="Tipo de Cessão"
									fullWidth
									defaultValue=""
									{...inputBind(`assignmentType`)}
									required={true}
									fields={[
										{ name: "E-mail", value: 0 },
										{ name: "FTP", value: 1 }]} />
							</Grid>							
							{watch("assignmentType") == 1 &&
								<Grid item xs={3}>
									<TextFieldComponent
										id="ftpHostName"
										fullWidth
										label="Host-FTP"
										name="ftpHostName"
										component={TextField}
										{...inputBind("ftpHostName")}
										required={true} />
								</Grid>}
							{watch("assignmentType") == 1 &&
								<Grid item xs={3}>
									<TextFieldComponent
										id="ftpUser"
										fullWidth
										label="Usuário-FTP"
										name="ftpUser"
										component={TextField}
										{...inputBind("ftpUser")}
										required={true} />
								</Grid>}
							{watch("assignmentType") == 1 &&
								<Grid item xs={3}>
									<TextFieldComponent
										id="FtpPassword"
										type={showFtpPassword ? 'text' : 'password'}
										fullWidth
										label="Senha-FTP"
										name="FtpPassword"
										component={TextField}
										{...inputBind("FtpPassword")}
										required={true}
										InputProps={{
											disableUnderline: true,
											endAdornment:
												<Button onClick={() => setShowFtpPassword(!showFtpPassword)}>
													<VisibilityIcon position="end" style={{ color: '#1e87f0' }} />
												</Button>
										}} />
								</Grid>}
							{watch("assignmentType") == 1 && <Grid item xs={3}>
								<TextFieldComponent
									id="pathLastro"
									fullWidth
									label="Caminho do Arquivo Lastro"
									name="pathLastro"
									component={TextField}
									{...inputBind("pathLastro")} />
							</Grid>}
							{watch("assignmentType") == 1 &&
								<Grid item xs={3}>
									<TextFieldComponent
										id="pathCnab"
										fullWidth
										label="Caminho do Arquivo CNAB"
										name="pathCnab"
										component={TextField}
										{...inputBind("pathCnab")} />
								</Grid>}
						</>}
				</Grid>
			</Box>
		</>
	);
}
export default InformationsFund;
