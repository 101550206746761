import { IProps } from 'global/interface/types';
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getEnumList } from 'services/enumList';

type GlobalContent = {
	enumOptionList: []
	setEnumOptionList: () => void
	getEnumRelatedBank: (page: number, size: number, search: string) => void
};

const EnumContext = createContext<GlobalContent>({ enumOptionList: [], setEnumOptionList: () => { }, getEnumRelatedBank: () => { } });

export const EnumRelatedBankProvider = ({ children }: IProps) => {
	const [enumOptionList, setEnumOptionList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: '' });

	useEffect(() => {
		getEnumRelatedBank(searchArgs.page, searchArgs.size, searchArgs.search);
	}, [searchArgs]);

	const getEnumRelatedBank = (page: number, size: number, search: string) => {
		getEnumList({ page, size, search }, "RelatedBank").then(resp => {
			if (resp) {
				setEnumOptionList(resp?.map((b: { name: string; value: string; valueAsInt: string; }) => {
					var valueAsInt = String(b.valueAsInt);
					if (valueAsInt.length == 1) { b.valueAsInt = valueAsInt.padStart(3, '0'); }
					return { name: `${b.valueAsInt} | ${b.name}`, value: b.value };
				}));
			}
		});
	};

	const contextGetEnumRelatedBankList = (page: number, size: number, search: string) => {
		if (page != searchArgs?.page, size != searchArgs?.size, search != searchArgs?.search) {
			setSearchArgs({ page, size, search });
		}
	};

	return (
		<EnumContext.Provider value={{
			enumOptionList: enumOptionList,
			setEnumOptionList: () => setEnumOptionList,
			getEnumRelatedBank: (page: number, size: number, search: string) => {
				return contextGetEnumRelatedBankList(page, size, search);
			},

		}}>
			{children}
		</EnumContext.Provider>
	);
};

export function useEnum() {
	const context = useContext(EnumContext);
	const {
		enumOptionList,
		getEnumRelatedBank
	} = context;
	return {
		enumOptionList,
		getEnumRelatedBank
	};
}
