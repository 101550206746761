import { IProps } from 'global/interface/types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getOperatorList } from 'services/operator';
import { useMyPermissions } from './permissions';
import { useTenant } from './tenant';

type GlobalContent = {
	operatorOptionList: []
	setOperatorOptionList: () => void
	getOperatorList: (page: number, size: number, name: string) => void
};

const OperatorContext = createContext<GlobalContent>({ operatorOptionList: [], setOperatorOptionList: () => { }, getOperatorList: () => { } });

export const OperatorProvider = ({ children }: IProps) => {
	const [operatorOptionList, setOperatorOptionList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, name: "" });
	const { selectedTenant } = useTenant();
	const { getMyPermissionLevel } = useMyPermissions();

	const hasPerm: boolean = !!getMyPermissionLevel("User", "read");

	useEffect(() => {
		if (!hasPerm)
			return;
		searchOperator(searchArgs.page, searchArgs.size, searchArgs.name, selectedTenant);
	}, [searchArgs, selectedTenant]);
	


	function searchOperator(page: number, size: number, name: string, selectedTenant: string | undefined) {
		return getOperatorList({ page, size, name, selectedTenant }).then(response => {
			setOperatorOptionList(response?.data);
		});
	};

	const contextGetOperatorList = (page: number, size: number, name: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || name != searchArgs?.name) {
			setSearchArgs({ page, size, name });
		}
	};

	return (
		<OperatorContext.Provider value={{
			operatorOptionList: operatorOptionList,
			setOperatorOptionList: () => setOperatorOptionList,
			getOperatorList: (page: number, size: number, name: string) => {
				return contextGetOperatorList(page, size, name);
			},
		}}>
			{children}
		</OperatorContext.Provider>
	);
};

export function useOperator() {
	const context = useContext(OperatorContext);
	const {
		operatorOptionList,
		getOperatorList
	} = context;
	return {
		operatorOptionList,
		getOperatorList
	};
}
