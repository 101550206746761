import React from 'react';

const PdfIcon = () => (
	<svg width="26" height="26" viewBox="0 0 36 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.4428 42V36.1818H12.4087C12.8652 36.1818 13.2383 36.2642 13.5281 36.429C13.8197 36.5919 14.0356 36.8125 14.1758 37.0909C14.3159 37.3693 14.386 37.6799 14.386 38.0227C14.386 38.3655 14.3159 38.6771 14.1758 38.9574C14.0375 39.2377 13.8235 39.4612 13.5337 39.6278C13.244 39.7926 12.8728 39.875 12.4201 39.875H11.011V39.25H12.3974C12.7099 39.25 12.9608 39.196 13.1502 39.0881C13.3396 38.9801 13.4769 38.8343 13.5621 38.6506C13.6493 38.465 13.6928 38.2557 13.6928 38.0227C13.6928 37.7898 13.6493 37.5814 13.5621 37.3977C13.4769 37.214 13.3387 37.0701 13.1474 36.9659C12.9561 36.8598 12.7023 36.8068 12.386 36.8068H11.1474V42H10.4428ZM17.3164 42H15.521V36.1818H17.396C17.9603 36.1818 18.4433 36.2983 18.8448 36.5312C19.2463 36.7623 19.5541 37.0947 19.7681 37.5284C19.9821 37.9602 20.0891 38.4773 20.0891 39.0795C20.0891 39.6856 19.9812 40.2074 19.7653 40.6449C19.5494 41.0805 19.235 41.4157 18.8221 41.6506C18.4092 41.8835 17.9073 42 17.3164 42ZM16.2255 41.375H17.271C17.752 41.375 18.1507 41.2822 18.467 41.0966C18.7833 40.911 19.0191 40.6468 19.1744 40.304C19.3297 39.9612 19.4073 39.553 19.4073 39.0795C19.4073 38.6098 19.3306 38.2055 19.1772 37.8665C19.0238 37.5256 18.7946 37.2642 18.4897 37.0824C18.1848 36.8987 17.805 36.8068 17.3505 36.8068H16.2255V41.375ZM21.271 42V36.1818H24.7596V36.8068H21.9755V38.7727H24.4982V39.3977H21.9755V42H21.271Z" fill="white"/>
		<path d="M0 0V46H36V12.5938L35.7188 12.2812L23.7188 0.28125L23.4062 0H0ZM2 2H22V14H34V44H2V2ZM24 3.4375L32.5625 12H24V3.4375Z" fill="white"/>
		<g clipPath="url(#clip0_11_12)">
			<path d="M29.63 26.3C28.92 25.555 27.464 25.13 25.406 25.13C24.306 25.13 23.029 25.236 21.645 25.484C20.7971 24.6686 20.0249 23.778 19.338 22.823C18.806 22.113 18.344 21.333 17.918 20.587C18.735 18.103 19.125 16.08 19.125 14.625C19.125 12.993 18.522 11.289 16.783 11.289C16.251 11.289 15.718 11.609 15.434 12.07C14.654 13.454 15.009 16.47 16.357 19.451C15.8496 20.9754 15.2815 22.479 14.654 23.958C14.086 25.307 13.447 26.691 12.737 27.968C8.834 29.53 6.314 31.34 6.03 32.758C5.924 33.291 6.101 33.788 6.492 34.178C6.634 34.285 7.131 34.711 7.982 34.711C10.572 34.711 13.305 30.523 14.689 28.004C15.754 27.649 16.819 27.294 17.883 27.01C19.0056 26.7051 20.1427 26.4565 21.29 26.265C24.022 28.713 26.435 29.104 27.642 29.104C29.132 29.104 29.665 28.5 29.842 28.004C30.162 27.364 29.948 26.655 29.629 26.3H29.63ZM28.21 27.33C28.103 27.862 27.57 28.217 26.826 28.217C26.613 28.217 26.436 28.181 26.222 28.146C24.874 27.826 23.596 27.152 22.319 26.087C23.3036 25.9205 24.3005 25.8375 25.299 25.839C26.045 25.839 26.684 25.874 27.109 25.981C27.606 26.087 28.387 26.407 28.209 27.329L28.21 27.33ZM20.686 25.662C19.6958 25.8476 18.7133 26.0724 17.741 26.336C16.893 26.5564 16.0525 26.8049 15.221 27.081C15.6533 26.2438 16.0558 25.3916 16.428 24.526C16.854 23.532 17.208 22.503 17.564 21.545C17.918 22.148 18.309 22.752 18.699 23.284C19.3367 24.097 19.9993 24.89 20.686 25.662V25.662ZM16.038 12.46C16.1007 12.3343 16.1967 12.2282 16.3156 12.1533C16.4344 12.0783 16.5715 12.0374 16.712 12.035C17.457 12.035 17.599 12.886 17.599 13.561C17.599 14.696 17.244 16.435 16.641 18.422C15.611 15.654 15.541 13.348 16.038 12.46ZM12.134 28.997C10.324 31.978 8.585 33.823 7.521 33.823C7.32902 33.8242 7.14202 33.762 6.989 33.646C6.776 33.433 6.669 33.185 6.74 32.901C6.953 31.836 9.011 30.346 12.134 28.997Z" fill="white"/>
		</g>
		<line x1="2" y1="5" x2="22" y2="5" stroke="white" strokeWidth="2"/>
		<line x1="2" y1="3" x2="22" y2="3" stroke="white" strokeOpacity="0.5" strokeWidth="2"/>
		<defs>
			<clipPath id="clip0_11_12">
				<rect width="24" height="24" fill="white" transform="translate(6 11)"/>
			</clipPath>
		</defs>
	</svg>
);

export default PdfIcon;