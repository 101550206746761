export const optionsActionsFields = [
	{
		name: 'Não',
		value: 'None'
	},
	{
		name: 'Encerrar etapa atual',
		value: 'EndCurrent'
	},
	{
		name: 'Encerrar etapa atual e iniciar nova',
		value: 'EndAndCreateNew'
	}
];