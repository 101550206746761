import React, { useState } from 'react';
import { ContentContainer } from 'components';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import Helper from 'helpers/format.helpers';
import { localizationOptions } from 'helpers/table.helpers';
import { getPersonsList } from 'services/person';
import { TablePagination, Typography, Tooltip, IconButton } from '@material-ui/core';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import {
	QueryCNPJIcon, ProtestoIcon, ProcessIcon, ProcuradoriaGeralIcon, RelatedTelephonesIcon,
	SintegraIcon, AddressIcon, KYCIcon, JudicialActionsIcon, RelatedPersonIcon, TelephoneIcon,
	CheckedIcon, NOTCheckedIcon,
} from 'assets';
import moment from 'moment';

const ScanList = (props) => {
	const { initialDate, finalDate } = props;
	const history = useHistory();
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const { setTitle } = useHeader();
	const { selectedTenant } = useTenant();

	const onRowsPerPageChange = (page) => {
		localStorage.setItem('rowsPerPage', page);
		setRowsPerPage(page);

	};

	const handleLink = (rowData) => {
		const discriminator = rowData?.discriminator;
		if (discriminator === "LegalPerson") {
			return history.push(`/pessoa-juridica/editar?id=${rowData.id}`);
		} else return history.push(`/pessoa-fisica/editar?id=${rowData.id}`);
	};

	return (
		<ContentContainer>
			<MaterialTable
				title={"Histórico de Consultas de Pessoas"}
				columns={[
					{ title: <Typography shrink style={{ color: "#5F5F5F", fontSize: 21 }}>Nome</Typography>, field: 'name', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 375 } },
					{ title: <Typography shrink style={{ color: "#5F5F5F", fontSize: 21 }}>Documento</Typography>, field: 'registrationNumber', render: ({ registrationNumber }) => (Helper.formatDocumentNumber(registrationNumber)), cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 250 } },
					{
						title:
							<Tooltip title="Consulta de CNPJ">
								<IconButton>
									<div style={{ fontSize: 14, minWidth: 99, display: 'inline-block', textAlign: 'center' }}><QueryCNPJIcon /></div>
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("basic_data") ?
							<div style={{ fontSize: 14, minWidth: 100, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 100, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Protesto">
								<IconButton>
									<ProtestoIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("ondemand_query_pesquisaprotesto") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Processos">
								<IconButton>
									<ProcessIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("processes") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Procuradoria Geral">
								<IconButton>
									<ProcuradoriaGeralIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("ondemand_pgfn") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Sintegra">
								<IconButton>
									<SintegraIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("ondemand_sintegra") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Endereço">
								<IconButton>
									<AddressIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("addresses_extended") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="KYC e Compliance">
								<IconButton>
									<KYCIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("kyc") ?
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Ações Judiciais">
								<IconButton>
									<JudicialActionsIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("owners_lawsuits") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Pessoas Relacionadas">
								<IconButton>
									<RelatedPersonIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("relationships") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					},
					{
						title:
							<Tooltip title="Telefones">
								<IconButton>
									<TelephoneIcon />
								</IconButton>
							</Tooltip>, render: ({ datasets }) => datasets?.includes("phones_extended") ? 
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><CheckedIcon /></div> :
							<div style={{ fontSize: 14, minWidth: 50, display: 'inline-block', textAlign: 'center' }}><NOTCheckedIcon /></div>
					}
				].filter(x => x !== undefined)}
				onRowClick={
					(_, rowData) => handleLink(rowData)
				}
				data={(allParams) =>
					new Promise((resolve, reject) => {
						const { page, pageSize, search } = allParams;

						let filterFinalDate = null;
						let filterInitialDate = null;

						if (finalDate) {
							filterFinalDate =
								moment(finalDate).isValid() &&
								moment(finalDate).format("YYYY-MM-DD") + "T23:59:59";
						}

						if (initialDate) {
							filterInitialDate =
								moment(initialDate).isValid() &&
								moment(initialDate).format("YYYY-MM-DD") + "T00:00:00";
						}

						const filtersValues = {
							page,
							size: pageSize,
							searchString: search,
							initialDate: filterInitialDate,
							finalDate: filterFinalDate,
							withDatasets: true
						};

						getPersonsList(filtersValues, selectedTenant)
							.then(result => {
								setTitle("Dashboard");
								if (result)
									resolve({
										data: result.data,
										page: result.page,
										totalCount: result.totalItems,
									});
								else {
									resolve({
										data: [],
										page: 0,
										totalCount: 0,
									});
								}
							}).catch(err => reject(err));
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					rowStyle: {
						fontSize: 16,

					},
				}}
			/>
		</ContentContainer>
	);
};

export default ScanList;
