import React from 'react';

export const SintegraIcon = ({ className }) => {
	return (
		<svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M24.875 4.08333L23 2.33333L21.125 4.08333L19.25 2.33333L17.375 4.08333L15.5 2.33333L13.625 4.08333L11.75 2.33333L9.875 4.08333L8 2.33333V18.6667H4.25V22.1667C4.25 24.1033 5.925 25.6667 8 25.6667H23C25.075 25.6667 26.75 24.1033 26.75 22.1667V2.33333L24.875 4.08333ZM19.25 23.3333H8C7.3125 23.3333 6.75 22.8083 6.75 22.1667V21H19.25V23.3333ZM24.25 22.1667C24.25 22.8083 23.6875 23.3333 23 23.3333C22.3125 23.3333 21.75 22.8083 21.75 22.1667V18.6667H10.5V5.83333H24.25V22.1667Z" fill="#5F5F5F" />
			<path d="M19.25 8.16666H11.75V10.5H19.25V8.16666Z" fill="#5F5F5F" />
			<path d="M23 8.16666H20.5V10.5H23V8.16666Z" fill="#5F5F5F" />
			<path d="M19.25 11.6667H11.75V14H19.25V11.6667Z" fill="#5F5F5F" />
			<path d="M23 11.6667H20.5V14H23V11.6667Z" fill="#5F5F5F" />
		</svg>
	);
};
