import { string, object, lazy, number } from 'yup';

const genericRequired = 'Esse campo é obrigatório.';

const isNullOrEmpty = (value: any) => {
	return value == null || value == "";
};

export const getValidationSchema = object().shape({
	bankAccountProductId: string().required(genericRequired),
	ownerId: string().required(genericRequired),
	type: string().nullable(),
	partnerName: string().required(genericRequired),
	cardName: string().nullable(),
	quorumMinimumApproval: number().transform((value, original) => {
		return isNaN(value) ? 0 : value;
	}),
	cardReceiptAddress: lazy(value => {
		var nothingIsFiled = isNullOrEmpty(value?.addressName) && isNullOrEmpty(value?.zipCode) && isNullOrEmpty(value?.city)
			&& isNullOrEmpty(value?.uf) && isNullOrEmpty(value?.district) && isNullOrEmpty(value?.number);
		return nothingIsFiled ? object().nullable().transform((value, _) => {
			return Object.values(value).every(x => x === null) ? null : value;
		}) : object().shape({
			addressName: string().nullable(),
			zipCode: string().nullable(),
			city: string().nullable(),
			uf: string().nullable(),
			district: string().nullable(),
			number: string().nullable(),
			complement: string().nullable()
		}).transform((value, _) => {
			return Object.values(value).every(x => x === null) ? null : value;
		});
	}),
	reason: string().typeError("Prezado(a) cliente, é necessário informar o motivo para abrir a conta.").required(genericRequired),
	otherReason: string().when('reason', {
		is: "Others",
		then: string().typeError("Outro motivo é obrigatório.").required("Outro motivo: Precisa ser preenchido."),
		otherwise: string().nullable()
	}),
});

export const defaultValues = {
	bankAccountProductId: "",
	ownerId: "",
	type: 'Payment',
	partnerName: "",
	cardName: null,
	cardReceiptAddress: null,
	reason: "Credit",
	step: 6,
	quorumMinimumApproval: 2
};
