import React, { useState } from 'react';
import { Grid, Box, TextField, FormGroup, FormControlLabel, Tooltip, Modal, Typography } from '@material-ui/core';
import CheckboxComponent from "components/CheckBox/Checkbox";
import TextFieldComponent from 'components/TextInput/TextField';
import SelectComponent from 'components/Select/Select';
import NumberInput from "components/NumberInput/NumberInput";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from "@material-ui/core/styles";
import UploadFile from "components/Upload/UploadFile/UploadFile";

const useStyles = makeStyles((theme) => ({
	paper: {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		position: "absolute",
		width: 940,
		height: 245,
		backgroundColor: "#ffffff",
		boxShadow: theme.shadows[6],
		padding: theme.spacing(5, 6, 3),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '15px'
	}
}));

function InformationsProductAccount({ isEditing, inputBind, inputBindNumber, setValue, watch }: any) {

	const classes = useStyles();
	const [modal, setModal] = useState(false);

	//@ts-ignore
	const changeCheckBoxValue = (event) => {
		const value = event.target.checked;
		const name = event.target.name;
		setValue(name, value);
	};

	const closeModal = () => {
		setModal(false);
	};

	const openModal = () => {
		setModal(true);
	};

	let currentUpload = watch("templateDoc");

	return (
		<>
			{modal &&
				<Modal
					title='Atualizar Arquivo'
					//@ts-ignore
					open={openModal}
					onClose={closeModal}>
					<div className={classes.paper}>
						<UploadFile currentUpload={currentUpload} fieldName={'templateDoc'} setValue={setValue} />
					</div>
				</Modal>
			}
			<Box py={3}>
				<Grid container spacing={4}>
					<Grid item xs={6}>
						<TextFieldComponent
							id="name"
							fullWidth
							label="Nome do Produto"
							name="name"
							component={TextField}
							{...inputBind("name")} />
					</Grid>
					<Grid item xs={6}>
						<TextFieldComponent
							id="templateDoc.fileName"
							fullWidth
							label="Caminho do Arquivo de Contrato"
							name="templateDoc.fileName"
							component={TextField}
							{...inputBind("templateDoc.fileName")}
							required={watch('useSignaturePortal') ? true : false}
							disabled={true}
							InputProps={{
								disableUnderline: true,
								endAdornment: (
									<>
										<>
											{isEditing() ? <a href={watch("templateDoc.tempGetUrl")} target="_blank">
												<Tooltip title="Download do Arquivo">
													{/*@ts-ignore*/}
													<CloudDownloadIcon position="end" style={{ margin: '0 16', fontSize: 26 }} />
												</Tooltip>
											</a> : null}
											<div onClick={openModal}>
												<Tooltip title="Atualizar Arquivo">
													{/*@ts-ignore*/}
													<CloudUploadIcon position="end" style={{ fontSize: 32, color: '#1e87f0', cursor: 'pointer' }} />
												</Tooltip>
											</div>
										</>
									</>)
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<SelectComponent
							id="bankingAccountType"
							name="bankingAccountType"
							label="Tipo de Conta"
							fullWidth
							defaultValue=""
							{...inputBind(`bankingAccountType`)}
							fields={[
								{ name: "Conta de Livre Movimentação", value: "Payment" },
								{ name: "Conta Escrow", value: "Escrow" },
							]} />
					</Grid>
					<Grid item xs={3}>
						<SelectComponent
							id="viewType"
							name="viewType"
							label="Visibilidade do Produto"
							fullWidth
							defaultValue=""
							{...inputBind(`viewType`)}
							required={true}
							fields={[
								{ name: "Aberto", value: "TopToBottom" },
								{ name: "Fechado", value: "BottomToTop" },
							]} />
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="rateExternalId"
							fullWidth
							label="ID do Pacote"
							name="rateExternalId"
							placeholder='informe o Id do pacote'
							component={TextField}
							{...inputBind("rateExternalId")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="packageValue"
							label="Valor do Pacote"
							cols={2}
							name="packageValue"
							adornmentText="R$"
							{...inputBindNumber("packageValue")}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box p={0} mt={2}>
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Parâmetros de Assinatura</Typography>
				<br />
				<Grid container spacing={4}>
					<Grid item xs={2}>
						<FormGroup>
							<FormControlLabel
								control={
									<CheckboxComponent
										id="useSignaturePortal"
										color="primary"
										name="useSignaturePortal"
										onClick={changeCheckBoxValue}
										inputProps={{ 'aria-label': 'secondary checkbox' }}
										{...inputBind("useSignaturePortal")}
									/>}
								label="Coletar assinatura" />
						</FormGroup>
					</Grid>
					{watch("useSignaturePortal") == true && <>
						<Grid item xs={3}>
							<SelectComponent
								id="signatureType"
								name="signatureType"
								label="Forma de envio para Assinatura"
								fullWidth
								defaultValue=""
								{...inputBind(`signatureType`)}
								required={watch("useSignaturePortal") == true ? true : false}
								fields={[
									{ name: "Email", value: "email" },
									{ name: "Sms", value: "sms" },
									{ name: "Whatsapp", value: "whatsapp" }
								]} />
						</Grid>
						<Grid item xs={3}>
							<SelectComponent
								id="signaturePortalProvider"
								name="signaturePortalProvider"
								label="Certificadora"
								fullWidth
								defaultValue=""
								{...inputBind(`signaturePortalProvider`)}
								fields={[
									{ name: "ClickSign", value: "ClickSign" },
									{ name: "RBM", value: "RBM" },
								]}
								required={true} />
						</Grid></>}
					<Grid item xs={3}>
						<FormGroup>
							<FormControlLabel
								control={
									<CheckboxComponent
										id="selfieSignature"
										color="primary"
										name="selfieSignature"
										onClick={changeCheckBoxValue}
										inputProps={{ 'aria-label': 'secondary checkbox' }}
										{...inputBind("selfieSignature")}
									/>}
								label="Assinatura com Selfie" />
						</FormGroup>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}
export default InformationsProductAccount;
