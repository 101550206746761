import React, { useState } from 'react';
import * as cognitoRepository from 'repository/cognito';

import qs from 'query-string';
import {
	InputWithLabel,
	DangerAlert,
	ContentContainer,
	PrimaryButton,
	LinkButton,
	Toast
} from 'components';
import {
	signIn
} from '../../../services/user';
import HelpLinkList from '../HelpLinkList';
import {
	LoginContainerStyled,
	LoginContentStyled
} from 'assets/style';
import LoginHeader from 'pages/Login/Header';
import { useUser } from 'contexts/user';

const CodeConfirmation = (props) => {
	const { setUser } = useUser();
	const [state, setState] = useState({
		confirmationCode: '',
		errors: { confirmationCode: null, message: null }
	});

	const { history, location } = props;
	const queryParams = qs.parse(history.location.search);

	origin = queryParams.origin;


	const changeFieldValue = (fieldName) => (event) => {
		setState({ ...state, [fieldName]: event.target.value });
	};

	const confirmCode = () => {		
		const errors = validateFields();
		setState({ ...state, errors });
		if (Object.keys(errors).length > 0) {
			return;
		}

		const { confirmationCode } = state;
		const { history } = props;
		cognitoRepository.validateCodeTOTP(confirmationCode)
			.then((response) => {
				Toast.showSuccessMessage('Código validado com sucesso.', 'Acesso confirmado');
				setUser({
					...response,
					authenticated: true
				});
				history.push('/');
			}).catch(error => {
				if (error.message?.indexOf("is CONFIRMED") > -1) {
					// try to update the user attributes

					history.push('/');
				}
				Toast.showErrorMessage('Não foi possível confirmar a conta. Por favor, tente novamente', 'Erro ao confirmar a conta', error);
			});
	};

	const confirmSetup = () => {
		const errors = validateFields();
		setState({ ...state, errors });
		if (Object.keys(errors).length > 0) {
			return;
		}

		const { confirmationCode } = state;
		const { history } = props;
		cognitoRepository.continueMFA(confirmationCode)
			.then((response) => {
				cognitoRepository.getCurrentUser().then(user => {
					Toast.showSuccessMessage('Acesso confirmado com sucesso.', 'Conta confirmada');
					setUser({
						...user,
						authenticated: true
					});
					history.push('/');
				});
			}).catch(error => {
				if (error.message?.indexOf("is CONFIRMED") > -1) {
					// try to update the user attributes

					history.push('/');
				}
				Toast.showErrorMessage('Não foi possível confirmar a conta. Por favor, tente novamente', 'Erro ao confirmar a conta', error);
			});
	};

	const validateFields = () => {
		const errors = {};

		if (!state.confirmationCode) {
			errors.confirmationCode = 'Campo "Código" é obrigatório';
		}

		return errors;
	};

	const { confirmationCode, errors } = state;
	return (
		<LoginContainerStyled>
			<ContentContainer>
				<LoginContentStyled>
					<form onSubmit={e => { queryParams.setup ? confirmSetup() : confirmCode(); e.preventDefault(); } }>
						<LoginHeader title='Confirmação de código MFA' subtitle='Para acessar o sistema, insira abaixo o código que está presente em seu aplicativo de MFA.' />
						<DangerAlert msg={errors.message} />
						<div className='wt-form uk-form-stacked'>
							<div className='uk-margin-top'>
								<InputWithLabel label='Código'
									placeholder='Código de verificação'
									type='number'
									errorMessage={errors.confirmationCode}
									value={confirmationCode}
									onChange={changeFieldValue('confirmationCode')} />
							</div>
							<div className='uk-margin-top'>						
								<div>
									<PrimaryButton type="submit" className='uk-width-1-1'>Confirmar</PrimaryButton>
								</div>
							</div>
							<div className='uk-margin-top'><small><i>Caso tenha perdido seu dispositivo, entre em contato com o administrador para definir um novo código de autenticação.</i></small></div>
						</div>
					</form>					
				</LoginContentStyled>
				<HelpLinkList />
			</ContentContainer>
		</LoginContainerStyled>
	);

};

export default CodeConfirmation;