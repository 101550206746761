import React from 'react';
// import PropTypes from 'prop-types';


interface ITransactionChartPayloadProps {
	active: boolean;
	payload: any[];
}


const TransactionChartPayload = ({ active, payload }: ITransactionChartPayloadProps) => {

	if (!active || !payload) {
		return null;
	}

	return (
		<div style={{ backgroundColor: 'white', border: '1px solid #cccccc', padding: 10 }}>
			<div style={{ color: payload[0].payload.fill }}>
				<div>{payload[0].payload.payload.key}: <b>{` ${payload[0].payload.value.toFixed(0)}`}</b></div>
			</div>
		</div>
	);
};

// TransactionChartPayload.propTypes = {
// 	active: PropTypes.bool,
// 	payload: PropTypes.array I
// };

export default TransactionChartPayload;