
export const optionsStatusFiltering = [
	{
		name: 'Aprovado',
		value: 'Approved'
	},
	{
		name: 'Aguardando aprovação',
		value: 'Pending'
	},
	{
		name: 'Rejeitado',
		value: 'Rejected'
	},
	{
		name: 'Tempo limite esgotado',
		value: 'Timeout'
	},
	{
		name: 'Cancelado pelo usuário',
		value: 'Canceled'
	},
	{
		name: 'Erro na autorização',
		value: 'Error'
	},
	{
		name: 'Em processamento',
		value: 'Processing'
	},
	{
		name: 'Efetivada',
		value: 'Finished'
	},
];