import { createContext, useContext, useState, useEffect } from 'react';
import { useTenant } from 'contexts/tenant';
import * as fundServices from 'services/fund';
import { useUser } from 'contexts/user';
import { useMyPermissions } from './permissions';
import { IProps } from 'global/interface/types';

type GlobalFundContent = {
	fundList: [],
	setFundList: () => void,
	fundById: (id: string) => any,
	getFundsList: (page: number, size: number, search: string) => void,
	fundOptionsList: [],
};
const FundContext = createContext<GlobalFundContent>({ fundList: [], setFundList: () => { }, fundById: () => { }, getFundsList: () => { }, fundOptionsList: [] });

export const FundProvider = ({ children }: IProps) => {
	// @ts-ignore
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';
	const [fundList, setFundList] = useState<[]>([]);
	const [fundOptionsList, setFundOptionsList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: "" });
	const { selectedTenant } = useTenant();
	const { user } = useUser();
	const { getMyPermissionLevel } = useMyPermissions();

	const hasFundPerm: boolean = !!getMyPermissionLevel("Fund", "read");

	useEffect(() => {
		if (!user?.authenticated || !hasFundPerm) return;
		searchFund(searchArgs.page, searchArgs.size, searchArgs.search, selectedTenant);
	}, [selectedTenant, searchArgs, user]);

	const searchFund = (page: number, size: number, search: string | undefined, selectedTenant: string | undefined) => {
		return fundServices.getFundsList({ page, size, searchString: search }, selectedTenant).then(response => {
			setFundOptionsList(response?.data.map((n: { name: any; tenantDisplay: any; id: any; }) => {
				return { name: isRootTenancy ? `${n.name || "Não informado"} (${n.tenantDisplay})` : n.name, value: n.id };
			}));
			setFundList(
				response?.data?.map((f: any) => {
					return { name: f.name, value: f.id };
				})
			);
		});
	};

	const fundById = (id: any) => {
		return new Promise((resolve, reject) => {
			// @ts-ignore
			var fund = fundList.find(p => p.value == id);
			if (fund)
				resolve(fund);

			fundServices.getFundById(id).then(async resp => {
				if (resp) {
					resolve(resp);
				}
			}).catch(err => {
				reject(err);
			});

		});
	};

	const contextualGetFundsList = (page: number, size: number, search: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || search != searchArgs?.search) {
			setSearchArgs({ page, size, search });
		}
	};

	return (
		<FundContext.Provider
			value={{
				fundById: (id: string) => fundById(id),
				fundList: fundList,
				setFundList: () => { },
				getFundsList: (page: number, size: number, search: string) => {
					return contextualGetFundsList(page, size, search);
				},
				fundOptionsList
			}}
		>
			{children}
		</FundContext.Provider>
	);
};

export function useFund() {
	const context = useContext(FundContext);
	const {
		fundList,
		getFundsList,
		fundOptionsList,
		fundById
	} = context;
	return {
		fundList,
		getFundsList,
		fundOptionsList,
		fundById
	};
}
