import { boolean, object, string } from "yup";

const genericRequired = 'Esse campo é obrigatório.';

export const validationApproveLiquidationSchema = object().shape({
	cosifAccountCode: string().required(genericRequired),
	isAutomaticBankTransfer: boolean().required(genericRequired).nullable()
});

export const defaultValuesApproveLiquidatiom = {
	cosifAccountCode: "", 
	isAutomaticBankTransfer: false
};