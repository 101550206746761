import React from 'react';
import ScrDataset from '../../../components/Datasets/ScrDataset';
import RequestCreditStatus from '../../../components/RequestCreditStatus/RequestCreditComponent';
import { Box } from '@material-ui/core';
import FgtsDataset from '../../../components/Datasets/FgtsDataset';

function NaturalCreditDatasets({ setLoading, loading, personId, person }) {

	const useSCR = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCR_DATASET;
	const useRequestCredit = !!+window.__RUNTIME_CONFIG__.REACT_APP_REQUEST_CREDIT;

	return (
		<Box py={2}>
			{useRequestCredit && <RequestCreditStatus person={person} />}
			<FgtsDataset loading={loading} setLoading={setLoading} idPerson={personId} typePerson={'NaturalPerson'} />
			{useSCR && <ScrDataset loading={loading} setLoading={setLoading} idPerson={personId} typePerson={'NaturalPerson'} />}
		</Box>
	);
}

export default NaturalCreditDatasets;