import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { createAmortizationSimulation } from '../../../services/amortization';
import { ContentContainer, Toast } from 'components';
import OperationsContent from '../Tabs/operationsContent';
import getFormFunctions from 'helpers/form-helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState } from 'react-hook-form';
import { getValidationSchema, getDefaultValues } from './schema/validation';
import { useHeader } from 'contexts/header';
import ModalWithLoading from 'components/ModalWithLoading-deprecated/ModalDeprecated';
import PrintConfiguration from './printConfiguration';
import ImageOutlined from '@material-ui/icons/ImageOutlined';
import PrintIcon from '@material-ui/icons/Print';

const ActionBar = (props) => {
	const {
		onPrint,
		onSimulate,
		disabledAll

	} = props;

	return (
		<Box mb={2} className="disablePrint">
			<Grid container spacing={1}>
				{onSimulate && <Grid item>
					<Button type="submit" className="button-actionBar" variant="contained" disabled={!onSimulate || disabledAll} style={{ backgroundColor: "#FFFFFF" }}>
						<ImageOutlined style={{ color: "#5F5F5F" }} />
						<Typography component="span" style={{ color: "#5F5F5F", textTransform: 'none', }}>&nbsp;Simular</Typography>
					</Button>
				</Grid>}

				{onPrint && <Grid item>
					<Button className="button-actionBar" color="primary" variant="contained" disabled={disabledAll} onClick={onPrint}>
						<PrintIcon />&nbsp;Imprimir
					</Button>
				</Grid>}
			</Grid>
		</Box >

	);
};

const SimulateCCB = () => {

	const [loading, setLoading] = useState(false);
	const [product, setProduct] = useState(null);
	const { control, getValues, setValue, handleSubmit, watch, reset } = useForm({
		resolver: yupResolver(getValidationSchema(product)),
		defaultValues: getDefaultValues(product)
	});
	const { errors } = useFormState({ control });
	const [simulationResponse, setSimulationResponse] = useState(null);
	const [modalPrintConfiguration, setModalPrintConfiguration] = useState(false);
	const [removedFromPrintList, setRemovedFromPrintList] = useState([]);
	const { setTitle } = useHeader();

	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema: getValidationSchema(product), control, errors, setValue });

	var productForm = watch("product");
	useEffect(() => {
		setTitle("Crédito - Simular operação");
		setProduct(productForm);
	}, [productForm]);

	const handleCloseModal = () => {
		setModalPrintConfiguration(false);
	};

	const handleOpenModal = () => {
		setModalPrintConfiguration(true);
	};

	const handleOnChange = (ev) => {

		var item = ev.target.id;
		var currentItemIndex = removedFromPrintList.indexOf(item);
		if (currentItemIndex == -1) {
			setRemovedFromPrintList([...removedFromPrintList, item]);
		}
		else {
			var modifiedRemovedFromPrint = [...removedFromPrintList];
			modifiedRemovedFromPrint.splice(currentItemIndex, 1);
			setRemovedFromPrintList([...modifiedRemovedFromPrint]);
		}
	};

	const simulate = (values) => {

		setSimulationResponse(null);
		setLoading(true);
		createAmortizationSimulation(values)
			.then(({ data = {} }) => {
				setLoading(false);
				setSimulationResponse(data);
			})
			.catch(error => { 
				setLoading(false);
				console.log(error); });

	};

	const actions = {
		onPrint: handleOpenModal,
		onSimulate: simulate
	};

	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const onSubmit = (values) => {
		simulate(values);
	};

	return (
		<ContentContainer>
			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<ActionBar {...actions} hideSubmit={true} />
				<Box p={3}>
					<Grid container spacing={3}>
						<Grid container
							direction="row"
							justifyContent="center"
							alignItems="center">
							<OperationsContent {...{ product: watch("product"), removedFromPrintList, inputBindNumber, inputBind, simulationResponse, getValues, watch, setValue, simulation: true }} />
						</Grid>
					</Grid>
				</Box>
			</form>
			<Grid container justifyContent='center' spacing={5}>
				{modalPrintConfiguration &&
					<ModalWithLoading
						className="disablePrint"
						title='Configurar Impressão'
						onCloseModal={handleCloseModal}>
						{modalPrintConfiguration && (
							<PrintConfiguration {...{ removedFromPrintList, setModalPrintConfiguration, handleOnChange }} />
						)}
					</ModalWithLoading>}
			</Grid>
		</ContentContainer >
	);
};
export default SimulateCCB;
