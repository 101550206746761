
import axios, { AxiosError } from 'axios';
import { getToken } from '../repository/cognito';
import { ApiConfig } from "../config";
import Helper from 'helpers/message.helpers';

export const getEnumList = async (filters: { page: number, size: number, search: string }, enumName: string) => {
	const { page, size, search } = filters;

	const token = await getToken();
	var url = `${ApiConfig.baseURL}/Enum/${enumName}`;
	if (search) {
		url += `?search=${search}&page=${page}&size=${size}`;
	};

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	try {
		const response = await axios.get(url, config);
		return response.data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors("Ocorreu um erro ao obter a lista de opções.", error);
		throw error;
	}
};
