import { DatePicker } from 'components';
import { Box, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import TextFieldComponent from 'components/TextInput/TextField';
import Helper from 'helpers/format.helpers';
import TextCustomMask from 'components/CustomMaskInput/TextCustomMask';
import NumberInput from "components/NumberInput/NumberInput";
import SelectComponent from "components/Select/Select";
import { queryZipCodeAndFillForm } from "services/zipCode";
import UF from "global/enums/uf";
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AutocompleteWithSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { useLegalPersons } from 'contexts/legalPerson';

function InformationsNaturalPerson(props) {
	const {
		inputBind,
		inputBindNumber,
		isEditing,
		isFieldRequired,
		onChangeNumber,
		control,
		setValue,
		watch
	} = props;
	const { legalPersonsOptionList, getLegalPersonsList } = useLegalPersons();

	const handleLink = () => {
		const personId = watch('workplaceId');
		return `/pessoa-juridica/editar?id=${personId}`;
	};

	return (
		<>
			<Box p={3}>
				<Typography component={'span'} variant="h6" gutterBottom>
					Informações Básicas
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<TextFieldComponent
							id="name"
							name="name"
							label="Nome"
							fullWidth
							component={TextField}
							{...inputBind("name")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="email"
							fullWidth
							name="email"
							label="Email"
							component={TextField}
							{...inputBind("email")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="registrationNumber"
							name="registrationNumber"
							label="CPF"
							placeholder="000.000.000-00"
							autoComplete="registrationNumber"
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.cpfMask },
							}}
							{...inputBind("registrationNumber")}
							disabled={isEditing()}
						/>
					</Grid>
					<Grid item xs={2}>
						<SelectComponent
							id="pep"
							name="pep"
							label="PEP"
							{...inputBind("pep")}
							defaultValue={false}
							fields={[{ name: 'Não', value: false }, { name: 'Sim', value: true }]}
						/>
					</Grid>
					<Grid item xs={2}>
						<SelectComponent
							id="documentType"
							label="Tipo de documento"
							name="documentType"
							{...inputBind("documentType")}
							defaultValue=""
							fields={[{ name: 'RG', value: 'RG' }, { name: 'CNH', value: 'CNH' }, { name: 'CTPS', value: 'CTPS' }]}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="documentNumber"
							label="Documento de identidade"
							name="documentNumber"
							component={TextField}
							{...inputBind("documentNumber")}
						/>
					</Grid>

					<Grid item xs={2}>
						<TextFieldComponent
							id="documentIssuer"
							label="Emissor/UF"
							name="documentIssuer"
							component={TextField}
							{...inputBind("documentIssuer")}
						/>
					</Grid>
					<Grid item xs={1}>
						<DatePicker
							id="documentDate"
							label="Data de emissão"
							variant="inline"
							{...inputBind("documentDate")}
							name="documentDate"
							disableFuture
							InputLabelProps={{ shrink: true, required: isFieldRequired("documentDate") }}
						/>
					</Grid>
					<Grid item xs={1}>
						<DatePicker
							id="documentExpiration"
							label="Data de expiração"
							variant="inline"
							{...inputBind("documentExpiration")}
							name="documentExpiration"
							InputLabelProps={{ shrink: true, required: isFieldRequired("documentExpiration") }}
						/>
					</Grid>
					<Grid item xs={2}>
						<DatePicker
							id="birthDate"
							name="birthDate"
							label="Data de nascimento"
							variant="inline"
							disableFuture
							{...inputBind("birthDate")}
							InputLabelProps={{ shrink: true, required: isFieldRequired("birthDate") }}
						/>
					</Grid>
					<Grid item xs={2}>
						<SelectComponent
							id="civilStatus"
							name="civilStatus"
							label="Estado Civil"
							{...inputBind("civilStatus")}
							defaultValue=""
							fields={[
								{ name: 'Não informado', value: "" },
								{ name: 'Casado(a)', value: "Married" },
								{ name: 'Viúvo(a)', value: "Widowed" },
								{ name: 'Separado(a)', value: "Separated" },
								{ name: 'Divorciado(a)', value: "Divorced" },
								{ name: 'Solteiro(a)', value: "Single" }]}
						/>
					</Grid>
					<Grid item xs={2}>
						<SelectComponent
							id="gender"
							label="Sexo"
							name="gender"
							{...inputBind("gender")}
							defaultValue="NotInformed"
							fields={[{ name: 'Não informado', value: "NotInformed" }, { name: 'Masculino', value: "Male" }, { name: 'Feminino', value: "Female" }]}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="mothersName"
							fullWidth
							label="Nome da mãe"
							name="mothersName"
							{...inputBind("mothersName")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="fathersName"
							fullWidth
							label="Nome do pai"
							name="fathersName"
							{...inputBind("fathersName")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="nationality"
							fullWidth
							label="Nacionalidade"
							name="nationality"
							component={TextField}
							{...inputBind("nationality")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="zipCode"
							fullWidth
							label="CEP"
							name="address.zipCode"
							placeholder="00000-000"
							component={TextField}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.zipCodeMask },
								onChange: (event) => {
									let value = Helper.onlyNumbers(event?.currentTarget?.value);
									if (value.length >= 8) {
										queryZipCodeAndFillForm(value, (fieldName, value) => {
											setValue(`address.${fieldName}`, value);
										});
									}
								},
							}}
							{...inputBind("address.zipCode")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="address"
							fullWidth
							label="Endereço"
							name="address.addressName"
							component={TextField}
							{...inputBind("address.addressName")}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="number"
							fullWidth
							type="number"
							label="Número"
							name="address.number"
							component={TextField}
							{...inputBind("address.number")}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="complement"
							fullWidth
							label="Complemento"
							name="address.complement"
							component={TextField}
							{...inputBind("address.complement")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="district"
							fullWidth
							label="Bairro"
							name="address.district"
							component={TextField}
							{...inputBind("address.district")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="city"
							fullWidth
							label="Cidade"
							name="address.city"
							component={TextField}
							{...inputBind("address.city")}
						/>
					</Grid>
					<Grid item xs={1}>
						<SelectComponent
							id="address-uf"
							name="address.uf"
							label="UF"
							fullWidth
							defaultValue=""
							{...inputBind(`address.uf`)}
							fields={UF} />
					</Grid>

					<Grid item xs={3}>
						<TextFieldComponent
							id="phone"
							fullWidth
							label="Telefone Celular"
							placeholder="(00) 0000-0000"
							name="phone"
							component={TextField}
							{...inputBind("phone")}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.phoneNumberMask },
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="phoneExtension"
							fullWidth
							label="Ramal"
							type="number"
							name="phoneExtension"
							{...inputBind("phoneExtension")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="phone2"
							fullWidth
							label="Telefone Opcional"
							name="phone2"
							placeholder="(00) 0000-0000"
							component={TextField}
							{...inputBind("phone2")}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.phoneNumberMask },
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="phone2Extension"
							fullWidth
							label="Ramal"
							type="number"
							name="phone2Extension"
							{...inputBind("phone2Extension")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="placeOfBirthCountry"
							fullWidth
							label="País de nascimento"
							name="placeOfBirthCountry"
							component={TextField}
							{...inputBind("placeOfBirthCountry")}
						/>
					</Grid>
					<Grid item xs={4}>
						<SelectComponent
							id="typeOfResidence"
							label="Tipo de residência"
							name="typeOfResidence"
							{...inputBind("typeOfResidence")}
							defaultValue=""
							fields={[{ name: 'Própria', value: 'Owner' }, { name: 'Alugada', value: 'Rent' }]}
						/>
					</Grid>
					<Grid item xs={2}>
						<Typography htmlFor="hasDependents" id="has-dependents-label" variant="subtitle2" shrink style={{ color: "#5F5F5F", paddingBottom: 10 }}>Possui Dependentes</Typography>
						<Checkbox
							id="hasDependents"
							color="primary"
							name="hasDependents"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="upTo6YearsOld"
							fullWidth
							label="Até 6 anos"
							type="number"
							component={TextField}
							{...inputBind("upTo6YearsOld")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="between7And15YearsOld"
							label="De 7 a 15 anos"
							fullWidth
							type="number"
							component={TextField}
							{...inputBind("between7And15YearsOld")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="over15YearsOld"
							label="Acima de 15 anos"
							name="over15YearsOld"
							fullWidth
							type="number"
							component={TextField}
							{...inputBind("over15YearsOld")}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box p={3}>
				<Typography component={'span'} variant="h6" gutterBottom>
					Dados Profissionais
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<AutocompleteWithSearch
							id="workplace"
							name="workplace"
							fullWidth
							displayField="workplace"
							label="Nome da Empresa onde trabalha"
							watch={watch}
							setValue={setValue}
							params={{
								page: 0,
								size: 10
							}}
							fetch={getLegalPersonsList}
							options={legalPersonsOptionList}
							{...inputBind('workplace')}
							endAdornment={
								watch("workplaceId") ? <Link to={handleLink()}>
									<SearchOutlinedIcon position="end" />
								</Link> : ''}
							showEndAdornment={watch("workplaceId")}
							onChange={(e, options) => {
								setValue('workplace', options?.name);
								setValue('workplaceId', options?.id);
								setValue('workplaceCompanyRegistrationNumber', options?.registrationNumber);
								setValue('economicActivityCodeId', options?.economicActivityCodeId);
								setValue('economicActivityCode', options?.economicActivityCode);
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="workplaceCompanyRegistrationNumber"
							fullWidth
							label="CNPJ da Empresa"
							name="workplaceCompanyRegistrationNumber"
							placeholder="00.000.000/0000-00"
							component={TextField}
							value={watch('workplaceCompanyRegistrationNumber')}
							{...inputBind("workplaceCompanyRegistrationNumber")}
							disabled={true}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.cnpjMask },
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" component="div" htmlFor="economicActivityCodeId" shrink style={{ color: "#5F5F5F", paddingBottom: 10 }}><strong>CNAE</strong></Typography>
						<TextField
							id="economicActivityCodeId"
							name="economicActivityCode"
							style={{ backgroundColor: 'white', borderRadius: 10, height: 48, padding: 10, borderBottom: 0 }}
							value={watch('economicActivityCode')?.description || ''}
							disabled={true}
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>

					<Grid item xs={2}>
						<SelectComponent
							id="natureOfOccupation"
							label="Natureza da ocupação"
							name="natureOfOccupation"
							{...inputBind("natureOfOccupation")}
							defaultValue=""
							fields={[
								{ name: 'Autônomo', value: 'Autonomous' },
								{ name: 'Empregado', value: 'PrivateEmployee' },
								{ name: 'Funcionário Público', value: 'PublicEmployee' },
								{ name: 'Empresário', value: 'Employer' },
								{ name: 'Rentista', value: 'Capitalist' },
								{ name: 'Militar', value: 'Military' },
								{ name: 'Outros', value: 'Other' },]}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="job-information-occupation"
							fullWidth
							label="Ocupação"
							name="occupation"
							component={TextField}
							{...inputBind("occupation")}
						/>
					</Grid>
					<Grid item xs={2}>
						<NumberInput
							id="netSalary"
							label="Salário Líquido"
							name="netSalary"
							onValueChange={(event) => onChangeNumber('netSalary', event)}
							adornmentText="R$"
							control={control}
							{...inputBindNumber("netSalary")}
						/>
					</Grid>
					<Grid item xs={2}>
						<NumberInput
							id="otherIncome"
							label="Outras Rendas"
							name="otherIncome"
							adornmentText="R$"
							{...inputBindNumber("otherIncome")}
						/>
					</Grid>
					<Grid item xs={2}>
						<DatePicker
							id="admissionDate"
							label="Data de admissão"
							variant="inline"
							InputLabelProps={{ shrink: true, required: isFieldRequired("admissionDate") }}
							disableFuture
							{...inputBind("admissionDate")}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box p={3}>
				<Typography component={'span'} variant="h6" gutterBottom>
					Informações sobre o Cônjuge
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={3}>
						<TextFieldComponent
							id="spouseDocumentNumber"
							fullWidth
							label="CPF"
							placeholder="000.000.000-00"
							name="spouseDocumentNumber"
							{...inputBind("spouseDocumentNumber")}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.cpfMask },
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextFieldComponent
							id="spouseName"
							fullWidth
							label="Nome"
							name="spouseName"
							{...inputBind("spouseName")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="partner-information-workplace"
							fullWidth
							label="CNPJ da empresa onde trabalha"
							name="spouseWorkplace"
							placeholder="00.000.000/0000-00"
							{...inputBind("spouseWorkplace")}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.cnpjMask },
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="partner-information-occupation"
							fullWidth
							label="Ocupação"
							name="spouseOccupation"
							{...inputBind("spouseOccupation")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="spouseCommercialPhone"
							fullWidth
							label="Telefone Comercial"
							placeholder="(00) 0000-0000"
							name="spouseCommercialPhone"
							{...inputBind("spouseCommercialPhone")}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.phoneNumberMask },
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="spouse-commercial-PhoneExtension"
							fullWidth
							label="Ramal"
							name="spouseCommercialPhoneExtension"
							{...inputBind("spouseCommercialPhoneExtension")}
						/>
					</Grid>
					<Grid item xs={2}>
						<DatePicker
							id="spouseBirthDate"
							label="Data de nascimento"
							variant="inline"
							InputLabelProps={{ shrink: true, required: isFieldRequired("spouseBirthDate") }}
							disableFuture
							{...inputBind("spouseBirthDate")}
						/>
					</Grid>
					<Grid item xs={2}>
						<NumberInput
							id="spouseNetSalary"
							label="Salário"
							name="spouseNetSalary"
							adornmentText="R$"
							{...inputBindNumber("spouseNetSalary")}
						/>

					</Grid>
					<Grid item xs={2}>
						<NumberInput
							id="spouseOtherIncome"
							label="Outras Rendas"
							name="spouseOtherIncome"
							adornmentText="R$"
							{...inputBindNumber("spouseOtherIncome")}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default InformationsNaturalPerson;