import React from 'react';

export const ChartLineUpIcon = ({ className }) => {
	return (
		<div className={className}>
			<svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.34797 8.56573L5.57893 7.92652L6.34797 8.56573ZM0.230961 14.3608C-0.12206 14.7855 -0.0639299 15.416 0.360799 15.769C0.785527 16.1221 1.41602 16.0639 1.76904 15.6392L0.230961 14.3608ZM19.4615 1L20.4302 0.751625C20.3626 0.487923 20.1904 0.263185 19.9533 0.129298C19.7163 -0.00458902 19.4349 -0.0360517 19.1742 0.0421738L19.4615 1ZM13.7603 10.2474L12.909 9.72261L13.7603 10.2474ZM10.283 10.1198L11.1704 9.6588L10.283 10.1198ZM9.66086 8.92215L8.77345 9.38314L8.77345 9.38314L9.66086 8.92215ZM12.5075 2.04217C11.9785 2.20087 11.6783 2.75835 11.837 3.28735C11.9957 3.81634 12.5532 4.11652 13.0822 3.95783L12.5075 2.04217ZM20.0313 7.24838C20.1685 7.78335 20.7134 8.10584 21.2484 7.96866C21.7834 7.83149 22.1058 7.2866 21.9687 6.75162L20.0313 7.24838ZM5.57893 7.92652L0.230961 14.3608L1.76904 15.6392L7.11701 9.20493L5.57893 7.92652ZM18.6103 0.475198L12.909 9.72261L14.6115 10.7722L20.3128 1.5248L18.6103 0.475198ZM11.1704 9.6588L10.5483 8.46116L8.77345 9.38314L9.3956 10.5808L11.1704 9.6588ZM19.1742 0.0421738L12.5075 2.04217L13.0822 3.95783L19.7489 1.95783L19.1742 0.0421738ZM18.4929 1.24838L20.0313 7.24838L21.9687 6.75162L20.4302 0.751625L18.4929 1.24838ZM12.909 9.72261C12.5014 10.3838 11.5285 10.3481 11.1704 9.6588L9.3956 10.5808C10.4698 12.6487 13.3886 12.7558 14.6115 10.7722L12.909 9.72261ZM7.11701 9.20493C7.57407 8.65502 8.44382 8.7486 8.77345 9.38314L10.5483 8.46116C9.55937 6.55753 6.95012 6.27682 5.57893 7.92652L7.11701 9.20493Z" fill="#6EC202" />
			</svg>
		</div>
	);
};
