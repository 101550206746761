import React, { useState } from 'react';
import { ContentContainer } from 'components';
import { Link, useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import Helper from 'helpers/format.helpers';
import { localizationOptions } from 'helpers/table.helpers';
import { getLegalPersonsList, deleteLegalPersonById } from 'services/legalPerson';
import { TablePagination, Typography, Button } from '@material-ui/core';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import FilterListIcon from '@material-ui/icons/FilterList';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import ActionBar from 'components/ActionBar/ActionBar';

const LegalPersonsList = ({_props, pendencies}) => {
	const history = useHistory();
	const tableRef = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const { setTitle } = useHeader();
	const { selectedTenant } = useTenant();
	const [enableFilter, setEnableFilter] = useState(false);

	const onRowsPerPageChange = (page) => {
		localStorage.setItem('rowsPerPage', page);
		setRowsPerPage(page);
	};

	const actions = {
		onRefresh: () => tableRef?.current?.onQueryChange()
	};

	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';
	const useScan3 = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCAN3;

	return (
		<ContentContainer>
			{<ActionBar {...actions} hideSubmit={true} />}

			<div className='uk-align-center'>
				<div className='uk-width-auto@m uk-width-1-1'>
					<Link to='/pessoa-juridica/nova' style={{ backgroundColor: '#3f51b5', textDecoration: "none" }} className='wt-button wt-button-lowercase uk-button'>
						<i className='fa fa-user-plus uk-margin-small-right' style={{ color: "white" }}></i>
						<Typography component="span" style={{ color: "white", textTransform: 'none', }}>Adicionar nova pessoa</Typography>
					</Link>
					<Button style={{ marginLeft: 10 }} onClick={() => setEnableFilter(!enableFilter)} >
						<FilterListIcon /> Filtrar
					</Button>
				</div>
				<br />

				<MaterialTable
					tableRef={tableRef}
					title="Pessoas Jurídicas"
					columns={[
						isRootTenancy && !useScan3 ? { title: 'Correspondente', field: 'tenantDisplay', draggable: false, filtering: false } : undefined,
						{ title: 'Nome', field: 'companyName', draggable: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 250 } },
						{ title: 'CNPJ', field: 'registrationNumber', draggable: false, render: ({ registrationNumber }) => (Helper.formatDocumentNumber(registrationNumber)) },
						{ title: 'Email', field: 'email', draggable: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200 } }, {
							title: 'Criado em',
							field: 'createdAt',
							render: ({ createdAt }) => createdAt && new Date(createdAt).toLocaleDateString('pt-BR') + " " + new Date(createdAt).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }),
							filterComponent: (props) => <KeyboardDatePicker
								{...props}
								format="dd/MM/yyyy"
								InputLabelProps={{ shrink: true }}
								placeholder="dd/mm/aaaa"
								variant="inline"
								value={props?.columnDef?.tableData?.filterValue || null}
								disableFuture={true}
								onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e)}
								helperText={false}
							/>
						},].filter(x => x !== undefined)}
					actions={[
						{
							icon: 'search',
							tooltip: 'Editar operação',
							position: 'row',
							onClick: (event, rowData) => history.push(`/pessoa-juridica/editar?id=${rowData.id}`)
						},
					]}
					editable={{
						onRowDelete: oldData =>
							new Promise(resolve => {
								deleteLegalPersonById(oldData.id).then(response => {
									resolve();
								}).catch(() => {
									resolve();
								});
							}),
					}}
					data={(allParams) =>
						new Promise((resolve, reject) => {
							const { page, pageSize, search, filters, orderBy, orderDirection } = allParams;

							const createdAt = filters.find(f => f.column.field === 'createdAt')?.value;

							const filterFinalDate =
								enableFilter &&
								createdAt &&
								moment(createdAt).isValid() &&
								moment(createdAt).set("hour", "23").set("minute", "59")?._d;

							const filterInitialDate =
								enableFilter &&
								createdAt &&
								moment(createdAt).isValid() &&
								moment(createdAt).set("hour", "0").set("minute", "0")?._d;

							const filtersValues = {
								page,
								size: pageSize,
								pendingPreApprove: pendencies ? true : false,
								searchString: search,
								companyName: enableFilter && filters.find(f => f.column.field === 'companyName')?.value,
								registrationNumber: enableFilter && filters.find(f => f.column.field === 'registrationNumber')?.value,
								email: enableFilter && filters.find(f => f.column.field === 'email')?.value,
								initialDate: filterInitialDate,
								finalDate: filterFinalDate,
								orderByField: orderBy?.field,
								orderByDirection: orderDirection
							};

							getLegalPersonsList(filtersValues, selectedTenant)
								.then(result => {
									setTitle("Pessoa Jurídica");
									if (result)
										resolve({
											data: result.data,
											page: result.page,
											totalCount: result.totalItems,
										});
									else {
										resolve({
											data: [],
											page: 0,
											totalCount: 0,
										});
									}
								}).catch(err => reject(err));
						})
					}
					onChangeRowsPerPage={onRowsPerPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
					localization={localizationOptions}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								rowsPerPageOptions={[5, 10, 20, 50]}
							/>
						),
					}}
					options={{
						actionsColumnIndex: -1,
						pageSize: rowsPerPage,
						debounceInterval: 500,
						searchAutoFocus: true,
						filtering: enableFilter
					}}
				/>
			</div>
		</ContentContainer>
	);
};

export default LegalPersonsList;
