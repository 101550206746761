import React from 'react';

export const SecurityIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 344 344" preserveAspectRatio="xMidYMid meet">
			<g transform="translate(0.000000,344.000000) scale(0.100000,-0.100000)" fill="#ffff" stroke="none">
				<path d="M1063 3006 l-632 -281 -1 -476 c0 -265 5 -523 11 -580 41 -411 216 -778 509 -1069 198 -198 433 -348 665 -426 l103 -34 84 26 c110 34 282 118
                393 192 384 256 651 626 759 1052 48 190 56 309 56 835 l-1 480 -637 283 c-350 155 -646 282 -657 281 -11 0 -305 -128 -652 -283z m1158 -245 l494 -220
                0 -443 c0 -487 -1 -502 -67 -708 -123 -387 -447 -745 -824 -910 -110 -48 -100 -49 -241 16 -349 159 -661 506 -787 877 -66 192 -69 220 -73 713 l-4 451 493
                221 c271 121 498 220 504 221 6 1 234 -98 505 -218z"/>
				<path d="M1995 1910 c-225 -226 -414 -410 -420 -410 -5 0 -98 88 -205 195 l-195 195 -100 -100 -100 -100 300 -300 300 -300 515 515 515 515 -100 100 -100 100 -410 -410z" />
			</g>
		</svg>
	);
};
