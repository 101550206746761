import axios, { AxiosError } from 'axios';
import * as yup from 'yup';
import { getToken } from "../repository/cognito";
import { ApiConfig } from "../config";
import { Toast } from '../components';
import { pickBy } from 'lodash';
import { validationSchema } from "../pages/Product/FieldsForm/schema/validation";
import moment from 'moment';

type FiltersType = {
	name: string;
	amortizationType: string;
	initialDate: Date | boolean;
	finalDate: Date | boolean;
	page: number;
	size: number;
	searchString: string;
};

type ProductType = {
	data: {
		bankComission: {
			amount: number;
			type: number;
		};
		maximumInterestRate: number;
		minimumInterestRate: number;
	}[],
};

type ProductPayloadType = yup.InferType<typeof validationSchema>;

export async function getProductsList(filters: FiltersType, tenant: string) {

	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));

	const token = await getToken();
	let url = ApiConfig.baseURL + `/Product`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get<ProductType>(url, config);
		let returnResult = result.data;
		returnResult.data = returnResult.data?.map(x => {
			if (x.bankComission?.amount > 0 && x.bankComission?.type == 0) {
				x.bankComission.amount = x.bankComission.amount / 100;
			}
			if (x.bankComission?.amount > 0 && x.bankComission?.type == 1) {
				x.bankComission.amount = x.bankComission.amount * 100;
			}
			if (x?.maximumInterestRate > 0 && x.maximumInterestRate) {
				x.maximumInterestRate = x.maximumInterestRate * 100;
			}
			if (x?.minimumInterestRate > 0 && x.minimumInterestRate) {
				x.minimumInterestRate = x.minimumInterestRate * 100;
			}
			return x;
		});
		return returnResult;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de produtos");
		}
		throw err;
	}
}


export async function getProductById(id: string, tenant: string) {

	if (!id) {
		throw new Error('id not provided');
	}

	const token = await getToken();
	var url = ApiConfig.baseURL + `/Product/${id}`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);

		const formtStr = (str: string) => {
			var valueStart = String(`${str}`).padStart(4, '0').substring(0, 2);
			var valueEnd = String(`${str}`).padStart(4, '0').substring(2, 4);
			return valueStart + ":" + valueEnd;
		};

		if (result?.data) {
			if (result.data.timeLimitToSubmitApproval) {
				result.data.timeLimitToSubmitApproval = formtStr(result.data.timeLimitToSubmitApproval);
			}
			if (result.data.timeLimitToSign) {
				result.data.timeLimitToSign = formtStr(result.data.timeLimitToSign);
			}
			if (result.data.timeLimitToApprove) {
				result.data.timeLimitToApprove = formtStr(result.data.timeLimitToApprove);
			}
			if (result.data.timeToSendToSignatures) {				
				result.data.timeToSendToSignatures = formtStr(result.data.timeToSendToSignatures);
			}

		}
		if (result?.data?.bankComission?.amount > 0 && result?.data?.bankComission?.type == 0) {
			result.data.bankComission.amount = result?.data?.bankComission.amount / 100;
		}
		if (result?.data?.bankComission?.amount > 0 && result?.data?.bankComission?.type == 1) {
			result.data.bankComission.amount = result?.data?.bankComission.amount * 100;
		}
		if (result?.data?.minimumInterestRate > 0) {
			result.data.minimumInterestRate = result?.data?.minimumInterestRate * 100;
		}
		if (result?.data?.maximumInterestRate > 0) {
			result.data.maximumInterestRate = result?.data?.maximumInterestRate * 100;
		}
		if (result?.data?.maximumEmissionCostInCents > 0) {
			result.data.maximumEmissionCostInCents = result?.data?.maximumEmissionCostInCents / 100;
		}
		if (result?.data?.minimumEmissionCostInCents > 0) {
			result.data.minimumEmissionCostInCents = result?.data?.minimumEmissionCostInCents / 100;
		}
		if (result?.data?.maximumValueWithoutDigitalSignature > 0) {
			result.data.maximumValueWithoutDigitalSignature = result?.data?.maximumValueWithoutDigitalSignature / 100;
		}
		if (result?.data?.minimumPrincipalAmount > 0) {
			result.data.minimumPrincipalAmount = result?.data?.minimumPrincipalAmount / 100;
		}
		if (result?.data?.maximumPrincipalAmount > 0) {
			result.data.maximumPrincipalAmount = result?.data?.maximumPrincipalAmount / 100;
		}
		return result.data;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter as informações do Produto");
		}
		throw err;
	}
}

export async function editOrCreate(data: ProductPayloadType, option: 'create' | 'edit', tenant: string) {
	function handleTime(dateTime: string) {
		if (dateTime) {
			return parseInt(dateTime.replace(/:/g, ''));
		}
	}

	const payload = {
		...data,
		bankComission: {
			...data.bankComission,
			amount: data?.bankComission?.type == 0 ? data?.bankComission?.amount * 100 : data?.bankComission?.amount / 100,
		},
		minimumInterestRate: data.minimumInterestRate ? data.minimumInterestRate / 100 : 0,
		maximumInterestRate: data.maximumInterestRate ? data?.maximumInterestRate / 100 : 0,
		maximumValueWithoutDigitalSignature: data.maximumValueWithoutDigitalSignature ? data.maximumValueWithoutDigitalSignature * 100 : 0,
		minimumPrincipalAmount: data.minimumPrincipalAmount ? data?.minimumPrincipalAmount * 100 : 0,
		maximumPrincipalAmount: data.maximumPrincipalAmount ? data?.maximumPrincipalAmount * 100 : 0,
		maximumEmissionCostInCents: data.maximumEmissionCostInCents ? data?.maximumEmissionCostInCents * 100 : 0,
		minimumEmissionCostInCents: data.minimumEmissionCostInCents ? data?.minimumEmissionCostInCents * 100 : 0,
		timeLimitToSubmitApproval: data.timeLimitToSubmitApproval && handleTime(data.timeLimitToSubmitApproval),
		timeLimitToApprove: data.timeLimitToApprove && handleTime(data.timeLimitToApprove),
		timeLimitToSign: data.timeLimitToSign && handleTime(data.timeLimitToSign),
		timeToSendToSignatures: data.timeToSendToSignatures && handleTime(data.timeToSendToSignatures)
	};

	const token = await getToken();
	const url = data.id ?
		ApiConfig.baseURL + `/Product/${data.id}` :
		ApiConfig.baseURL + `/Product`;

	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(urlWithTenant, payload, config),
			successMessage: 'Produto cadastrado com sucesso',
			errorMessage: 'Ocorreu um erro no cadastro o produto'
		},
		edit: {
			function: () => axios.put(urlWithTenant, payload, config),
			successMessage: 'Produto editado com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar o produto'
		}
	};

	let result;

	try {

		result = await request[option].function();
		Toast.showSuccessMessage(request[option].successMessage);
		return result;

	}
	catch (err) {
		const error = err as AxiosError;
		if (error.response) {
			if (error.response?.data.message) {
				const message = error.response.data.message + '</br>' + error?.response?.data?.errors.map((err: Error) => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message);
			} else if (error.response?.data?.errors) {
				Toast.showErrorMessage(error?.response.data?.errors);
			} else {
				Toast.showErrorMessage(error.response.data || request[option].errorMessage);

			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}
		throw err;
	}
}

export async function deleteProductById(id: string) {

	if (!id) {
		throw new Error('id not provided');
	}

	const token = await getToken();
	var url = ApiConfig.baseURL + `/Product/${id}`;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await (await axios.delete(url, config)).data;
		Toast.showSuccessMessage("Produto excluído com sucesso.");
		return result;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível excluir o Produto de Crédito");
		}
		throw err;
	}
}