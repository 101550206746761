import { string, object, number, date } from 'yup';

export const validationTransferSchema = object().shape({
	bankAccountBeneficiaryId: string().typeError('Selecione o favorecido da transferência.').required('Favorecido da transferência é obrigatório.'),
	amount: number().typeError('Valor precisa ser um número').required('Valor da Transferência: Precisa ser preenchido.')
		.min(0.01, 'Valor da Transferência: Precisa ser maior que 0.')
		.transform((value: any, _: any) => {
			return isNaN(value) ? 0 : value;
		}),
	transferDate: date().typeError('Data inválida.').required('Data da transferência: Precisa ser preenchido.'),
	sessionId: string().nullable(),
	code: string().nullable(),
});

export const defaultValues = {
	bankAccountBeneficiaryId: "",
	amount: 0,
	transferDate: new Date().toDateString(),
	paymentPurpose: null,
	sessionId: "",
	code: ""
};
