import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';

export async function getCreditStatusRequestList(page, size, search, id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditStatusRequest?id=${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params: {
			page, size, searchString: search?.replace(/[^a-zA-Z0-9]/g, '')
		}
	};

	let result;

	try {
		result = await (await axios.get(url, config)).data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de solicitações de limite de crédito");
		}

		return Promise.reject(err);

	}
	return result;
}

export async function postCreditStatusRequest(data) {
	delete data.productIdDisplay;

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditStatusRequest`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url,
			{
				...data,
				requestedLimit: data.requestedLimit * 100
			},
			config);
		Toast.showSuccessMessage("Solicitação de limite de crédito enviado com sucesso");
		Promise.resolve(result);

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível criar a solicitação de limite de crédito");
		}

		return Promise.reject(err);

	}
	return result;
}

export async function postCreditApproval(data, id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/CreditStatusRequest/approval`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url,
			{
				...data,
				creditStatusRequestId: id,
				creditLimit: data.creditLimit * 100
			},
			config);
		Toast.showSuccessMessage("Solicitação de limite de crédito aprovado com sucesso");
		Promise.resolve(result);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível aprovar a solicitação de limite de crédito");
		}

		return Promise.reject(err);

	}
	return result;
}