import { Redirect, Route } from "react-router-dom";
import { useUser } from 'contexts/user';

export default function PrivateRoute(props) {
	const { user } = useUser();

	if (!user) {
		return <Redirect to="/login/0" />;
	}

	return <Route {...props} />;
};
