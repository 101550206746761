import React, { useEffect, useState } from 'react';
import { FieldValues, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from "helpers/form-helpers";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import SelectComponent from "components/Select/Select";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import * as bankAccountOperatorService from "services/digitalBankAccount";
import { validationSchema, defaultValues } from "../schema/validation";
import { CreateOperatorType } from "global/types/digitalAccount.types";
import AutocompleteWithSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { useOperator } from 'contexts/operator';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { Link, useParams } from 'react-router-dom';
import { RouteWithIdParamsType } from 'global/types/types';
import { Toast } from 'components';
import ModalComponent from 'components/Modal/ModalComponent';
import HasPassCode from '../../Code/codeForm';
import { useUser } from 'contexts/user';
import { useTenant } from 'contexts/tenant';
import { IMFAState } from 'global/interface/types';

const levelPermission = [
	{ name: 'Visualizador', value: "Viewer" },
	{ name: 'Aprovador em Conjunto', value: "JointApprover" },
	{ name: 'Aprovador Master', value: "MasterApprover" }
];

const InformationsOperator = ({ tableRef }: any) => {
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const { user } = useUser();
	const { selectedTenant } = useTenant();
	const [open, setOpen] = useState(false);
	const [username, setUsername] = useState("");
	const { operatorOptionList, getOperatorList } = useOperator();
	const { control, handleSubmit, setValue, watch, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues,
	});
	const { errors } = useFormState({ control });
	const { inputBind } = getFormFunctions({ validationSchema, control, errors, setValue });
	const [formValues, setFormValues] = useState<CreateOperatorType>();
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
	const cognitoClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID;

	const handleOperatorLink = () => {
		//@ts-ignore
		const operadorId = watch('users.userId');
		return `/operador/editar?id=${operadorId}`;
	};

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		//@ts-ignore
		if (user?.authenticated == true) setUsername(user['payload']['cognito:username']);
	}, [user]);

	const generateSession = async () => {
		await bankAccountOperatorService.generateSessionId(username, modalFormData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const values = formValues as CreateOperatorType;
			const data = {
				userId: values.users.userId,
				level: values.users.level,
				sessionId: sessionId,
				code: modalFormData.code
			};
			//@ts-ignore
			bankAccountOperatorService.createOperator(bankAccountId, data, selectedTenant).then(() => {
				tableRef.current.onQueryChange();
				reset();
			}).finally(() => handleClose());
		});
	};

	//@ts-ignore
	const onSubmit = (values: FieldValues) => { handleOpen(); setFormValues(values); };

	const onError = (values: FieldValues) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	return (
		<>
			<ModalComponent
				open={open}
				onClose={handleClose}
				buttonText='Confirmar'
				onClick={() => generateSession()}
				title='Operador'
				subtitle='Tem certeza que deseja adicionar o operador a uma conta?'
				children={<HasPassCode setFormData={(data: IMFAState) => { setModalFormData(data); }} />}
			/>
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<Box mb={2} width='60%'>
					<br />
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<AutocompleteWithSearch
								id="users.userId"
								displayField="name"
								fullWidth
								label="Operador"
								watch={watch}
								setValue={setValue}
								params={{
									page: 0,
									size: 10
								}}
								fetch={getOperatorList}
								options={operatorOptionList}
								{...inputBind(`users.userId`)}
								endAdornment={<Link to={handleOperatorLink}>
									{/*@ts-ignore*/}
									<SearchOutlinedIcon position="end" />
								</Link>}
								//@ts-ignore
								showEndAdornment={watch("users.userId")}
								onChange={(_: React.ChangeEvent<HTMLInputElement>, options: any) => {
									//@ts-ignore
									setValue("users.userId", options?.id || '');
									//@ts-ignore
									setValue("name", options?.name || '');
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SelectComponent
								id="users.level"
								label="Nível de Permissão"
								defaultValue=''
								fields={levelPermission}
								{...inputBind('users.level')}
							/>
						</Grid>

						<Grid item>
							<Button className="button-actionBar" type="submit" variant="contained" style={{ backgroundColor: "#5F5F5F" }}>
								<SaveOutlinedIcon style={{ color: "#fff" }} />
								<Typography component="span" style={{ color: "white", textTransform: 'none', }}>
									&nbsp;Salvar
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Box>
			</form>
		</>
	);
};

export default InformationsOperator;