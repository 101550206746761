import React, { useEffect, useState } from 'react';
import Accordions from 'components/Accordion/Accordion';
import DataSetMasterContainer from 'containers/DataSetMasterContainer';
import { Typography } from '@material-ui/core';
import { Toast } from 'components';
import * as datasetService from '../../../services/dataset';
import TableMotorCredit from '../../../components/Datasets/TableMotorCredit';

const Compliance = (props) => {

	const { creditNoteId, setLoading } = props;
	const [datasetMotorCompliance, setdatasetMotorCompliance] = useState(null);

	const getMotorCompliance = () => {
		setLoading(true);
		datasetService.getDataSetReport('CreditNote', creditNoteId, 'complianceanalysis').then(response => {
			if (response?.data[0]?.result) { return setdatasetMotorCompliance(response?.data[0]), setLoading(false); }
			return setLoading(false), false;
		});
	};

	useEffect(() => {
		getMotorCompliance();
	}, []);

	const generatedatasetMotorCompliance = () => {
		setLoading(true);
		Toast.showInfoMessage("Motor de Compliance foi solicitado");
		if (datasetMotorCompliance) {
			return getMotorCompliance(),
			setLoading(false),
			Toast.showSuccessMessage("Motor de Compliance gerado");
		} else {
			setLoading(true);
			datasetService.fetchDataSetReport('CreditNote', creditNoteId, 'complianceanalysis').then(response => {
				if (response?.data[0]) { return getMotorCompliance(), setLoading(false), Toast.showSuccessMessage("Dataset gerado"); }
				return Toast.showErrorMessage("Não foi possível obter os dados"),
				setLoading(false);
			});
		}
	};

	return (
		<>
			<Typography variant="h6">
				Operação
			</Typography>
			<Accordions accordions={[{
				name: 'Análise de Compliance',
				value: 'mot_comp',
				children: <DataSetMasterContainer onClickUpdateData={() => generatedatasetMotorCompliance()} dataSet={datasetMotorCompliance}>
					<TableMotorCredit rows={datasetMotorCompliance} />
				</DataSetMasterContainer>,
				key: 'mot_comp'
			}]} />

		</>
	);
};

export default Compliance;