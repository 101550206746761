import React, { useState } from 'react';
import { Grid, TextField, Box, Button, Typography } from '@material-ui/core';
import TextFieldComponent from 'components/TextInput/TextField';
import { validationSchema, defaultValues } from 'pages/BankAccountRequest/ModalApproveAndReject/schema/validation';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from 'helpers/form-helpers';
import { approveBankAccountById, disapprovedBankAccountById } from 'services/bankAccount';
import { useHistory } from "react-router-dom";
import { DatePicker } from 'components';
import SelectComponent from 'components/Select/Select';
import { useBlockReason } from 'contexts/blockReason';
import MultiSelectComponent from 'components/MultiSelect/MultiSelect';
import ModalComponent from 'components/Modal/ModalComponent';

function ModalApproveAndReject({ onCloseModal, id, approve }) {

	const history = useHistory();
	const { blockReasonList } = useBlockReason();
	const [selected, setSelected] = useState([]);
	const { handleSubmit, setValue, control, watch } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: { ...defaultValues, approve }
	});

	const { errors } = useFormState({ control });

	const { inputBind } = getFormFunctions({ validationSchema, setValue, control, errors });

	const sendApprovalAndReject = async (values) => {
		const { message, reasonDisapproval, blockType, blockUntil } = values;

		var promise = null;
		if (approve == true) {
			promise = approveBankAccountById(id, message);

		} else if (approve == false) {
			promise = disapprovedBankAccountById(id, { message, reasonDisapproval, blockType, blockUntil });
		}

		promise.then(_ => {
			history.push('/solicitacao-conta-digital');
		})
			.catch(err => {
				console.log("Error:", err);
			});
	};

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		setValue("reasonDisapproval", value);
		setSelected(
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		<ModalComponent
			title={approve ? 'Aprovar solicitação de conta' : 'Reprovar solicitação de conta'}
			subtitle={approve ? "tem certeza que deseja aprovar a solicitação?" : "tem certeza que deseja reprovar a solicitação?"}
			onClose={onCloseModal}
			open={true}
			enableButton={false}
			children={
				<>
					<form
						onSubmit={handleSubmit(values => sendApprovalAndReject(values))}
					>
						{approve == false &&
							<>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<MultiSelectComponent
											id="reasonDisapproval"
											label="Motivo da Reprovação"
											value={selected || []}
											onChange={handleChange}
											fields={blockReasonList}
											{...inputBind("reasonDisapproval")}
											required={true}
										/>
									</Grid>
									<Grid item xs={12}>
										<SelectComponent
											id="blockType"
											name="blockType"
											label="Tipo de Bloqueio"
											{...inputBind("blockType")}
											defaultValue={null}
											required={true}
											fields={[
												{ name: 'Ajustável', value: 'Adjustable' },
												{ name: 'Final', value: "Final" },
											]} />
									</Grid>
									{watch("blockType") == 'Final' &&
										<Grid item xs={12}>
											<DatePicker
												id="blockUntil"
												label="Data de Bloqueio"
												variant="inline"
												name="blockUntil"
												fullWidth
												disablePast={true}
												InputLabelProps={{ shrink: true, required: true }}
												{...inputBind("blockUntil")}
												required={true} />
										</Grid>}
								</Grid>
							</>}
						<Grid container spacing={5}>
							<Controller
								render={({ field }) => (
									<>
										<Grid item xs={12}>
											<TextFieldComponent
												id="message"
												fullWidth
												type="text"
												name="message"
												component={TextField}
												label="Observações"
												margin="dense"
												multiline={true}
												rows={4}
												fieldAreaHeight={100}
												{...inputBind("message")}
												required={watch('reasonDisapproval') == 'OUTROS_MOTIVOS' ? true : false}
											/>
										</Grid>
									</>)}
								control={control}
								name="newTimelineDescription" />
						</Grid>
						<Grid container justifyContent='center' spacing={10}>
							<Grid item >
								<Button color={approve ? "primary" : "secondary"} variant='contained' type='submit' fullWidth size='large'>
									<Typography style={{ textTransform: 'none' }} component="span" >
										{approve ? 'Aprovar' : 'Reprovar'}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</form>
				</>
			}
		/>

	);
}
export default ModalApproveAndReject;
