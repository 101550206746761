import { string, object, number, date, array, boolean, mixed } from 'yup';

const genericRequired = 'Esse campo é obrigatório.';

export const validationSchema = object().shape({
	approve: boolean(),
	reasonDisapproval: mixed().when('approve', {
		is: false,
		then: array().typeError('Selecione o motivo da reprovação.').min(1, "Selecione ao menos 1 reprovação.").required(genericRequired),
		otherwise: string().nullable()
	}),
	action: string().nullable(),
	message: string().when("reasonDisapproval", {
		is: "OUTROS_MOTIVOS",
		then: string().typeError('A mensagem precisa ser um texto.').required(genericRequired),
		otherwise: string().nullable()
	}),
	blockType: string().when("approve", {
		is: false,
		then: string().typeError('Esse campo é obrigatório.').required(genericRequired),
		otherwise: string().nullable()
	}),
	blockUntil: date().when("blockType", {
		is: "Final",
		then: date().typeError('Data inválida').required(genericRequired),
		otherwise: date().nullable()
	}),

});

export const defaultValues = {
	reasonDisapproval: "",
	action: "NOK",
	message: null,
	approve: false,
	blockType: null,
	blockUntil: new Date().toDateString()
};