import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import {
	InputWithLabel,
	ContentContainer,
	PrimaryButton,
	DangerAlert,
	ModalWithLoading,
} from 'components';
import Helper from 'helpers/message.helpers';
import * as cognitoRepository from 'repository/cognito';
import AccountConfirmation from '../AccountConfirmation';
import {
	LoginContainerStyled,
	LoginContentStyled
} from 'assets/style';
import LoginHeader from 'pages/Login/Header';
import { useUser } from '../../../contexts/user';
import QRCode from "qrcode.react";
//@ts-ignore
export default function SignIn(props) {

	const history = useHistory();
	const { setUser } = useUser();
	const [loading, setLoading] = useState(false);
	const useMFA = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_MFA;

	const [state, setState] = useState({
		email: '',
		password: '',
		errors: { email: '', password: '', message: '' },
	});

	const [newPasswordModal, setNewPasswordModal] = useState({
		show: false,
		password: '',
		passwordConfirmation: '',
		errors: { password: '', passwordConfirmation: '', message: '' }
	});

	const [setupMfa, setSetupMfa] = useState({
		show: false,
		secret: '',
	});
	//@ts-ignore
	function signIn(event) {
		event.preventDefault();


		const errors = validateFields();
		//@ts-ignore
		setState(prevState => ({ ...prevState, errors }));
		if (Object.keys(errors).length > 0) {
			return;
		}

		setLoading(true);
		const { email, password } = state;
		cognitoRepository.signIn(email, password)
			.then((response) => {
				if (useMFA) {
					cognitoRepository.getUserData().then(data => {
						if (!data.UserMFASettingList) {
							cognitoRepository.setupMFA().then(response => {
								console.log(response);
							}).catch(error => handleSignInErrors(error));
						}
					});
				} else {
					//@ts-ignore
					setUser({
						...response,
						authenticated: true
					});
					setLoading(false);

					if (response?.payload?.email_verified != true) {
						history.push('/account-confirmation?resend=true');
					}
					else {
						history.push('/');
					}
				}
			}).catch(error => {
				handleSignInErrors(error);
				setLoading(false);
			});
		return false;
	}
	//@ts-ignore
	function handleSignInErrors(error) {
		console.log(error);
		if (error.errorMessage) {
			showErrorMessage(error.errorMessage);
		} else if (error.associateSecretCode && useMFA) {
			const { email } = state;
			var totpUri = "otpauth://totp/MFA:" + email + "?secret=" + error.secretCode + "&issuer=Backoffice de Crédito " + window.__RUNTIME_CONFIG__.REACT_APP_MAIN_TITLE;
			toggleSetupMFA(true, totpUri);
		} else if (error.validateCode) {
			history.push('/code-confirmation');
		} else if (error.newPasswordRequired) {
			toggleNewPasswordModalVisibility(true);
		} else {
			if (error.code === 'UserNotConfirmedException') {
				//@ts-ignore
				localStorage.setItem(AccountConfirmation.EMAIL_FIELD, state.email.toLocaleLowerCase().trim());
				//@ts-ignore
				localStorage.setItem(AccountConfirmation.PASS_FIELD, state.password);
				history.push('/account-confirmation?resend=true');
			} else if (error.message.includes('UserMigration failed with error')) {
				showErrorMessage(error.message.replace('UserMigration failed with error', ''));
			} else {
				showErrorMessage(Helper.codeToMessage(error.code));
			}
		}
	}
	//@ts-ignore
	const showErrorMessage = errorMessage => setState(prevState => ({ ...prevState, errors: { message: errorMessage } }));


	function validateFields() {
		const { email, password } = state;
		const errors = {};
		if (!email) {
			//@ts-ignore
			errors.email = 'Campo "E-mail" é obrigatório';
		}

		if (!password) {
			//@ts-ignore
			errors.password = 'Campo "Senha" é obrigatório';
		}
		return errors;
	}

	function confirmNewPassword() {
		const { email } = state;

		const errors = validateNewPasswordModal();
		//@ts-ignore
		setNewPasswordModal(prevState => ({
			...prevState,
			errors
		}
		));

		if (Object.keys(errors).length > 0) {
			return;
		}


		cognitoRepository.confirmNewPassword(email, newPasswordModal.password)
			.then((response) => {
				//@ts-ignore
				setUser({
					...response,
					authenticated: true
				});
				if (response?.payload?.email_verified != true) {
					history.push('/account-confirmation?resend=true');
				}
				else {
					history.push('/');
				}
			})
			.catch(error => {
				handleSignInErrors(error);
				// @ts-ignore
				setNewPasswordModal(prevState => ({
					...prevState,
					errors: {
						message: 'A senha deve conter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, caracteres especiais e número'
					}
				}));
			});
	}

	function validateNewPasswordModal() {
		const errors = {};

		if (!newPasswordModal.password) {
			// @ts-ignore
			errors.password = 'Campo "Senha" é obrigatório';
		}

		if (!newPasswordModal.passwordConfirmation) {
			// @ts-ignore
			errors.passwordConfirmation = 'Campo "Confirmar senha" é obrigatório.';
		} else if (newPasswordModal.password !== newPasswordModal.passwordConfirmation) {
			// @ts-ignore
			errors.passwordConfirmation = 'A senha e a confirmação de senha não conferem';
		}

		return errors;
	}

	// @ts-ignore
	function changeFieldValue(fieldName, event) {
		event.persist();
		setState(prevState => ({
			...prevState,
			[fieldName]: event.target.value
		}));
	}

	// @ts-ignore
	function changeModalFieldValue(fieldName, value) {
		setNewPasswordModal(prevState => ({
			...prevState,
			[fieldName]: value
		}));
	}

	// @ts-ignore
	const toggleNewPasswordModalVisibility = (visible) => {
		changeModalFieldValue('show', visible);
	};

	// @ts-ignore
	const toggleSetupMFA = (visible, secret) => {
		setSetupMfa(prevState => ({
			...prevState,
			show: visible,
			secret
		}));
	};

	const { email, password, errors } = state;

	return (
		<LoginContainerStyled>
			{/* @ts-ignore */}
			<ContentContainer>
				<LoginContentStyled>
					{/* @ts-ignore */}
					<LoginHeader title='Iniciar sessão' />
					{errors.message && <DangerAlert msg={errors.message} />}
					<form>
						<div className='uk-margin-top'>
							<InputWithLabel label='Email'
								type='email'
								errorMessage={errors.email}
								value={email}
								//@ts-ignore
								placeholder='Seu e-mail'
								onChange={(event) => changeFieldValue('email', event)} />
						</div>
						<div className='uk-margin-top'>
							<InputWithLabel label='Senha'
								errorMessage={errors.password}
								value={password}
								type='password'
								//@ts-ignore
								placeholder='Sua senha'
								onChange={(event) => changeFieldValue('password', event)} />
						</div>
						<div className='uk-margin-top'>
							<PrimaryButton
								type='submit'
								className='uk-width-1-1'
								//@ts-ignore
								onClick={(event) => signIn(event)}
							>
								{loading ?
									//@ts-ignore
									<div className="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
									: 'Acessar'}
							</PrimaryButton>
						</div>
					</form>
					<div className='uk-margin-top uk-flex uk-flex-between'>
						<Link to='/forgot-password' className='uk-text-small'>
							Esqueceu sua senha?
						</Link>
					</div>
				</LoginContentStyled>
				{newPasswordModal.show &&
					<ModalWithLoading
						requestKey="NEW_PASSWORD_REQUIRED"
						title='Cadastrar nova senha'
						confirmText='Continuar'
						onConfirm={confirmNewPassword}
						onCloseModal={() => toggleNewPasswordModalVisibility(false)}>
						<p className='uk-text-center'>Para acessar o sistema, você deverá cadastrar uma nova senha:</p>
						{newPasswordModal?.errors?.message && <DangerAlert msg={newPasswordModal?.errors?.message} />}
						<div className='uk-margin-top'>
							<InputWithLabel label='Senha'
								//@ts-ignore
								placeholder='Sua Senha'
								type='password'
								errorMessage={newPasswordModal.errors?.password}
								value={newPasswordModal.password}
								onChange={event => changeModalFieldValue('password', event.target.value)} />
						</div>
						<div className='uk-margin-top'>
							<InputWithLabel label='Confirmar Senha'
								//@ts-ignore
								placeholder='Confirme sua senha'
								type='password'
								errorMessage={newPasswordModal.errors?.passwordConfirmation}
								value={newPasswordModal.passwordConfirmation}
								onChange={event => changeModalFieldValue('passwordConfirmation', event.target.value)} />
						</div>
					</ModalWithLoading>
				}
				{setupMfa.show &&
					<ModalWithLoading
						requestKey="SETUP_MFA"
						title='Configurar autenticação MFA'
						confirmText='Continuar'
						onConfirm={() => history.push('/code-confirmation?setup=true')}
						onCloseModal={() => toggleSetupMFA(false, '')}>
						<p className='uk-text-center'>Para acessar o sistema, você deverá configurar uma autenticação MFA. <br /><br/>Para isso, instale o aplicativo <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US">"Google Authenticator"</a> em seu dispositivo móvel. Em seguida, no aplicativo, escolha a opção "Ler QR Code". Para mais informações, <a target="_blank" href="https://canaltech.com.br/apps/google-authenticator-como-usar/">clique aqui</a>.</p>
						<QRCode
							id="qr-gen"
							value={setupMfa.secret}
							size={290}
							level={"H"}
							includeMargin={true}
							className='uk-align-center'
						/>
					</ModalWithLoading>
				}
			</ContentContainer>
		</LoginContainerStyled>
	);
}