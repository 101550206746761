import { string, object, addMethod, boolean, number, lazy } from 'yup';
import Helper from 'helpers/schema.helpers';

const genericRequired = 'Esse campo é obrigatório.';

addMethod(string, 'email', function () {
	return this.test({
		name: 'email',
		message: 'E-mail não é válido.',
		test: (value) => {
			return value ? Helper.isEmailValid(value) : true;
		}
	});
});

const isNullOrEmpty = (value) => {
	return value == null || value == "";
};

export const validationSchema = object().shape({
	externalId: string().typeError('Selecione o código da carteira.').required(genericRequired),
	name: string().required(genericRequired),
	companyName: string().required(genericRequired),
	registrationNumber: string().required("CNPJ é obrigátorio."),
	email: string().typeError('Campo "E-mail" é obrigatório').required('Campo "E-mail" é obrigatório').email(),
	templateDoc: object().when("assignment", assignment => {
		return assignment == true ?
			object().shape({
				fileName: string().typeError('Modelo de contrato é obrigatório').required(genericRequired)
			}) :
			object().nullable().transform((value, _) => {
				return value?.fileName === null ? null : value;
			});
	}),
	templateBatchPath: object().when("assignment", assignment => {
		return assignment == true ?
			object().shape({
				fileName: string().typeError('Modelo de contrato é obrigatório').required(genericRequired)
			}) :
			object().nullable().transform((value, _) => {
				return value?.fileName === null ? null : value;
			});
	}),
	assignment: boolean(),
	assignmentType: string().when("assignment", {
		is: true,
		then: string().typeError(genericRequired).required(genericRequired),
		otherwise: string().nullable()
	}),
	assignmentCalculation: object().shape({
		amount: number().typeError('Valor precisa ser um número.').required(genericRequired),
		type: number().typeError('Tipo de Custo de Emissão é obrigatório.').required(genericRequired),
	}),
	ftpHostName: string().when("assignmentType", {
		is: 1,
		then: string().typeError('O campo Host-FTP é obrigatório').required(genericRequired),
		otherwise: string().nullable()
	}),
	ftpUser: string().when("assignmentType", {
		is: 1,
		then: string().typeError('O campo Usuário-FTP é obrigatório').required(genericRequired),
		otherwise: string().nullable()
	}),
	FtpPassword: string().when("assignmentType", {
		is: 1,
		then: string().typeError('O campo Senha-FTP é obrigatório').required(genericRequired),
		otherwise: string().nullable()
	}),
	pathLastro: string().nullable(),
	pathCnab: string().nullable(),
	signerMinimumQuorum: number().typeError('Valor precisa ser um número.').required(genericRequired),
	signaturePortalProvider: string().typeError('Selecione a Certificadora.').required(genericRequired),
	maximumValueWithoutDigitalSignature: number().typeError('Valor precisa ser um número.').nullable(),
	titleSpecies: number().typeError('Espécie de Título precisa ser um número.').nullable(),
	bankAccount: lazy(value => {
		var nothingIsFiled = isNullOrEmpty(value?.agency) && isNullOrEmpty(value?.account) && isNullOrEmpty(value?.bankCode)
			&& isNullOrEmpty(value?.type) && isNullOrEmpty(value?.accountDigit) && isNullOrEmpty(value?.agencyDigit);
		return nothingIsFiled ? object().nullable().transform((value, _) => {
			return Object.values(value ?? {}).every(x => x === null) ? null : value;
		}) : object().shape({
			bankCode: string().typeError('Selecione o código bancário.').required(genericRequired),
			account: number().typeError('Valor precisa ser um número.').required(genericRequired),
			accountDigit: number().nullable(),
			agency: number().typeError('Valor precisa ser um número.').required(genericRequired),
			agencyDigit: string().nullable(),
			type: string().typeError('Selecione o tipo de Conta.').required(genericRequired)
		}).transform((value, _) => {
			return Object.values(value ?? {}).every(x => x === null) ? null : value;
		});
	}),
	cnabType: string().typeError('O campo CNAB é obrigatório').required("CNAB é obrigátorio."),
	administrator: string().nullable(),
	manager: string().nullable(),
	address: object().shape({
		addressName: string().typeError('O campo Endereço é obrigatório').required(genericRequired),
		zipCode: string().typeError('O campo CEP é obrigatório').required(genericRequired),
		city: string().typeError('O campo Cidade é obrigatório').required(genericRequired),
		uf: string().typeError('O campo UF é obrigatório').required(genericRequired),
		district: string().typeError('O campo Bairro é obrigatório').required(genericRequired),
		number: string().typeError('O campo Numero é obrigatório').required(genericRequired),
		complement: string().nullable()
	})
});
export const defaultValues = {
	externalId: 1,
	name: "",
	companyName: "",
	registrationNumber: "",
	email: "",
	templateDoc: null,
	templateBatchPath: null,
	assignment: false,
	assignorCode: "",
	titleSpecies: null,
	assignmentType: "Email",
	assignmentCalculation: {
		type: "",
		ammount: 0
	},
	ftpHostName: "",
	ftpUser: "",
	FtpPassword: "",
	pathLastro: "",
	pathCnab: "",
	signerMinimumQuorum: 0,
	signaturePortalProvider: 0,
	maximumValueWithoutDigitalSignature: 0,
	bankAccount: null,
	cnabType: "444",
	administrator: "",
	manager: "",
};