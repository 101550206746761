export const optionsMonth = [
	{ name: "Janeiro", value: "January" },
	{ name: "Fevereiro", value: "February" },
	{ name: "Março", value: "March" },
	{ name: "Abril", value: "April" },
	{ name: "Maio", value: "May" },
	{ name: "Junho", value: "June" },
	{ name: "Julho", value: "July" },
	{ name: "Agosto", value: "August" },
	{ name: "Setembro", value: "September" },
	{ name: "Outubro", value: "October" },
	{ name: "Novembro", value: "November" },
	{ name: "Dezembro", value: "December" },
];