import React, { useEffect, useState } from 'react';
import { DatePicker } from 'components';
import {
	Avatar, Box, Divider, Grid, ListItem,
	ListItemAvatar, ListItemText, makeStyles, Paper,
	Table, TableBody, TableCell, TableContainer, TableHead,
	TableRow, Typography, MenuItem, Select, FormGroup, FormControlLabel
} from '@material-ui/core';
import NumberInput from "components/NumberInput/NumberInput";
import { Alert } from '@material-ui/lab';
import {
	AccountBalanceOutlined,
	AccessAlarmOutlined,
	ThumbUpAltOutlined,
	Brightness4Outlined,
	Update,
	AccountBalance,
	TrendingUp,
	Timeline as TimelineIcon,
} from '@material-ui/icons';
import BeachAccessIcon from '@material-ui/icons/AllInclusiveOutlined';
import WorkIcon from '@material-ui/icons/Work';
import moment from 'moment';
import Helper from 'helpers/format.helpers';
import { useProduct } from 'contexts/product';
import AutocompleteWithSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { usePerson } from 'contexts/person';
import SelectComponent from 'components/Select/Select';
import { optionsIndexer } from '../OptionsFields/options.indexer';
import { optionsMonth } from '../OptionsFields/options.Month';
import MultiSelectComponent from 'components/MultiSelect/MultiSelect';
import TextCustomMask from 'components/CustomMaskInput/TextCustomMask';
import TextFieldComponent from 'components/TextInput/TextField';
import CheckboxComponent from 'components/CheckBox/Checkbox';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	rootTabs: {
		flexGrow: 1,
	},
	childrenCenter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',

		'& .MuiButton-containedPrimary': {
			marginTop: '15px',
		},
		['@media print']: {
			display: 'none',
		}
	},
}));


function OperationsContent({ product, creditNote, removedFromPrintList, inputBindNumber, inputBind, simulationResponse, simulation, getValues, children, watch, setValue }) {

	const classes = useStyles();
	const { getProductsList, productOptionsList } = useProduct();
	const { getPersonsList, personOptionsList } = usePerson();
	const amortizationType = product?.amortizationType?.toLowerCase();
	const [selectedMonth, setSelectedMonth] = useState(getValues('amortization.skippedMonths') || []);

	const getValuesIndexer = ({ hasPlus }) => {
		const isIndexer = getValues('amortization.indexerValue');
		if (isIndexer) {
			const indx = getValues('amortization.indexer');
			return `${hasPlus ? '+ ' : ''}${isIndexer}% ${indx}`;
		} else if (hasPlus) {
			return '';
		}
		return 'Sem indexador pós-fixado';
	};

	var getPeriodDescription = () => {

		var first = simulationResponse?.startDate;
		var last = simulationResponse?.dueDate;

		return dateDiff(first, last);
	};

	function dateDiff(startingDate, endingDate) {

		var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));

		if (!endingDate) {
			endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
		}
		var endDate = new Date(endingDate);
		if (startDate > endDate) {
			var swap = startDate;
			startDate = endDate;
			endDate = swap;
		}
		var startYear = startDate?.getFullYear();
		var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
		var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

		var yearDiff = endDate?.getFullYear() - startYear;
		var monthDiff = endDate?.getMonth() - startDate?.getMonth();
		if (monthDiff < 0) {
			yearDiff--;
			monthDiff += 12;
		}
		var dayDiff = endDate?.getDate() - startDate?.getDate();
		if (dayDiff < 0) {
			if (monthDiff > 0) {
				monthDiff--;
			} else {
				yearDiff--;
				monthDiff = 11;
			}
			dayDiff += daysInMonth[startDate?.getMonth()];
		}

		var returnStr = "";
		let yearLabel = "anos";
		if (yearDiff == 1) {
			yearLabel = "ano";
		}

		let monthLabel = "meses";
		if (monthDiff == 1) {
			monthLabel = "mês";
		}

		let dayLabel = "dias";
		if (dayDiff == 1) {
			dayLabel = "dia";
		}

		if (yearDiff > 0 && monthDiff > 0) {
			return `${yearDiff} ${yearLabel} e ${monthDiff} ${monthLabel}`;
		} else if (yearDiff > 0) {
			return `${yearDiff} ${yearLabel}`;
		} else if (monthDiff > 0) {
			return `${monthDiff} ${monthLabel}`;
		} else {
			return `${dayDiff} ${dayLabel}`;
		}
	}

	useEffect(() => {
		simulation && amortizationType == "fgts" &&
			watch("personId") != null && watch("personId") != "" ? setValue("amortization.requestedAmount", 0) : null;
		watch("registrationNumber") != null & watch("registrationNumber") != "" ? setValue("amortization.requestedAmount", 0) : null;
		watch("personId") != null && watch("personId") != "" ? setValue("amortization.paymentMonth", null) : null;

	}, [watch("personId"), watch("registrationNumber")]);

	useEffect(() => {
		simulation && amortizationType == "fgts" &&
			watch("amortization.requestedAmount") != 0 ? setValue("personId", "") : null;

	}, [watch("amortization.requestedAmount")]);

	const onChangeDate = (date) => {
		setValue("amortization.startDate", date);
		const startDate = getValues("amortization.startDate") && new Date(getValues("amortization.startDate"));
		const day = startDate ? startDate.getDate() : null;
		setValue("amortization.paymentDay", day);
	};

	const isCorban = !!+window.__RUNTIME_CONFIG__.REACT_APP_CORBAN;
	const isPaymentDay = !!+window.__RUNTIME_CONFIG__.REACT_APP_PAYMENTDAY_FIELD;

	const valueTotal = simulationResponse?.paymentScheduleItems?.map(x => x.payment).reduce((a, b) => a + b);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		setValue("amortization.skippedMonths", value);
		setSelectedMonth(
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		<>
			<Box py={3} className="disablePrint">
				<Typography variant="h6" gutterBottom>
					Parâmetros da operação
				</Typography>
				<Grid container spacing={3}>
					{simulation &&
						<>
							<Grid item xs={12} sm={3}>
								<SelectComponent
									id="legalPerson"
									label="Tipo de Pessoa"
									name="legalPerson"
									defaultValue=""
									{...inputBind("legalPerson")}
									fields={[{ name: 'Pessoa Física', value: false }, { name: 'Pessoa Jurídica', value: true }]}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<AutocompleteWithSearch
									id="productId"
									name="productId"
									displayField="productIdDisplay"
									fullWidth
									label="Produto"
									watch={watch}
									fetch={getProductsList}
									options={productOptionsList}
									setValue={setValue}
									rowData={{ productId: watch("productId"), productIdDisplay: watch("productIdDisplay") }}
									params={{
										page: 0,
										size: 10
									}}
									{...inputBind(`productId`)}
									onChange={(_, options) => {
										setValue("product", options);
										setValue("productId", options?.value || '');
										setValue("productIdDisplay", options?.name || '');
										let amortizationType = options?.amortizationType;
										setValue("amortization.amortizationType", amortizationType?.toLowerCase());
									}}
								/>
							</Grid>
						</>
					}
					{simulation && amortizationType == "fgts" && <Grid item xs={12} sm={3}>
						<AutocompleteWithSearch
							id="personId"
							name="personId"
							fullWidth
							displayField="personDisplay"
							label="Tomador"
							watch={watch}
							setValue={setValue}
							rowData={{ personId: creditNote?.personId, personIdDisplay: creditNote?.personIdDisplay, discriminator: creditNote?.personDiscriminator }}
							params={{
								page: 0,
								size: 10
							}}
							fetch={getPersonsList}
							options={personOptionsList}
							{...inputBind('personId')}
							onChange={(_, options) => {
								setValue("personId", options?.value || '');
								setValue("personDisplay", options?.name);
								setValue("personDiscriminator", options?.discriminator);
								setValue("registrationNumber", '');
								if (watch("sameBeneficiary")) {
									setValue("beneficiaryId", options?.value || '');
									setValue("beneficiaryDisplay", options?.name);
									setValue("beneficiaryDiscriminator", options?.discriminator);
								};
							}}
						/>
					</Grid>}
					{simulation ? <Grid item xs={12} sm={3}>
						<NumberInput
							simulation={amortizationType == "fgts" ? true : false}
							id="amortization.requestedAmount"
							label={amortizationType != "fgts" ? "Valor solicitado" : "Saldo FGTS"}
							fullWidth
							name="amortization.requestedAmount"
							adornmentText="R$"
							{...inputBindNumber("amortization.requestedAmount")}
							defaultValue={0}
							disabled={watch("personId") != null && watch("personId") != "" ||
								watch("registrationNumber") != null && watch("registrationNumber") != "" ? true : false}
						/>
					</Grid> : amortizationType != "fgts" &&
					<Grid item xs={12} sm={3}>
						<NumberInput
							simulation={amortizationType == "fgts" ? true : false}
							id="amortization.requestedAmount"
							label="Valor solicitado"
							fullWidth
							name="amortization.requestedAmount"
							adornmentText="R$"
							{...inputBindNumber("amortization.requestedAmount")}
						/>
					</Grid>
					}

					{simulation && amortizationType === 'fgts' ?
						<Grid item xs={12} sm={3}>
							<TextFieldComponent
								simulation={amortizationType == "fgts" ? true : false}
								id="registrationNumber"
								name="registrationNumber"
								label="CPF"
								fullWidth
								placeholder="000.000.000-00"
								InputProps={{
									inputComponent: TextCustomMask,
									inputProps: { mask: Helper.cpfMask },
								}}
								{...inputBind("registrationNumber")}
								disabled={amortizationType == "fgts" ? watch("personId") != null && watch("personId") != "" : false}
							/>
						</Grid> : null}

					{simulation && amortizationType == "fgts" &&
						<Grid item xs={12} sm={3}>
							<SelectComponent
								id="amortization.paymentMonth"
								name="amortization.paymentMonth"
								label="Mês do aniversário"
								fullWidth
								defaultValue=""
								{...inputBind(`amortization.paymentMonth`)}
								fields={optionsMonth}
								required={watch("personId") != null && watch("personId") != "" ? false : true}
								disabled={watch("personId") != null && watch("personId") != "" ||
									watch("registrationNumber") != null && watch("registrationNumber") != "" ? true : false}
							/>
						</Grid>}
					<Grid item xs={12} sm={3}>
						<NumberInput
							required
							id="amortization.apr"
							name="amortization.apr"
							label="Taxa de Juros (%)"
							type="number"
							adornmentText="%"
							fullWidth
							decimalScale={6}
							inputProps={{ min: product?.minimumInterestRate * 100 ?? 0, max: product?.maximumInterestRate * 100 ?? 0, step: 0.01 }}
							{...inputBindNumber("amortization.apr")}
						/>
					</Grid>
					{amortizationType != "fgts" && <Grid item xs={12} sm={3}>
						<SelectComponent
							id="amortization.calculateByValueType"
							label="Tipo de Cálculo"
							name="amortization.calculateByValueType"
							defaultValue=""
							{...inputBind("amortization.calculateByValueType")}
							fields={[{ name: 'Valor Bruto', value: 'Gross' }, { name: 'Valor Líquido', value: 'Liquid' }]}
						/>
					</Grid>}
					<Grid item xs={3}>
						<NumberInput
							required
							id="amortization.termInMonths"
							name="amortization.termInMonths"
							label={amortizationType != "fgts" ? "Duração" : "Quantidade de parcelas adiantadas"}
							type="number"
							decimalScale={0}
							fullWidth
							autoComplete="amortization.termInMonths"
							inputProps={{ min: "1", max: "100", step: "0.1" }}
							{...inputBindNumber("amortization.termInMonths")}
						/>
					</Grid>
					{amortizationType != "fgts" && <Grid item xs={12} sm={3}>
						<SelectComponent
							id="amortization.periodicity"
							label="Periodicidade Juros"
							name="amortization.periodicity"
							{...inputBindNumber("amortization.periodicity")}
							defaultValue=""
							fields={[{ name: 'Mensal', value: 'Monthly' }, { name: 'Anual', value: 'Yearly' }]}
						/>
					</Grid>}
					{amortizationType == "studentloan" && <>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.fixedPaymentDuringGraduation"
								label="Pagamento durante o curso"
								fullWidth
								name="amortization.fixedPaymentDuringGraduation"
								adornmentText="R$"
								{...inputBindNumber("amortization.fixedPaymentDuringGraduation")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								required
								id="amortization.termsUntilGraduation"
								name="amortization.termsUntilGraduation"
								label="Parcelas até conclusão"
								type="number"
								fullWidth
								decimalScale={0}
								inputProps={{ min: "0", max: "160", step: "1" }}
								{...inputBindNumber("amortization.termsUntilGraduation")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								required
								id="amortization.absAfterGraduation"
								name="amortization.absAfterGraduation"
								label="Carência após conclusão"
								type="number"
								fullWidth
								decimalScale={0}
								inputProps={{ min: "0", max: "160", step: "1" }}
								{...inputBindNumber("amortization.absAfterGraduation")}
							/>
						</Grid>
					</>}
					{amortizationType != "fgts" && <Grid item xs={3}>
						<NumberInput
							required
							id="amortization.absAmortizationInMonths"
							name="amortization.absAmortizationInMonths"
							label="Carência Amortização"
							type="number"
							fullWidth
							decimalScale={0}
							inputProps={{ min: "0", max: "160", step: "1" }}
							{...inputBindNumber("amortization.absAmortizationInMonths")}
						/>
					</Grid>}
					{amortizationType != "fgts" && <Grid item xs={3}>
						<NumberInput
							required
							id="amortization.absInterestInMonths"
							name="amortization.absInterestInMonths"
							label="Carência Juros"
							type="number"
							fullWidth
							decimalScale={0}
							inputProps={{ min: "0", max: "160", step: "1" }}
							{...inputBindNumber("amortization.absInterestInMonths")}
						/>
					</Grid>}
					{isPaymentDay && amortizationType != "fgts" && <Grid item xs={12} sm={3}>
						<NumberInput
							required
							id="amortization.paymentDay"
							name="amortization.paymentDay"
							label="Dia de vencimento"
							fullWidth
							decimalScale={0}
							inputProps={{ min: "0", max: "31", step: "1" }}
							{...inputBindNumber("amortization.paymentDay")}
						/>
					</Grid>}
					<Grid item xs={3}>
						<DatePicker
							id="amortization.startDate"
							label="Data de início"
							variant="inline"
							name="amortization.startDate"
							fullWidth
							InputLabelProps={{ shrink: true, required: true }}
							onChangeDate={onChangeDate}
							{...inputBind("amortization.startDate")}
						/>
					</Grid>
					{amortizationType != "fgts" && <Grid item xs={3}>
						<SelectComponent
							id="amortization.daysInYear"
							label="Base de cálculo"
							name="amortization.daysInYear"
							{...inputBind("amortization.daysInYear")}
							defaultValue=""
							fields={[{ name: '360', value: 360 }, { name: '365', value: 365 }, { name: '252', value: 252 }]}
						/>
					</Grid>}
					{isCorban && 
					<Grid item xs={12} sm={3}>
						<NumberInput
							id="amortization.agentCommission.amount"
							name="amortization.agentCommission.amount"
							label="CORBAN"
							type="number"
							fullWidth
							decimalScale={2}
							inputProps={{ min: "0", max: "10", step: "1" }}
							adornmentText={watch('amortization.agentCommission.type') == 'Percentage' ? "%" : "R$"}
							{...inputBindNumber("amortization.agentCommission.amount")}
						/>
					</Grid>}
					
					{product?.allowFinanceTaxExemption == true && <Grid item xs={12} sm={3}>
						<SelectComponent
							id="amortization.financeTaxExempted"
							label="Isentar Operação de IOF"
							name="amortization.financeTaxExempted"
							{...inputBind("amortization.financeTaxExempted")}
							fields={[
								{ name: 'Sim', value: true }, { name: 'Não', value: false }
							]}
						/>
					</Grid>}
					{["cleanprice", "cleanpriceincentivized", "commercialpapper"].includes(amortizationType) && <>
						<Grid item xs={12} sm={3}>
							<SelectComponent
								id="amortization.indexer"
								label="Indexador Pós-Fixado"
								name="amortization.indexer"
								{...inputBindNumber("amortization.indexer")}
								defaultValue=""
								fields={optionsIndexer}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.indexerValue"
								label="Percentual Indexador"
								name="amortization.indexerValue"
								adornmentText="%"
								{...inputBindNumber("amortization.indexerValue")}
							/>
						</Grid>
					</>}
					{amortizationType == "receivablesprice" && <>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.fiduciaryGuarantee"
								label="Garantia Fiduciária"
								name="amortization.fiduciaryGuarantee"
								adornmentText="%"
								{...inputBindNumber("amortization.fiduciaryGuarantee")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<SelectComponent
								id="amortization.indexer"
								label="Indexador Pós-Fixado"
								name="amortization.indexer"
								{...inputBindNumber("amortization.indexer")}
								defaultValue=""
								fields={optionsIndexer}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.indexerValue"
								label="Percentual Indexador"
								name="amortization.indexerValue"
								adornmentText="%"
								{...inputBindNumber("amortization.indexerValue")}
							/>
						</Grid>
					</>}

					{(amortizationType == "homeequitysac" || amortizationType == "homeequityprice") && <>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.realStatevalue"
								label="Valor do imóvel"
								name="amortization.realStatevalue"
								adornmentText="R$"
								{...inputBindNumber("amortization.realStatevalue")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.administrationFeeIncents"
								label="Taxa de Administração"
								name="amortization.administrationFeeIncents"
								adornmentText="R$"
								{...inputBindNumber("amortization.administrationFeeIncents")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.physicalDamageInsurance"
								label="Seguro DFI"
								name="amortization.physicalDamageInsurance"
								adornmentText="%"
								decimalScale={6}
								{...inputBindNumber("amortization.physicalDamageInsurance")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<MultiSelectComponent
								id="amortization.skippedMonths"
								label="Meses sem pagamento"
								value={selectedMonth}
								onChange={handleChange}
								fields={optionsMonth}
								{...inputBind("amortization.skippedMonths")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.deathOrPermanentIncapabilityInsurance"
								label="Seguro MIP"
								name="amortization.deathOrPermanentIncapabilityInsurance"
								adornmentText="%"
								decimalScale={6}
								{...inputBindNumber("amortization.deathOrPermanentIncapabilityInsurance")}
							/>
						</Grid>
					</>}

					{amortizationType == "commercialpapper" && <>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.quantity"
								label="Quantidade de Notas Comerciais"
								name="amortization.quantity"
								decimalScale={0}
								inputProps={{ min: "0", max: "10000000", step: "1" }}
								{...inputBindNumber("amortization.quantity")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.unitPrice"
								label="Valor unitário"
								name="amortization.unitPrice"
								adornmentText="R$"
								{...inputBindNumber("amortization.unitPrice")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput
								id="amortization.issueNumber"
								label="Número da série"
								name="amortization.issueNumber"
								decimalScale={0}
								inputProps={{ min: "0", max: "10000000", step: "1" }}
								{...inputBindNumber("amortization.issueNumber")}
							/>
						</Grid>
					</>}
				</Grid>
			</Box>
			<Grid container alignItems="center" justifyContent="center" style={{ listStyle: "none" }}>
				<Grid item className={classes.childrenCenter}>
					{!simulationResponse && <Alert severity="warning">Clique em simular para ver a operação!</Alert>}
					{children}
				</Grid>
			</Grid>
			<Box sx={{ display: 'grid', width: '100%' }} >
				{simulationResponse && (
					<Grid container alignItems="center" justifyContent="center" style={{ listStyle: "none" }}>
						<Grid item sm={9}>
							<Grid container>
								{!removedFromPrintList?.includes("startDate") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<ThumbUpAltOutlined />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Data de Emissão" secondary={moment(simulationResponse?.startDate).format("DD/MM/YYYY")} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("termInMonths") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<Brightness4Outlined />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Prazo" secondary={getPeriodDescription()} />
										</ListItem><Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("monthlyInterest") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<TrendingUp />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Taxa A.M" secondary={Helper.formatPercentage(simulationResponse?.monthlyInterest)} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("dueDate") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<AccessAlarmOutlined />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Data de Vencimento" secondary={moment(simulationResponse?.dueDate).format("DD/MM/YYYY")} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("financeTax") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<AccountBalance />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="IOF" secondary={Helper.formatCurrency(simulationResponse?.financeTax || simulationResponse?.finTax)} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("yearlyInterest") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<TimelineIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Taxa A.A" secondary={Helper.formatPercentage(simulationResponse?.yearlyInterest)} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("initialValue") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<AccountBalanceOutlined />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Valor do Contrato" secondary={Helper.formatCurrency(simulationResponse?.initialValue)} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("emissionCost") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<WorkIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Custo de emissão" secondary={Helper.formatCurrency(simulationResponse?.emissionCost || simulationResponse?.comm)} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("effectiveMonthlyCost") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<TrendingUp />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="CET A.M." secondary={Helper.formatPercentage(simulationResponse?.effectiveMonthlyCost)} />
										</ListItem>
										<Divider variant="inset" component="li" />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("liquidValue") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<BeachAccessIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Valor Líquido" secondary={Helper.formatCurrency(simulationResponse?.liquidValue)} />
										</ListItem>
										<Divider variant="inset" component="li" />
										<br />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("indexer") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<Update />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Indexador" secondary={getValuesIndexer({ hasPlus: false })} />
										</ListItem>
										<Divider variant="inset" component="li" />
										<br />
									</Grid>
								</>}
								{!removedFromPrintList?.includes("effectiveYearlyCost") && <>
									<Grid item sm={4}>
										<ListItem className="disablePrint">
											<ListItemAvatar>
												<Avatar>
													<TimelineIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="CET A.A." secondary={Helper.formatPercentage(simulationResponse?.effectiveYearlyCost)} />
										</ListItem>
										<Divider variant="inset" component="li" />
										<br />
									</Grid>
								</>}
							</Grid>
						</Grid>
						<br />
						<Grid container xs={9} alignItems="center" justifyContent="center">
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											{!removedFromPrintList?.includes("installment") ? <TableCell>Parcela</TableCell> : <TableCell />}
											{!removedFromPrintList?.includes("dueDateTable") ? <TableCell align="right">Vencimento</TableCell> : <TableCell />}
											{!removedFromPrintList?.includes("principalAmountInCents") ? <TableCell align="right">Saldo Devedor</TableCell> : <TableCell />}
											{!removedFromPrintList?.includes("amortization") ? <TableCell align="right">Amortização</TableCell> : <TableCell />}
											{!removedFromPrintList?.includes("interest") ? <TableCell align="right">Juros</TableCell> : <TableCell />}
											{!removedFromPrintList?.includes("payment") ? <TableCell align="right">Pagamento</TableCell> : <TableCell />}
										</TableRow>
									</TableHead>
									<TableBody>
										{simulationResponse?.paymentScheduleItems?.map((row, i) => (
											<TableRow key={row.name}>
												{!removedFromPrintList?.includes("installment") ? <TableCell align="right">{i}</TableCell> : <TableCell />}
												{!removedFromPrintList?.includes("dueDateTable") ? <TableCell align="right">{moment(row.dueDate).format("DD/MM/YYYY")}</TableCell> : <TableCell />}
												{!removedFromPrintList?.includes("principalAmountInCents") ? <TableCell align="right">{Helper.formatCurrency(row.principalAmountInCents)}</TableCell> : <TableCell />}
												{!removedFromPrintList?.includes("amortization") ? <TableCell align="right">{Helper.formatCurrency(row.amortization)}</TableCell> : <TableCell />}
												{!removedFromPrintList?.includes("interest") ? <TableCell align="right">{Helper.formatCurrency(row.interest)}</TableCell> : <TableCell />}
												{!removedFromPrintList?.includes("payment") ? <TableCell align="right">{`${Helper.formatCurrency(row.payment)} ${getValuesIndexer({ hasPlus: true })}`}</TableCell> : <TableCell />}
											</TableRow>
										))}
										{!removedFromPrintList?.includes("valueTotal") ? <TableRow>
											<TableCell colSpan={5} align="right"><b>TOTAL:</b></TableCell>
											<TableCell align="right">{`${Helper.formatCurrency(valueTotal)}`}</TableCell>
										</TableRow> : <TableRow />}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				)}
				<h5 className='enablePrint id-simulation'>Para recuperar essa simulação, use o ID: {simulationResponse?.id}</h5>
			</Box>
		</>
	);
}

export default OperationsContent;
