import { string, number, object, lazy, mixed } from 'yup';
import { DefaultWarranty, HomeEquityWarranty, JudiciaryProcessWarranty, VehicleWarranty, WarrantyType } from 'global/types/types';

const genericRequired = 'Esse campo é obrigatório.';

export const validationSchema = lazy(value => {
	let baseShape = {
		warrantyType: mixed<WarrantyType>()
			.oneOf(['Vehicle', 'HomeEquity', 'JudiciaryProcess'], "Selecione um tipo de garantia"),
	};
	

	if (value.warrantyType == "Vehicle") {
		return object().shape({
			...baseShape,
			renavam: string().required(genericRequired),
			board: string().required(genericRequired),
			typeOfVehicle: string().required(genericRequired),
			chassis: string().required(genericRequired),
			supplier: string().required(genericRequired),
			taxNumberOfSupplier: string().required(genericRequired),
			brand: string().required(genericRequired),
			color: string().required(genericRequired),
			model: string().required(genericRequired),
			yearOfManufacture: string().required(genericRequired).max(4, 'Ano de fabricação precisa conter 4 digitos.').min(4, 'Ano de fabricação não pode ser menor que 4 digitos.'),
			yearOfModel: string().required(genericRequired).max(4, 'Ano do modelo precisa conter 4 digitos.').min(4, 'Ano do modelo não pode ser menor que 4 digitos.'), 
			typeOfFuel: string().required(genericRequired),
		})
			.transform((value: any, _: any) => {
				return new VehicleWarranty(value);
			});
	}

	if (value.warrantyType == "HomeEquity") {
		return object().shape({
			...baseShape,
			registryOffice: string().required(genericRequired),			
			registrationNumber: string().required(genericRequired),
			width: number().required(genericRequired),
			length: number().required(genericRequired),
			addressName: string().required(genericRequired),
			zipCodeAddress: string().required(genericRequired),
			uf: string().required(genericRequired),
			district: string().required(genericRequired),
			addressNumber: string().required(genericRequired),
			complementAddress: string().required(genericRequired),
			propertyFeatures: string().nullable(),
		})
			.transform((value: any, _: any) => {
				return new HomeEquityWarranty(value);
			});
	}

	if (value.warrantyType == "JudiciaryProcess") {
		return object().shape({
			...baseShape,
			number: string().required(genericRequired),
			court: string().required(genericRequired),
			valueOfCause: number().required(genericRequired),
			claimant: string().required(genericRequired),
			claimed: string().required(genericRequired),
			typeOfProcess: string().required(genericRequired),
		})
			.transform((value: any, _: any) => {
				return new JudiciaryProcessWarranty(value);
			});
	}
	return object().shape({ ...baseShape });
});

export const defaultValues = new DefaultWarranty();