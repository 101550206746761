import authRepository from 'repository/auth';
import * as cognitoRepository from 'repository/cognito';
class AuthRepository {
	
	getExpirationDateMilliseconds = () => localStorage.getItem(AuthRepository.TOKEN_EXP_KEY);

	setToken(token, expiration) {
		localStorage.setItem(AuthRepository.AUTH_TOKEN_KEY, token);
		localStorage.setItem(AuthRepository.TOKEN_EXP_KEY, expiration);
	}

	signOut() {
		localStorage.clear();
	}

	static AUTH_TOKEN_KEY = 'auth-token';
	static TOKEN_EXP_KEY = 'exp';
}

export default new AuthRepository();