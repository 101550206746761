import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import { ContentContainer, DateRangeSelectionModal } from 'components';
import { fetchDashboardInfo } from '../../services/dashboard';
import SalesChart from './SalesChart';
import moment from 'moment';
import TransactionChart from "./TransactionChart";
import { extractQueryParamsFromSearch, saveQueryParamsToSearch } from 'random/query-params';
import QueryFields from './query-fields';
import { AccountConfirmation } from 'pages/Login';
import DashboardHeader from './Header';
import CreditNoteListComponent from 'components/CreditNoteList';
import { useHistory } from 'react-router-dom';
import { useHeader } from 'contexts/header';
import { useMyPermissions } from 'contexts/permissions';

const CreditDashboard = () => {

	const history = useHistory();
	const { setTitle } = useHeader();
	const { getMyPermissionLevel } = useMyPermissions();
	const hasCreditPerm = !!getMyPermissionLevel("CreditNote", "read");

	localStorage.removeItem(AccountConfirmation.EMAIL_FIELD);
	localStorage.removeItem(AccountConfirmation.PASS_FIELD);

	const fetchDashboardDataStatePeriod = (state) => fetchDashboardData(state.initialDate || moment().subtract(7, 'days'), state.finalDate || moment());
	const fetchDashboardData = async (initialDate, finalDate) => {

		const fieldValues = {
			initialDate,
			finalDate
		};
		var data = await fetchDashboardInfo(initialDate, finalDate);
		saveQueryParamsToSearch(history, location, QueryFields, fieldValues);
		data && setState({
			...state,
			data
		});
	};

	const queryParams = extractQueryParamsFromSearch(window.location.search, QueryFields);

	const [state, setState] = useState({
		showDatePicker: false,
		initialDate: queryParams.initialDate || moment().subtract(7, 'days'),
		finalDate: queryParams.finalDate || moment(),
		msgFilter: '',
		data: {}
	});

	useEffect(() => {
		setTitle("Dashboard");
		fetchDashboardDataStatePeriod(state);
	}, []);

	useEffect(() => {
		fetchDashboardData(state.initialDate, state.finalDate);
	}, [state.initialDate, state.finalDate]);

	const filterDate = (initialDate, finalDate) => {
		const newState = {
			...state,
			showDatePicker: false,
			initialDate: (initialDate && moment(initialDate)) || null,
			finalDate: (finalDate && moment(finalDate)) || null
		};
		setState(newState);
	};

	const toggleDatePickerVisibility = (show) => {
		const newState = {
			...state,
			showDatePicker: show
		};
		setState(newState);
	};

	const { initialDate, finalDate, showDatePicker, data } = state;
	return (
		<ContentContainer>
			<Grid container spacing={1}>
				<Grid item xs={12} lg={3}>
					<button className='select-date-step wt-button-filter uk-button uk-margin-bottom' onClick={() => hasCreditPerm && toggleDatePickerVisibility(true)}>
						{!initialDate && !finalDate && 'Detalhes dos últimos 7 dias'}
						{initialDate && `De ${initialDate.format('DD/MM/YYYY')}`}
						{finalDate && ` até ${finalDate.format('DD/MM/YYYY')}`}
						<i className="fas fa-angle-right fa-fw" ></i>
					</button>
				</Grid>
			</Grid>
			<DashboardHeader
				revenue={data?.creditAmount ?? 0}
				saleCount={data?.operationsAmount ?? 0}
				reversal={data?.operationsCanceled ?? 0}
				mediumTicket={data?.averageCreditAmount ?? 0} />
			<br />
			<Grid container direction="row" spacing={1}>
				<Grid item xs={12} lg={3}>
					<TransactionChart data={data.operationsByProduct || []} />
				</Grid>
				<Grid item xs={12} lg={9}>
					<SalesChart data={data.operationsByStatus || []} />
				</Grid>
			</Grid>
			<br />
			{hasCreditPerm &&
				<Grid container spacing={2}>
					<CreditNoteListComponent
						key={`${initialDate}${finalDate}`}
						hideActions={true}
						title="Operações no período"
						initialDate={(initialDate && initialDate.toDate()) || null}
						finalDate={(finalDate && finalDate.toDate()) || null} />
				</Grid>}
			{showDatePicker && <DateRangeSelectionModal
				initialDate={(initialDate && initialDate.toDate()) || null}
				finalDate={(finalDate && finalDate.toDate()) || null}
				onFilter={filterDate}
				onCloseModal={() => toggleDatePickerVisibility(false)} />}
		</ContentContainer>
	);
};

export default CreditDashboard;