import React, { useEffect, useState } from 'react';
import { Button, TextField, Select, MenuItem, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { TablePagination } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { TableContainer } from 'containers/TableContainer';
import { localizationOptions } from 'helpers/table.helpers';
import typeOfDocument from 'global/enums/typeOfDocument';

function DocumentsRequired(props) {
	const { documentsRequired, setDocumentsRequired } = props;
	const addDocButton = React.useRef(null);
	const [editingTable, setEditingTable] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);

	useEffect(() => {
		if (documentsRequired.length < 1) {
			addDocButton.current.click();
			setEditingTable(true);
		}
	}, []);

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const personTypeField = [
		{ name: 'Pessoa Física', value: 'Natural' },
		{ name: 'Pessoa Jurídica', value: 'Legal' },
	];

	return (
		<TableContainer>
			<MaterialTable
				title={
					<Button onClick={() => {
						addDocButton.current.click();
						setEditingTable(true);

					}} disabled={editingTable} variant="contained" color="primary">
						<Typography variant="h7" component="span" style={{ color: "white", textTransform: 'none' }} >+ documento</Typography>
					</Button>
				}
				icons={{
					Add: props => (
						<div ref={addDocButton} >
						</div>
					),
				}}
				columns={[
					{
						title: 'Grupo',
						field: 'group',
						render: ({ group }) => group,
						editComponent: (props) => {
							return (
								<NumberFormat
									id='Group'
									name='Group'
									fullWidth
									value={props.value}
									onChange={(event) => props.onChange(event.target.value)}
									customInput={TextField}
								/>
							);
						}
					},
					{
						title: 'Tipo de Pessoa',
						field: 'personType',
						render: (requiredDoc) => (personTypeField?.find(p => p.value == requiredDoc.personType))?.name,
						editComponent: props => {
							const findedItem = personTypeField.find(item => item.value === props.value)?.name;
							return (
								<Select
									value={props.value || ''}
									fullWidth
									onChange={(event) => props.onChange(event.target.value)}
									component={TextField}
								>
									<MenuItem value={props.value}>{findedItem?.name}</MenuItem>
									{personTypeField && personTypeField.map(field => (
										<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
									))}
								</Select>
							);
						}
					},
					{
						title: 'Tipo de Documento',
						field: 'fileType',
						render: (requiredDoc) => (typeOfDocument?.find(f => f.value == requiredDoc?.fileType))?.name,
						editComponent:
							props => {
								const findedItem = typeOfDocument.find(item => item.value === props.value);
								return (
									<Select
										value={props.value || ''}
										fullWidth
										onChange={(event) => props.onChange(event.target.value)}
										component={TextField}
									>
										<MenuItem value={props.value}>{findedItem?.name}</MenuItem>
										{typeOfDocument && typeOfDocument.map(field => (
											<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
										))}
									</Select>
								);
							}
					}
				]}
				editable={{
					onRowAdd: newData =>
						new Promise((resolve, reject) => {
							setTimeout(() => {
								setEditingTable(false);
								setDocumentsRequired([...documentsRequired, newData]);
								resolve();
							}, 1000);
						}),
					onRowUpdate: (newData, oldData) => {
						return new Promise(resolve => {
							let index = documentsRequired.map(f => f.id ? f.id : f.tableData.id).indexOf(oldData.id ? oldData.id : oldData.tableData.id);
							let newDocumentRequired = [...documentsRequired];
							newDocumentRequired[index] = newData;
							setDocumentsRequired(newDocumentRequired);
							setEditingTable(false);
							resolve();
						});
					},
					onRowAddCancelled: () => {
						return new Promise(resolve => {
							setEditingTable(false);
							resolve();
						});
					},
					onRowDelete: (rowData) => {
						return new Promise(resolve => {
							let currentDoc = documentsRequired;
							let index = currentDoc?.map(f => f.productId).indexOf(rowData.productId);
							currentDoc.splice(index, 1);
							setDocumentsRequired([...currentDoc]);
							resolve();
						});
					}
				}}
				data={documentsRequired}
				localization={localizationOptions}
				onChangeRowsPerPage={onRowsPerPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true
				}}
			/>
		</TableContainer>
	);
}

export default DocumentsRequired;