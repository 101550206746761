type ZipCodeData = {
	erro: boolean;
	cep: string;
	logradouro: string;
	complemento: string,
	bairro: string,
	localidade: string,
	uf: string,
	ibge: string,
	gia: string,
	ddd: string,
	siafi: string
};

const queryZipCode = (zipCode: string): Promise<ZipCodeData> => {
	return fetch(`https://viacep.com.br/ws/${zipCode}/json/`).then((res) =>
		res.json()
	);
};

const queryZipCodeAndFillForm = (zipCode: string, setFieldValue: (field: string, value: string | null) => void) => {
	setFieldValue("zipCode", zipCode);
	queryZipCode(zipCode)
		.then((result) => {			
			if (result.erro != true){
				setFieldValue("addressName", result.logradouro);
				setFieldValue("district", result.bairro);
				setFieldValue("city", result.localidade);
				setFieldValue("uf", result.uf);		
			}	
		})
		.catch(() => {				
			setFieldValue("addressName", null);
			setFieldValue("district", null);
			setFieldValue("city", null);
			setFieldValue("uf", null);
		});
};

export {
	queryZipCodeAndFillForm,
	queryZipCode
};
