import { Grid, Box, TextField } from '@material-ui/core';
import { validationSchema, defaultValues } from './validation';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from 'helpers/form-helpers';
import TextFieldComponent from 'components/TextInput/TextField';
import { Select, DatePicker } from 'components';
import * as creditNoteService from 'services/creditNote';
import { useHistory } from 'react-router-dom';
import { optionsInsertBlock } from '../OptionsFields/options.insertBlock';
import ModalComponent from 'components/Modal/ModalComponent';
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';

function ModalBlock({ onCloseModal, id, openModal, recordType = "" }) {
	var pathApprove = recordType === "CreditApproval" || recordType === "ComplianceApproval" || recordType === "InstrumentApproval";
	var pathSignatures = recordType === "SignaturesValidation";

	const history = useHistory();
	const { handleSubmit, setValue, control, watch, getValues } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});

	useEffect(() => {
		pathApprove ? setValue("messageRequired", false) : setValue("messageRequired", true);
	}, [pathApprove]);

	const { errors } = useFormState({ control });

	const { inputBind } = getFormFunctions({ validationSchema, setValue, control, errors });

	const handleApprove = async (values) => {
		const { message } = values;
		await creditNoteService.approveCreditNoteById(id, recordType, message).then(() => history.push('/ccb'));
	};

	const handleCancel = async (values) => {
		delete values.cancel;
		delete values.messageRequired;
		await creditNoteService.cancelCreditNoteById(id, values).then(() => history.push('/ccb'));
	};

	const handleRejectOp = async (values) => {
		const { message } = values;
		delete values.messageRequired;
		var prom = pathSignatures ? creditNoteService.signatureCreditNoteById(id, true, message) : creditNoteService.disapproveCreditNoteById(id, values);
		prom.then(() => history.push('/ccb'));

	};

	function HandleForm() {
		return (
			<form style={{ margin: "10px" }}
				onSubmit={handleSubmit(values => {
					if (openModal == true && pathApprove) return handleApprove(values);
					if (openModal == true && recordType === "") return handleCancel(values);
					else if (openModal == false || pathSignatures) return handleRejectOp(values);
				})}
			>
				<Box py={1}>
					<Grid item>
						<TextFieldComponent
							rows={4}
							multiline
							fieldAreaHeight={100}
							id="message"
							fullWidth
							name="message"
							label="Digite o Motivo"
							component={TextField}
							{...inputBind(`message`)}
							required={getValues("messageRequired") === true ? true : false}
						/>
					</Grid>
				</Box>
				{recordType === 'onReject' &&
					<><Box py={1}>
						<Grid item>
							<Select
								id="insertBlock"
								name="insertBlock"
								label="Realizar Bloqueio?"
								{...inputBind("insertBlock")}
								defaultValue={null}
								fields={optionsInsertBlock} />
						</Grid>
					</Box>{watch("insertBlock") != null &&
						<Box py={2}>
							<Grid item>
								<DatePicker
									id="blockUntil"
									label="Data de Bloqueio"
									variant="inline"
									name="blockUntil"
									fullWidth
									disablePast={true}
									InputLabelProps={{ shrink: true, required: true }}
									{...inputBind("blockUntil")}
									required={true} />
							</Grid>
						</Box>}
					</>}
				{recordType?.length === 0 &&
					<><Box py={1}>
						<Grid item>
							<Select
								id="insertBlock"
								name="insertBlock"
								label="Realizar Bloqueio?"
								{...inputBind("insertBlock")}
								defaultValue={null}
								fields={optionsInsertBlock} />
						</Grid>
					</Box>{watch("insertBlock") != null &&
						<Box py={2}>
							<Grid item>
								<DatePicker
									id="blockUntil"
									label="Data de Bloqueio"
									variant="inline"
									name="blockUntil"
									fullWidth
									disablePast={true}
									InputLabelProps={{ shrink: true, required: true }}
									{...inputBind("blockUntil")}
									required={true} />
							</Grid>
						</Box>}
					</>}
				<br />
				<Grid container justifyContent='center' spacing={8}>
					<Grid item xs={5}>
						<Button color={pathApprove ? "primary" : "secondary"} type="submit" variant='contained' size='large' >
							Confirmar
						</Button>
					</Grid>
				</Grid>
			</form>
		);
	};

	return (
		<ModalComponent
			open={open}
			onClose={onCloseModal}
			title="Informe o motivo"
			subtitle={`Tem certeza que deseja ${pathApprove ? "aprovar?" : pathSignatures || recordType === "onReject" ? "rejeitar?" : "cancelar?"}`}
			enableButton={false}
			children={<HandleForm />}
		/>
	);
}
export default ModalBlock;
