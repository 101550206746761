import * as cognitoRepository from 'repository/cognito';


export function changePassword(oldPassword, newPassword) {
	try {
		return new Promise((resolve, reject)=>{
			const result = cognitoRepository.changePassword(oldPassword, newPassword);
			resolve(result);
		});
	} catch (exception) {
		Promise.reject(exception);
	}
}

export function* signIn({ data }) {
	const { resolve, reject, email, password } = data;

	try {
		const result = yield cognitoRepository.signIn(email, password);
		resolve(result);
	} catch (exception) {
		reject(exception);
	}
}

export function* confirmNewUserPassword({ data }) {
	const { resolve, reject, email, newPassword } = data;

	try {
		const result = yield cognitoRepository.confirmNewPassword(email, newPassword);
		resolve(result);
	} catch (exception) {
		reject(exception);
	}
}

export async function sendVerificationCodeForPasswordRecover(email) {
	return cognitoRepository.sendVerificationCodeForPasswordRecover(email);
}

export async function passwordRecover( email, verificationCode, password) {
	return Promise.resolve(cognitoRepository.passwordRecover(email, verificationCode, password));	
}