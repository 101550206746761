import axios, { AxiosError } from 'axios';
import * as yup from 'yup';
import { getToken } from "../repository/cognito";
import { ApiConfig } from "../config";
import { Toast } from '../components';
import { pickBy } from 'lodash';
import { validationSchema } from "../pages/ProductAccount/CreateEdit/validation";

type FiltersType = {
	page: number;
	size: number;
	searchString: string | undefined;
	name: string | boolean;
};

type BankAccountProductValidation = yup.InferType<typeof validationSchema>;

export async function getBankAccountProductList(filters: FiltersType, tenant: string) {

	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));

	const token = await getToken();
	let url = ApiConfig.baseURL + `/BankAccountProduct`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		let returnResult = result.data;
		//@ts-ignore
		returnResult.data = returnResult.data?.map(x => {
			if (x.packageValue > 0) {
				x.packageValue = x.packageValue / 100;
			}
			return x;
		});
		return returnResult;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de produtos de contas digitais");
		}
		throw err;
	}
}


export async function getBankAccountProductById(id: string, tenant: string) {

	if (!id) {
		throw new Error('id not provided');
	}

	const token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountProduct/${id}`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		if (result?.data?.packageValue > 0) {
			result.data.packageValue = result?.data?.packageValue / 100;
		}
		return result.data;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter as informações do Produto de conta digital");
		}
		throw err;
	}
}


export async function deleteProductAccountById(id: string) {

	if (!id) {
		throw new Error('id not provided');
	}

	const token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccountProduct/${id}`;
	
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await (await axios.delete(url, config)).data;
		Toast.showSuccessMessage("Produto excluído com sucesso.");
		return result;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível excluir o Produto de conta digital");
		}
		throw err;
	}
}


export async function editOrCreate(data: BankAccountProductValidation, option: 'create' | 'edit', tenant: string) {

	const payload = {
		...data,
		packageValue: data.packageValue ? data.packageValue * 100 : 0,
	};

	const token = await getToken();
	const url = data.id ?
		ApiConfig.baseURL + `/BankAccountProduct/${data.id}` :
		ApiConfig.baseURL + `/BankAccountProduct`;

	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(urlWithTenant, payload, config),
			successMessage: 'Produto cadastrado para conta digital com sucesso',
			errorMessage: 'Ocorreu um erro ao cadastrar um produto para conta digital'
		},
		edit: {
			function: () => axios.put(urlWithTenant, payload, config),
			successMessage: 'Produto para conta digital editado com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar o produto para conta digital'
		}
	};

	let result;

	try {

		result = await request[option].function();
		Toast.showSuccessMessage(request[option].successMessage);
		return result;

	}
	catch (err) {
		const error = err as AxiosError;
		if (error.response) {
			if (error.response?.data.message) {
				const message = error.response.data.message + '</br>' + error?.response?.data?.errors.map((err: Error) => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message);
			} else if (error.response?.data?.errors) {
				Toast.showErrorMessage(error?.response.data?.errors);
			} else {
				Toast.showErrorMessage(error.response.data || request[option].errorMessage);

			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}
		throw err;
	}
}