import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';
import { pickBy } from 'lodash';

export async function getOperatorGroupList(props, id) {

	const params = pickBy(props, v => (v !== undefined && v !== '' && v !== false));
	var token = await getToken();
	var url = ApiConfig.baseURL + `/User/${id}/Groups`;

	if (params.selectedTenant){
		url = url + "?tenant=" + params.selectedTenant;
	}

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de grupo do usuário", err);
		}
		throw err;
	}
}

export async function getOperatorList(props) {
	const { size, page, email, name, paginationToken, selectedTenant } = props;
	var token = await getToken();
	var url = ApiConfig.baseURL + `/User?size=${size}&page=${page}`;

	if (selectedTenant){
		url = url + "&tenant=" + selectedTenant;
	}

	url = name ? url + `&filter=name^="${name}"` : url;
	url = email ? url + `&filter=email^="${email}"` : url;
	url = paginationToken ? url + `&paginationToken=${paginationToken}` : url;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de operadores");
		}
		throw err;

	}
}

export async function deleteOperatorById(id, selectedTenant) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/User/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
    
	if (selectedTenant){
		url = url + "?tenant=" + selectedTenant;
	}

	let result;

	try {
		result = await axios.delete(url, config);
		Toast.showSuccessMessage("Operador excluído com sucesso.");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao tentar excluir o operador");
		}
		throw err;

	}
}

export async function getOperatorById(id, selectedTenant) {

	if (!id) {
		throw new Error('id not provided');
	}

	var token = await getToken();
	var url = ApiConfig.baseURL + `/User/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	if (selectedTenant){
		url = url + "?tenant=" + selectedTenant;
	}

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter as informações do Operador");
		}
		throw err;
	}
}

export async function editOrCreateOperator(data, option, userId, selectedTenant) {

	let { phoneNumber, sendNotifications } = data;

	var sendNotificationsValue = sendNotifications == true ? 1 : 0;
	const onlyNumbers = /\D/g;
	phoneNumber = phoneNumber?.toString().replace(onlyNumbers, '');
	
	const payload = {
		...data,
		phoneNumber: phoneNumber,
		registrationNumber: data?.registrationNumber?.toString().replace(onlyNumbers, ''),
		sendNotifications: sendNotificationsValue
	};

	var token = await getToken();
	var url = userId ?
		ApiConfig.baseURL + `/User/${userId}` :
		ApiConfig.baseURL + `/User`;

	if (selectedTenant){
		url = url + "?tenant=" + selectedTenant;
	}

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(url, payload, config),
			successMessage: 'Operador criado com sucesso',
			errorMessage: 'Não foi possível criar o operador.'
		},
		edit: {
			function: () => axios.put(url, payload, config),
			successMessage: 'Operador atualizado com sucesso.',
			errorMessage: 'Não foi possível atualizar o operador.'
		}
	};
	let result;

	try {

		result = await request[option].function();
		Toast.showSuccessMessage(request[option].successMessage);
		return result;

	}
	catch (err) {
		if (err.response) {
			if (err.response?.data.message) {
				const message = err.response.data.message + '</br>' + err?.response?.data?.errors.map(err => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message, err);
			} else if (err.response?.data?.errors) {
				Toast.showErrorMessage(err?.response.data?.errors);
			} else {
				Toast.showErrorMessage(err.response.data || request[option].errorMessage);

			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}
		throw err;

	}
}

export async function resetPasswordOperator(id, selectedTenant) {

	if (!id) {
		throw new Error('id not provided');
	}

	var token = await getToken();
	var url = ApiConfig.baseURL + `/User/${id}/ResetPassword`;

	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	try {
		return await axios.put(url, {}, config);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage(`Ocorreu um erro ao resetar a senha do operador.`, 'Erro', err);
		}
		throw err;
	}
}

export async function disableMFA(id, selectedTenant) {

	if (!id) {
		throw new Error('id not provided');
	}

	var token = await getToken();
	var url = ApiConfig.baseURL + `/User/${id}/DisableMFA`;
	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;
    
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	try {
		return await axios.put(url, {}, config);
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage(`Ocorreu um erro ao desabilitar o MFA.`, 'Erro', err);
		}
		throw err;
	}
}