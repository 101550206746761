
export default ({ active, payload, label }) => {
	if (!active) {
		return null;
	}
	return (
		<div style={{ backgroundColor: "white", border: "1px solid #cccccc", padding: 10 }}>
			{payload && payload[0].payload.key} : {payload && payload[0].payload.value}			
		</div>
	);
};