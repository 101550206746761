import React, { useState } from 'react';
import * as cognitoRepository from 'repository/cognito';

import qs from 'query-string';
import {
	InputWithLabel,
	DangerAlert,
	ContentContainer,
	PrimaryButton,
	LinkButton,
	Toast
} from 'components';
import {	
	signIn
} from '../../../services/user';
import HelpLinkList from '../HelpLinkList';
import {
	LoginContainerStyled,
	LoginContentStyled
} from 'assets/style';
import LoginHeader from 'pages/Login/Header';
import { useUser } from 'contexts/user';

const AccountConfirmation = (props) => {

	const resendCode = () => {
		const email = getEmail();
		if (!email) {
			setState({ ...state, errors: { email: 'Campo "E-mail" é obrigatório' } });
			return;
		}

		cognitoRepository.resendVerificationCode()
			.then((response) =>{
				Toast.showSuccessMessage('O código de confirmação foi enviado com sucesso para o seu e-mail.', 'Código enviado');
			}).catch(error =>
				Toast.showErrorMessage('Não foi possível enviar o código de confirmação. Por favor, tente novamente.', 'Erro ao enviar código', error)
			);
	};
	const getEmail = () => localStorage.getItem(AccountConfirmation.EMAIL_FIELD) || state.email;
	const { user } = useUser();
	const [state, setState] = useState({
		email: user?.payload?.email || '',
		confirmationCode: '',
		errors: { confirmationCode: null, email: null, message: null }
	});	

	const { history, location } = props;
	const queryParams = qs.parse(history.location.search);
	if (queryParams.resend && getEmail()) {
		history.replace({
			pathName: location.pathname
		});

		resendCode();
	}

	origin = queryParams.origin;
	

	const changeFieldValue = (fieldName) => (event) => {
		setState({ ...state, [fieldName]: event.target.value });
	};

	const confirmAccount = () => {
		const errors = validateFields();
		setState({...state, errors});
		if (Object.keys(errors).length > 0) {
			return;
		}

		const email = getEmail();
		const { confirmationCode } = state;	
		const { history } = props;		
		cognitoRepository.verifyEmail(confirmationCode)
			.then(() => {
				Toast.showSuccessMessage('Conta confirmada com sucesso.', 'Conta confirmada');
				const password = localStorage.getItem(AccountConfirmation.PASS_FIELD);								
				if (password) {
					tryAutomaticallySignInUser( history, email, password);
				} else {
					history.push('/');
				}
			}).catch(error => {
				if (error.message?.indexOf("is CONFIRMED") > -1){
					// try to update the user attributes
					
					history.push('/');
				}
				Toast.showErrorMessage('Não foi possível confirmar a conta. Por favor, tente novamente', 'Erro ao confirmar a conta', error);
			});
	};

	const tryAutomaticallySignInUser = ( history, email, password) => {
		signIn(email, password)
			.then(() => {
				const urlToRedirect = origin === AccountConfirmation.Origin.SIGN_IN ? '/' : '/';
				history.push(urlToRedirect);
			}).catch(() => {
				history.push('/login/0');
			});
	};

	const validateFields = () => {
		const errors = {};

		const email = getEmail();
		if (!email) {
			errors.email = 'Campo "E-mail" é obrigatório';
		}

		if (!state.confirmationCode) {
			errors.confirmationCode = 'Campo "Código" é obrigatório';
		}

		return errors;
	};

	const { email, confirmationCode, errors } = state;
	return (
		<LoginContainerStyled>
			<ContentContainer>
				<LoginContentStyled>
					<LoginHeader title='Confirmação de conta' subtitle='Para confirmar seu cadastro, insira abaixo o código que enviamos para o e-mail informado' />
					<DangerAlert msg={errors.message} />
					<div className='wt-form uk-form-stacked'>
						{!localStorage.getItem(AccountConfirmation.EMAIL_FIELD) &&
							<div className='uk-margin-top'>
								<InputWithLabel label='E-mail'
									placeholder='E-mail'
									value={email}
									disabled={true}
									errorMessage={errors.email}
									onChange={changeFieldValue('email')} />
							</div>
						}
						<div className='uk-margin-top'>
							<InputWithLabel label='Código'
								placeholder='Código de verificação'
								type='number'
								errorMessage={errors.confirmationCode}
								value={confirmationCode}
								onChange={changeFieldValue('confirmationCode')} />
						</div>
						<div className='uk-grid uk-child-width-1-2 uk-margin-top'>
							<div>
								<LinkButton onClick={resendCode}>Reenviar código</LinkButton>
							</div>
							<div className='uk-text-right'>
								<PrimaryButton onClick={confirmAccount}>Confirmar</PrimaryButton>
							</div>
						</div>
					</div>

				</LoginContentStyled>
				<HelpLinkList />
			</ContentContainer>
		</LoginContainerStyled>
	);
	
};

export default AccountConfirmation;