import React, { createContext, useContext, useState, useEffect } from 'react';
import { getTenantList } from 'services/tenant';
import { useUser } from 'contexts/user';
import { IProps } from 'global/interface/types';

type GlobalTenant = {
	tenantList: [],
	selectedTenant: string | undefined,
	getTenantList: (page: number, size: number, search: any, selectedTenant: string) => void,
	setSelectedTenant: (selected: { id: string; name: string; }) => void,
};

const TenantContext = createContext<GlobalTenant>({ selectedTenant: undefined, tenantList: [], setSelectedTenant: () => { }, getTenantList: () => { } });

export const TenantProvider = ({ children }: IProps) => {
	const [selectedTenant, setTenant] = useState<string | undefined>(undefined);
	const [tenantList, setTenantList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: "" });
	const { user } = useUser();

	const setSelectedTenant = (selected: { id: string; name: string; }) => {
		setTenant(selected?.id);
	};

	const searchTenant = (page: number, size: number, search: string, selectedTenant: string | undefined) => {
		return getTenantList(page, size, search, selectedTenant).then((response: any) => {
			setTenantList(response?.data);
		});
	};

	useEffect(() => {
		if (!user?.authenticated)
			return;
		searchTenant(searchArgs.page, searchArgs.size, searchArgs.search, selectedTenant);
	}, [searchArgs, user, selectedTenant]);

	const contextGetTenantList = (page: number, size: number, search: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || search != searchArgs?.search) {
			setSearchArgs({ page, size, search });
		}
	};

	return (
		<TenantContext.Provider
			value={{
				selectedTenant: selectedTenant,
				setSelectedTenant,
				getTenantList: (page: any, size: any, search: any) => {
					return contextGetTenantList(page, size, search);
				},
				tenantList: tenantList
			}}
		>
			{children}
		</TenantContext.Provider>
	);
};

export function useTenant() {
	const context = useContext(TenantContext);
	const {
		selectedTenant,
		setSelectedTenant,
		getTenantList,
		tenantList
	} = context;
	return {
		selectedTenant,
		setSelectedTenant,
		getTenantList,
		tenantList
	};
}
