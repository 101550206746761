import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';
import { pickBy } from 'lodash';

export async function getFundsList(filters, tenant) {

	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));

	var token = await getToken();
	var url = ApiConfig.baseURL + '/Fund';
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter o fundo", err);
		}
		throw err;
	}
}

export async function getFundById(id) {

	if (!id) {
		throw new Error('id not provided');
	}

	var token = await getToken();
	var url = ApiConfig.baseURL + `/Fund/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		let payload = {
			...result.data
		};
		
		if (payload.assignmentCalculation){
			if (payload.assignmentCalculation.type === 1){
				payload.assignmentCalculation.amount = payload?.assignmentCalculation?.amount * 100;
			} else if (payload.assignmentCalculation.type === 0){
				payload.assignmentCalculation.amount = payload?.assignmentCalculation?.amount / 100;
			}
		}

		return payload;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter as informações do Produto");
		}
		throw err;
	}
}

export async function editOrCreate(data, option, tenant) {

	var payload = {
		...data,
		assignmentCalculation: {
			...data.assignmentCalculation,
			amount: data?.assignmentCalculation?.amount,
		}
	};

	if (payload.assignmentCalculation){
		if (payload.assignmentCalculation.type === 1){
			payload.assignmentCalculation.amount = payload.assignmentCalculation.amount;
		} else if (payload.assignmentCalculation.type === 0){
			payload.assignmentCalculation.amount = payload.assignmentCalculation.amount * 100;
		}
	}

	var token = await getToken();
	
	var url = data.id ? 
		ApiConfig.baseURL + `/Fund/${data.id}` :
		ApiConfig.baseURL + `/Fund`;

	var urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(urlWithTenant, payload, config),
			successMessage: 'Fundo cadastrado com sucesso',
			errorMessage: 'Ocorreu um erro no cadastrar o Fundo'
		},
		edit: {
			function: () => axios.put(urlWithTenant, payload, config),
			successMessage: 'Fundo editado com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar o Fundo'
		}
	};

	let result;

	try {

		result = await request[option].function();
		Toast.showSuccessMessage(request[option].successMessage);
		return result;

	}
	catch (err) {
		if (err.response) {
			if (err.response?.data.message) {
				const message = err.response.data.message + '</br>' + err?.response?.data?.errors.map(err => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message, err);
			} else if (err.response?.data?.errors) {
				Toast.showErrorMessage(err?.response.data?.errors);
			} else {
				Toast.showErrorMessage(err.response.data || request[option].errorMessage);

			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}
		throw err;
	}
}