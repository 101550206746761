import { Info } from '@material-ui/icons';
import Helper from 'helpers/schema.helpers';
import { string, object, date, number, boolean, array, addMethod } from 'yup';

const genericRequired = 'Esse campo é obrigatório.';

export const getValidationSchema = (product) => {

	const formatBrazilianRegistrationNumber = (value) => Helper.cpfValidate(value);
	
	addMethod(string, 'customCPFValidator', function () {
		return this.test({
			name: 'cpfValidation',
			message: 'CPF: inválido.',
			test: (value) => {
				return value ? formatBrazilianRegistrationNumber(value) : true;
			}
		});
	});

	var getNumberWithPrecision = (number) => {
		return parseFloat(parseFloat(number).toPrecision(6));
	};

	var minimum = getNumberWithPrecision((product?.minimumInterestRate ?? 0));
	var maximum = getNumberWithPrecision((product?.maximumInterestRate ?? 100));

	const isNullOrEmpty = (value) => {
		return value == null || value == "";
	};
	

	switch (product?.amortizationType?.toLowerCase()) {
		case "studentloan":
			return object().shape({
				productId: string().required('Produto é um campo obrigatório.'),
				legalPerson: boolean().required('Tipo de Pessoa é um campo obrigatório.'),
				amortization: object().shape({
					calculateByValueType: string().required('Tipo de Cálculo é um campo obrigatório.'),
					startDate: date().typeError('Data inválida').required("Data de início é obrigatória."),
					termInMonths: number().typeError('Valor precisa ser um número.').required("Duração em meses é obrigatória."),
					periodicity: string().required("Periodicidade Juros é obrigatória."),
					daysInYear: number().typeError('Valor precisa ser um número.').required("Dias no ano é obrigatório"),
					requestedAmount: number().typeError('Valor precisa ser um número.').required("Valor do principal é obrigatório."),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required("Carência Amortização é obrigatória."),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required("Carência Juros é obrigatória."),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					financeTaxExempted: boolean(),
					paymentDay: number().required("Dia de pagamento é obrigatório").min(1, "Informe o dia de vencimento").max(31, "Informe o dia de vencimento"),
					absAfterGraduation: number().typeError('Valor precisa ser um número.').required("O valor da parcela durante o curso é obrigatório."),
					fixedPaymentDuringGraduation: number().typeError('Valor precisa ser um número.').required("O valor da parcela durante o curso é obrigatório."),
					termsUntilGraduation: number().typeError('Valor precisa ser um número.').required("Quantidade de parcelas até a conclusão do curso é obrigatório."),
				}),
			});
		case "receivablesprice":
			return object().shape({
				productId: string().required('Produto é um campo obrigatório.'),
				legalPerson: boolean().required('Tipo de Pessoa é um campo obrigatório.'),
				amortization: object().shape({
					calculateByValueType: string().required('Tipo de Cálculo é um campo obrigatório.'),
					startDate: date().typeError('Data inválida').required("Data de início é obrigatória."),
					termInMonths: number().typeError('Valor precisa ser um número.').required("Duração em meses é obrigatória."),
					periodicity: string().required("Periodicidade Juros é obrigatória."),
					daysInYear: number().typeError('Valor precisa ser um número.').required("Dias no ano é obrigatório"),
					requestedAmount: number().typeError('Valor precisa ser um número.').required("Valor do principal é obrigatório."),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required("Carência Amortização é obrigatória."),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required("Carência Juros é obrigatória."),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					paymentDay: number().required("Dia de pagamento é obrigatório").min(1, "Informe o dia de vencimento").max(31, "Informe o dia de vencimento"),
					fiduciaryGuarantee: number().typeError('O percentual de garantia é obrigatótia.').required('O percentual de garantia é obrigatótia.'),
					financeTaxExempted: boolean()
				}),
			});
		case "cleanpricenoiof":
			return object().shape({
				productId: string().required('Produto é um campo obrigatório.'),
				legalPerson: boolean().required('Tipo de Pessoa é um campo obrigatório.'),
				amortization: object().shape({
					calculateByValueType: string().required('Tipo de Cálculo é um campo obrigatório.'),
					startDate: date().typeError('Data inválida').required("Data de início é obrigatória."),
					termInMonths: number().typeError('Valor precisa ser um número.').required("Duração em meses é obrigatória."),
					periodicity: string().required("Periodicidade Juros é obrigatória."),
					daysInYear: number().typeError('Valor precisa ser um número.').required("Dias no ano é obrigatório"),
					requestedAmount: number().typeError('Valor precisa ser um número.').required("Valor do principal é obrigatório."),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required("Carência Amortização é obrigatória."),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required("Carência Juros é obrigatória."),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					paymentDay: number().required("Dia de pagamento é obrigatório").min(1, "Informe o dia de vencimento").max(31, "Informe o dia de vencimento")					
				}),
			});
		case "fgts":
			return object().shape({
				productId: string().required(genericRequired),
				personId: string().nullable(),
				legalPerson: boolean(),
				registrationNumber: string().when(["personId", "requestedAmount", "amortization"], {
					is: (personId, requestedAmount, amortization) => {
						const monthAndAmountFilled = (!requestedAmount && !amortization.paymentMonth) === true;
						const shouldOnlyUseRegistrationNumber = isNullOrEmpty(personId) && monthAndAmountFilled;
						return shouldOnlyUseRegistrationNumber;
					},
					then: string().customCPFValidator().required(genericRequired),
					otherwise: string().customCPFValidator().nullable(),
				}),
				amortization: object().when("personId", personId => {
					return object().shape({
						amortizationType: string().required(genericRequired),
						requestedAmount: personId == null || personId == "" ?
							number().typeError("Valor inválido.") :
							number().nullable(),
						startDate: date().typeError('Data inválida').required(),
						termInMonths: number()
							.required(genericRequired)
							.min(1, "Informe a quantidade de parcelas")
							.max(10, "Quantidade máxima de parcelas excedida (10 parcelas)")
							.typeError("Valor inválido."),
						apr: number()
							.min(minimum, `Valor mínimo: ${minimum}%`)
							.max(maximum, `Valor máximo: ${maximum}%`)
							.typeError("Valor deve ser um número")
							.required(genericRequired),
						paymentMonth: string().when("requestedAmount", {
							is: (requestedAmount) => {
								return !requestedAmount;
							},
							then: string().nullable().typeError("Valor inválido."),
							otherwise: string().required(genericRequired),
						}),
						agentCommission: object().shape({
							amount: number().transform((value, original) => {
								return isNaN(value) ? 0 : value;
							}),
						}),
						financeTaxExempted: boolean(),
					});
				})
			});
		case "homeequityprice":
			return object().shape({
				productId: string().required('Produto é um campo obrigatório.'),
				legalPerson: boolean().required('Tipo de Pessoa é um campo obrigatório.'),
				amortization: object().shape({
					calculateByValueType: string().required('Tipo de Cálculo é um campo obrigatório.'),
					startDate: date().typeError('Data inválida').required("Data de início é obrigatória."),
					termInMonths: number().typeError('Valor precisa ser um número.').required("Duração em meses é obrigatória."),
					periodicity: string().required("Periodicidade Juros é obrigatória."),
					daysInYear: number().typeError('Valor precisa ser um número.').required("Dias no ano é obrigatório"),
					requestedAmount: number().typeError('Valor precisa ser um número.').required("Valor do principal é obrigatório."),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required("Carência Amortização é obrigatória."),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required("Carência Juros é obrigatória."),
					financeTaxExempted: boolean(),

					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					paymentDay: number().typeError('Valor precisa ser um número.').required(genericRequired),
					administrationFeeIncents: number().typeError('Valor precisa ser um número.').required(genericRequired),
					realStatevalue: number().typeError('Valor precisa ser um número.').required(genericRequired),
					physicalDamageInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					deathOrPermanentIncapabilityInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					skippedMonths: array().of(string().max(11, "Número de meses selecionado excedido")).typeError("Meses sem pagamento: Selecione pelo menos um mês.")
				}),
			});
		case "homeequitysac":
			return object().shape({
				productId: string().required('Produto é um campo obrigatório.'),
				legalPerson: boolean().required('Tipo de Pessoa é um campo obrigatório.'),
				amortization: object().shape({
					calculateByValueType: string().required('Tipo de Cálculo é um campo obrigatório.'),
					startDate: date().typeError('Data inválida').required("Data de início é obrigatória."),
					termInMonths: number().typeError('Valor precisa ser um número.').required("Duração em meses é obrigatória."),
					periodicity: string().required("Periodicidade Juros é obrigatória."),
					daysInYear: number().typeError('Valor precisa ser um número.').required("Dias no ano é obrigatório"),
					requestedAmount: number().typeError('Valor precisa ser um número.').required("Valor do principal é obrigatório."),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required("Carência Amortização é obrigatória."),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required("Carência Juros é obrigatória."),
					financeTaxExempted: boolean(),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					paymentDay: number().typeError('Valor precisa ser um número.').required(genericRequired),
					administrationFeeIncents: number().typeError('Valor precisa ser um número.').required(genericRequired),
					realStatevalue: number().typeError('Valor precisa ser um número.').required(genericRequired),
					physicalDamageInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					deathOrPermanentIncapabilityInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					skippedMonths: array().of(string().max(11, "Número de meses selecionado excedido")).typeError("Meses sem pagamento: Selecione pelo menos um mês."),
				}),
			});
		default:
			return object().shape({
				productId: string().required('Produto é um campo obrigatório.'),
				legalPerson: boolean().required('Tipo de Pessoa é um campo obrigatório.'),
				amortization: object().shape({
					calculateByValueType: string().required('Tipo de Cálculo é um campo obrigatório.'),
					startDate: date().typeError('Data inválida').required("Data de início é obrigatória."),
					termInMonths: number().typeError('Valor precisa ser um número.').required("Duração em meses é obrigatória."),
					periodicity: string().required("Periodicidade Juros é obrigatória."),
					daysInYear: number().typeError('Valor precisa ser um número.').required("Dias no ano é obrigatório"),
					requestedAmount: number().typeError('Valor precisa ser um número.').required("Valor do principal é obrigatório."),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required("Carência Amortização é obrigatória."),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required("Carência Juros é obrigatória."),
					financeTaxExempted: boolean(),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					paymentDay: number().required("Dia de pagamento é obrigatório").min(1, "Informe o dia de vencimento").max(31, "Informe o dia de vencimento"),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
						type: string().typeError('Valor precisa ser um número.')
					}),
				}),
			});
	}
};

export const getDefaultValues = (product) => {
	switch (product?.amortizationType) {
		case "studentloan":
			return {
				legalPerson: false,
				amortization: {
					amortizationType: "studentloan",
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					calculateByValueType: 'Liquid',
					ammGraceperiod: 0,
					interestGracePeriod: 0,
					absAmortizationInMonths: 0,
					periodicity: 'Monthly',
					absInterestInMonths: 0,
					agentCommission: {
						amount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						type: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					indexerValue: 0,
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					daysInYear: 360,
					absAfterGraduation: 3,
					fixedPaymentDuringGraduation: 1500,
					termsUntilGraduation: 6,
					paymentDay: new Date().getDate(),
					financeTaxExempted: false,
				}
			};
		case "receivablesprice":
			return {
				legalPerson: false,
				amortization: {
					amortizationType: "receivablesprice",
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					calculateByValueType: 'Liquid',
					ammGraceperiod: 0,
					interestGracePeriod: 0,
					absAmortizationInMonths: 0,
					periodicity: 'Monthly',
					absInterestInMonths: 0,
					agentCommission: {
						amount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						type: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					indexerValue: 0,
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					daysInYear: 360,
					fiduciaryGuarantee: 0,
					paymentDay: new Date().getDate(),
					financeTaxExempted: false,
				}
			};
		case "fgts":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				legalPerson: false,
				personId: "",
				amortization: {
					amortizationType: "fgts",
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					registrationNumber: null,
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					paymentMonth: null,
					financeTaxExempted: false,
				},
			};
		case "homeequityprice":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				sameBeneficiary: true,
				amortization: {
					amortizationType: "homeequityprice",
					legalPerson: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					financeTaxExempted: false,
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Liquid',
					indexer: '',
					indexerValue: 0,
					administrationFeeIncents: 1500,
					realStatevalue: 100000.00,
					physicalDamageInsurance: 0,
					deathOrPermanentIncapabilityInsurance: 0,
					skippedMonths: [],
					paymentDay: new Date().getDay(),
				},
				personId: "",
				observations: ""
			};
		case "homeequitysac":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				sameBeneficiary: true,
				amortization: {
					amortizationType: "homeequitysac",
					legalPerson: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					financeTaxExempted: false,
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Liquid',
					indexer: '',
					indexerValue: 0,
					administrationFeeIncents: 1500,
					realStatevalue: 100000.00,
					physicalDamageInsurance: 0,
					deathOrPermanentIncapabilityInsurance: 0,
					skippedMonths: [],
					paymentDay: new Date().getDay(),
				},
				personId: "",
				observations: ""
			};
		case "cleanpricenoiof":
			return {
				legalPerson: false,
				amortization: {
					amortizationType: "receivablesprice",
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					calculateByValueType: 'Liquid',
					ammGraceperiod: 0,
					interestGracePeriod: 0,
					absAmortizationInMonths: 0,
					periodicity: 'Monthly',
					absInterestInMonths: 0,
					agentCommission: {
						amount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						type: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					indexerValue: 0,
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					daysInYear: 360,
					fiduciaryGuarantee: 0,
					paymentDay: new Date().getDate(),
					financeTaxExempted: false,
				}
			};
		default:
			return {
				legalPerson: false,
				amortization: {
					amortizationType: "cleanprice",
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					calculateByValueType: 'Gross',
					ammGraceperiod: 0,
					interestGracePeriod: 0,
					absAmortizationInMonths: 0,
					periodicity: 'Monthly',
					absInterestInMonths: 0,
					agentCommission: {
						amount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						type: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					indexerValue: 0,
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					daysInYear: 360,
					paymentDay: new Date().getDate(),
					financeTaxExempted: false,
				}
			};
	}
};
