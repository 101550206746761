import React, { useState } from 'react';
import { ContentContainer } from 'components';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { TablePagination, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useHeader } from 'contexts/header';
import ActionBar from 'components/ActionBar/ActionBar';
import { deleteGroupById, getGroupList } from 'services/group';
import { useTenant } from 'contexts/tenant';

const GroupList = (props) => {

	const { selectedTenant } = useTenant();
	const history = useHistory();
	const tableRef = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const { setTitle } = useHeader();
	const [paginationState, setPaginationState] = useState({ 0: null });

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		setPaginationState({ 0: null });
		localStorage.setItem('rowsPerPage', page);
	};

	const actions = {
		onRefresh: () => tableRef?.current?.onQueryChange(),
	};

	return (
		<ContentContainer>
			{<ActionBar {...actions} hideSubmit={true} />}
			<div className='uk-section uk-section-small uk-padding-remove-top'>
				<div className='uk-grid-small' data-uk-grid>
					<div className='uk-width-auto@m uk-width-1-1'>
						<Link to='/grupo/novo' style={{ backgroundColor: '#3f51b5', textDecoration: "none" }} className='wt-button wt-button-lowercase uk-button'>
							<i className='fa fa-user-plus uk-margin-small-right' style={{ color: "white" }}></i>
							<Typography component="span" style={{ color: "white", textTransform: 'none', }}>Adicionar grupo</Typography>
						</Link>
					</div>
				</div>
			</div>
			<MaterialTable
				tableRef={tableRef}
				title='Grupos'
				columns={[
					{ title: 'Nome', field: 'groupName', render: ({ groupName }) => groupName },
					{ title: 'Precedência', field: 'precedence' },
					{ title: 'Data de Criaçao', field: 'creationDate', render: ({ creationDate }) => creationDate && new Date(creationDate).toLocaleDateString('pt-BR') }
				].filter(x => x !== undefined)}
				actions={[
					{
						icon: 'search',
						tooltip: 'Editar',
						position: 'row',
						onClick: (_, rowData) => history.push(`/grupo/editar?id=${rowData.groupName}`)
					},
				]}
				editable={{
					onRowDelete: oldData =>
						new Promise(resolve => {
							deleteGroupById(oldData.groupName, selectedTenant).then(_ => {
								resolve();
							}).catch(_ => {
								resolve();
							});
						})
				}}
				data={({ page, pageSize, search }) =>
					new Promise((resolve, reject) => {

						const filters = {
							page,
							size: pageSize,
							paginationToken: paginationState[page],
							searchString: search,
							selectedTenant
						};
						getGroupList(filters)
							.then(result => {
								setTitle("Grupos");
								if (result?.data)
									resolve({
										data: result.data,
										page: result.page,
										totalCount: result.totalItems,
									});
								var paginationToken = result?.paginationToken;
								setPaginationState({ ...paginationState, [page + 1]: paginationToken });

							}).catch(err => reject(err));
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true
				}}
			/>
		</ContentContainer>
	);
};

export default GroupList;
