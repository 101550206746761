import React, { createContext, useContext, useState, useEffect } from 'react';
import Helper from 'helpers/format.helpers';
import { getNaturalPersonsList } from 'services/naturalPerson';
import { useMyPermissions } from './permissions';
import { useTenant } from './tenant';
import { IProps } from 'global/interface/types';

type GlobalNaturalPersonContent = {
	naturalPersonsOptionList: []
	setNaturalPersonsOptionList: () => void
	getNaturalPersonsList: (page: number, size: number, search: string) => void
};

const NaturalPersonsContext = createContext<GlobalNaturalPersonContent>({ naturalPersonsOptionList: [], setNaturalPersonsOptionList: () => { }, getNaturalPersonsList: () => { } });

export const NaturalPersonsProvider = ({ children }: IProps) => {
	const [naturalPersonsOptionList, setNaturalPersonsOptionList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: undefined });
	const { selectedTenant } = useTenant();
	const { getMyPermissionLevel } = useMyPermissions();
	//@ts-ignore
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';

	const hasNaturalPersonPerm: boolean = !!getMyPermissionLevel("NaturalPerson", "read");

	useEffect(() => {
		if (!hasNaturalPersonPerm) return;
		//@ts-ignore
		searchNaturalPersons(searchArgs.page, searchArgs.size, searchArgs.search, selectedTenant);

	}, [searchArgs, selectedTenant]);


	const searchNaturalPersons = (page: number, size: number, searchString: string, selectedTenant: undefined) => {

		//@ts-ignore
		return getNaturalPersonsList({ page, size, searchString }, selectedTenant).then(resp => {
			//@ts-ignore
			setNaturalPersonsOptionList(resp?.data.map(n => {
				return { name: isRootTenancy ? `${n.name || "Não informado"} (${n.tenantDisplay}) - (${Helper.formatDocumentNumber(n.registrationNumber)})` : n.name, value: n.name, id: n.id };
			}));
		});
	};



	const contextGetNaturalPersonsList = (page: number, size: number, search: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || search != searchArgs?.search) {
			//@ts-ignore
			setSearchArgs({ page, size, search });
		}
	};

	return (
		<NaturalPersonsContext.Provider value={{
			naturalPersonsOptionList: naturalPersonsOptionList,
			setNaturalPersonsOptionList: () => setNaturalPersonsOptionList,
			getNaturalPersonsList: (page: number, size: number, search: string) => {
				return contextGetNaturalPersonsList(page, size, search);
			},
		}}>
			{children}
		</NaturalPersonsContext.Provider>
	);
};

export function useNaturalPersons() {
	const context = useContext(NaturalPersonsContext);
	const {
		naturalPersonsOptionList,
		getNaturalPersonsList
	} = context;
	return {
		naturalPersonsOptionList,
		getNaturalPersonsList
	};
}
