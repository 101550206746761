import React, { useEffect, useState } from 'react';
import { UploadTab } from 'components';
import { getLegalPersonById } from 'services/legalPerson';
import { getNaturalPersonById } from 'services/naturalPerson';
import { uniqBy } from 'lodash';

function Upload({ readOnly, editable, taker, files, setLoading, setFiles, getRootProps, getInputProps, involvedPersons }) {

	const [involvedLegalPerson, setInvolvedLegalPerson] = useState([]);
	const [involvedNaturalPerson, setInvolvedNaturalPerson] = useState([]);

	useEffect(() => {
		
		var legalPersons = [];
		var naturalPersons = [];
		var proms = [];
		
		if (taker && taker.ownerDiscriminator == 'LegalPerson') {
			var prom = legalPersonDocuments(taker.ownerId).then((data) => {					
				legalPersons.push(data);					
			});
			proms.push(prom);
		} else if (taker && taker.ownerDiscriminator == 'NaturalPerson') {
			var prom = naturalPersonDocuments(taker.ownerId).then((data) => {					
				naturalPersons.push(data);					
			});
			proms.push(prom);
		}
		//Identifica o tipo de pessoa envolvida
		var involved = uniqBy(involvedPersons, (i) => i.personId);
		involved?.map((rp) => {
			if (rp.discriminator === 'NaturalPerson' || rp.person?.discriminator === 'NaturalPerson') {				
				var prom = naturalPersonDocuments(rp.personId).then((data) => {					
					naturalPersons.push(data);					
				});
				proms.push(prom);

			} else if (rp.discriminator === 'LegalPerson' || rp.person?.discriminator === 'LegalPerson') {
				var prom = legalPersonDocuments(rp.personId).then((data) => {
					legalPersons.push(data);					
				});
				proms.push(prom);				
			}
		});
				
		Promise.all(proms).then(() => {						
			setInvolvedLegalPerson(legalPersons);
			setInvolvedNaturalPerson(naturalPersons);			
		});
	}, [involvedPersons]);

	//Busca as informações da pessoa física envolvida
	const naturalPersonDocuments = async (id) => {		
		return getNaturalPersonById(id).then(({data}) => {			
			return {
				id: data?.id,
				uploads: data?.uploads,
				name: data?.name
			};			 
		});
	};

	//Busca as informações da pessoa jurídica envolvida
	const legalPersonDocuments = async (id) => {
		return getLegalPersonById(id).then(result => {
			const person = {
				id: result?.data?.id,
				name: result?.data?.companyName,
				uploads: result?.data?.uploads
			};
			return person;			
		});
	};

	useEffect(() => {
		if (!taker.ownerDiscriminator){
			return;
		}
		if (taker.ownerDiscriminator == "LegalPerson") {
			getLegalPersonDocuments(taker?.ownerId);
		} else {
			getNaturalPersonDocuments(taker?.ownerId);
		};

	}, [taker]);

	const getNaturalPersonDocuments = (id) => {
		return getNaturalPersonById(id).then(({ data }) => {
			const person = {
				id: data.id,
				uploads: data.uploads,
				name: data.name
			};
			return person;
		});
	};

	const getLegalPersonDocuments = (id) => {
		return getLegalPersonById(id).then(({ data }) => {
			const person = {
				id: data.id,
				name: data.companyName,
				uploads: data.uploads
			};
			return person;
		});
	};

	return (
		<>
			<UploadTab files={files}
				setFiles={setFiles}
				setLoading={setLoading}
				getRootProps={getRootProps}
				getInputProps={getInputProps}
				title="Documentos"
				editable={editable}
				readOnly={readOnly} />

			{/* {legalPersons?.filter(c => c.id).map(person =>
				<UploadTab files={person.uploads} setFiles={() => { }} title={`Documentos ${person.name}`} showDropzone={false} editable={false} />
			)}

			{naturalPersons?.filter(c => c.id).map(person =>
				<UploadTab files={person?.uploads} setFiles={() => { }} title={`Documentos ${person.name}`} showDropzone={false} editable={false} />
			)} */}

			{involvedLegalPerson?.filter(c => c.id).map(person =>
				<UploadTab files={person.uploads} setFiles={() => { }} title={`Documentos ${person.name}`} showDropzone={false} editable={false} />
			)}

			{involvedNaturalPerson?.filter(c => c.id).map(person =>
				<UploadTab files={person?.uploads} setFiles={() => { }} title={`Documentos ${person.name}`} showDropzone={false} editable={false} />
			)}
		</>
	);
}

export default Upload;