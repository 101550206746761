import { createContext, useContext, useEffect, useState } from "react"; 

type GlobalContent = {
	pdfOrCsv: any
	setPdfOrCsv: (period: any) => void; 
	getSelectedFilter: (period: any) => void;
}

const FilterPdfOrCsvContext = createContext<GlobalContent>({
	pdfOrCsv: [],
	setPdfOrCsv: (period: any) => period,
	getSelectedFilter: (period: any) => period
});

interface IProps {
	children: JSX.Element | JSX.Element[]
}

export const FilterPdfOrCsvProvider = ({ children }: IProps) => {
	const [pdfOrCsv, setPdfOrCsv] = useState();
	
	useEffect(() => {
		if (pdfOrCsv) pdfOrCsv; 
	}, [pdfOrCsv]);

	const getSelectedFilter = (period: any) => {
		setPdfOrCsv(period);
	};

	return (
		<FilterPdfOrCsvContext.Provider
			value={{
				pdfOrCsv: pdfOrCsv,
				setPdfOrCsv: setPdfOrCsv,
				getSelectedFilter: (period: any) => {
					return getSelectedFilter(period);
				}
			}}
		>
			{children}
		</FilterPdfOrCsvContext.Provider>
	);
};

export function usePdfOrCsv() {
	const context = useContext(FilterPdfOrCsvContext);
	const { pdfOrCsv, setPdfOrCsv, getSelectedFilter } = context;
	return {
		pdfOrCsv,
		setPdfOrCsv,
		getSelectedFilter
	};
}