import { string, object, date, number, boolean, array, lazy } from 'yup';

const genericRequired = 'Esse campo é obrigatório.';

export const getValidationSchema = (product) => {

	var getNumberWithPrecision = (number) => {
		return parseFloat(parseFloat(number).toPrecision(6));
	};

	var minimum = getNumberWithPrecision((product?.minimumInterestRate ?? 0));
	var maximum = getNumberWithPrecision((product?.maximumInterestRate ?? 100));

	var useCDC = product?.isConsumerCreditNote;
	var isFGTS = product?.amortizationType == "FGTS";

	const isNullOrEmpty = (value) => {
		return value == null || value === '';
	};

	switch (product?.amortizationType?.toLowerCase()) {
		case "studentloan":
			return object().shape({
				fundId: window.__RUNTIME_CONFIG__.REACT_APP_USE_FUND == "1" ? string().nullable() : string().nullable(),
				productId: string().required(genericRequired),
				liquidationType: string().required(genericRequired),


				assignmentCalculation: lazy(value => {
					var nothingIsFiled = isNullOrEmpty(value?.type) && isNullOrEmpty(value?.amount);
					return nothingIsFiled ? object().nullable().transform((value, _) => {
						return Object.values(value ?? {}).every(x => x === null) ? null : value;
					}) : object().shape({
						amount: number().nullable(),
						type: string().nullable()
					}).transform((value, _) => {
						return Object.values(value).every(x => x === null) ? null : value;
					});
				}),

				// acceptanceDate: date().typeError('Data inválida').nullable(),
				amortization: object().shape({
					amortizationType: string().required(genericRequired),
					calculateByValueType: string().required(genericRequired),
					legalPerson: boolean(),
					financeTaxExempted: boolean(),
					requestedAmount: number().required(genericRequired),
					startDate: date().typeError('Data inválida').required(),
					termInMonths: number().required(genericRequired),
					absAmortizationInMonths: number().required(genericRequired),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					periodicity: string().required(genericRequired),
					daysInYear: number().required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					paymentDay: number().typeError('Valor precisa ser um número.').required(genericRequired).min(1, "Informe o dia de vencimento").max(31, "Informe o dia de vencimento"),
					absAfterGraduation: number().typeError('Valor precisa ser um número.').required("O valor da parcela durante o curso é obrigatório."),
					fixedPaymentDuringGraduation: number().typeError('Valor precisa ser um número.').required("O valor da parcela durante o curso é obrigatório."),
					termsUntilGraduation: number().typeError('Valor precisa ser um número.').required("Quantidade de parcelas até a conclusão do curso é obrigatório."),
				}),
				isRegistered: boolean(),
				bulkAssignment: boolean(),
				personId: string().typeError('Tomador inválido').required(genericRequired),
				bankAccountId: string().when("liquidationType", {
					is: "EletronicTransfer",
					then: string().typeError('Conta inválida').required("Valor obrigatório para liquidações via TED."),
					otherwise: string().nullable()
				}),
				invoiceBarCode: string().when("liquidationType", {
					is: "Invoice",
					then: string().typeError('Valor precisa ser um número.').required("Valor obrigatório para liquidações via boleto."),
					otherwise: string().nullable()

				}),
				collateral: string().nullable(),
				changeBankAccountId: string().typeError('Conta inválida').nullable(),
				invoiceNumber: useCDC && isFGTS ? number().typeError('Número da NF-e: é obrigatório.').required(genericRequired) : string().nullable(),
				invoiceValue: useCDC && isFGTS ? number().typeError('Valor da NF-e: é obrigatório.').required(genericRequired) : string().nullable(),
				observations: string().nullable()
			});
		case "receivablesprice":
			return object().shape({
				fundId: window.__RUNTIME_CONFIG__.REACT_APP_USE_FUND == "1" ? string().nullable() : string().nullable(),
				productId: string().required(genericRequired),
				assignmentCalculation: lazy(value => {
					var nothingIsFiled = isNullOrEmpty(value?.type) && isNullOrEmpty(value?.amount);
					return nothingIsFiled ? object().nullable().transform((value, _) => {
						return Object.values(value ?? {}).every(x => x === null) ? null : value;
					}) : object().shape({
						amount: number().nullable(),
						type: string().nullable()
					}).transform((value, _) => {
						return Object.values(value).every(x => x === null) ? null : value;
					});
				}),
				liquidationType: string().required(genericRequired),
				amortization: object().shape({
					amortizationType: string().required(genericRequired),
					calculateByValueType: string().required(genericRequired),
					legalPerson: boolean(),
					requestedAmount: number().required(genericRequired),
					startDate: date().typeError('Data inválida').required(),
					termInMonths: number().required(genericRequired),
					absAmortizationInMonths: number().required(genericRequired),
					financeTaxExempted: boolean(),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					periodicity: string().required(genericRequired),
					daysInYear: number().required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					paymentDay: number().typeError('Valor precisa ser um número.').required(genericRequired).min(1, "Informe o dia de vencimento").max(31, "Informe o dia de vencimento"),
					fiduciaryGuarantee: number().typeError('O percentual de garantia é obrigatótia.').required('O percentual de garantia é obrigatótia.'),
				}),
				isRegistered: boolean(),
				bulkAssignment: boolean(),
				personId: string().typeError('Tomador inválido').required(genericRequired),
				bankAccountId: string().when("liquidationType", {
					is: "EletronicTransfer",
					then: string().typeError('Conta inválida').required("Valor obrigatório para liquidações via TED."),
					otherwise: string().nullable()
				}),
				invoiceBarCode: string().when("liquidationType", {
					is: "Invoice",
					then: string().typeError('Valor precisa ser um número.').required("Valor obrigatório para liquidações via boleto."),
					otherwise: string().nullable()

				}),
				collateral: string().nullable(),
				observations: string().nullable()
			});

		case "fgts":
			return object().shape({
				fundId: window.__RUNTIME_CONFIG__.REACT_APP_USE_FUND == "1" ? string().nullable() : string().nullable(),
				productId: string().required(genericRequired),
				liquidationType: string().required(genericRequired),
				assignmentCalculation: lazy(value => {
					var nothingIsFiled = isNullOrEmpty(value?.type) && isNullOrEmpty(value?.amount);
					return nothingIsFiled ? object().nullable().transform((value, _) => {
						return Object.values(value ?? {}).every(x => x === null) ? null : value;
					}) : object().shape({
						amount: number().nullable(),
						type: string().nullable()
					}).transform((value, _) => {
						return Object.values(value).every(x => x === null) ? null : value;
					});
				}),
				amortization: object().shape({
					amortizationType: string().required(genericRequired),
					financeTaxExempted: boolean(),
					requestedAmount: number().required(genericRequired),
					startDate: date().typeError('Data inválida').required(),
					termInMonths: number().required(genericRequired).min(1, "Informe a quantidade de parcelas").max(10, "Quantidade máxima de parcelas excedida (10 parcelas)"),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
				}),
				isRegistered: boolean(),
				bulkAssignment: boolean(),
				personId: string().typeError('Tomador inválido').required(genericRequired),
				collateral: string().nullable(),
				changeBankAccountId: string().typeError('Conta inválida').nullable(),
				invoiceNumber: useCDC && isFGTS ? number().typeError('Número da NF-e: é obrigatório.').required(genericRequired) : string().nullable(),
				invoiceValue: useCDC && isFGTS ? number().typeError('Valor da NF-e: é obrigatório.').required(genericRequired) : string().nullable(),
				observations: string().nullable()
			});
		case "homeequityprice":
			return object().shape({
				productId: string().required(genericRequired),
				liquidationType: string().required(genericRequired),
				assignmentCalculation: lazy(value => {
					var nothingIsFiled = isNullOrEmpty(value?.type) && isNullOrEmpty(value?.amount);
					return nothingIsFiled ? object().nullable().transform((value, _) => {
						return Object.values(value ?? {}).every(x => x === null) ? null : value;
					}) : object().shape({
						amount: number().nullable(),
						type: string().nullable()
					}).transform((value, _) => {
						return Object.values(value).every(x => x === null) ? null : value;
					});
				}),
				amortization: object().shape({
					financeTaxExempted: boolean(),
					amortizationType: string().required(genericRequired),
					calculateByValueType: string().required(genericRequired),
					legalPerson: boolean(),
					requestedAmount: number().typeError('Valor precisa ser um número.').required(genericRequired),
					startDate: date().typeError('Data inválida').required(),
					termInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					apr: number().typeError('Valor precisa ser um número.').required(genericRequired),
					periodicity: string().required(genericRequired),
					daysInYear: number().required(genericRequired),
					agentCommission: object().shape({
						amount: number().typeError('Valor precisa ser um número.').required(genericRequired),
					}),
					paymentDay: number().typeError('Valor precisa ser um número.').required(genericRequired),
					administrationFeeIncents: number().typeError('Valor precisa ser um número.').required(genericRequired),
					realStatevalue: number().typeError('Valor precisa ser um número.').required(genericRequired),
					physicalDamageInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					deathOrPermanentIncapabilityInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					skippedMonths: array().of(string().max(11, "Número de meses selecionado excedido")).typeError("Meses sem pagamento: Selecione pelo menos um mês.")
				}),
				personId: string().typeError('Tomador inválido').required(genericRequired),
				bankAccountId: string().when("liquidationType", {
					is: "EletronicTransfer",
					then: string().typeError('Conta inválida').required("Valor obrigatório para liquidações via TED."),
					otherwise: string().nullable()
				}),
				invoiceBarCode: string().when("liquidationType", {
					is: "Invoice",
					then: string().typeError('Valor precisa ser um número.').required("Valor obrigatório para liquidações via boleto."),
					otherwise: string().nullable()
				}),
				observations: string().nullable()
			});
		case "homeequitysac":
			return object().shape({
				productId: string().required(genericRequired),
				liquidationType: string().required(genericRequired),
				assignmentCalculation: lazy(value => {
					var nothingIsFiled = isNullOrEmpty(value?.type) && isNullOrEmpty(value?.amount);
					return nothingIsFiled ? object().nullable().transform((value, _) => {
						return Object.values(value ?? {}).every(x => x === null) ? null : value;
					}) : object().shape({
						amount: number().nullable(),
						type: string().nullable()
					}).transform((value, _) => {
						return Object.values(value).every(x => x === null) ? null : value;
					});
				}),
				amortization: object().shape({
					amortizationType: string().required(genericRequired),
					calculateByValueType: string().required(genericRequired),
					legalPerson: boolean(),
					financeTaxExempted: boolean(),
					requestedAmount: number().typeError('Valor precisa ser um número.').required(genericRequired),
					startDate: date().typeError('Data inválida').required(),
					termInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					absInterestInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					apr: number().typeError('Valor precisa ser um número.').required(genericRequired),
					periodicity: string().required(genericRequired),
					daysInYear: number().required(genericRequired),
					agentCommission: object().shape({
						amount: number().typeError('Valor precisa ser um número.').required(genericRequired),
					}),
					paymentDay: number().typeError('Valor precisa ser um número.').required(genericRequired),
					administrationFeeIncents: number().typeError('Valor precisa ser um número.').required(genericRequired),
					realStatevalue: number().typeError('Valor precisa ser um número.').required(genericRequired),
					physicalDamageInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					deathOrPermanentIncapabilityInsurance: number().typeError('Valor precisa ser um número.').required(genericRequired),
					skippedMonths: array().of(string().max(11, "Número de meses selecionado excedido")).typeError("Meses sem pagamento: Selecione pelo menos um mês.")
				}),
				personId: string().typeError('Tomador inválido').required(genericRequired),
				bankAccountId: string().when("liquidationType", {
					is: "EletronicTransfer",
					then: string().typeError('Conta inválida').required("Valor obrigatório para liquidações via TED."),
					otherwise: string().nullable()
				}),
				invoiceBarCode: string().when("liquidationType", {
					is: "Invoice",
					then: string().typeError('Valor precisa ser um número.').required("Valor obrigatório para liquidações via boleto."),
					otherwise: string().nullable()
				}),
				observations: string().nullable()
			});
		default:
			return object().shape({
				fundId: window.__RUNTIME_CONFIG__.REACT_APP_USE_FUND == "1" ? string().nullable() : string().nullable(),
				productId: string().required(genericRequired),
				liquidationType: string().required(genericRequired),
				assignmentCalculation: lazy(value => {
					var nothingIsFiled = isNullOrEmpty(value?.type) && isNullOrEmpty(value?.amount);
					return nothingIsFiled ? object().nullable().transform((value, _) => {
						return Object.values(value ?? {}).every(x => x === null) ? null : value;
					}) : object().shape({
						amount: number().nullable(),
						type: string().nullable()
					}).transform((value, _) => {
						return Object.values(value).every(x => x === null) ? null : value;
					});
				}),
				amortization: object().shape({
					amortizationType: string().required(genericRequired),
					calculateByValueType: string().required(genericRequired),
					legalPerson: boolean(),
					financeTaxExempted: boolean(),
					requestedAmount: number().typeError('Valor precisa ser um número.').required(genericRequired),
					startDate: date().typeError('Data inválida').required(),
					termInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					absAmortizationInMonths: number().typeError('Valor precisa ser um número.').required(genericRequired),
					absInterestInMonths: number().transform((value, original) => {
						return isNaN(value) ? 0 : value;
					}),
					apr: number()
						.min(minimum, `Valor mínimo: ${minimum}%`)
						.max(maximum, `Valor máximo: ${maximum}%`)
						.required(genericRequired),
					periodicity: string().required(genericRequired),
					daysInYear: number().required(genericRequired),
					agentCommission: object().shape({
						amount: number().transform((value, original) => {
							return isNaN(value) ? 0 : value;
						}),
					}),
					paymentDay: number().typeError('Valor precisa ser um número.').required(genericRequired).min(1, "Informe o dia de vencimento").max(31, "Informe o dia de vencimento"),
				}),
				isRegistered: boolean(),
				bulkAssignment: boolean(),
				personId: string().typeError('Tomador inválido').required(genericRequired),
				bankAccountId: string().when("liquidationType", {
					is: "EletronicTransfer",
					then: string().typeError('Conta inválida').required("Valor obrigatório para liquidações via TED."),
					otherwise: string().nullable()
				}),
				invoiceBarCode: string().when("liquidationType", {
					is: "Invoice",
					then: string().typeError('Valor precisa ser um número.').required("Valor obrigatório para liquidações via boleto."),
					otherwise: string().nullable()
				}),
				collateral: string().nullable(),
				changeBankAccountId: string().typeError('Conta inválida').nullable(),
				invoiceNumber: useCDC && isFGTS ? number().typeError('Número da NF-e: é obrigatório.').required(genericRequired) : string().nullable(),
				invoiceValue: useCDC && isFGTS ? number().typeError('Valor da NF-e: é obrigatório.').required(genericRequired) : string().nullable(),
				observations: string().nullable()
			});
	}
};

export const getDefaultValues = (product) => {
	switch (product?.amortizationType?.toLowerCase()) {
		case "studentloan":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "studentloan",
					financeTaxExempted: false,
					legalPerson: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Liquid',
					absAfterGraduation: 3,
					fixedPaymentDuringGraduation: 1500,
					termsUntilGraduation: 6,
					paymentDay: new Date().getDate()
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: "",
				changeBankAccountId: "",
				invoiceNumber: null,
				invoiceValue: 0
			};
		case "receivablesprice":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "receivablesprice",
					legalPerson: false,
					financeTaxExempted: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Liquid',
					indexer: '',
					indexerValue: 0,
					fiduciaryGuarantee: 0,
					paymentDay: new Date().getDate()
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: "",
			};
		case "cleanpricenoiof":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "cleanpricenoiof",
					legalPerson: false,
					financeTaxExempted: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Gross',
					indexer: '',
					indexerValue: 0,
					fiduciaryGuarantee: 0,
					paymentDay: new Date().getDate()
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: "",
			};
		case "priceincentivized":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "priceincentivized",
					legalPerson: false,
					financeTaxExempted: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Gross',
					indexer: '',
					indexerValue: 0,
					fiduciaryGuarantee: 0,
					paymentDay: new Date().getDate()
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: "",
			};
		case "commercialpapper":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "commercialpapper",
					legalPerson: false,
					financeTaxExempted: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Gross',
					indexer: 'CDI',
					indexerValue: 0,
					fiduciaryGuarantee: 0,
					paymentDay: new Date().getDate(),
					quantity: 100,
					unitPrice: 1000,
					issueNumber: 1
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: "",
			};
		case "fgts":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "fgts",
					financeTaxExempted: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: ""
			};
		case "homeequityprice":
			return {
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "homeequityprice",
					legalPerson: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					financeTaxExempted: false,
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Liquid',
					indexer: '',
					indexerValue: 0,
					administrationFeeIncents: 1500,
					realStatevalue: 100000.00,
					physicalDamageInsurance: 0,
					deathOrPermanentIncapabilityInsurance: 0,
					skippedMonths: [],
					paymentDay: new Date().getDay(),
				},
				personId: "",
				observations: ""
			};
		case "homeequitysac":
			return {
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "homeequitysac",
					legalPerson: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					financeTaxExempted: false,
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Liquid',
					indexer: '',
					indexerValue: 0,
					administrationFeeIncents: 1500,
					realStatevalue: 100000.00,
					physicalDamageInsurance: 0,
					deathOrPermanentIncapabilityInsurance: 0,
					skippedMonths: [],
					paymentDay: new Date().getDay(),
				},
				personId: "",
				observations: ""
			};
		case "cleanpricenoiof":
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "cleanpricenoiof",
					legalPerson: false,
					financeTaxExempted: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Gross',
					indexer: 'CDI',
					indexerValue: 0,
					paymentDay: new Date().getDate()
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: "",
				changeBankAccountId: "",
				invoiceNumber: null,
				invoiceValue: 0
			};
		default:
			return {
				acceptanceDate: new Date().toDateString(),
				liquidationType: 'EletronicTransfer',
				assignmentCalculation: null,
				sameBeneficiary: true,
				amortization: {
					amortizationType: "cleanprice",
					legalPerson: false,
					financeTaxExempted: false,
					requestedAmount: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_REQUESTED_AMOUNT),
					startDate: new Date().toDateString(),
					termInMonths: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_TERM_VALUE),
					absAmortizationInMonths: 0,
					absInterestInMonths: 0,
					apr: parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_APR_VALUE),
					periodicity: 'Monthly',
					daysInYear: 360,
					agentCommission: {
						"amount": parseFloat(window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_VALUE),
						"type": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_TYPE,
						"baseValue": window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					},
					calculateByValueType: 'Gross',
					indexer: 'CDI',
					indexerValue: 0,
					paymentDay: new Date().getDate()
				},
				isRegistered: false,
				bulkAssignment: false,
				personId: "",
				observations: "",
				collateral: "",
				changeBankAccountId: "",
				invoiceNumber: null,
				invoiceValue: 0
			};
	}
};