import React, { useState } from 'react';
import { ContentContainer } from "components";
import { useHistory } from 'react-router-dom';
import MaterialTable from "material-table";
import { MenuItem, Select, TablePagination } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { localizationOptions } from "helpers/table.helpers";
import ActionBar from "components/ActionBar/ActionBar";
import { getDigitalBankAccountActiveList } from "services/digitalBankAccount";
import { BankAccountsType } from "global/types/digitalAccount.types";
import Helper from "helpers/format.helpers";
import { useHeader } from "contexts/header";
import { useTenant } from 'contexts/tenant';
import Button from 'components/Button/Button';
import { optionsModality } from '../OptionsFields/options.modality';

const BankAccountActiveList = () => {
	const [enableFilter, setEnableFilter] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState<number>(Number(localStorage.getItem('rowsPerPage')) || 5);
	const tableRef = React.useRef(null);
	const history = useHistory();
	const { setTitle } = useHeader();
	const { selectedTenant } = useTenant();
	const isSeletorTenant = !!+window.__RUNTIME_CONFIG__.REACT_APP_SELETOR_TENANTS;

	const onRowsPerPageChange = (page: number) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', String(page));
	};

	const actions = {
		disableApproveOrReject: false,
		//@ts-ignore
		onRefresh: () => tableRef?.current?.onQueryChange(),
	};

	return (
		<ContentContainer>
			<>
				<ActionBar {...actions} hideSubmit={true} />
				<br />
				<MaterialTable
					style={{ paddingLeft: 20, paddingRight: 20 }}
					tableRef={tableRef}
					title={
						<Button
							variant="outlined"
							onClick={() => setEnableFilter(!enableFilter)}
							children={"Ativas"}
							color="default"
							startIcon={<FilterListIcon />}
						/>
					}
					// @ts-ignore
					columns={[
						isSeletorTenant ? { title: 'Correspondente', field: 'tenantDisplay', filtering: false } : undefined,
						{ title: 'Nome', field: 'person.name' },
						{ title: 'Dados da Conta', field: 'agency', filtering: false, render: (rowData: BankAccountsType) => `Agência: ${rowData.agency} Conta: ${rowData.account} ` },
						{
							title: 'CPF/CNPJ',
							field: 'person.registrationNumber',
							render: ({ person }: { person: { registrationNumber: string } }) => Helper.formatDocumentNumber(person.registrationNumber)
						},
						{
							title: 'Modalidade',
							field: 'modalityDisplay',
							filterComponent: (props: any) => <Select
								{...props}
								fullWidth
								onChange={(e) => {
									// @ts-ignore
									props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value);
								}}
							>
								<MenuItem value={""}>Todos</MenuItem>
								{optionsModality && optionsModality.map(field => (
									<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
								))}
							</Select>
						},
						{
							title: 'Saldo',
							field: 'balance',
							render: ({ balance }: any) => Helper.formatCurrencyAsIs(balance / 100), filtering: false
						},
					].filter(col => col !== undefined)}
					actions={[
						{
							icon: 'search',
							tooltip: 'Editar',
							position: 'row',
							onClick: (_, rowData) => {
								if (Array.isArray(rowData)) {
									return;
								}
								//@ts-ignore
								const id = (rowData as BankAccountsType).id;
								if (id) {
									history.push(`/contas-digitais/${id}`);
								}
							},
						},
					]}
					data={(allParams) =>
						new Promise((resolve, reject) => {
							const { page, pageSize, search, filters, orderBy, orderDirection } = allParams;

							const filtersValues = {
								f_modality: enableFilter && filters.find(f => f.column.field === 'modalityDisplay')?.value,
								f_personName: enableFilter && filters.find(f => f.column.field === 'person.name')?.value,
								f_personRegistrationNumber: enableFilter && filters.find(f => f.column.field === 'person.registrationNumber')?.value,
								page,
								size: pageSize,
								searchString: search,
								orderByField: orderBy?.field,
								orderByDirection: orderDirection,
								isDeleted: false,
							};

							//@ts-ignore
							getDigitalBankAccountActiveList(filtersValues, selectedTenant)
								.then(result => {
									//@ts-ignore
									setTitle("Contas digitais ativas");
									if (result?.data)
										resolve({
											//@ts-ignore
											data: result.data,
											page: result.page,
											totalCount: result.totalItems,
										});
									else {
										resolve({
											data: [],
											page: 0,
											totalCount: 0,
										});
									}
								}).catch(err => reject(err));
						})
					}
					onChangeRowsPerPage={onRowsPerPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
					localization={localizationOptions}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								rowsPerPageOptions={[5, 10, 20, 50]}
							/>
						),
					}}
					options={{
						actionsColumnIndex: -1,
						pageSize: rowsPerPage,
						debounceInterval: 500,
						searchAutoFocus: true,
						filtering: enableFilter
					}}
				/>
			</>
		</ContentContainer>
	);
};

export default BankAccountActiveList;
