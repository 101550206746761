
const typeOfDocument =
	[
		{ value: "Addition", name: "Aditamento" },
		{ value: "Authorization", name: "Autorização" },
		{ value: "MinutesOfElection", name: "Ata de eleição"},
		{ value: "BalanceSheet", name: "Balancete"},
		{ value: "Balance", name: "Balanço"},
		{ value: "P7S", name: "Book de Assinatura" },
		{ value: "SigningStampContract", name: "Carimbo do Tempo" },
		{ value: "RegistrationNumber", name: "Cartão CNPJ/CPF" },
		{ value: "WorkCard", name: "Carteira de Trabalho" },
		{ value: "FiduciaryAssignment", name: "Cessão Fiduciária" },
		{ value: "DriversLicense", name: "CNH - Carteira de habilitação" },
		{ value: "ResidenceProofCompany", name: "Comp. de residência - empresa" },
		{ value: "ResidenceProofPartners", name: "Comp. de residência - PF" },
		{ value: "Draft", name: "Contrato" },
		{ value: "SignedContract", name: "Contrato Assinado" },
		{ value: "SocialContract", name: "Contrato Social" },
		{ value: "Document", name: "Documento" },
		{ value: "IdentificationDocumentWithPhoto", name: "Doc. de identificação com foto" },
		{ value: "DRE", name: "DRE"},
		{ value: "Bylaws", name: "Estatuto Social"},
		{ value: "BankStatement", name: "Extrato Bancário" },
		{ value: "ManagerialBilling", name: "Faturamento Gerencial" },
		{ value: "RegistrationForm", name: "Ficha de Cadastro" },
		{ value: "Assignment", name: "Instrumento de Cessão" },
		{ value: "IncomeTax", name: "IR"},
		{ value: "Invoice", name: "Nota Fiscal" },
		{ value: "Others", name: "Outros" },
		{ value: "Procuration", name: "Procuração" },
		{ value: "PLDFT", name: "Questionário PLDFT"},
		{ value: "Regulation", name: "Regulamento"},
		{ value: "CreditReport", name: "Relatório de Crédito" },
		{ value: "Selfie", name: "Selfie" },
		{ value: "SelfieWithIdentityCard", name: "Selfie com doc. de identidade" },
		{ value: "LastDeclaration", name: "Última Declaração" }
	];

export default typeOfDocument;