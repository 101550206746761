import { string, object, number, boolean, date } from 'yup';

const genericRequired = 'Esse campo é obrigatório.';

export const validationSchema = object().shape({
	name: string().typeError('O campo Nome do Produto é obrigatório').required(genericRequired),
	code: number().typeError('Selecione o tipo de carteira.').required(genericRequired),
	viewType: number().typeError('Selecione a visibilidade do produto.').required(genericRequired),
	templateDoc: object().when("draftGeneration", draftGeneration => {
		return draftGeneration == true ?
			object().shape({
				fileName: string().typeError('Modelo de contrato é obrigatório').required(genericRequired)
			}) :
			object().nullable();
	}),
	amortizationType: string().typeError('Selecione o tipo de cálculo').required(genericRequired),
	defaultFundId: string().when("allowOwnFund", {
		is: false,
		then: string().typeError('O campo Fundo Padrão é obrigatório').required(genericRequired),
		otherwise: string().nullable()
	}),
	minimumInterestRate: number().nullable(),
	maximumInterestRate: number().nullable(),
	requireGuarantor: boolean(),
	bankComission: object().shape({
		amount: number().typeError('Valor precisa ser um número.').required(genericRequired),
		type: number().typeError('Tipo de Custo de Emissão é obrigatório.').required(genericRequired),
	}),
	allowOwnFund: boolean(),
	maximumValueWithoutDigitalSignature: number().nullable(),
	instrumentApproval: boolean(),
	allowFinanceTaxExemption: boolean(),
	draftGeneration: boolean(),
	useSignaturePortal: boolean(),
	signatureType: number().when("useSignaturePortal", {
		is: true,
		then: number().typeError('O campo Forma de envio de Assinatura é obrigatório').required(genericRequired),
		otherwise: number().nullable()
	}),
	signaturePortalProvider: number().when("useSignaturePortal", {
		is: true,
		then: number().typeError('Selecione a Certificadora.').required(genericRequired),
		otherwise: number().nullable()
	}),
	signatureValidation: string().when("useSignaturePortal", {
		is: true,
		then: string().typeError('Selecione o tipo de assinatura.').required(genericRequired),
		otherwise: string().nullable(),
	}),
	automaticCreditStatusApproval: boolean(),
	automaticComplianceApproval: boolean(),
	automaticCreditAnalysisExecution: boolean(),
	automaticComplianceAnalysisExecution: boolean(),
	defaultCreditAnalysisGroup: string().when("automaticCreditAnalysisExecution", {
		is: true,
		then: string().typeError('O campo Grupo de Análise de Crédito é obrigatório').required(genericRequired),
		otherwise: string().nullable()
	}),
	defaultComplianceAnalysisGroup: string().when("automaticComplianceAnalysisExecution", {
		is: true,
		then: string().typeError('O campo Grupo de Análise de Compliance é obrigatório').required(genericRequired),
		otherwise: string().nullable()
	}),
	financeEmissioncost: boolean(),
	financeFinTax: boolean(),
	requireWitness: boolean(),
	useCollateral: boolean(),
	openBankAccount: boolean(),
	isConsumerCreditNote: boolean(),
	maximumPrincipalAmount: number().typeError('Valor precisa ser um número.').nullable(),
	minimumPrincipalAmount: number().typeError('Valor precisa ser um número.').nullable(),
	maximumAutomaticLiquidationValueInCents: number().typeError('Valor precisa ser um número').nullable(),
	maximumAbsInterestPeriods: number().typeError('Valor precisa ser um número').nullable(),
	maximumAbsAmortizationPeriods: number().typeError('Valor precisa ser um número').nullable(),
	maximumEmissionCostInCents: number().typeError('Valor precisa ser um número.').nullable(),
	minimumEmissionCostInCents: number().typeError('Valor precisa ser um número.').nullable(),
	cosifBankAccount: string().nullable(),
	signatureValidationStep: boolean(),
	limitOpToPerson: boolean().transform((value, _) => {
		return value === null ? false : value;
	}),
	defaultCollateralDescription: string().when("useCollateral", {
		is: true,
		then: string().nullable(),
		otherwise: string().nullable()
	}),
	timeLimitToSubmitApproval: string().typeError('Horário inválido').nullable(),
	timeLimitToApprove: string().typeError('Horário inválido').nullable(),
	timeLimitToSign: string().typeError('Horário inválido').nullable(),
	timeToSendToSignatures: string().typeError('Horário inválido').required(genericRequired),
});

export const defaultValues = {
	name: null,
	code: 1,
	viewType: 1,
	templateDoc: null,
	amortizationType: null,
	defaultFundId: null,
	minimumInterestRate: 0,
	maximumInterestRate: 0,
	requireGuarantor: false,
	bankComission: {
		amount: 0.0,
		type: 0
	},
	financeEmissioncost: true,
	financeFinTax: true,
	allowOwnFund: false,
	maximumValueWithoutDigitalSignature: 0,
	instrumentApproval: false,
	allowFinanceTaxExemption: false,
	draftGeneration: false,
	useSignaturePortal: false,
	signatureType: 0,
	signaturePortalProvider: 0,
	automaticCreditStatusApproval: false,
	automaticComplianceApproval: false,
	automaticComplianceAnalysisExecution: false,
	automaticCreditAnalysisExecution: false,
	requireWitness: false,
	useCollateral: true,
	openBankAccount: false,
	isConsumerCreditNote: false,
	maximumPrincipalAmount: 0,
	minimumPrincipalAmount: 0,
	maximumAutomaticLiquidationValueInCents: 0,
	maximumAbsAmortizationPeriods: 0,
	maximumAbsInterestPeriods: 0,
	maximumEmissionCostInCents: 0,
	minimumEmissionCostInCents: 0,
	cosifBankAccount: "",
	signatureValidationStep: false,
	signatureValidation: 0,
	limitOpToPerson: false,
	defaultCollateralDescription: "",
	timeLimitToSubmitApproval: null,
	timeLimitToApprove: null,
	timeLimitToSign: null,
	timeToSendToSignatures: null
};
