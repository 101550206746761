import React, { useEffect, useState } from 'react';
import { UploadTab } from 'components';
import { getLegalPersonById } from 'services/legalPerson';
import { getNaturalPersonById } from 'services/naturalPerson';
import { uniqBy } from 'lodash';

function UploadContent({  readOnly, editable, taker, files, setLoading, setFiles, getRootProps, getInputProps, involvedPersons }) {

	const [ legalPersons, setLegalPersons] = useState([]);
	const [ naturalPersons, setNaturalPersons] = useState([]);
  
  
	useEffect(()=>{
		
		if(taker.personDiscriminator == "LegalPerson"){
			getLegalPersonDocuments(taker.personId);

		} else {
			getNaturalPersonDocuments(taker.personId);
		};

		uniqBy(involvedPersons, (i)=> i.personId)?.map(person=>{
			if(person.discriminator==='NaturalPerson'){
				getNaturalPersonDocuments(person.personId);
			} else if(person.discriminator==='LegalPerson'){
				getLegalPersonDocuments(person.personId);
			}
		});
	},[]);


	const getNaturalPersonDocuments = (id) =>{

		getNaturalPersonById(id).then(({data})=>{
			const person = {
				id: data.id,
				uploads: data.uploads,
				name:data.name 
			};

			setNaturalPersons(prevState=>([...prevState, person]));

		});
	};

	const getLegalPersonDocuments = (id) =>{
		getLegalPersonById(id).then(({data})=>{
			const person = {
				id: data.id,
				name:data.companyName,
				uploads: data.uploads 
			};

			setLegalPersons(prevState=>([...prevState, person]));

		});
	};


	return(		
		<>
			<UploadTab files={files}
				setFiles={setFiles}
				setLoading={setLoading}
				getRootProps={getRootProps}
				getInputProps={getInputProps}
				title="Documentos da Operação"
				editable={editable}
				readOnly={readOnly} />

			{legalPersons?.filter(c => c.id).map(person=>
			  <UploadTab files={person.uploads} setFiles={()=>{}} title={`Documentos ${person.name}`} showDropzone={false} editable={false} />
			)}
			{naturalPersons?.filter(c => c.id).map(person=> 
			  <UploadTab files={person.uploads} setFiles={()=>{}} title={`Documentos ${person.name}`} showDropzone={false} editable={false} />
			)}
      
		</> 
	);
}

export default UploadContent;