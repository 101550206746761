
import ApiClient from 'services/apiClient';

export async function fetchDashboardInfo(initialDate, finalDate) {
	const url = 'Dashboard/Metrics';
	const params = {
		initialDate: initialDate.format("YYYY-MM-DD"),
		finalDate: finalDate.format("YYYY-MM-DD")
	};
	try {
		const result = await ApiClient.get(url, { params });
		return Promise.resolve(result.data);
	} catch (exception) { 	
		return Promise.reject(exception);
	}
}