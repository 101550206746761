import React, { forwardRef, useEffect } from 'react';
import { Box, Button, TextField, Select, MenuItem } from '@material-ui/core';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { Edit } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SearchPersonFields from 'components/SearchPerson/SearcPerson';
import { TableContainer } from 'containers/TableContainer';


const typeOfRelationships = [
	{ name: 'Credor', value: 'Creditor' },
	{ name: 'Agente de cobrança', value: 'BillingAgent' },
	{ name: 'Usuário aprovador', value: 'BillingAgentApproverUser' },
	{ name: 'Procurador', value: 'Proxy' },
];

function InvolvedPersonsTab(props: any) {

	const { involvedPersons, setInvolvedPersons, readOnly } = props;
	const [isEditingTable, setIsEditingTable] = React.useState(false);
	const addPersonButton = React.useRef(null);

	useEffect(() => {
		if (involvedPersons.length < 1 && !readOnly) {
			//@ts-ignore    
			addPersonButton.current.click();
			setIsEditingTable(true);
		}
	}, []);

	//@ts-ignore 
	const handleLink = (personId, discriminator) => {
		if (discriminator === "LegalPerson") {
			return `/pessoa-juridica/editar?id=${personId}`;
		} else return `/pessoa-fisica/editar?id=${personId}`;
	};

	//@ts-ignore 
	const table = <MaterialTable
		title={<Button onClick={() => {
			//@ts-ignore 
			addPersonButton.current.click();
			setIsEditingTable(true);
		}
		} variant="contained" color="primary" disabled={isEditingTable || readOnly}> + envolvido</Button>}
		options={{ toolbarButtonAlignment: 'left', searchFieldAlignment: 'left' }}
		icons={{
			Add: _ => (
				<div ref={addPersonButton} >
				</div>
			),
			Edit: forwardRef((props, ref) => {
				return (
					<Edit {...props} onClick={() => {
						setTimeout(() => {
							setIsEditingTable(!!isEditingTable);
						}, 10);
					}} ref={ref} />
				);
			}),

		}}
		editable={!readOnly ? {
			onRowAdd: !readOnly ? newData =>
				new Promise((resolve, reject) => {
					setIsEditingTable(false);
					setInvolvedPersons([...involvedPersons, newData]);
					//@ts-ignore 
					resolve();
				}) : null,
			onRowUpdate: !readOnly ? (newData, oldData) => {
				return new Promise(resolve => {
					//@ts-ignore 
					let index = involvedPersons.map(f => f.id ? f.id : f.tableData.id).indexOf(oldData.id ? oldData.id : oldData.tableData.id);
					let newInvolvedPersons = [...involvedPersons];
					newInvolvedPersons[index] = newData;
					setIsEditingTable(false);
					setInvolvedPersons(newInvolvedPersons);
					//@ts-ignore 
					resolve();
				});
			} : null,
			onRowAddCancelled: () => {
				return new Promise(resolve => {
					setIsEditingTable(false);
					//@ts-ignore 
					resolve();
				});
			},
			onRowUpdateCancelled: () => {
				setIsEditingTable(false);
			},
			onRowDelete: (rowData) =>
				new Promise(resolve => {
					let currentPerson = involvedPersons;
					//@ts-ignore 
					let index = currentPerson?.map(f => f.personId).indexOf(rowData.personId);
					currentPerson.splice(index, 1);
					setInvolvedPersons([...currentPerson]);
					//@ts-ignore 
					resolve();
				}),
		} : []}
		columns={[
			{
				title: 'Pessoa',
				field: 'personIdDisplay',
				//@ts-ignore 
				render: ({ personIdDisplay, discriminator, personId }) => {
					return (
						<React.Fragment>
							{personIdDisplay}
							<Link to={handleLink(personId, discriminator)}>
								{/*@ts-ignore  */}
								<SearchOutlinedIcon style={{ float: 'right' }} position="end" />
							</Link>
						</React.Fragment>);
				},
				editComponent: props => (
					//@ts-ignore
					<SearchPersonFields onRowDataChange={props.onRowDataChange} rowData={props.rowData} />
				)
			},
			{
				title: 'Tipo de Relação',
				field: 'typeOfRelationship', 
				render: ({ typeOfRelationship }) => (typeOfRelationships?.find(x => x.value == typeOfRelationship))?.name,
				editComponent:
					props => {
						const findedItem = typeOfRelationships.find(item => item.value === props.value);
						return (
							<Select
								value={props.value || ''}
								fullWidth
								onChange={(event) => props.onChange(event.target.value)}
								//@ts-ignore
								component={TextField}
							>
								<MenuItem value={props.value}>{findedItem?.name}</MenuItem>
								{typeOfRelationships && typeOfRelationships.map(field => (
									<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
								))}
							</Select>
						);
					}
			}
		]}
		data={involvedPersons}
		localization={localizationOptions}
		//@ts-ignore
		options={{
			actionsColumnIndex: -1,
			pageSize: 5,
			pageSizeOptions: [1, 5, 10, 20],
		}}
	/>;

	return (
		<>
			<Box p={1}>
				<TableContainer>
					{table}
				</TableContainer>
			</Box>
		</>
	);
}

export default InvolvedPersonsTab;