
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentContainer, ModalWithLoading, Toast } from 'components';
import { Box, AppBar, Tabs, Tab, Divider, Grid, TextField, Button } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import getFormFunctions from 'helpers/form-helpers';
import { validationSchema, defaultValues } from './validation';
import { useLoading } from 'contexts/loading';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import InformationsProductAccount from '../FieldsForm/informationsProductAccount';
import InvolvedPersonsTab from '../Tabs/involvedPersons';
import DocumentsRequired from 'pages/ProductAccount/Tabs/documentsRequired';
import ModalAssign from 'components/Assign/ModalAssign';
import * as  bankAccountProductService from 'services/bankAccountProduct'; 
import { useProductAccount } from 'contexts/productAccount';

export default function ProductAccountEdit(props: any) {

	const { setTitle } = useHeader();
	const { selectedTenant } = useTenant();
	const history = useHistory();
	const { loading, addLoader, completeLoader } = useLoading();
	const [productAccount, setProductAccount] = useState();
	const [modalDelete, setModalDelete] = useState(false);
	const [documentsRequired, setDocumentsRequired] = useState([]);
	const [tab, setTab] = useState('Informações');
	const [involvedPersons, setInvolvedPersons] = useState([]);
	const [modalAssign, setModalAssign] = useState(false);
	const { handleSubmit, setValue, watch, getValues, control, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});

	const { errors } = useFormState({ control });

	const { isFieldRequired, inputBind, inputBindNumber } = getFormFunctions({ validationSchema, setValue, control, errors });

	const getProductAccountId = () => props?.location?.search?.replace('?id=', '');

	const isEditing = () => {
		return !!getProductAccountId();
	};

	//@ts-ignore
	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const fetchBankAccountProductById = () => {
		//@ts-ignore
		addLoader("fetchBankAccountProductById");
		//@ts-ignore
		bankAccountProductService.getBankAccountProductById(getProductAccountId(), selectedTenant)
			.then(result => {
				//@ts-ignore
				setTitle(`Produto conta - ${result.name}`);
				//@ts-ignore
				completeLoader("fetchBankAccountProductById");
				//@ts-ignore
				setProductAccount(result);
				setInvolvedPersons(result?.relatedPerson);
				setDocumentsRequired(result?.requiredDoc);
				//@ts-ignore
			}).catch(err =>{ completeLoader("fetchBankAccountProductById"); console.log("Error", err);});
	};

	useEffect(() => {
		if (isEditing()) {
			fetchBankAccountProductById();
		} else {
			//@ts-ignore
			setTitle("Produto para conta digital - novo");
		}
	}, []);

	useEffect(() => {
		if (productAccount) {
			reset(productAccount);
		}
	}, [productAccount]);

	//@ts-ignore
	const handleDeleteProductAccount = async () => {
		//@ts-ignore
		addLoader("handleDeleteProductAccount");
		//@ts-ignore
		bankAccountProductService.deleteProductAccountById(getProductAccountId())
			.then(() => {//@ts-ignore
				history.push('/produto-conta');
				//@ts-ignore
				completeLoader("handleDeleteProductAccount");
			})
			.catch(err => {//@ts-ignore
				completeLoader("handleDeleteProductAccount");
			});
	};

	//@ts-ignore
	const saveProductAccount = (values) => {

		const option = isEditing() ? 'edit' : 'create';
		let record = { ...values, id: getProductAccountId() };
		record.relatedPerson = involvedPersons;
		record.requiredDoc = documentsRequired;
		//@ts-ignore
		addLoader("saveProductAccount");
		//@ts-ignore
		bankAccountProductService.editOrCreate(record, option, selectedTenant)
			.then(result => {
				//@ts-ignore
				completeLoader("saveProductAccount");
				if (isEditing()) {
					fetchBankAccountProductById();
				}
				else {
					history.push(`/produto-conta/editar?id=${result.data}`);
					//@ts-ignore
					completeLoader("saveProductAccount");
				}
			})
			.catch(error => {
				//@ts-ignore
				completeLoader("saveProductAccount");
				console.log(error);
			});
	};

	const handleModalAssign = () => {
		setModalAssign(true);
	};
	const handleCloseModalAssign = () => {
		setModalAssign(false);
	};

	const handleCloseModalDelete = () => {
		setModalDelete(false);
	};

	const handleModalDelete = () => {
		setModalDelete(true);
	};

	const actions = isEditing() ? {
		onAssign: handleModalAssign,
		onDelete: handleModalDelete,
		disabledAll: loading
	} : {
	};

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsProductAccount {...{ getProductAccountId, isEditing, productAccount, inputBind, inputBindNumber, control, setValue, getValues, watch }} />
		},
		{
			name: 'Envolvidos',
			show: true,
			children: <InvolvedPersonsTab {...{ inputBindNumber, isFieldRequired, inputBind, involvedPersons, setInvolvedPersons }} />
		},
		{
			name: 'Documentos Obrigatórios',
			show: true,
			children: <DocumentsRequired {...{ documentsRequired, setDocumentsRequired }} />
		}
	];

	//@ts-ignore
	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	//@ts-ignore
	const onSubmit = (values) => {
		//@ts-ignore
		saveProductAccount(values);
	};

	const style = { borderTopLeftRadius: 10, borderTopRightRadius: 7, borderBottomLeftRadius: 10, borderBottomRightRadius: 7, backgroundColor: 'white', padding: 10, margin: 1 };

	return (
		<>
			{ /*@ts-ignore*/}
			<ContentContainer>
				{modalAssign &&
					<ModalAssign recordType={"BankAccountProduct"} recordId={getProductAccountId()} onCloseModal={handleCloseModalAssign} />
				}
				{modalDelete &&
				<>
					<ModalWithLoading title='Tem certeza que deseja excluir esse Produto?' onCloseModal={handleCloseModalDelete}>
						<Grid container direction='column' justifyContent='center' spacing={2}>
							<Grid item>
								<Button color="secondary" variant='contained' onClick={() => handleDeleteProductAccount()}>Excluir</Button>
							</Grid>
						</Grid>
					</ModalWithLoading>
				</>
				}
				{loading && (
					//@ts-ignore
					<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
				)}
				<form
					onSubmit={handleSubmit(onSubmit, onError)}
				>
					<Grid container direction="row" spacing={4}>
						<Grid item xs={12} lg={6}>
							<ActionBar {...actions} />
						</Grid>
						{isEditing() &&
							<Grid item xs={12} lg={6} className="disablePrint">
								<div className="status-area" style={{ display: 'flex', justifyContent: 'flex-end' }}>
									{/*@ts-ignore*/}
									{productAccount?.createdBy && <small style={style}><strong>Criado em:</strong>&nbsp;{productAccount?.createdAt && new Date(productAccount?.createdAt).toLocaleDateString('pt-BR')}</small>}
									{/*@ts-ignore*/}
									{productAccount?.ownerUser && <small style={style}><strong>Operador:</strong>&nbsp;{productAccount?.ownerUser?.userIdDisplay}</small>}
									{/*@ts-ignore*/}
									{productAccount?.ownerGroup && <small style={style}><strong>Grupo:</strong>&nbsp;{productAccount?.ownerGroup?.groupDisplay}</small>}
								</div>
							</Grid>}
					</Grid>

					<AppBar position="relative" color="transparent" elevation={0}>
						<Tabs
							centered={false}
							value={tab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
						>
							{tabs.map((item, index) => (
								item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
							))}
						</Tabs>
					</AppBar>
					{
						tabs.map((item, index) =>
							<TabPanel key={index} value={tab} index={item.name}>
								{item.children}
							</TabPanel>
						)
					}
				</form>
			</ContentContainer >
		</>
	);
}

