import { string, object, date, addMethod, lazy, number } from 'yup';
import Helper from 'helpers/schema.helpers';

addMethod(string, 'customCPFValidator', function () {
	return this.test({
		name: 'cpfValidation',
		message: 'CPF não é válido.',
		test: (value) => {
			return value ? Helper.cpfValidate(value) : true;
		}
	});
});

addMethod(string, 'customCNPJValidator', function () {
	return this.test({
		name: 'cnpjValidation',
		message: 'CNPJ não é válido.',
		test: (value) => {
			return value ? Helper.cnpjValidate(value) : true;
		}
	});
});

const REQUIRED_FIELDS_STR = window.__RUNTIME_CONFIG__.REACT_APP_REQUIRED_FIELDS_NATURALPERSON;

const REQUIRED_FIELDS = REQUIRED_FIELDS_STR?.split(',');

function isFieldRequired(fieldName) {
	return REQUIRED_FIELDS?.indexOf(fieldName) > -1;
}

function generateRequiredForYupFieldSchema(yupSchema, fieldName, errorMsg) {
	return isFieldRequired(fieldName) ?
		yupSchema.required(errorMsg) :
		yupSchema.nullable();
}

const isNullOrEmpty = (value) => {
	return value == null || value == "";
};

export const validationSchema = object().shape({
	name: generateRequiredForYupFieldSchema(string(), "name", "Nome é obrigatório."),
	registrationNumber: generateRequiredForYupFieldSchema(string().customCPFValidator(), "registrationNumber", "CPF é obrigatório"),
	phone: generateRequiredForYupFieldSchema(string().typeError('Telefone é obrigatório.'), "phone", "Telefone é obrigatório."),
	email: generateRequiredForYupFieldSchema(string().email("Email inválido."), "email", "Email é obrigatório."),
	nationality: generateRequiredForYupFieldSchema(string().typeError('A nacionalidade é obrigatória.'), "nationality", "A nacionalidade é obrigatória."),
	address: lazy(value => {
		var nothingIsFiled = isNullOrEmpty(value?.addressName) && isNullOrEmpty(value?.zipCode) && isNullOrEmpty(value?.city)
			&& isNullOrEmpty(value?.uf) && isNullOrEmpty(value?.district) && isNullOrEmpty(value?.number);
		var returnValue = (nothingIsFiled && !isFieldRequired("address") ? object().nullable() : object().shape({
			addressName: generateRequiredForYupFieldSchema(string().typeError('O campo Endereço é obrigatório.'), "address", "Endereço é obrigatório."),
			zipCode: generateRequiredForYupFieldSchema(string().typeError('O campo CEP é obrigatório.'), "address", "CEP é obrigatório."),
			city: generateRequiredForYupFieldSchema(string().typeError('O campo Cidade é obrigatório.'), "address", "Cidade é obrigatório."),
			uf: generateRequiredForYupFieldSchema(string().typeError('O campo UF é obrigatório.'), "address", "UF é obrigatório."),
			district: generateRequiredForYupFieldSchema(string().typeError('O campo Bairro é obrigatório.'), "address", "Bairro é obrigatório."),
			number: generateRequiredForYupFieldSchema(string().typeError('O campo Número é obrigatório.'), "address", "Número é obrigatório."),
			complement: string().nullable()
		})).transform((value, _) => {
			return Object.values(value).every(x => x === null) ? {
				_isEmpty: true
			} : value;
		});
		return returnValue;
	}),
	birthDate: generateRequiredForYupFieldSchema(date().typeError('Data de nascimento é obrigatória.'), "birthDate", "Data de nascimento é obrigatória."),
	civilStatus: generateRequiredForYupFieldSchema(string().typeError('Estado civil é obrigatório'), "civilStatus", "Estado civil é obrigatório."),
	gender: generateRequiredForYupFieldSchema(string().typeError('Gênero é obrigatório'), "gender", "Gênero é obrigatório."),
	documentNumber: generateRequiredForYupFieldSchema(string().typeError('Documento de identidade: Precisa ser preenchido.'), "documentNumber", "Documento de identidade: Precisa ser preenchido."),
	documentIssuer: generateRequiredForYupFieldSchema(string().typeError('Emissor/UF: Precisa ser preenchido.'), "documentIssuer", "Emissor/UF: Precisa ser preenchido."),
	documentDate: generateRequiredForYupFieldSchema(date().typeError('Data inválida'), "documentDate", "Data de emissão: Precisa ser preenchido."),
	documentExpiration: date().typeError('Data inválida').nullable(),
	placeOfBirthCountry: string().nullable(),
	typeOfResidence: string().nullable(),
	workplace: string().nullable(),
	workplaceCompanyRegistrationNumber: string().customCNPJValidator().nullable(),
	occupation: generateRequiredForYupFieldSchema(string().typeError('A ocupação é obrigatória.'), "occupation", "A ocupação é obrigatória."),
	natureOfOccupation: string().nullable(),
	commercialPhone: string().nullable(),
	netSalary: generateRequiredForYupFieldSchema(number().typeError('Valor precisa ser um número.'), "netSalary", "Salário é obrigatório."),
	mothersName: generateRequiredForYupFieldSchema(string().typeError('Nome da mãe é obrigatório'), "mothersName", "Nome da mãe é obrigatório."),
	admissionDate: date().typeError('Data inválida').nullable(),
	economicActivityCodeId: string().nullable(),
	spouseCPF: string().customCPFValidator().nullable(),
	spouseBirthDate: date().typeError('Data inválida').nullable(),
	spouseWorkplace: string().customCNPJValidator().nullable(),
});

export const defaultValues = {
	registrationNumber: "",
	phone: "",
	phoneExtension: "",
	email: "",
	name: '',
	nationality: "",
	pep: "",
	address: null,
	birthDate: null,
	civilStatus: "",
	gender: "",
	documentType: "",
	documentNumber: "",
	documentIssuer: "",
	documentDate: null,
	documentExpiration: null,
	phone2: "",
	phone2Extension: "",
	mothersName: null,
	fathersName: "",
	placeOfBirthCountry: "",
	placeOfBirthState: "",
	typeOfResidence: "",
	between7And15YearsOld: 0,
	upTo6YearsOld: 0,
	over15YearsOld: 0,
	workplace: "",
	workplaceCompanyRegistrationNumber: "",
	occupation: "",
	natureOfOccupation: "",
	commercialPhone: "",
	commercialPhoneExtension: "",
	netSalary: null,
	otherIncome: 0,
	admissionDate: null,
	commercialPhone2: "",
	commercialPhone2Extension: "",
	spouseDocumentNumber: "",
	spouseName: "",
	spouseWorkplace: "",
	spouseOccupation: "",
	spouseCommercialPhone: "",
	spouseBirthDate: null,
	spouseNetSalary: 0,
	spouseOtherIncome: 0,
	operation: "",
	assets: [{
		assetType: "",
		settled: ""
	}],
};
