import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';

export async function getEconomicActivityCodeList(search) {
	
	var token = await getToken();
	var url = ApiConfig.baseURL + `/EconomicActivityCode/${search}`;
	const config = {
		headers: {Authorization: `Bearer ${token}`},
	};

	let result;

	try {
		result = await (await axios.get(url, config)).data;
	}
	catch (err) {
		Toast.showErrorMessage("Não foi possível obter a lista de atividades", err);
	}
	return result;
}



export async function createActivityCode({code, description}) {


	var token = await getToken();
	var url = ApiConfig.baseURL + `/EconomicActivityCode`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
  
	try {

		let result = await axios.post(url,{code, description}, config);
		return result;

	}
	catch (err) {
		return Promise.reject(err);

	}
}