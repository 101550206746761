import { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, useParams, useRouteMatch } from 'react-router-dom';
import ActionBar from "components/ActionBar/ActionBar";
import PageTabs from "components/PageTabs/PageTabs";
import AccountStatement from "../AccountStatement/accountStatement";
import AccountTransfers from "../AccountTransfers/accountTransfers";
import AccountPayments from "../AccountPayments/accountPayments";
import AccountFavoreds from "../AccountFavored/accountFavored";
import { getBankAccountBalance, getBankAccountStatement } from "services/digitalBankAccount";
import Helper from 'helpers/format.helpers';
import AccountOperators from '../AccountOperator/accountOperator';
import { useHeader } from 'contexts/header';
import { RouteWithIdParamsType } from 'global/types/types';
import { Card } from 'assets/style';
import { Box, Grid } from '@material-ui/core';
import { QueryFiltersType } from 'global/types/digitalAccount.types';
import { subDays } from "date-fns";
import { ContentContainer } from 'components';
import { useMyPermissions } from 'contexts/permissions';
import { postBankAccountStatementExportPfd, postBankAccountStatementSpreadsheet } from 'services/creditNote';
import { usePdfOrCsv } from 'contexts/filterPdfAndCsv';
import { bankAccountPostCNAB240 } from 'services/bankAccount';
import moment from 'moment';

const BankAccountForm = () => {
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const [balance, setBalance] = useState<any>({ available: 0, currentBalance: 0});
	// const [period, setPeriod] = useState({ initialDate: subDays(new Date(), 15), finalDate: new Date(), period: 'Últimos 15 dias' });
	const { setTitle } = useHeader();
	const match = useRouteMatch();

	const { pdfOrCsv } = usePdfOrCsv();
	const { getMyPermissionLevel } = useMyPermissions();
	const hasUserPerm = !!getMyPermissionLevel("User", "read");
	
	useEffect(() => {
		getBankAccountBalance(bankAccountId).then((response) => {
			//@ts-ignore
			setTitle(`Conta Digital - Agência: ${response.bankAccount.agency} 
			 Conta: ${response.bankAccount.account || ''}${response.bankAccount.accountDigit ? '-' + response.bankAccount.accountDigit : '' || ''}`);
			setBalance(response);
		}).catch((e) => console.log(e));
	}, []);

	const getStatement = useCallback(async (filters: QueryFiltersType) => {
		return await getBankAccountStatement(bankAccountId, filters);
	}, []);

	const postExportPdf = async () => {
		return await postBankAccountStatementExportPfd(bankAccountId, pdfOrCsv?.initialDate, pdfOrCsv?.finalDate).then((result) => {
			console.log(result);
			const file = new Blob(
				[result],
				{ type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		});
	};

	const postExportStatementSpreadsheet = async () => {
		return await postBankAccountStatementSpreadsheet(bankAccountId, pdfOrCsv?.initialDate, pdfOrCsv?.finalDate).then((result) => {
			console.log(result);
			const file = new Blob(
				[result],
				{ type: 'application/csv', }
			);
			const fileURL = URL.createObjectURL(file);
			var downloadFile = document.createElement("a");
			downloadFile.href = fileURL;
			downloadFile.download = `${fileURL.slice(-36)}.csv`;
			downloadFile.click();
		});
	};

	const postCnab240 = async () => {
		await bankAccountPostCNAB240(bankAccountId, moment(pdfOrCsv?.initialDate).format(), moment(pdfOrCsv?.finalDate).format())
			.then((result: any) => window.open(result?.data?.tempUrl, "_blank"));
	};

	useEffect(() => {
		const filters = {
			initialDate: subDays(new Date(), 15),
			finalDate: new Date()
		};
		//@ts-ignore
		getStatement(filters).then(() => { });
	}, []);

	const tabs = [
		{
			name: 'Extrato',
			show: true,
			to: match.url + "/extrato"
		},
		{
			name: 'Transferências',
			show: true,
			to: match.url + "/transferencias"
		},
		balance?.bankAccount?.type !== 2 && {
			name: 'Pagamentos',
			show: true,
			to: match.url + "/pagamentos"
		},
		{
			name: 'Operadores',
			show: hasUserPerm ? true : false,
			to: match.url + "/operadores"
		},
		{
			name: 'Favorecidos',
			show: true,
			to: match.url + "/favorecidos"
		}
	];

	const actions = {
		onExportPdf: () => postExportPdf(),
		onExportXlsx: () => postExportStatementSpreadsheet(),
		onCnab240: () => postCnab240(),
		disabledAll: true
	};

	return (
		<ContentContainer>
			<Box >
				<Grid container spacing={4}>
					<Grid item>
						<Card>
							<span id="title-currentValue">
								Saldo atual
							</span>
							<span className="bankAccount-currentValue">
								{Helper.formatCurrencyAsIs(balance.available / 100)}
							</span>
						</Card>
					</Grid>
					<Grid item>
						<Card>
							<span id="title-currentValue">
								Saldo disponível
							</span>
							<span className="bankAccount-currentValue">
								{Helper.formatCurrencyAsIs(balance.currentBalance / 100)}
							</span>
						</Card>

					</Grid>
				</Grid>
			</Box>
			<ActionBar {...actions} hideSubmit={true} />
			<br />
			<PageTabs tabs={tabs} />
			<Route path={`${match.path}/extrato`} component={AccountStatement} />
			<Route path={`${match.path}/transferencias`} component={AccountTransfers} />
			<Route path={`${match.path}/pagamentos`} component={AccountPayments} />
			{hasUserPerm ? <Route path={`${match.path}/operadores`} component={AccountOperators} /> : null}
			<Route path={`${match.path}/favorecidos`} component={AccountFavoreds} />
			<Route exact path={`${match.path}`}>
				<Redirect to={match.url + "/extrato"} />
			</Route>
		</ContentContainer>
	);
};

export default BankAccountForm;