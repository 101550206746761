import React from 'react';
import {
	Paper,
	Grid,
	Typography,
	makeStyles,
	createStyles,
	Button,
} from '@material-ui/core';
import 'assets/css/circle.css';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import TransactionChartTooltip from './Tooltip';
import { ThreeDotsIcon } from 'assets';

const COLORS = ['#ff8203', '#077e9f', '#666b75', "#33658A", "#2F4858", "#27187E", "#FF8600", "#00798C", "#003D5B"];


const TransactionChart = ({ data }) => {
	const useStyles = makeStyles(() =>
		createStyles({
			root: {
				flexGrow: 1,
				margin: "10px 10px",
			},
			chartContainer: {
				flexGrow: 1,
				padding: "20px",
				textAlign: "center",
				height: "320px",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				borderRadius: "24px",
				borderBlockColor: "red",
			},
		})
	);
	const classes = useStyles();
	const tooltipoFormatter = e => e.toFixed(2) + '%';
	let arrayData = data || [];
	return (
		<Grid item xs={12}>
			<Paper className={classes.chartContainer} elevation={0}>
				<div className="card-item-row">
					<Typography>
						Transações por instrumento
					</Typography>				
				</div>
				<ResponsiveContainer height={250} width="100%">
					<PieChart
						height={250}>
						{arrayData && <Pie data={arrayData}
							dataKey='value'
							nameKey='name'
							outerRadius={90}
							fill='#8884d8'
							label>
							{
								arrayData.map((_entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
							}
						</Pie>}
						<Tooltip formatter={tooltipoFormatter}
							content={<TransactionChartTooltip />} />
					</PieChart>
				</ResponsiveContainer>								
			</Paper>
		</Grid >
	);
};


export default TransactionChart;
