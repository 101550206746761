import React from 'react';

export const ProcuradoriaGeralIcon = ({ className }) => {
	return (
		<svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16.2867 24.8188C16.0542 25.0438 15.6409 25.0813 15.3696 24.8188C15.1371 24.5938 15.0984 24.1938 15.3696 23.9313L19.7484 19.6938L17.9271 17.9313L13.5484 22.1688C13.303 22.4188 12.8896 22.4063 12.6313 22.1688C12.3601 21.9063 12.3988 21.5063 12.6313 21.2813L17.0101 17.0438L15.1888 15.2813L10.8101 19.5188C10.5776 19.7438 10.1642 19.7813 9.89298 19.5188C9.64756 19.2813 9.64756 18.8813 9.89298 18.6313L14.2717 14.3938L12.4376 12.6313L8.05881 16.8688C7.82631 17.0938 7.41298 17.1313 7.14173 16.8688C6.89631 16.6188 6.89631 16.2313 7.14173 15.9813L12.7992 10.5063L15.2146 12.8313C16.4417 14.0188 18.5601 14.0063 19.7871 12.8313C21.053 11.6063 21.053 9.63126 19.7871 8.40626L17.3846 6.08126L17.7463 5.73126C18.7538 4.75626 20.3942 4.75626 21.4017 5.73126L26.8784 11.0313C27.8859 12.0063 27.8859 13.5938 26.8784 14.5688L16.2867 24.8188ZM28.6996 16.3438C30.7146 14.3938 30.7146 11.2313 28.6996 9.26876L23.223 3.96876C21.208 2.01876 17.9401 2.01876 15.9121 3.96876L15.5505 4.31876L15.1888 3.96876C13.1738 2.01876 9.90589 2.01876 7.87798 3.96876L3.30548 8.39376C1.47131 10.1688 1.30339 12.9313 2.78881 14.8813L4.66173 13.0688C4.15798 12.1313 4.32589 10.9438 5.13964 10.1563L9.71214 5.73126C10.7196 4.75626 12.3601 4.75626 13.3676 5.73126L17.9659 10.1813C18.1984 10.4063 18.2371 10.8063 17.9659 11.0688C17.6946 11.3313 17.2813 11.2938 17.0488 11.0688L12.7992 6.96876L5.30756 14.2063C4.04173 15.4188 4.04173 17.4063 5.30756 18.6313C5.81131 19.1188 6.45714 19.4188 7.14173 19.5063C7.23214 20.1563 7.52923 20.7813 8.04589 21.2813C8.56256 21.7813 9.20839 22.0688 9.88006 22.1563C9.97048 22.8063 10.2676 23.4313 10.7842 23.9313C11.3009 24.4313 11.9467 24.7188 12.6184 24.8063C12.7088 25.4813 13.0188 26.0938 13.5226 26.5813C14.1296 27.1688 14.9434 27.4938 15.8088 27.4938C16.6742 27.4938 17.488 27.1688 18.0951 26.5813L28.6996 16.3438Z" fill="#5F5F5F" />
		</svg>

	);
};
