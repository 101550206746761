import React, { useState } from 'react';
import { ContentContainer } from 'components';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { TablePagination, Button, Typography } from '@material-ui/core';
import { getFundsList } from 'services/fund';
import { Link, useHistory } from 'react-router-dom';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import Helper from 'helpers/format.helpers';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ActionBar from 'components/ActionBar/ActionBar';

const FundList = (props) => {
	const { hideActions } = props;
	const history = useHistory();
	const tableRef = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const { setTitle } = useHeader();
	const { selectedTenant } = useTenant();
	const [enableFilter, setEnableFilter] = useState(false);


	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const actions = {
		onRefresh: () => tableRef?.current?.onQueryChange(),
	};

	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';

	return (
		<ContentContainer>
			{<ActionBar {...actions} hideSubmit={true} />}
			{!hideActions && <>
				<div className='uk-width-auto@m uk-width-1-1'>
					<div className='uk-width-auto@m uk-width-1-1'>
						<Link to='/fundo/novo' style={{ backgroundColor: '#3f51b5', textDecoration: "none" }} className='wt-button wt-button-lowercase uk-button'>
							<i className='fa fa-plus uk-margin-small-right' style={{ color: "white" }}></i>
							<Typography component="span" style={{ color: "white", textTransform: 'none', }}>Adicionar novo fundo</Typography>
						</Link>
						<Button style={{ marginLeft: 10 }} onClick={() => setEnableFilter(!enableFilter)} >
							<FilterListIcon /> Filtrar
						</Button>
					</div>
				</div>
				<br />
			</>
			}
			<MaterialTable
				tableRef={tableRef}
				title='Fundos'
				columns={[
					isRootTenancy ? { title: 'Correspondente', field: 'tenantDisplay', filtering: false } : undefined,
					{ title: 'Nome', field: 'name' },
					{ title: 'CNPJ', field: 'registrationNumber', render: ({ registrationNumber }) => Helper.formatDocumentNumber(registrationNumber) },
					{ title: 'Email', field: 'email' },
					{
						title: 'Fluxo cessão', field: 'assignment', render: ({ assignment }) => assignment == true ?
							<span style={{ fontSize: 14, minWidth: 100, display: 'inline-block', textAlign: 'center' }}>Sim</span> :
							<span style={{ fontSize: 14, minWidth: 100, display: 'inline-block', textAlign: 'center' }}>Não</span>
					},
					{
						title: 'Criado em', field: 'createdAt',
						render: ({ createdAt }) => createdAt && new Date(createdAt).toLocaleDateString('pt-BR'),
						filterComponent: (props) => <KeyboardDatePicker
							{...props}
							format="dd/MM/yyyy"
							InputLabelProps={{ shrink: true }}
							placeholder="dd/mm/aaaa"
							variant="inline"
							value={props?.columnDef?.tableData?.filterValue || null}
							disableFuture={true}
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e)}
							helperText={false}
						/>
					}].filter(x => x !== undefined)}
				actions={[
					{
						icon: 'search',
						tooltip: 'Editar',
						position: 'row',
						onClick: (_, rowData) => history.push(`/fundo/editar?id=${rowData.id}`)
					},
				]}
				data={(allParams) =>
					new Promise((resolve, reject) => {
						const { page, pageSize, search, filters } = allParams;

						const createdAt = filters.find(f => f.column.field === 'createdAt')?.value;

						const filterFinalDate = enableFilter &&
							createdAt &&
							moment(createdAt).isValid() &&
							moment(createdAt).set("hour", "23").set("minute", "59")?._d;

						const filterInitialDate =
							enableFilter &&
							createdAt &&
							moment(createdAt).isValid() &&
							moment(createdAt).set("hour", "0").set("minute", "0")?._d;

						const filtersValues = {
							name: enableFilter && filters.find(f => f.column.field === 'name')?.value,
							registrationNumber: enableFilter && filters.find(f => f.column.field === 'registrationNumber')?.value,
							email: enableFilter && filters.find(f => f.column.field === 'email')?.value,
							initialDate: filterInitialDate,
							finalDate: filterFinalDate,
							page,
							size: pageSize,
							searchString: search
						};

						getFundsList(filtersValues, selectedTenant)
							.then(result => {
								setTitle("Fundos");
								if (result?.data)
									resolve({
										data: result.data,
										page: result.page,
										totalCount: result.totalItems,
									});
								else {
									resolve({
										data: [],
										page: 0,
										totalCount: 0,
									});
								}
							}).catch(err => reject(err));
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					filtering: enableFilter
				}}
			/>
		</ContentContainer>
	);
};

export default FundList;
