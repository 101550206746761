import React from 'react';

const UploadIcon = () => (
	<svg width="18" height="25" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.5 0C2.0325 0 0 2.0325 0 4.5V35.5C0 37.9675 2.0325 40 4.5 40H27.5C29.9675 40 32 37.9675 32 35.5V14.5C31.9999 14.1022 31.8419 13.7207 31.5605 13.4395L31.5449 13.4238L18.5605 0.439453C18.2793 0.158149 17.8978 7.65285e-05 17.5 0H4.5ZM4.5 3H16V11.5C16 13.9675 18.0325 16 20.5 16H29V35.5C29 36.3465 28.3465 37 27.5 37H4.5C3.6535 37 3 36.3465 3 35.5V4.5C3 3.6535 3.6535 3 4.5 3ZM19 5.12109L26.8789 13H20.5C19.6535 13 19 12.3465 19 11.5V5.12109Z" fill="white"/>
		<path d="M24.5594 25.4947C24.2688 25.7628 23.8844 25.8947 23.5 25.8947C23.1156 25.8947 22.7322 25.7618 22.4397 25.4958L17.5 21.0134V34.0658C17.5 34.8187 16.8283 35.3894 16.0422 35.3894C15.2561 35.3894 14.5 34.8191 14.5 34.0658V21.0134L9.56078 25.4947C8.97484 26.0267 8.02562 26.0267 7.43968 25.4947C6.85374 24.9627 6.85374 24.1009 7.43968 23.569L14.9397 16.7598C15.5256 16.2278 16.4748 16.2278 17.0608 16.7598L24.5608 23.569C25.1453 24.1031 25.1453 24.9627 24.5594 25.4947Z" fill="white"/>
	</svg>

);

export default UploadIcon;