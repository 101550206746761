import React, { forwardRef, useEffect, useState, useCallback } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { Autocomplete } from '@material-ui/lab';
import { Edit } from "@material-ui/icons";
import debounce from 'random/debounce';
import { TablePagination } from '@material-ui/core';
import * as groupServices from 'services/group';
import { getOperatorGroupList } from 'services/operator';
import { useLoading } from 'contexts/loading';
import { useTenant } from 'contexts/tenant';
import { TableContainer } from 'containers/TableContainer';

const SearchGroup = ({ onRowDataChange, rowData }) => {
	const [searchField, setSearchField] = useState('');
	const { selectedTenant } = useTenant();
	const [options, setOptions] = useState([]);

	const verify = useCallback(
		debounce(name => {
			fetchGroup(name);
		}, 500), []);

	useEffect(() => {
		fetchGroup();
	}, []);

	useEffect(() => {
		verify(searchField);
	}, [searchField]);

	const fetchGroup = (name) => {
		groupServices.getGroupList({ page: 0, size: 10, name, selectedTenant }).then(resp => {
			setOptions(resp?.data.map(n => {
				return { name: n.groupName, value: n.userPoolId };
			}));
		});
	};

	return (
		<Autocomplete
			fullWidth
			noOptionsText={'Nenhum registro foi encontrado.'}
			defaultValue={{ value: rowData.userPoolId, name: rowData.groupName }}
			options={options}
			getOptionLabel={(option) => option.name}
			filterOptions={(options, _) => options}
			renderInput={(params) => <TextField {...params} onChange={(event) => setSearchField(event?.target?.value)} shrink />}
			onFocus={() => setSearchField('')}
			onInputChange={(_, __, reason) => { if (reason === 'clear') setSearchField(''); }}
			onChange={(event, group) => {
				console.log("onChange", event, group);
				onRowDataChange({
					...rowData,
					userPoolId: group?.value,
					groupName: group?.name
				});

			}
			}
		/>);
};

function GroupFields(props) {
	const { selectedTenant } = useTenant(); 
	const { userId } = props;
	const { addLoader, completeLoader } = useLoading();
	const [groups, setGroups] = useState([]);
	const [isEditingTable, setIsEditingTable] = React.useState(false);
	const addPersonButton = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);

	useEffect(() => {
		addLoader("useEffect[]");
		getOperatorGroupList({ page: 0, size: 10, selectedTenant }, userId).then(result => {
			if (result.data.length < 1) {
				addPersonButton.current.click();
				setIsEditingTable(true);
				completeLoader("useEffect[]");
			}
			else {
				setGroups(result?.data);
				setIsEditingTable(false);
				completeLoader("useEffect[]");
			}
		}).catch(err => { console.log(err); completeLoader("handleRejectOp"); });
	}, []);

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const table = <MaterialTable
		title={
			<Button className="button-actionBar" onClick={() => { addPersonButton.current.click(); setIsEditingTable(true); }}
				variant="contained"
				color="primary"
				disabled={isEditingTable}>
				<Typography component="span" style={{ color: "white", textTransform: 'none' }} >+ grupo</Typography></Button>}
		icons={{
			Add: _ => (
				<div ref={addPersonButton} >
				</div>
			),
			Edit: forwardRef((props, ref) => {
				return (
					<Edit {...props} onClick={() => {
						setTimeout(() => {
							setIsEditingTable(!!isEditingTable);
						}, 10);
					}} ref={ref} />
				);
			}),
		}}
		editable={{
			onRowAdd: newData =>
				new Promise((resolve, reject) => {
					groupServices.postGroupInsertUser(newData?.groupName, userId, selectedTenant).then(_ => {
						addLoader("getOperatorGroupList");
						getOperatorGroupList({ page: 0, size: 10, selectedTenant }, userId).then(resp => {
							setGroups(resp.data);
							setIsEditingTable(false);
							completeLoader("getOperatorGroupList");
						}
						);
					}).catch(err => { console.log(err); reject(); completeLoader("getOperatorGroupList"); });
					setIsEditingTable(false);
					resolve();
				}),
			onRowAddCancelled: () => {
				return new Promise(resolve => {
					setIsEditingTable(false);
					resolve();
				});
			},
			onRowDelete: oldData =>
				new Promise(resolve => {
					groupServices.removeUserGroupById(oldData.groupName, userId, selectedTenant).then(_ => {
						addLoader("getOperatorGroupList");
						getOperatorGroupList({ page: 0, size: 10, selectedTenant }, userId).then(resp => {
							setGroups(resp.data);
							setIsEditingTable(false);
							completeLoader("getOperatorGroupList");
						});
						resolve();
					}).catch(err => { resolve(); });
				})
		}}
		columns={[
			{
				title: 'Grupo',
				field: 'groupName',
				editComponent: props => (
					<SearchGroup onRowDataChange={props.onRowDataChange} rowData={props.rowData} />
				)
			}
		]}
		data={groups || []}
		onChangeRowsPerPage={onRowsPerPageChange}
		onRowsPerPageChange={onRowsPerPageChange}
		localization={localizationOptions}
		components={{
			Pagination: props => (
				<TablePagination
					{...props}
					rowsPerPageOptions={[5, 10, 15]}
				/>
			),
		}}
		options={{
			search: false,
			actionsColumnIndex: -1,
			pageSize: rowsPerPage,
			debounceInterval: 500,
			searchAutoFocus: true,
			toolbarButtonAlignment: 'left',
			searchFieldAlignment: 'left'
		}}
	/>;
	return (
		<>
			<Box p={1}>
				<TableContainer>
					{table}
				</TableContainer>
			</Box>
		</>
	);
}
export default GroupFields;