import React, { useState } from 'react';
import { ContentContainer } from 'components';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { TablePagination, Button, Typography, Select, TextField, MenuItem } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import FilterListIcon from '@material-ui/icons/FilterList';
import ActionBar from 'components/ActionBar/ActionBar';
import { deleteProductAccountById, getBankAccountProductList } from 'services/bankAccountProduct';
import Helper from 'helpers/format.helpers';

function ProductAccountList(props: any) {

	const { hideActions } = props;
	const history = useHistory();
	const tableRef = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const { setTitle } = useHeader();
	const [selectedRows, setSelectedRows] = useState([]);
	const { selectedTenant } = useTenant();
	const [enableFilter, setEnableFilter] = useState(false);

	//@ts-ignore
	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const deleteSelected = async () => {
		//@ts-ignore
		selectedRows.map(row => {//@ts-ignore
			deleteProductAccountById(row?.id)
				.then(_ => {//@ts-ignore
					tableRef.current.onQueryChange();
				})
				.catch(err => {//@ts-ignore
					tableRef.current.onQueryChange();
				});
		});
		setSelectedRows([]);
	};

	const actions = { //@ts-ignore
		onRefresh: () => tableRef?.current?.onQueryChange(),
		onDelete: selectedRows.length > 0 && deleteSelected,
	};

	const TypeAccount = [
		{
			name: 'Conta de Livre Movimentação',
			value: 'Payment'
		},
		{
			name: 'Escrow',
			value: 'Escrow'
		},
	];

	//@ts-ignore
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';

	return (
		<>
			<ContentContainer>
				{/*@ts-ignore */}
				{<ActionBar {...actions} hideSubmit={true} />}
				{!hideActions && <>
					<div className='uk-width-auto@m uk-width-1-1'>
						<div className='uk-width-auto@m uk-width-1-1'>
							<Link to='/produto-conta/novo' style={{ backgroundColor: '#3f51b5', textDecoration: "none" }} className='wt-button wt-button-lowercase uk-button'>
								<i className='fa fa-plus uk-margin-small-right' style={{ color: "white" }}></i>
								<Typography component="span" style={{ color: "white", textTransform: 'none', }}>Adicionar novo produto</Typography>
							</Link>
							<Button style={{ marginLeft: 10 }} onClick={() => setEnableFilter(!enableFilter)} >
								<FilterListIcon /> Filtrar
							</Button>
						</div>
					</div>
					<br />
				</>
				}
				{/*@ts-ignore */}
				<MaterialTable
					tableRef={tableRef}
					title='Lista de Produtos para contas digitais '
					columns={[
						isRootTenancy ? { title: 'Correspondente', field: 'tenantDisplay', filtering: false } : undefined,
						{ title: 'Produto', field: 'name' },
						{
							title: 'Tipo de conta', field: 'bankingAccountType', filtering: false,
							//@ts-ignore
							render: ({ bankingAccountType }) => { return bankingAccountType == "Payment" ? "Conta de Livre Movimentação" : "Escrow"; },
							//@ts-ignore
							filterComponent: (props) => <Select
								{...props}
								fullWidth
								onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
								component={TextField}
							>
								<MenuItem value={""}></MenuItem>
								{TypeAccount && TypeAccount.map(field => (
									<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
								))}
							</Select>
						},
						{ title: 'Valor do Pacote', field: 'packageValue', filtering: false, render: ({ packageValue }: any) => Helper.formatCurrencyAsIs(packageValue) }

					].filter(x => x !== undefined)}
					actions={[
						{
							icon: 'search',
							tooltip: 'Editar',
							position: 'row',
							//@ts-ignore
							onClick: (_, rowData) => history.push(`/produto-conta/editar?id=${rowData.id}`)
						},
					]}
					editable={{
						onRowDelete: oldData =>
							new Promise(resolve => { //@ts-ignore
								deleteProductAccountById(oldData.id).then(_ => {//@ts-ignore
									resolve();
								}).catch(err => {//@ts-ignore
									resolve();
								});
							})
					}}
					data={(allParams) =>
						new Promise((resolve, reject) => {
							const { page, pageSize, search, filters } = allParams;

							const filtersValues = {
								page,
								size: pageSize,
								searchString: search,
								name: enableFilter && filters.find(f => f.column.field === 'name')?.value,
							};

							//@ts-ignore
							getBankAccountProductList(filtersValues, selectedTenant)
								.then(result => {
									//@ts-ignore
									setTitle("Produto para Conta Digital");
									if (result?.data)
										resolve({
											data: result.data,
											page: result.page,
											totalCount: result.totalItems,
										});
									else {
										resolve({
											data: [],
											page: 0,
											totalCount: 0,
										});
									}
								}).catch(err => reject(err));
						})
					}
					onChangeRowsPerPage={onRowsPerPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
					localization={localizationOptions}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								rowsPerPageOptions={[5, 10, 20, 50]}
							/>
						),
					}}

					options={{
						actionsColumnIndex: -1,
						pageSize: rowsPerPage,
						debounceInterval: 500,
						searchAutoFocus: true,
						filtering: enableFilter
					}}
				/>
			</ContentContainer>
		</>
	);
};
export default ProductAccountList;