import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';
import permissionLevel from 'global/enums/permission-level';
import permissionResource from 'global/enums/permission-resource';
import permissionType from 'global/enums/permission-type';

export function getDefaultPermissionList() {

	return [
		{
			"resource": "CreditNote",
			"resourceDisplay": "Operações de Crédito",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "User",
			"resourceDisplay": "Usuários",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "UserGroup",
			"resourceDisplay": "Grupo de Usuários",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "BankAccountRequest",
			"resourceDisplay": "Solicitações de abertura de conta",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "BankAccount",
			"resourceDisplay": "Contas Digitais",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "LegalPerson",
			"resourceDisplay": "Pessoas Jurídicas",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "NaturalPerson",
			"resourceDisplay": "Pessoas Físicas",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "Person",
			"resourceDisplay": "Pessoas",
			"readPermission": "None",
		},
		{
			"resource": "Permission",
			"resourceDisplay": "Permissões",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "Fund",
			"resourceDisplay": "Fundos",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "CreditProduct",
			"resourceDisplay": "Produtos Crédito",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},
		{
			"resource": "BankAccountProduct",
			"resourceDisplay": "Produtos Conta",
			"createPermission": "None",
			"updatePermission": "None",
			"readPermission": "None",
			"deletePermission": "None",
		},

	];

};

export async function postPermissionList(permissions, userId, selectedTenant, principalType) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Permission/${principalType}/${userId}`;
	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let payload = permissions.reduce((acc, item) => {
		item.readPermission && acc.push({ resource: item.resource, level: permissionLevel.indexOf(item.readPermission), type: 'Read' });
		item.createPermission && acc.push({ resource: item.resource, level: permissionLevel.indexOf(item.createPermission), type: 'Create' });
		item.updatePermission && acc.push({ resource: item.resource, level: permissionLevel.indexOf(item.updatePermission), type: 'Update' });
		item.deletePermission && acc.push({ resource: item.resource, level: permissionLevel.indexOf(item.deletePermission), type: 'Delete' });
		return acc;
	}, []);

	let result;

	try {
		result = await axios.post(url, payload, config);
		Toast.showSuccessMessage("Permissões salvas com sucesso");
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível salvar a permissiões", err);
		}
		throw err;
	}
}

export function permissionService(userPerms) {
	const permissionList = getDefaultPermissionList();
	const permissionListWithUserPerms = permissionList.map((perm) => {
		const userPerm = userPerms.find((userPerm) => userPerm.resourceDisplay === perm.resource);
		if (userPerm) {
			let normalize = (permArray) => {
				if (permArray.length) {
					return permissionLevel[permArray[0].level];
				}
				return "None";
			};
			perm.readPermission = perm.readPermission && normalize(userPerms.filter(item => item.resourceDisplay == perm.resource && permissionType[item.type] == "Read"));
			perm.createPermission = perm.createPermission && normalize(userPerms.filter(item => item.resourceDisplay == perm.resource && permissionType[item.type] == "Create"));
			perm.updatePermission = perm.updatePermission && normalize(userPerms.filter(item => item.resourceDisplay == perm.resource && permissionType[item.type] == "Update"));
			perm.deletePermission = perm.deletePermission && normalize(userPerms.filter(item => item.resourceDisplay == perm.resource && permissionType[item.type] == "Delete"));
			return perm;

		}
		return perm;
	});
	return permissionListWithUserPerms;
};


export async function getPermissionList(id, selectedTenant, principalType) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/Permission/${principalType}/${id}`;
	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		const { data } = result;
		const perms = await permissionService(data);
		return perms;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de permissões do usuário", err);
		}
		throw err;
	}
}

export async function getMyPermissionList() {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/Permission`;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		const { data } = result;
		const perms = await permissionService(data);
		return perms;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de permissões do usuário", err);
		}
		throw err;
	}
}