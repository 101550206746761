import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';

export async function assign(recordType, recordId, userId, groupName, selectedTenant) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/${recordType}/${recordId}/assign`;
    
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	let result;

	try {
		result = await axios.post(url, {
			userId: userId,
			groupName: groupName,
			tenant: selectedTenant
		}, config);

		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível atribuir, tente novamente mais tarde.", err);
		}
		throw err;
	}
}