import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext({ loading: false, addLoader:()=>{}, completeLoader: () => {} });

export const LoadingProvider = ({ children }) => {
	const [loaders, setLoaders] = useState([]);
	
	const addLoader = (name) => { 				
		setLoaders(prev => [...loaders, name]);		
	};

	const completeLoader = (name) => {	
		setLoaders(prev => prev.filter(v => v != name));						
	};

	return (
		<LoadingContext.Provider
			value={{
				loading: loaders.length > 0,
				addLoader,
				completeLoader
			}}
		>
			{children}
		</LoadingContext.Provider>
	);
};

export function useLoading() {
	const context = useContext(LoadingContext);
	const {
		loading,
		addLoader,
		completeLoader
	} = context;
	return {
		loading,
		addLoader,
		completeLoader
	};
}
