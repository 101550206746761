import { Route, useRouteMatch } from 'react-router-dom';
import BankAccountForm from '../Details/BankAccountForm/bankAccountForm';
import BankAccountActiveList from 'pages/BankAccountRequest/Details/AccountActiveList/AccountActive';

const BankAccountActive = () => {
	const match = useRouteMatch();

	return (
		<>
			<Route path={`${match.path}/:bankAccountId`} component={BankAccountForm} />
			<Route exact path={match.path} component={BankAccountActiveList} />
		</>
	);
};

export default BankAccountActive;
