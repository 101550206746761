import React, { useState, useEffect } from "react";
import {
	Box,
	Grid,
	InputLabel,
	FormGroup,
	FormControlLabel,
	TextField,
	Typography,
	makeStyles,
	FormHelperText

} from "@material-ui/core";
import { Controller } from "react-hook-form";
import TextFieldComponent from "components/TextInput/TextField";
import Helper from 'helpers/format.helpers';
import TextCustomMask from "components/CustomMaskInput/TextCustomMask";
import NumberInput from "components/NumberInput/NumberInput";
import CheckboxComponent from "components/CheckBox/Checkbox";
import Select from "components/Select/Select";
import DatePicker from "components/DatePicker/DatePicker";
import { Autocomplete } from "@material-ui/lab";
import { getEconomicActivityCodeList } from "services/economicActivityCode";
import { queryZipCodeAndFillForm } from "services/zipCode";
import UF from "global/enums/uf";
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AutocompleteWithSearch from "components/AutocompleteSearch/AutocompleteSearch";
import { useNaturalPersons } from "contexts/naturalPerson";

const useHelperTextStyles = makeStyles(() => ({
	root: {
		color: 'red'
	}
}));

const AutocompleteEconomicActivityCodeList = ({ setValue, watch, errors }) => {
	const helperTextStyles = useHelperTextStyles();
	const [economicActivityCodeList, setEconomicActivityCodeList] = useState([]);
	const [searchEconomicActivityCode, setSearchEconomicActivityCode] =
		useState("");

	useEffect(() => {
		fetchEconomicActivityCodeList();
	}, [searchEconomicActivityCode]);

	const fetchEconomicActivityCodeList = () => {
		if (searchEconomicActivityCode?.toString().length >= 3) {
			getEconomicActivityCodeList(searchEconomicActivityCode).then((resp) => {
				if (resp) {
					setEconomicActivityCodeList(
						resp?.map((e) => {
							return { value: e.code, name: e.description, id: e.id };
						})
					);
				}
			});
		}
	};

	return (
		<>
			<Typography variant="subtitle2" component="div" style={{ color: "#5F5F5F", paddingBottom: 10 }}><strong>CNAE</strong></Typography>
			<InputLabel htmlFor="economicActivityCodeId" shrink>
			</InputLabel>
			<Autocomplete
				options={economicActivityCodeList}
				id="economicActivityCodeId"
				name="economicActivityCodeId"
				value={{
					value: watch("economicActivityCode")?.code,
					name: watch("economicActivityCode")?.description,
					id: watch("economicActivityCodeId"),
				}}
				fullWidth
				filterOptions={(options, state) => options} // used to disable filter
				noOptionsText={"Nenhum registro foi encontrado."}
				getOptionLabel={(option) => option?.name}
				renderInput={(params) => <TextField
					shrink
					{...params}
					InputProps={{
						...params.InputProps,
						disableUnderline: true,
						style: { backgroundColor: 'white', borderRadius: 10, padding: 10, border: errors && errors?.economicActivityCodeId?.message ? '1px solid red' : '' },
						onChange: (event) => {
							setSearchEconomicActivityCode(event?.currentTarget?.value);
							setValue('economicActivityCode', {
								code: event?.currentTarget?.value,
								description: event?.currentTarget?.value
							});
						},
					}}
				/>}
				onChange={(e, options) => {
					setValue('economicActivityCode', {
						code: options?.value,
						description: options?.name,
					});
					setValue('economicActivityCodeId', options?.id);
				}}
				onFocus={() => setSearchEconomicActivityCode('')}
			/>
			<FormHelperText classes={{ root: helperTextStyles.root }}>{errors && errors?.economicActivityCodeId?.message}</FormHelperText>
		</>
	);
};

function InformationsLegalPerson(props) {
	const {
		setValue,
		inputBind,
		inputBindNumber,
		isFieldRequired,
		control,
		watch,
		errors,
		onChange,
	} = props;
	const [files, setFiles] = useState([]);
	const { getNaturalPersonsList, naturalPersonsOptionList } = useNaturalPersons();

	const changeCheckBoxValue = (event) => {
		const value = event.target.checked;
		const name = event.target.name;
		setValue(name, value);
	};

	const deleteFile = (index) => {
		const newFiles = [...files];
		newFiles.splice(index, 1);
		setFiles(newFiles);
	};

	const handleLink = () => {
		const personId = watch('responsibleId');
		return `/pessoa-fisica/editar?id=${personId}`;
	};

	return (
		<>
			<Box style={{ paddingTop: 30 }}>
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Identificação do cliente</Typography>
				<br />
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<TextFieldComponent
							id="registrationNumber"
							fullWidth
							name="registrationNumber"
							label="CNPJ"
							placeholder="00.000.000/0000-00"
							component={TextField}
							InputProps={{
								disableUnderline: true,
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.cnpjMask },
							}}
							{...inputBind("registrationNumber")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="companyName"
							name="companyName"
							label="Razão Social"
							fullWidth
							component={TextField}
							{...inputBind("companyName")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="name"
							fullWidth
							name="name"
							label="Nome fantasia"
							component={TextField}
							{...inputBind("name")}
						/>
					</Grid>

					<Grid item xs={4}>
						<Box pt={0}>
							<Select
								id="typeOfActivity"
								label="Ramo de Atividade"
								name="typeOfActivity"
								{...inputBind("typeOfActivity")}
								fields={[
									{ name: "Industria", value: "Industry" },
									{ name: "Serviços", value: "services" },
									{ name: "Negócios", value: "business" },
								]}
							/>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="municipalRegistration"
							fullWidth
							name="municipalRegistration"
							label="Inscrição Municipal"
							component={TextField}
							{...inputBind("municipalRegistration")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="stateRegistration"
							fullWidth
							name="stateRegistration"
							label="Inscrição Estadual"
							component={TextField}
							{...inputBind("stateRegistration")}
						/>
					</Grid>
					<Grid item xs={2}>
						<Box pt={0}>
							<Select
								id="legalNature"
								label="Natureza Jurídica"
								name="legalNature"
								{...inputBind("legalNature")}
								fields={[
									{ name: "LTDA - Sociedade Empresária Limitada", value: "LTDA" },
									{ name: "MEI - Microempresário Individual", value: "MEI" },
									{ name: "EI - Empresário Individual", value: "EI" },
									{ name: "EIREILI - Empresário Individual de Responsabilidade Limitada", value: "EIREILI" },
									{ name: "SA - Sociedade Anônima", value: "SA" },
									{ name: "SLU - Sociedade Limitada Unipessoal", value: "SLU" }
								]}
							/>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<DatePicker
							id="openDate"
							label="Data de Abertura"
							variant="inline"
							{...inputBind("openDate")}
							name="openDate"
							disableFuture
							InputLabelProps={{
								shrink: true,
								required: isFieldRequired("openDate"),
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="email"
							fullWidth
							name="email"
							label="Email"
							component={TextField}
							{...inputBind("email")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="site"
							fullWidth
							name="site"
							label="Website"
							component={TextField}
							{...inputBind("site")}
						/>
					</Grid>
					<Grid item xs={4}>
						<AutocompleteWithSearch
							id="responsibleId"
							name="responsibleId"
							displayField="responsibleIdDisplay"
							fullWidth
							label="Responsável"
							watch={watch}
							setValue={setValue}
							//rowData={{ personId: person?.personId, personIdDisplay: person?.personIdDisplay }}
							params={{
								page: 0,
								size: 10
							}}
							fetch={getNaturalPersonsList}
							options={naturalPersonsOptionList}
							{...inputBind(`responsibleId`)}
							endAdornment={
								watch("responsibleId") ? <Link to={handleLink()}>
									<SearchOutlinedIcon position="end" />
								</Link> : ''
							}
							showEndAdornment={watch("responsibleId")}
							onChange={(_, options) => {
								setValue('responsibleIdDisplay', options?.name);
								setValue('responsibleId', options?.id);
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="address-zipCode"
							fullWidth
							label="CEP"
							name="address.zipCode"
							placeholder="00000-000"
							component={TextField}
							InputProps={{
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.zipCodeMask },
								onChange: (event) => {
									let value = Helper.onlyNumbers(event?.currentTarget?.value);
									if (value.length >= 8) {
										queryZipCodeAndFillForm(value, (fieldName, value) => {
											setValue(`address.${fieldName}`, value);
										});
									}
								},
							}}
							{...inputBind("address.zipCode")}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="address-addressName"
							fullWidth
							label="Endereço"
							name="address.addressName"
							component={TextField}
							{...inputBind("address.addressName")}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="address-number"
							fullWidth
							type="number"
							label="Número"
							name="address.name"
							component={TextField}
							{...inputBind("address.number")}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="address-complement"
							fullWidth
							label="Complemento"
							name="address.complement"
							component={TextField}
							{...inputBind("address.complement")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="address-district"
							fullWidth
							label="Bairro"
							name="address.district"
							component={TextField}
							{...inputBind("address.district")}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="address-city"
							fullWidth
							label="Cidade"
							name="address.city"
							component={TextField}
							{...inputBind("address.city")}
						/>
					</Grid>
					<Grid item xs={1}>
						<Select
							id="address-uf"
							name="address.uf"
							label="UF"
							fullWidth
							defaultValue=""
							{...inputBind(`address.uf`)}
							fields={UF} />
					</Grid>
					<Grid item xs={2}>
						<TextFieldComponent
							id="phone"
							fullWidth
							label="Telefone Celular"
							placeholder="(00) 0000-0000"
							name="phone"
							component={TextField}
							{...inputBind("phone")}
							InputProps={{
								disableUnderline: true,
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.phoneNumberMask },
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="phoneExtension"
							fullWidth
							label="Ramal"
							type="number"
							name="phoneExtension"
							component={TextField}
							{...inputBind("phoneExtension")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="phone2"
							fullWidth
							label="Telefone Opcional"
							name="phone2"
							placeholder="(00) 0000-0000"
							component={TextField}
							{...inputBind("phone2")}
							InputProps={{
								disableUnderline: true,
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.phoneNumberMask },
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextFieldComponent
							id="phone2Extension"
							fullWidth
							label="Ramal"
							type="number"
							name="phone2Extension"
							component={TextField}
							{...inputBind("phone2Extension")}
						/>
					</Grid>
					<Grid item xs={5}>
						<AutocompleteEconomicActivityCodeList
							setValue={setValue}
							watch={watch}
							errors={errors}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box p={0} mt={3}>
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>
					Dados financeiros
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<Box pt={2.7}>
							<NumberInput
								id="averageMonthlyRevenue"
								label="Faturamento médio mensal (Últimos 12 Meses)"
								name="averageMonthlyRevenue"
								adornmentText="R$"
								{...inputBindNumber("averageMonthlyRevenue")}
							/>
						</Box>
					</Grid>
					<Grid item xs={3} >
						<Box pt={2.7}>
							<NumberInput
								id="averageGrossProfitMargin"
								label="Margem média de Lucro Bruto"
								name="averageGrossProfitMargin"
								adornmentText="%"
								{...inputBindNumber("averageGrossProfitMargin")}
							/>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box pt={2.7}>
							<NumberInput
								id="initialShareCapital"
								label="Capital social inicial"
								type="number"
								name="initialShareCapital"
								adornmentText="R$"
								{...inputBindNumber("initialShareCapital")}
							/>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box pt={2.7}>
							<NumberInput
								id="netWorth"
								label="Patrimônio Líquido"
								type="number"
								name="netWorth"
								adornmentText="R$"
								{...inputBindNumber("netWorth")}
							/>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="purchases"
							label="Faturamento médio mensal (Arranjo)"
							name="purchases"
							adornmentText="R$"
							{...inputBindNumber("purchases")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="amountRentedFinanced"
							label="Valor Alugado/Financiado"
							name="amountRentedFinanced"
							adornmentText="R$"
							{...inputBindNumber("amountRentedFinanced")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="exportPercentage"
							label="Exportação (em %)"
							name="exportPercentage"
							adornmentText="%"
							{...inputBindNumber("exportPercentage")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="costWithSuppliers"
							label="Custo com Fornecedores"
							name="costWithSuppliers"
							adornmentText="R$"
							{...inputBindNumber("costWithSuppliers")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="numberOfEmployees"
							fullWidth
							type="number"
							label="Número de Funcionários"
							name="numberOfEmployees"
							component={TextField}
							margin="dense"
							{...inputBind("numberOfEmployees")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="payrollValue"
							label="Valor da Folha de Pagamento"
							name="payrollValue"
							adornmentText="R$"
							{...inputBindNumber("payrollValue")}
						/>
					</Grid>
					<Grid item xs={3}>
						<NumberInput
							id="spendingAmountWithTax"
							label="Valor gasto com imposto"
							name="spendingAmountWithTax"
							adornmentText="R$"
							{...inputBindNumber("spendingAmountWithTax")}
						/>
					</Grid>
					<Grid item xs={3}>
						<Select
							id="typeOfResidence"
							label="Local da Sede"
							name="typeOfResidence"
							{...inputBind("typeOfResidence")}
							defaultValue=""
							fields={[
								{ name: "Própria", value: "Owner" },
								{ name: "Alugada", value: "Rent" },
							]}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldComponent
							id="averageSalesPeriod"
							label="PMV"
							type="number"
							name="averageSalesPeriod"
							margin="dense"
							{...inputBind("averageSalesPeriod")}
						/>
					</Grid>
					<Grid item xs={4}>
						<NumberInput
							id="fosterablePotential"
							label="Potencial Fomentável"
							name="fosterablePotential"
							adornmentText="%"
							{...inputBindNumber("fosterablePotential")}
						/>
					</Grid>
					<Grid item xs={4}>
						<NumberInput
							id="delayedSalePercentage"
							label="Percentual de Venda em Atraso"
							name="delayedSalePercentage"
							adornmentText="%"
							{...inputBindNumber("delayedSalePercentage")}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box p={0} mt={3} className="disablePrint">
				<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Status do cadastro</Typography>
				<Grid container spacing={2}>
					<Controller
						render={({ field }) => (
							<>
								<Grid item xs={12}>
									<TextFieldComponent
										id="description"
										fullWidth
										type="text"
										name="description"
										component={TextField}
										margin="dense"
										multiline={true}
										rows={4}
										fieldAreaHeight={100}
										{...inputBind("description")}
									/>
								</Grid>
							</>)}
						control={control}
						name="description" />
					<Grid item xs={3}>
						<FormGroup>
							<FormControlLabel control={<CheckboxComponent
								id="allowAccessToSchedule"
								color="primary"
								name="allowAccessToSchedule"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								onClick={changeCheckBoxValue}
								{...inputBind("allowAccessToSchedule")}
							/>} label="Permitir contato" />
						</FormGroup>
					</Grid>

					<Grid item xs={3}>
						<FormGroup>
							<FormControlLabel control={<CheckboxComponent
								id="allowAccessToReceivables"
								color="primary"
								name="allowAccessToReceivables"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								onClick={changeCheckBoxValue}
								{...inputBind("allowAccessToReceivables")}
							/>} label="Permitir acesso aos recebíveis" />
						</FormGroup>
					</Grid>
					<Grid item xs={3}>
						<FormGroup>
							<FormControlLabel control={<CheckboxComponent
								id="allowPartnerApiSubscription"
								color="primary"
								name="allowPartnerApiSubscription"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								onClick={changeCheckBoxValue}
								{...inputBind("allowPartnerApiSubscription")}
							/>} label="Permitir acesso a API parceira" />
						</FormGroup>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default InformationsLegalPerson;
