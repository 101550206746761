import React, { createContext, useContext, useState, useEffect } from 'react';

const ModalContext = createContext({ modalContent: false, setModalContent: () => { } });

export const ModalProvider = ({ children }) => {
	const [modalContent, setModalContent] = useState(null);

	return (
		<ModalContext.Provider
			value={{
				modalContent,
				setModalContent,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export function useModal() {
	const context = useContext(ModalContext);
	const {
		setModalContent,
		modalContent,
	} = context;
	return {
		setModalContent,
		modalContent
	};
}
