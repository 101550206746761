import React, { useState } from 'react';
import ModalComponent from 'components/Modal/ModalComponent';
import { ContentContainer } from 'components';
import InformationsOperator from './FiedsForm/informationOperator';
import BankAccountOperatorList from './List/operatorList';

const AccountOperators = () => {
	const [open, setOpen] = useState(false);
	const tableRef = React.useRef(null);
	const [modalProps, setModalProps] = useState({
		title: "",
		subtitle: "",
		onClick: () => { },
		buttonText: "",
		children: <></>
	});

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<ContentContainer>

			<ModalComponent
				open={open}
				onClose={handleClose}
				{...modalProps}
			/>
			<InformationsOperator tableRef={tableRef} />
			<BankAccountOperatorList setModalProps={setModalProps} handleOpen={handleOpen} handleClose={handleClose} tableRef={tableRef} />

		</ContentContainer>
	);
};

export default AccountOperators;
