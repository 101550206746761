import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@material-ui/core';
import LegalDatasets from 'pages/LegalPerson/RiskDatasets/datasets';
import NaturalDatasets from 'pages/NaturalPerson/RiskDatasets/datasets';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { uniqBy } from 'lodash';

const KYCComponente = ({ isEditing, setLoading, involvedPersons, taker }) => {
	
	const naturalPersons = uniqBy([
		...involvedPersons?.filter(i => i.discriminator === 'NaturalPerson'),
		taker && taker.personDiscriminator == "NaturalPerson" ? { personId: taker.personId, personIdDisplay: taker.personIdDisplay } : undefined]
		.filter(x => x !== undefined),
	(i) => i.personId);

	const legalPersons = uniqBy([
		...involvedPersons?.filter(i => i.discriminator === 'LegalPerson'),
		taker && taker.personDiscriminator == "LegalPerson" ? { personId: taker?.personId, personIdDisplay: taker?.personIdDisplay } : undefined].filter(x => x !== undefined),
	(i) => i.personId);

	//Filtering Legal and Natural Persons from involvedPersons and adding person taker based on discriminator
	return (
		<>
			{!isEditing() && <Box p={3}>
				<Typography variant="h6" gutterBottom>
					Salve o registro para ver os dados
				</Typography>
			</Box>}
			{isEditing() && <>
				{legalPersons.length > 0 && <>
					<Typography variant="h6">
						Pessoas Jurídicas
					</Typography>
					{legalPersons?.map(person => (
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography>{person?.personIdDisplay}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Container>
									<Box>
										<LegalDatasets setLoading={setLoading} legalPersonId={person.personId} />
									</Box>
								</Container>
							</AccordionDetails>
						</Accordion>
					))}
					<br />					
				</>}
				{naturalPersons.length > 0 && <Typography variant="h6"> 
					Pessoas Físicas
				</Typography>}
				{naturalPersons?.map(person => (
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>{person?.personIdDisplay}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Container>
								<Box>
									<NaturalDatasets setLoading={setLoading} naturalPersonId={person.personId} />
								</Box>
							</Container>
						</AccordionDetails>
					</Accordion>
				))}
			</>}
		</>
	);
};

export default KYCComponente;