import { string, object, number, date, array } from 'yup';

export const getValidationSchema = object().shape({
	dueDate: date().typeError('Data inválida'),
	operation: number().typeError("Operação inválida").required(),
	uploads: array()
		.min(1, "Necessário ao menos uma CTE")
		.max(300, "Permitido até 300 CTEs")

});

export const defaultValues = {
	dueDate: new Date().toDateString(),
	operation: 0,
	uploads: []
};