import React from 'react';
import styled from 'styled-components';

const HelpContainerStyled = styled.nav`
	font-size: .8em;
	display: block;
	width: 100%;
	max-width: 450px;
	margin: auto;
	padding: 20px 0;
	text-align: center;

	a {
		margin-left: 10px;
		text-decoration: none;
		color: white;
		&:first-child {
			margin-left: 0
		}
	}
`;

export default props => (
	<HelpContainerStyled {...props}>
		<a href="https://www.uy3.com.br/politica-de-privacidade/" target="_blank" rel="noopener noreferrer">Ajuda</a>
		<a href="https://www.uy3.com.br/politica-de-privacidade/" target="_blank" rel="noopener noreferrer">Privacidade</a>
		<a href="https://www.uy3.com.br/politica-de-privacidade/" target="_blank" rel="noopener noreferrer">Termos</a>
	</HelpContainerStyled>
);