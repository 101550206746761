import React, { useEffect, useState } from 'react';
import { localizationOptions } from "helpers/table.helpers";
import MaterialTable from "material-table";
import { MenuItem, Select, TablePagination } from "@material-ui/core";
import * as  servicesTransfers from "services/digitalBankAccount";
import { ContentContainer, Toast } from 'components';
import Helper from 'helpers/format.helpers';
import { useParams } from 'react-router';
import { ModalProps, RouteWithIdParamsType } from 'global/types/types';
import HelperStatus from 'helpers/message.helpers';
import HasPassCode from '../../Code/codeForm';
import { useUser } from 'contexts/user';
import { ParamsApproveOrReject, ParamsCancel } from 'global/types/digitalAccount.types';
import Button from 'components/Button/Button';
import FilterListIcon from "@material-ui/icons/FilterList";
import { optionsStatusFiltering } from '../../OptionsFields/options.status';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { IMFAState } from 'global/interface/types';
import ApproversDetails from 'components/ApproversDetails/ApproversDetails';

//@ts-ignore
const TransferList = (props) => {
	const { setModalProps, handleOpen, handleClose, tableRef } = props;
	const [enableFilter, setEnableFilter] = useState(false);	
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const { user } = useUser();
	const [rowsPerPage, setRowsPerPage] = useState<number>(Number(localStorage.getItem('rowsPerPage')) || 5);
	const [username, setUsername] = useState("");
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	const stateRef = React.useRef<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	stateRef.current = modalFormData;
	const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
	const cognitoClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID;
	
	const onRowsPerPageChange = (page: number) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', String(page));
	};

	const defaultModalProps = {
		title: 'Aprovar transferência',
		subtitle: 'Tem certeza que deseja aprovar a transferência?',
		onClick: () => { },
		buttonText: "Confirmar",
		children: <HasPassCode setFormData={(data: IMFAState) => { setModalFormData(data); }} />
	} as ModalProps;

	const defaultDetailsModalProps = {
		enableButton: false,
	} as ModalProps;

	useEffect(() => {
		//@ts-ignore
		if (user?.authenticated == true) setUsername(user['payload']['cognito:username']);
	}, [user]);
	
	const handleApprove = async (paymentId: string) => {
		let modalData = stateRef.current;
		await servicesTransfers.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				payload: {
					action: "Approve", sessionId: sessionId, code: modalData.code
				},
				paymentId,
				path: "Transfer"
			} as unknown as ParamsApproveOrReject;

			servicesTransfers.approveOrReject(data).then(() => Toast.showSuccessMessage("Transferência aprovada com sucesso!"))
				.finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	const handleReject = async (paymentId: string) => {
		let modalData = stateRef.current;
		await servicesTransfers.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				payload: {
					action: "Reject", sessionId: sessionId, code: modalData.code
				},
				paymentId,
				path: "Transfer"
			} as unknown as ParamsApproveOrReject;

			servicesTransfers.approveOrReject(data).then(() => Toast.showSuccessMessage("Transferência reprovada com sucesso!"))
				.finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	const handleCancel = async (beneficiaryId: string) => {
		let modalData = stateRef.current;
		await servicesTransfers.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				beneficiaryId,
				sessionId,
				passCode: modalData.code,
				path: "Transfer"
			} as unknown as ParamsCancel;

			servicesTransfers.cancel(data).then(() => {
				Toast.showSuccessMessage("Transferência cancelada com sucesso!");
			}).finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	const handleTransferReceipt = async (bankAccountId: string, beneficiaryId: string ) => {
		await servicesTransfers.postTransferreceipt(bankAccountId, beneficiaryId).then((result) => {
			const file = new Blob(
				[result],
				{ type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}).catch((error) => console.log(error)); 
	};

	return (
		<ContentContainer>
			<MaterialTable
				style={{ paddingLeft: 20, paddingRight: 20, maxWidth: '91vw' }}
				tableRef={tableRef}
				title={
					<Button
						variant="outlined"
						color="default"
						onClick={() => setEnableFilter(!enableFilter)}
						children={"Transferências"}
						startIcon={<FilterListIcon />}
					/>
				}
				columns={[
					{
						title: 'Data da Ocorrência', field: 'transferDate',
						render: ({ transferDate }) => transferDate && new Date(transferDate).toLocaleDateString('pt-BR'),
						filterComponent: (props: any) => <KeyboardDatePicker
							{...props}
							format="dd/MM/yyyy"
							InputLabelProps={{ shrink: true }}
							placeholder="dd/mm/aaaa"
							variant="inline"
							value={props?.columnDef?.tableData?.filterValue || null}
							disableFuture={true}
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e)}
							helperText={false}
						/>
					},
					{ title: 'Favorecido', field: 'bankAccountBeneficiary.name' },
					//@ts-ignore
					{
						title: 'CPF/CNPJ', field: 'bankAccountBeneficiary.registrationNumber',
						render: ({ bankAccountBeneficiary }: any) => (Helper.formatDocumentNumber(bankAccountBeneficiary.registrationNumber))
					},
					{ title: 'Tipo de pagamento', field: 'paymentPurposeDisplay', filtering: false, },
					//@ts-ignore
					{
						title: 'Dados da Conta', field: 'bankAccountBeneficiary.agency', filtering: false,
						render: ({ bankAccountBeneficiary }) => bankAccountBeneficiary.agency
							&& `Ag:${bankAccountBeneficiary.agency}  Conta:${bankAccountBeneficiary.accountNumber} `
					},
					{
						title: 'Lançamento', field: 'accountingEntry',
						filtering: false,
						cellStyle: (_, { accountingEntry }) => (
							{
								color: accountingEntry === "TED Enviada" || accountingEntry === "PIX Enviado"
									? '#F50057' : '#00CD2D'
							})
					},
					{
						title: 'Valor da transferência', field: 'amount', filtering: false,
						render: ({ amount }) => Helper.formatCurrencyAsIs(amount)
					},
					{
						title: 'Status',
						field: 'statusDisplay',
						render: ({ statusDisplay }) => statusDisplay,
						//@ts-ignore
						cellStyle: (_, { statusDisplay }) => ({ color: HelperStatus.statusTheme(statusDisplay) }),
						filterComponent: (props) => <Select
							{...props}
							fullWidth
							//@ts-ignore
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
						>
							<MenuItem value={""}></MenuItem>
							{optionsStatusFiltering && optionsStatusFiltering.map(field => (
								<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
							))}
						</Select>,
					}
				]}
				actions={[
					(rowData) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "loupe",
							tooltip: 'Detalhes',
							position: 'row',							
							onClick: async () => {												
								setModalProps(
									{
										...defaultDetailsModalProps,
										title: 'Detalhes da Transferência',
										subtitle: '',
										children: <ApproversDetails data={rowData}/>
									}
								);
								handleOpen();									
							}
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "description",
							tooltip: 'Comprovante',
							position: 'row',
							hidden: rowData.statusDisplay !== "Efetivada",							
							onClick: () => handleTransferReceipt(bankAccountId, rowData.id)
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "thumb_up",
							tooltip: 'Aprovar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: async (_: React.FormEvent<HTMLFormElement>, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										enabledButton: false,
										onClick: () => handleApprove(rowData.id),
									}
								);
							}
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "thumb_down",
							tooltip: 'Reprovar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: async (_: React.FormEvent<HTMLFormElement>, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										title: 'Reprovar transferência',
										subtitle: 'Tem certeza que deseja  reprovar a transferência?',
										onClick: () => handleReject(rowData.id),
									}
								);

							}
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#ff0000' } },
							icon: "cancel",
							tooltip: 'Cancelar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: (_: React.FormEvent<HTMLFormElement>, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										title: 'Cancelar transferência',
										subtitle: 'Tem certeza que deseja cancelar a transferência?',
										onClick: () => handleCancel(rowData.id),
									}
								);
							}
						};
					}
				]}
				data={(allParams) =>
					new Promise((resolve, reject) => {
						const { page, pageSize, search, filters, orderBy, orderDirection } = allParams;

						const transferDate = filters.find(f => f.column.field === 'transferDate')?.value;

						const filterTransferDate = enableFilter &&
							transferDate &&
							moment(transferDate).isValid() &&
							// @ts-ignore
							moment(transferDate).set("hour", 0).set("minute", 0)?._d;

						const filtersValues = {
							f_transferDate: filterTransferDate,
							f_amount: enableFilter && filters.find(f => f.column.field === 'amount')?.value,
							f_beneficiaryName: filters.find(f => f.column.field === 'bankAccountBeneficiary.name')?.value,
							f_beneficiaryRegistrationNumber: enableFilter && filters.find(f => f.column.field === 'bankAccountBeneficiary.registrationNumber')?.value,
							f_status: enableFilter && enableFilter && filters.find(f => f.column.field === 'statusDisplay')?.value,
							page,
							size: pageSize,
							searchString: search,
							orderByField: orderBy?.field,
							orderByDirection: orderDirection,
							isDeleted: false
						};

						servicesTransfers.getTransfersList(bankAccountId, filtersValues).then(result => {

							if (result?.data) {
								return resolve({
									//@ts-ignore
									data: result.data,
									//@ts-ignore
									page: result.page,
									//@ts-ignore
									totalCount: result.totalItems,
								});
							} else {
								resolve({
									data: [],
									page: 0,
									totalCount: 0,
								});
							}
						}).catch((err) => {
							return reject(err.message);
						});
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]} />
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					filtering: enableFilter
				}} />
		</ContentContainer>
	);
};

export default TransferList;
