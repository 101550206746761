import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { validationSchema, defaultValues } from './validation';
import { ContentContainer, Toast, UploadTab } from 'components';
import * as legalPersonService from '../../../services/legalPerson';
import { AppBar, Tabs, Tab, Typography, Box, Grid, Divider } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from 'helpers/form-helpers';
import InformationsLegalPerson from '../FieldsForm/informationsLegalPerson';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import Datasets from '../RiskDatasets/datasets';
import { useDropzone } from 'react-dropzone';
import LegalCreditDatasets from '../LegalCreditDatasets';
import { OperationsTab } from 'components/OperationsTab/OperationsTab';
import { OperationsContainer } from 'components/OperationsTab/OperationsContainer';
import AssetTab from 'components/AssetTab/AssetsTab';
import BankAccountsTab from 'components/BankDataTab/BankAccountsTab';
import * as datasetService from '../../../services/dataset';
import { useHeader } from 'contexts/header';
import { useLoading } from 'contexts/loading';
import { useTenant } from 'contexts/tenant';
import ModalAssign from 'components/Assign/ModalAssign';

function LegalPersonEdit(props) {

	const history = useHistory();
	const { selectedTenant } = useTenant();
	const { loading, addLoader, completeLoader } = useLoading();
	const [person, setPerson] = useState();
	const [files, setFiles] = useState([]);
	const [tab, setTab] = useState('Informações');
	const [assets, setAssets] = useState([]);
	const { control, handleSubmit, setValue, reset, watch } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});
	const getLegalPersonId = () => props?.location?.search?.replace('?id=', '');
	const { errors } = useFormState({ control });
	const [bankAccounts, setBankAccounts] = useState([]);
	const { setTitle } = useHeader();
	const [datasetReportPdf, setDatasetReportPdf] = useState(null);
	const [modalAssign, setModalAssign] = useState(false);

	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });


	useEffect(() => {
		if (isEditing()) {
			setTitle("Pessoa Jurídica - editar cadastro");
			fetchLegalPersonInfoById();
		} else {
			setTitle("Pessoa Jurídica - novo cadastro");
		}
	}, []);

	useEffect(() => {
		if (person) {
			reset(person);
		}
	}, [person]);

	const legalPersonId = getLegalPersonId();
	useEffect(() => {
		if (legalPersonId) {
			datasetService.getDataSetReport('LegalPerson', legalPersonId, 'pdfreport')
				.then(response => {
					if (response?.data[0].result == 'Em Execução') {
						setDatasetReportPdf(null);
					}
					else if (response?.data[0]?.result) {
						setDatasetReportPdf(response?.data[0]);
					} else {
						console.log("Não foi possível obter o Relatório de Crédito");
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, []);

	const { isFieldRequired, inputBind, inputBindNumber, onChangeNumber } = getFormFunctions({ validationSchema, control, errors, setValue });

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const fetchLegalPersonInfoById = () => {
		addLoader("fetchLegalPersonInfoById");
		legalPersonService.getLegalPersonById(getLegalPersonId())
			.then(legalPerson => {
				completeLoader("fetchLegalPersonInfoById");
				setFiles(legalPerson?.data?.uploads || []);
				setPerson(legalPerson?.data);
				if (legalPerson?.data?.assets) setAssets(legalPerson?.data?.assets);
				if (legalPerson?.data?.bankAccounts) setBankAccounts(legalPerson?.data?.bankAccounts);
			}).catch(err => completeLoader("fetchLegalPersonInfoById"));
	};

	const saveLegalPerson = (values) => {

		addLoader("saveLegalPerson");
		const option = isEditing() ? 'edit' : 'create';
		values.uploads = files;
		values.assets = assets;
		values.bankAccounts = bankAccounts;
		legalPersonService.editOrCreateLegalPerson(values, option, selectedTenant)
			.then(result => {
				completeLoader("saveLegalPerson");
				if (result?.data) {
					history.push(`/pessoa-juridica/editar?id=${result.data}`);
				} else {
					fetchLegalPersonInfoById();
				}
			})
			.catch(error => {
				completeLoader("saveLegalPerson");
				console.log(error);
			});
	};

	const isEditing = () => {
		return !!getLegalPersonId();
	};

	const useScan3 = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCAN3;

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsLegalPerson {...{ setValue, inputBind, inputBindNumber, isFieldRequired, control, watch, errors }} />
		},		
		{
			name: 'Dados Bancários',
			show: true,
			children: <BankAccountsTab editable={true}  {...{ inputBind, inputBindNumber, isEditing, isFieldRequired, onChangeNumber, bankAccounts, setBankAccounts, errors }} />
		},
		{
			name: 'Documentos',
			show: true,
			children: <UploadTab files={files} setFiles={setFiles} getRootProps={getRootProps} getInputProps={getInputProps} />
		},
		{
			name: 'Risco',
			show: !!+window.__RUNTIME_CONFIG__.REACT_APP_RISK_TAB && isEditing(),
			children: isEditing() ?
				<Datasets
					setLoading={(isLoading) => { isLoading ? addLoader("Datasets") : completeLoader("Datasets"); }}
					loading={loading} legalPersonId={getLegalPersonId()} />
				: <Box p={3}><Typography variant="h6" gutterBottom>
					Salve o registro para ver os dados
				</Typography>
				</Box>
		},
		{
			name: 'Crédito',
			show: !!+window.__RUNTIME_CONFIG__.REACT_APP_CREDIT_TAB && !useScan3 && isEditing(),
			children:
				<LegalCreditDatasets {...{ person, setValue, setLoading: (isLoading) => isLoading ? addLoader("Crédito") : completeLoader("Crédito"), loading, inputBind, inputBindNumber, isFieldRequired, control, watch, personId: getLegalPersonId() }} />
		},
		{
			name: 'Operações',
			show: !useScan3 && isEditing(),
			children:
				<OperationsContainer personId={getLegalPersonId()} >
					<OperationsTab />
				</OperationsContainer>
		}
	];

	const onSubmit = (values) => {
		saveLegalPerson(values);
	};

	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
	};

	const handlePrint = () => {
		window.print();
	};

	const handleModalAssign = () => {
		setModalAssign(true);
	};

	const handleCloseModalAssign = () => {
		setModalAssign(false);
	};

	const actions = isEditing() ? {
		onAssign: handleModalAssign,
		onPrint: () => window.setTimeout(handlePrint, 10),
		disabledAll: loading,
		onReport: datasetReportPdf
	} : {
	};

	const style = { borderTopLeftRadius: 10, borderTopRightRadius: 6, borderBottomLeftRadius: 10, borderBottomRightRadius: 6, backgroundColor: 'white', padding: 10, margin: 1 };

	return (
		<ContentContainer>
			{modalAssign &&
				<ModalAssign recordType={"LegalPerson"} recordId={getLegalPersonId()} onCloseModal={handleCloseModalAssign} />
			}

			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<Grid container direction="row" spacing={4}>
					<Grid item xs={12} lg={6}>
						<ActionBar {...actions} />
					</Grid>
					{isEditing() &&
						<Grid item xs={12} lg={6} className="disablePrint">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{person?.createdBy && <small style={style}><strong>Criado em:</strong>&nbsp;{person?.createdAt && new Date(person?.createdAt).toLocaleDateString('pt-BR')}</small>}
								{person?.ownerUser && <small style={style}><strong>Operador:</strong>&nbsp;{person?.ownerUser?.userIdDisplay}</small>}
								{person?.ownerGroup && <small style={style}><strong>Grupo:</strong>&nbsp;{person?.ownerGroup?.groupDisplay}</small>}
							</div>
						</Grid>}
				</Grid>

				<AppBar position="relative" color="transparent" elevation={0}>
					<Tabs
						centered={false}
						value={tab}
						onChange={handleTabChange}
						textColor="#5F5F5F"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
						TabIndicatorProps={{ style: { background: "#5F5F5F" } }}
					>
						{tabs.map((item, index) => (
							item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
						))}
					</Tabs>
					<Divider style={{ backgroundColor: 'white', height: 2, borderRadius: 160, }} />
				</AppBar>
				{
					tabs.map((item, index) =>
						<TabPanel key={index} value={tab} index={item.name}>
							{item.children}
						</TabPanel>
					)
				}

			</form>
		</ContentContainer>
	);
}

export default LegalPersonEdit;
