import React, { useEffect, useState } from "react";
import { Container, Grid, IconButton, InputAdornment, makeStyles, OutlinedInput, Typography } from "@material-ui/core";
import PassCodeInput from "./passCodeInput";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IMFAState } from "global/interface/types";

const useStyles = makeStyles((theme) => ({
	span: {
		fontWeight: 700,
		textAlign: 'center',
		fontSize: 20
	},
	typography: {
		textAlign: 'center',
		color: "#5F5F5F",
		fontFamily: 'sans-serif',
		fontSize: 15
	},
}));

const HasPassCode = (props: { setFormData: (data: IMFAState) => void }) => {
	const classes = useStyles();
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});

	useEffect(() => {
		props.setFormData(modalFormData);
	}, [modalFormData]);

	const handleChange = (prop: keyof IMFAState) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setModalFormData({ ...modalFormData, [prop]: event?.target?.value });
	};

	const handleClickShowPassword = () => {
		return setModalFormData((prevState: IMFAState) => {
			return { ...prevState, "showPassword": !prevState.showPassword };
		});
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const style = { color: '#1e87f0' };
	return (
		<Container>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<Typography style={{ color: "#5F5F5F", paddingBottom: 10 }}>
						<strong>Senha</strong>
					</Typography>
					<OutlinedInput
						id="password"
						type={modalFormData.showPassword ? 'text' : 'password'}
						value={modalFormData.password}
						onChange={handleChange('password')}
						placeholder="Digite sua senha de acesso"
						endAdornment={
							<InputAdornment position="end" >
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{modalFormData.showPassword ? <Visibility style={style} /> : <VisibilityOff style={style} />}
								</IconButton>
							</InputAdornment>
						}
						fullWidth
					/>

				</Grid>
				<Grid item xs={12}>
					<div className={classes.span}>
						<span>CONFIRMAÇÃO DE CÓDIGO MFA</span>
						<Typography className={classes.typography}>
							Informe o código que está presente em seu aplicativo de MFA.
						</Typography>
					</div>
				</Grid>
				<Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} item xs={12}>
					<PassCodeInput
						onChange={(code: string) => {
							setModalFormData({ ...modalFormData, code: code });
						}} />
				</Grid>
			</Grid>
		</Container>
	);
};
export default HasPassCode;