import { string, object, addMethod, boolean, ref } from 'yup';
import Helper from 'helpers/schema.helpers';

addMethod(string, 'email', function () {
	return this.test({
		name: 'email',
		message: 'E-mail não é válido.',
		test: (value) => {
			return value ? Helper.isEmailValid(value) : true;
		}
	});
});

addMethod(string, 'customCPFValidator', function () {
	return this.test({
		name: 'cpfValidation',
		message: 'CPF não é válido.',
		test: (value) => {
			return value ? Helper.cpfValidate(value) : true;
		}
	});
});

export function getValidationSchema(isEditing) {
	return isEditing == false ? object().shape({
		email: string().typeError('E-mail: Precisa ser preenchido.').required('E-mail: Precisa ser preenchido.').email(),
		name: string().typeError('Nome: Precisa ser preenchido.').required('Nome completo é obrigátorio.'),
		registrationNumber: string().typeError('CPF: Precisa ser preenchido.').required("CPF é obrigátorio."),
		phoneNumber: string().typeError('Telefone: Precisa ser preenchido.').required("Telefone é obrigatório."),
		address: string().typeError('Endereço: Precisa ser preenchido.').required("Endereço é obrigatório."),
		sendNotifications: boolean(),
		password: string().typeError('Senha: Precisa ser preenchido.')
			.required('Senha: Precisa ser preenchido.').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"A senha deve conter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, caracteres especiais e número")
			.min(8, 'A senha é muito curta - deve ter no mínimo 8 caracteres.'),
		passwordConfirmation: string().typeError('Confirmar senha: Precisa ser preenchido.').required('Confirmar senha: Precisa ser preenchido.').oneOf([ref("password"), null], "A senha e a confirmação de senha não conferem"),
		emailVerified: boolean()
	}) :
		object().shape({
			email: string().typeError('E-mail: Precisa ser preenchido.').required('E-mail: Precisa ser preenchido.').email(),
			name: string().typeError('Nome: Precisa ser preenchido.').required('Nome: Precisa ser preenchido.'),
			registrationNumber: string().typeError('CPF: Precisa ser preenchido.').required("CPF: Precisa ser preenchido."),
			phoneNumber: string().typeError('Telefone: Precisa ser preenchido.').required("Telefone: Precisa ser preenchido."),
			address: string().typeError('Endereço: Precisa ser preenchido.').required("Endereço: Precisa ser preenchido."),
			sendNotifications: boolean(),
			emailVerified: boolean(),
		});
}

export const defaultValues = {
	email: null,
	emailVerified: false,
	name: null,
	registrationNumber: null,
	phoneNumber: null,
	address: null,
	sendNotifications: false,
	password: null,
	passwordConfirmation: null,
};