import { string, object, boolean, number } from 'yup';

const genericRequired = 'Esse campo é obrigatório.';

export const validationSchema = object().shape({
	rateExternalId: string().typeError('ID do pacote: Precisa ser preenchido.').nullable().transform((value, _) => {
		return value === "" ? null : value;
	}),
	name: string().typeError('Nome do Produto: Precisa ser preenchido.').required(genericRequired),
	templateDoc: object().when("useSignaturePortal", useSignaturePortal => {
		return useSignaturePortal == true ?
			object().shape({
				fileName: string().typeError('Modelo de contrato é obrigatório').required(genericRequired)
			}) :
			object().nullable().transform((value, _) => {
				return value.fileName === null ? null : value;
			});
	}),
	useSignaturePortal: boolean(),
	bankingAccountType: string().typeError('Selecione o tipo de conta.').required(genericRequired),
	signaturePortalProvider: string().when("useSignaturePortal", {
		is: true,
		then: string().typeError('Selecione a Certificadora.').required(genericRequired),
		otherwise: string().nullable()
	}),
	signatureType: string().when("useSignaturePortal", {
		is: true,
		then: string().typeError('Selecione a forma de envio para assinatura.').required(genericRequired),
		otherwise: string().nullable()
	}),
	selfieSignature: boolean(),
	viewType: string().typeError('Selecione a visibilidade do produto.').required(genericRequired),
	packageValue: number().typeError('Valor precisa ser um número.').required(genericRequired),
});

export const defaultValues = {
	rateExternalId: null,
	name: null,
	templateDoc: null,
	useSignaturePortal: false,
	bankingAccountType: "Payment",
	signaturePortalProvider: "RBM",
	signatureType: "email",
	selfieSignature: false,
	viewType: "BottomToTop",
	packageValue: 0
};