import { createContext, useContext, useState, useEffect } from 'react';
import { useTenant } from 'contexts/tenant';
import { useUser } from 'contexts/user';
import Helper from 'helpers/format.helpers';
import { getPersonsList } from 'services/person';
import { useMyPermissions } from './permissions';
import { IProps } from 'global/interface/types';

type GlobalPersonContent  = {
	personList: []
	personOptionsList: []
	setPersonList: () => void
	getPersonsList: (page: number, size: number, search: string) => void
};

const PersonContext = createContext<GlobalPersonContent>({ personList: [], setPersonList: () => { }, getPersonsList: () => { }, personOptionsList: [] });

export const PersonProvider = ({ children }: IProps) => {

	//@ts-ignore
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';
	const [personList, setPersonList] = useState<[]>([]);
	const [personOptionsList, setPersonOptionsList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: "" });
	const { selectedTenant } = useTenant();
	const { user } = useUser();
	const { getMyPermissionLevel } = useMyPermissions();

	const hasPerm: boolean = !!getMyPermissionLevel("Person", "read");

	useEffect(() => {
		if (!user?.authenticated || !hasPerm) return;
		searchPersons(searchArgs.page, searchArgs.size, searchArgs.search, selectedTenant);
	}, [selectedTenant, searchArgs, user]);

	const searchPersons = (page: number, size: number, searchString: string, selectedTenant: string | undefined) => {
		return getPersonsList({ page, size, searchString }, selectedTenant).then(response => {
			setPersonList(response?.data.map((p: { name: string; id: string; }) => {
				return { name: p.name, value: p.id };
			}));
			setPersonOptionsList(response?.data.map((n: { name: string; tenantDisplay: string; registrationNumber: any; id: string; discriminator: string; }) => {
				return { name: isRootTenancy ? `${n.name || "Não informado"} (${n.tenantDisplay}) - (${Helper.formatDocumentNumber(n.registrationNumber)})` : n.name, value: n.id, discriminator: n.discriminator };
			}));
		});
	};

	const contextualGetPersonsList = (page: number, size: number, search: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || search != searchArgs?.search) {
			setSearchArgs({ page, size, search });
		}
	};

	return (
		<PersonContext.Provider
			value={{
				personList: personList,
				setPersonList: () => setPersonList,
				getPersonsList: (page: number, size: number, search: string) => {
					return contextualGetPersonsList(page, size, search);
				},
				personOptionsList: personOptionsList
			}}
		>
			{children}
		</PersonContext.Provider>
	);
};

export function usePerson() {
	const context = useContext(PersonContext);
	const {
		personList,
		setPersonList,
		getPersonsList,
		personOptionsList
	} = context;
	return {
		personList,
		setPersonList,
		getPersonsList,
		personOptionsList
	};
}
