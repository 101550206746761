import React, { useEffect, useState } from 'react';
import * as datasetService from '../../../services/dataset';
import Accordions from 'components/Accordion/Accordion';
import {
	Toast
} from 'components';
import DataSetMasterContainer from 'containers/DataSetMasterContainer';
import TableMotorCredit from '../../../components/Datasets/TableMotorCredit';
import NaturalCreditDatasets from 'pages/NaturalPerson/NaturalCreditDatasets';
import LegalCreditDatasets from 'pages/LegalPerson/LegalCreditDatasets';
import { Box, Typography } from '@material-ui/core';
import { uniqBy } from 'lodash';

function CreditNoteDatasets(props) {

	const useCreditAnalysis = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_CREDIT_ANALYSIS_DATASET;	
	
	const { creditNoteId, setLoading, involvedPersons, taker, loading } = props;
	const [datasetMotorCredit, setDatasetMotorCredit] = useState(null);

	var naturalPersons = involvedPersons?.filter(i => i.discriminator === 'NaturalPerson');
	var legalPersons = involvedPersons?.filter(i => i.discriminator === 'LegalPerson');

	const takerIsLegalPerson = taker.personDiscriminator == 'LegalPerson';

	var takerObj = { personId: taker.personId, personIdDisplay: taker.personIdDisplay };

	if (takerIsLegalPerson) {
		legalPersons.push(takerObj);
		legalPersons = uniqBy(legalPersons, obj => obj.personId);
	} else {
		naturalPersons.push(takerObj);
		naturalPersons = uniqBy(naturalPersons, obj => obj.personId);
	}

	const getMotorCredit = () => {
		setLoading(true);
		datasetService.getDataSetReport('CreditNote', creditNoteId, 'creditanalysis').then(response => {
			if (response?.data[0]?.result) return setDatasetMotorCredit(response?.data[0]), setLoading(false);
			return setLoading(false), false;
		});
	};

	useEffect(() => {
		getMotorCredit();
	}, []);

	const generateDatasetMotorCredit = () => {
		Toast.showInfoMessage("Motor de crédito foi solicitado");
		if (datasetMotorCredit) {
			return getMotorCredit(),
			setLoading(false),
			Toast.showSuccessMessage("Motor de crédito gerado");
		} else {
			datasetService.fetchDataSetReport('CreditNote', creditNoteId, 'creditanalysis').then(response => {
				if (response?.data[0]?.status === 'OK') return getMotorCredit(), setLoading(false), Toast.showSuccessMessage("Dataset gerado");
				return Toast.showErrorMessage("Não foi possível obter os dados"),
				setLoading(false);
			});
		}
	};

	return (
		<Box py={2}>
			{useCreditAnalysis && <><Typography variant="h6">Operação</Typography>
				<Accordions accordions={[{
					name: 'Motor de Crédito',
					value: 'mot_cred',
					children:
					<DataSetMasterContainer onClickUpdateData={() => generateDatasetMotorCredit()} dataSet={datasetMotorCredit}
						children={<TableMotorCredit rows={datasetMotorCredit} />} >
					</DataSetMasterContainer>,
					key: 'mot_cred'
				}]} /></>}

			{legalPersons?.length > 0 && <><br /><Typography variant="h6">Pessoas Jurídicas</Typography></>}
			{legalPersons?.map(person => {				
				return (
					<Accordions accordions={[{
						name: person.personIdDisplay,
						value: person.personId,
						children: <LegalCreditDatasets {...{ loading, setLoading }} personId={person.personId} person={person} />,
						key: person.personId
					}]} />
				);
			})}
			{naturalPersons?.length > 0 && <><br /><Typography variant="h6">Pessoas Físicas</Typography></>}
			{naturalPersons?.map(person => {
				return (
					<Accordions accordions={[{
						name: person.personIdDisplay,
						value: person.personId,
						children: <NaturalCreditDatasets {...{ loading, setLoading, person }} personId={person.personId} />,
						key: person.personId
					}]} />

				);
			})}
		</Box>
	);
}

export default CreditNoteDatasets;