
export const optionsModality = [
	{
		name: 'Conta Corrente de Pessoa Física',
		value: "NaturalCheckingAccount",
	},
	{
		name: 'Conta Simples de Pessoa Física',
		value: "NaturalSimpleAccount",
	},
	{
		name: 'Conta Corrente de Pessoa Jurídica',
		value: "LegalCheckingAccount",
	},
	{
		name: 'Entidades Públicas',
		value: "PublicEntities",
	},
	{
		name: 'Depósitos Instituições Financeiras',
		value: "FinancialInstitutionsDeposits",
	},
	{
		name: 'Poupança de Pessoa Física',
		value: "NaturalSavingsAccount",
	},
	{
		name: 'Poupança de Pessoa Jurídica',
		value: "LegalSavingsAccount",
	},
	{
		name: 'Poupança de Crédito Imobiliário',
		value: "RealEstateSavingsAccount",
	},
	{
		name: 'Depósitos Lotéricos',
		value: "LotteryDeposits",
	},
	{
		name: 'Conta Escrow',
		value: "EscrowAccount",
	},
];
