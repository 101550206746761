import React, { useEffect, useState } from 'react';
import { MenuItem, Select, SelectProps, TablePagination } from "@material-ui/core";
import * as servicePay from "services/digitalBankAccount";
import { ContentContainer, Toast } from 'components';
import { useParams } from 'react-router';
import { ModalProps, PaymentType, RouteWithIdParamsType } from 'global/types/types';
import HelperStatus from 'helpers/message.helpers';
import Helper from 'helpers/format.helpers';
import { localizationOptions } from "helpers/table.helpers";
import MaterialTable from 'material-table';
import moment from 'moment';
import HasPassCode from '../../Code/codeForm';
import { useUser } from 'contexts/user';
import { ParamsApproveOrReject, ParamsCancel } from 'global/types/digitalAccount.types';
import Button from 'components/Button/Button';
import FilterListIcon from "@material-ui/icons/FilterList";
import { optionsStatusFiltering } from '../../OptionsFields/options.status';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { IMFAState } from 'global/interface/types';
import ApproversDetails from 'components/ApproversDetails/ApproversDetails';

//@ts-ignore
const PaymentList = (props) => {
	const { setModalProps, handleOpen, handleClose, tableRef } = props;
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const [rowsPerPage, setRowsPerPage] = useState<number>(Number(localStorage.getItem('rowsPerPage')) || 5);
	const [enableFilter, setEnableFilter] = useState(false);
	const { user } = useUser();
	const [username, setUsername] = useState("");
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	const stateRef = React.useRef<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	stateRef.current = modalFormData;
	const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
	const cognitoClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID;

	const onRowsPerPageChange = (page: number) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', String(page));
	};

	const defaultDetailsModalProps = {
		enableButton: false,
	} as ModalProps;

	const defaultModalProps = {
		title: 'Aprovar pagamento',
		subtitle: 'Tem certeza que deseja aprovar o pagamento?',
		onClick: () => { },
		buttonText: "Confirmar",
		children: <HasPassCode setFormData={(data: IMFAState) => { setModalFormData(data); }} />
	} as ModalProps;

	useEffect(() => {
		//@ts-ignore
		if (user?.authenticated == true) setUsername(user['payload']['cognito:username']);
	}, [user]);

	const handleApprove = async (paymentId: string) => {
		let modalData = stateRef.current;
		await servicePay.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				payload: {
					action: "Approve", sessionId: sessionId, code: modalData.code
				},
				paymentId,
				path: "Pay"
			} as unknown as ParamsApproveOrReject;

			servicePay.approveOrReject(data).then(() => Toast.showSuccessMessage("Pagamento aprovado com sucesso!"))
				.finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	const handleReject = async (paymentId: string) => {
		let modalData = stateRef.current;
		await servicePay.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				payload: {
					action: "Reject", sessionId: sessionId, code: modalData.code
				},
				paymentId,
				path: "Pay"
			} as unknown as ParamsApproveOrReject;

			servicePay.approveOrReject(data).then(() => Toast.showSuccessMessage("Pagamento reprovado com sucesso!"))
				.finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};


	const handleCancel = async (beneficiaryId: string) => {
		let modalData = stateRef.current;
		await servicePay.generateSessionId(username, modalData.password, userPoolId, cognitoClientId).then((sessionId) => {
			const data = {
				bankAccountId,
				beneficiaryId,
				sessionId,
				passCode: modalData.code,
				path: "Pay"
			} as unknown as ParamsCancel;

			servicePay.cancel(data).then(() => {
				Toast.showSuccessMessage("Pagamento cancelado com sucesso!");
				servicePay.getBeneficiariesList(bankAccountId, { page: 0, size: 10, isDeleted: false });
			}).finally(() => { tableRef.current.onQueryChange(); handleClose(); });
		});
	};

	const handlePaymentReceipt = async (bankAccountId: string, beneficiaryId: string) => {
		await servicePay.postPaymentreceipt(bankAccountId, beneficiaryId)
			.then((result) => {
				const file = new Blob(
					[result],
					{ type: 'application/pdf' });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			}).catch((error) => console.log(error));
	};


	return (
		<ContentContainer>
			<MaterialTable
				style={{ paddingLeft: 20, paddingRight: 20, maxWidth: '91vw' }}
				tableRef={tableRef}
				title={
					<Button
						variant="outlined"
						color="default"
						onClick={() => setEnableFilter(!enableFilter)}
						children={"Pagamentos"}
						startIcon={<FilterListIcon />}
					/>
				}
				columns={[
					{
						title: 'Data do pagamento', field: 'date',
						render: ({ date }) => date && new Date(date).toLocaleDateString('pt-BR'),
						filterComponent: (props: any) => <KeyboardDatePicker
							{...props}
							format="dd/MM/yyyy"
							InputLabelProps={{ shrink: true }}
							placeholder="dd/mm/aaaa"
							variant="inline"
							value={props?.columnDef?.tableData?.filterValue || null}
							disableFuture={true}
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e)}
							helperText={false}
						/>
					},
					{ title: 'Valor do pagamento', field: 'amount', render: ({ amount }: PaymentType) => Helper.formatCurrencyAsIs(amount) },
					{
						title: 'Status',
						field: 'statusDisplay',
						render: ({ statusDisplay }: PaymentType) => statusDisplay,
						cellStyle: (_, { statusDisplay }: PaymentType) => ({ color: `${HelperStatus.statusTheme(statusDisplay)}` }),
						//@ts-ignore
						filterComponent: (props: JSX.IntrinsicAttributes & SelectProps) => <Select
							{...props}
							fullWidth
							//@ts-ignore
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
						>
							<MenuItem value={""}>Todos</MenuItem>
							{optionsStatusFiltering && optionsStatusFiltering.map(field => (
								<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
							))}
						</Select>
					}
				]}
				actions={[
					(rowData : any) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "loupe",
							tooltip: 'Detalhes',
							position: 'row',							
							onClick: async () => {												
								setModalProps(
									{
										...defaultDetailsModalProps,
										title: 'Detalhes da Aprovação',
										subtitle: '',
										children: <ApproversDetails data={rowData}/>
									}
								);
								handleOpen();									
							}
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "description",
							tooltip: 'Comprovante',
							position: 'row',
							hidden: rowData.statusDisplay !== "Efetivada",
							onClick: () => handlePaymentReceipt(bankAccountId, rowData.id)
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "thumb_up",
							tooltip: 'Aprovar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: (_, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										onClick: () => handleApprove(rowData.id),
									}
								);
							}
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#5F5F5F' } },
							icon: "thumb_down",
							tooltip: 'Reprovar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: (_, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										title: 'Reprovar pagamento',
										subtitle: 'Tem certeza que deseja  reprovar o pagamento?',
										onClick: () => handleReject(rowData.id),
									}
								);

							}
						};
					},
					(rowData) => {
						return {
							iconProps: { style: { color: '#ff0000' } },
							icon: "cancel",
							tooltip: 'Cancelar',
							position: 'row',
							hidden: rowData.statusDisplay !== "Aguardando aprovação",
							onClick: (_, rowData: any) => {
								handleOpen();
								setModalProps(
									{
										...defaultModalProps,
										title: 'Cancelar pagamento',
										subtitle: 'Tem certeza que deseja cancelar o pagamento?',
										onClick: () => handleCancel(rowData.id),
									}
								);
							}
						};
					}
				]}
				data={(allParams) =>
					new Promise((resolve, reject) => {
						const { page, pageSize, filters, orderBy, orderDirection } = allParams;
						const paymentDate = filters.find(f => f.column.field === 'date')?.value;

						const filterPaymentDate = enableFilter &&
							paymentDate &&
							moment(paymentDate).isValid() &&
							// @ts-ignore
							moment(paymentDate).set("hour", 0).set("minute", 0)?._d;

						const filtersValues = {
							f_paymentDate: filterPaymentDate,
							f_amount: filters.find(f => f.column.field === 'amount')?.value,
							f_status: enableFilter && enableFilter && filters.find(f => f.column.field === 'statusDisplay')?.value,
							page,
							size: pageSize,
							orderByField: orderBy?.field,
							orderByDirection: orderDirection,
							isDeleted: false
						};

						servicePay.getPaymentsList(bankAccountId, filtersValues).then(result => {
							if (result?.data) 
								resolve({
									data: result.data,
									page: result.page,
									totalCount: result.totalItems,
								});
							else {
								resolve({
									data: [],
									page: 0,
									totalCount: 0,
								});
							}
						}).catch(err => reject(err));
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]} />
					),
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					search: false,
					filtering: enableFilter
				}} />
		</ContentContainer>
	);
};

export default PaymentList;
