import axios, { AxiosError } from 'axios';
import { ApiConfig } from '../config';
import { getToken } from "../repository/cognito";
import { Toast } from '../components';

type TenantResponseType = {
	id: string;
	name: string;
}[]

export async function getTenantList(page: number, size: number, search: string | undefined, tenant: string | undefined) {

	const token = await getToken();
	let url = ApiConfig.baseURL + `/Tenant`;
	if (tenant) url = url + "?tenant=" + tenant;
	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params: {
			page, size, searchString: search
		}
	};

	try {
		const response = await axios.get<TenantResponseType>(url, config);
		return response;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de parceiros");
		}
		throw err;
	}
}