import React, { PureComponent } from 'react';

import {
	InputWithLabel,
	ContentContainer,
	PrimaryButton,
	DangerAlert,
	Toast
} from 'components';
import {
	changePassword
} from '../../../services/user';
import { CognitoErrorType } from 'global/enums';
import Helper from 'helpers/message.helpers';

class ChangePassword extends PureComponent {
	state = {
		errorMessage: '',
		oldPassword: '',
		newPassword: '',
		newPasswordConfirmation: ''
	};

	changeFieldValue = (name, value) => this.setState({ ...this.state, [name]: value });

	changePassword() {
		const errorMessage = this.validatePasswordInput();
		this.changeFieldValue('errorMessage', errorMessage);
		if (errorMessage) {
			return;
		}

		
		const { oldPassword, newPassword } = this.state;
		
		changePassword(oldPassword, newPassword)
			.then(result => {
				Toast.showSuccessMessage('Senha alterada com sucesso');
			}).catch(error => {
				const errorMessage = this.extractErrorFromError(error);
				Toast.showErrorMessage(errorMessage);
			});
	}

	extractErrorFromError(error) {
		console.log('extractErrorFromError', error);
		if (error) {
			if (error.code === CognitoErrorType.NOT_AUTHORIZED) {
				return 'A senha antiga informada é inválida';
			}

			const isInvalidPasswordException = error.code === 'InvalidPasswordException';
			
			const cognitoErrorMessage = !isInvalidPasswordException ? 
				Helper.codeToMessage(error.code) : Helper.invalidPasswordMessage(error.message);
			if (cognitoErrorMessage) {
				return cognitoErrorMessage;
			}
		}
		return 'Não foi possível alterar a senha. Por favor, tente novamente';
	}

	validatePasswordInput() {
		const { oldPassword, newPassword, newPasswordConfirmation } = this.state;
		if (oldPassword.length < 6) {
			return 'A senha atual deve ter no mínimo 6 caracteres.';
		}

		if (newPassword.length < 6) {
			return 'A nova senha deve ter no mínimo 6 caracteres.';
		}

		if (newPassword !== newPasswordConfirmation) {
			return 'A nova senha e a confirmação não conferem.';
		}

		return null;
	}

	render() {
		const { oldPassword, newPassword, newPasswordConfirmation, errorMessage } = this.state;
		const { changePasswordStatus } = this.props;

		return (
			<ContentContainer>
				<div className='uk-grid-small uk-child-width-1-1@s uk-position-relative' data-uk-grid>					
					<div className='uk-form-controls uk-remove-margin-top'>
						<InputWithLabel label='Senha Atual'
							placeholder='Sua senha atual'
							type='password'
							value={oldPassword}
							onChange={event => this.changeFieldValue('oldPassword', event.target.value)} />
					</div>
					<div>
						<InputWithLabel label='Nova Senha'
							placeholder='Sua nova senha deve ter no mínimo 6 caracteres'
							type='password'
							value={newPassword}
							onChange={event => this.changeFieldValue('newPassword', event.target.value)} />
					</div>
					<div>
						<InputWithLabel label='Confirmação da Senha'
							placeholder='Confirme sua nova senha de no mínimo 6 caracteres'
							type='password'
							value={newPasswordConfirmation}
							onChange={event => this.changeFieldValue('newPasswordConfirmation', event.target.value)} />
					</div>
					{ errorMessage && <DangerAlert msg={errorMessage} /> }
					<div className='uk-width-1-1 uk-text-right'>
						<PrimaryButton
							disabled={!oldPassword.length || !newPassword.length || !newPasswordConfirmation.length}
							onClick={this.changePassword.bind(this)}>
							Alterar senha
						</PrimaryButton>
					</div>
				</div>
			</ContentContainer>
		);
	}
}

export default ChangePassword;