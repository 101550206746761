import React, { useEffect, useState } from 'react';
import * as datasetService from '../../../services/dataset';
import Accordions from 'components/Accordion/Accordion';
import {
	AddressesExtended,
	BasicDataNaturalPerson,
	Collections,
	EmailAge,
	FinancialData,
	Kyc,
	OccupationData,
	OnDemandPgfn,
	OnDemandQueryPesquisaProtesto,
	OndemandPfAntecedente,
	OnlinePresence,
	Passages,
	PhonesExtended,
	Processes,
	ProfessionData,
	RelatedPeoplePhones,
	ScoreClearSale,
	SocialAssistance
} from 'components/Datasets';

function Datasets(props) {

	const { setLoading, loading } = props;
	const [internalLoading, setInternalLoading] = useState(false);
	const [datasets, setDatasets] = useState();

	useEffect(() => {
		getDatasetsByUserId();
	}, []);

	const generateDataset = (dataset) => {
		setInternalLoading(true);
		datasetService.addDataSet('NaturalPerson', getNaturalPersonId(), dataset).then(response => {
			if (response.data) {
				const datasetName = response.data[0]?.dataSet;
				const newDatasets = [...datasets];
				const index = newDatasets?.map(f => f.dataSet).indexOf(datasetName);
				if (index !== -1) newDatasets[index] = response.data[0];
				else newDatasets.push(response.data[0]);
				setDatasets(newDatasets);
				getDatasetsByUserId();
			}
			setInternalLoading(false);

		}).catch(err => {
			setInternalLoading(false);
		});
	};

	const getNaturalPersonId = () => props.naturalPersonId;

	const getDatasetsByUserId = () => {
		setLoading(true);
		datasetService.getDatasetsByUserId('NaturalPerson', getNaturalPersonId()).then(response => {
			setDatasets(response?.data);
			setLoading(false);
		});

	};

	const findProperDataset = (datasetName) => datasets?.find(dataset => dataset?.dataSet == datasetName);

	const datasetProps = {
		disabled: loading,
		addDataset: generateDataset,
		loading: internalLoading,
	};

	const useScan3 = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCAN3;

	const accordionData = [
		{
			name: 'Consulta de CPF',
			key: 'personal_basic_data',
			value: 'PersonalBasicData',
			children: <BasicDataNaturalPerson data={findProperDataset('personal_basic_data')} value='PersonalBasicData' {...datasetProps} />
		},
		{
			name: 'Consulta de Email',
			key: 'email_reputation',
			value: 'EmailReputation',
			children: <EmailAge data={findProperDataset('email_reputation')} value='EmailReputation' {...datasetProps} />
		},
		{
			name: 'Score de Risco',
			key: 'consumer_risk_score',
			value: 'ConsumerRiskScore',
			children: <ScoreClearSale data={findProperDataset('consumer_risk_score')} value='ConsumerRiskScore' {...datasetProps} />
		},
		{
			name: 'Endereços',
			value: 'AddressesPersonal',
			key: 'addresses_personal',
			children: <AddressesExtended data={findProperDataset('addresses_personal')} value="AddressesPersonal" {...datasetProps} />
		},
		{
			name: 'Atividade Empresarial e Profissional',
			value: 'ProfessionalData',
			key: 'professional_data',
			children: <OccupationData data={findProperDataset('professional_data')} value="ProfessionalData" {...datasetProps} />
		},
		{
			name: 'Menções na web',
			key: 'WebMentions',
			value: 'web_mentions',
			children: <Passages data={findProperDataset('web_mentions')} value="WebMentions" {...datasetProps} />
		},
		{
			name: 'Informações Financeiras',
			key: 'finance_data',
			value: 'FinanceData',
			children: <FinancialData data={findProperDataset('finance_data')} value="FinanceData" {...datasetProps} />,
		},
		{
			name: 'Presença online',
			key: 'online_negotiation',
			value: 'OnlineNegotiation',
			children: <OnlinePresence data={findProperDataset('online_negotiation')} value="OnlineNegotiation" {...datasetProps} />
		},
		{
			name: 'Presença em cobrança',
			key: 'charging',
			value: 'Charging',
			children: <Collections data={findProperDataset('charging')} value="Charging" {...datasetProps} />
		},
		{
			name: 'Programas de benefícios e Assistência Social',
			key: 'social_benefit',
			value: 'SocialBenefit',
			children: <SocialAssistance data={findProperDataset('social_benefit')} value="SocialBenefit" {...datasetProps} />
		},
		{
			name: 'Serviços públicos',
			key: 'public_jobs',
			value: 'PublicJobs',
			children: <ProfessionData data={findProperDataset('public_jobs')} value="PublicJobs" {...datasetProps} />
		},
		{
			name: 'Telefones',
			key: 'phones_list',
			values: 'PhonesList',
			children: <PhonesExtended data={findProperDataset('phones_list')} value="PhonesList" {...datasetProps} />
		},
		!useScan3 ? {
			name: 'Telefones de relacionados',
			key:'related_people_phones_list',
			value:'RelatedPeoplePhonesList',
			children:<RelatedPeoplePhones data={findProperDataset('related_people_phones_list') } value="RelatedPeoplePhonesList" {...datasetProps}/>,
		}: null,
		{
			name: 'Certidão Antecedentes Criminais - Polícia Federal',
			value: 'PfAntecedente',
			key: 'pf_antecedente',
			children: <OndemandPfAntecedente data={findProperDataset('pf_antecedente')} value="PfAntecedente" {...datasetProps} />
		},
		{
			name: 'Protestos',
			key: 'pesquisaprotesto',
			value: 'Pesquisaprotesto',
			children: <OnDemandQueryPesquisaProtesto data={findProperDataset('pesquisaprotesto')} value="Pesquisaprotesto" {...datasetProps} />
		},
		{
			name: 'KYC e Compliance',
			value: "KycData",
			key: 'kyc_data',
			children: <Kyc data={findProperDataset('kyc_data')} value="KycData" {...datasetProps} />
		},
		{
			name: 'Processos Judiciais e Administrativos',
			value: 'JudicialProcesses',
			key: 'judicial_processes',
			children: <Processes data={findProperDataset('judicial_processes')} value="JudicialProcesses" {...datasetProps} />
		},
		{
			name: 'Procuradoria Geral da Fazenda Nacional',
			value: 'Pgfn',
			key: 'pgfn',
			children: <OnDemandPgfn data={findProperDataset('pgfn')} value="Pgfn" {...datasetProps} />
		}
	].filter(i => i != null);;

	return (
		<div>
			<br />
			<Accordions accordions={accordionData} />
		</div>
	);
}

export default Datasets;