import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';

export async function createAmortizationSimulation(data) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/Amortization`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const payload = {
		...data,
		personId: data.personId,
		registrationNumber: data?.registrationNumber?.replaceAll(".", "").replaceAll("-",""),
		amortization: {
			...data.amortization,
			indexerValue: data.amortization.indexerValue / 100,
			unitPrice: data.amortization.unitPrice * 100,
			administrationFeeIncents: data.amortization.administrationFeeIncents * 100,
			realStatevalue: data.amortization.realStatevalue * 100,
			physicalDamageInsurance: data.amortization.physicalDamageInsurance / 100,
			deathOrPermanentIncapabilityInsurance: data.amortization.deathOrPermanentIncapabilityInsurance / 100,
			requestedAmount: data.amortization.requestedAmount * 100, // from currency to cents					
			apr: data.amortization.apr / 100, // from pct to decimal 		
			agentCommission: {
				amount: data?.amortization?.agentCommission?.type == 'Percentage' ? (data?.amortization?.agentCommission.amount ?? 0) / 100 : (data?.amortization?.agentCommission.amount ?? 0) * 100,
				type: data?.amortization?.agentCommission?.type,
				baseValue: data?.amortization?.agentCommission?.baseValue
			},
			fixedPaymentDuringGraduation: data.amortization.fixedPaymentDuringGraduation * 100
		},
	};

	try {
		return await axios.post(url, payload, config);
	}
	catch (err) {
		if (err.response) {
			if (err.response?.data.message) {
				const message = err.response.data.message + '</br>' + err?.response?.data?.errors.map(err => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message, err);
			} else if (err.response?.data?.errors) {
				Toast.showErrorMessage(err?.response.data?.errors);
			}
		} else {
			return Toast.showErrorMessage("Não foi possível obter a simulação", err);
		}
		throw err;

	}
}

export async function getAmortizationSimulation(id) {

	if (!id) {
		throw new Error('id not provided');
	}

	var token = await getToken();
	var url = ApiConfig.baseURL + `/Amortization/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	try {
		return axios.get(url, config).then(({ data }) => {
			if (data) {
				data.amortization.requestedAmount = data?.amortization.requestedAmount / 100;
				data.amortization.unitPrice = data?.amortization.unitPrice / 100;
				data.amortization.administrationFeeIncents = data?.amortization.administrationFeeIncents / 100;
				data.amortization.realStatevalue = data?.amortization.realStatevalue / 100;
				data.amortization.physicalDamageInsurance = data?.amortization.physicalDamageInsurance * 100;
				data.amortization.deathOrPermanentIncapabilityInsurance = data?.amortization.deathOrPermanentIncapabilityInsurance * 100;
				data.amortization.apr = data?.amortization.apr * 100;
				data.amortization.indexerValue = data?.amortization.indexerValue * 100;

				if (data?.agentCommission?.amount && data?.agentCommission?.type == "Percentage") {
					data.amortization.agentCommission = {
						type: "Percentage",
						amount: data?.agentCommission?.amount * 100,
						baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					};
				}
				if (data?.agentCommission?.amount && data?.amortization?.agentCommission?.type == "Absolute") {
					data.agentCommission = {
						type: "Absolute",
						amount: data?.agentCommission?.amount / 100,
						baseValue: window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_AGENT_COMMISSION_BASE_VALUE
					};
				}
			}
			return { data };
		});
	}
	catch (err) {
		if (typeof (err?.response?.data?.errors) == "string") {
			return Toast.showErrorMessage(err?.response?.data?.errors, err);
		}
		if (typeof (err?.response?.data?.errors) == "object" && err?.response?.data?.errors.length) {
			return Toast.showErrorMessage(err?.response?.data?.errors, err);
		}
		else {
			return Toast.showErrorMessage("Não foi possível obter a simulação", err);
		}
	}
}