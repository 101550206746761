import { IProps } from 'global/interface/types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import * as productAccountService from 'services/bankAccountProduct';
import { useMyPermissions } from './permissions';
import { useTenant } from './tenant';

type GlobalProductAccountContent = {
    productAccountOptionList: []
    setProductAccountOptionList: () => void
    getProductAccountList: (page: number, size: number, search: any, searchName: string) => void,
    getProductAccountOptionById: () => void
};

const ProductAccountContext = createContext<GlobalProductAccountContent>({ productAccountOptionList: [], setProductAccountOptionList: () => { }, getProductAccountList: () => { }, getProductAccountOptionById: () => { } });

export const ProductAccountProvider = ({ children }: IProps) => {
	const [productAccountOptionList, setProductAccountOptionList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: undefined, searchName: "" });
	const { selectedTenant } = useTenant();
	const { getMyPermissionLevel } = useMyPermissions();

	const hasPerm: boolean = !!getMyPermissionLevel("BankAccountProduct", "read");
	
	useEffect(() => {
		if (!hasPerm) return;
		searchProductAccount(searchArgs.page, searchArgs.size, searchArgs.search, searchArgs.searchName, selectedTenant);
	}, [searchArgs, selectedTenant]);

	const getProductAccountOptionById = (id: string) => {
		return new Promise((resolve, reject) => {
			//@ts-ignore
			var productAccount = productAccountOptionList.find(p => p.value == id);
			if (productAccount)
				resolve(productAccount);
			//@ts-ignore
			productAccountService.getBankAccountProductById(id, selectedTenant).then(async response => {
				if (response) {
					resolve({
						packageValues: response.packageValues,
					});
				}
			}).catch(err => {
				resolve({
					packageValues: 0,
				});
			});;

		});
	};

	function searchProductAccount(page: number, size: number, search: undefined, searchName: string, selectedTenant: any) {
		return productAccountService.getBankAccountProductList({ page, size, searchString: search, name: searchName }, selectedTenant).then(response => {
			setProductAccountOptionList(response?.data);
		});
	};

	const contextGetProductAccountList = (page: number, size: number, search: any, searchName: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || search != searchArgs?.search) {
			setSearchArgs({ page, size, search, searchName });
		}
	};

	return (
		<ProductAccountContext.Provider value={{
			productAccountOptionList: productAccountOptionList,
			setProductAccountOptionList: () => setProductAccountOptionList,
			getProductAccountList: (page: number, size: number, search: any, searchName: string) => {
				return contextGetProductAccountList(page, size, search, searchName);
			},
			getProductAccountOptionById: () => getProductAccountOptionById
		}}>
			{children}
		</ProductAccountContext.Provider>
	);
};

export function useProductAccount() {
	const context = useContext(ProductAccountContext);
	const {
		productAccountOptionList,
		getProductAccountList,
		getProductAccountOptionById
	} = context;
	return {
		productAccountOptionList,
		getProductAccountList,
		getProductAccountOptionById
	};
}
