import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { Select } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState } from 'react-hook-form';
import { validationApproveLiquidationSchema, defaultValuesApproveLiquidatiom } from './Schema/ModalApproveLiquidationSchema';
import CheckboxComponent from "components/CheckBox/Checkbox";
import Button from 'components/Button/Button';
import { approveliquidCreditNoteById, getCosifAccountTransaction } from 'services/creditNote';
import { Alert, AlertTitle } from '@material-ui/lab';

export const ModalApproveLiquidation = ({ approveliquidCreditNote, isLoading }) => {
	const [cosifData, setCosifData] = useState();
	const { handleSubmit, setValue, control, formState: { errors } } = useForm({
		resolver: yupResolver(validationApproveLiquidationSchema),
		defaultValues: defaultValuesApproveLiquidatiom
	});

	useEffect(() => {
		getCosifAccountTransaction().then((result) => {
			setCosifData(result.data);
		});
	}, []);

	function onSubmit(values) {
		const payload = {
			CosifAccountCode: values?.cosifAccountCode,
			IsAutomaticBankTransfer: values?.isAutomaticBankTransfer
		};
		approveliquidCreditNote(payload);
	}

	function onErrors(values) { console.error(values); };

	const changeCheckBoxValue = (event) => {
		const value = event.target.checked;
		const name = event.target.name;
		setValue(name, value);
	};

	const options = cosifData?.map(item => {
		return { name: item?.description, value: item?.code };
	});

	return (
		<form onSubmit={handleSubmit(onSubmit, onErrors)}>
			<Grid spacing={2} style={{ marginBottom: '20px' }}>
				<Grid item >
					<Select
						id="cosifAccountCode"
						name="cosifAccountCode"
						label="Conta de débito"
						required
						disabled={cosifData === undefined}
						errors={errors.cosifAccountCode}
						defaultValue=""
						control={control}
						fields={options}
					/>
				</Grid>
				<Grid item style={{ marginTop: '20px' }}>
					<FormGroup>
						<FormControlLabel
							control={
								<CheckboxComponent
									id="isAutomaticBankTransfer"
									name="isAutomaticBankTransfer"
									control={control}
									onClick={changeCheckBoxValue}
									disabled={false}
									label=''
								/>
							}
							label="Lançar transação automaticamente" />
					</FormGroup>
				</Grid>
				<Alert severity="warning">
					<AlertTitle>Atenção!</AlertTitle>
					Ao selecionar essa opção, será criado um lançamento no core bancário e uma nova
					transferência será realizada para o beneficiário da operação.
				</Alert>
			</Grid>

			<Button
				type='submit'
				disabled={isLoading}
			>
				Aprovar
			</Button>
		</form>
	);
}; 
