import React, { createContext, useContext, useState, useEffect } from 'react';
import { getReasonList } from 'services/bankAccount';

const BlockReasonContext = createContext({ blockReasonList: [], setBlockReasonList: () => { } });

export const BlockReasonProvider = ({ children }) => {
	const [blockReasonList, setBlockReasonList] = useState([]);

	useEffect(() => {
		getReasonList().then(result => { 
			setBlockReasonList(result.map(x => {
				return {name: x.description, value: x.code};
			}));
		});
	}, []);
		

	return (
		<BlockReasonContext.Provider
			value={{
				blockReasonList,
				setBlockReasonList
			}}
		>
			{children}
		</BlockReasonContext.Provider>
	);
};

export function useBlockReason() {
	const context = useContext(BlockReasonContext);
	const {
		blockReasonList,
		setBlockReasonList,
	} = context;
	return {
		blockReasonList,
		setBlockReasonList
	};
}
