import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { UserProvider } from 'contexts/user';
import { MyPermissionsProvider } from 'contexts/permissions';
import { ThemeProvider } from 'contexts/theme';
import { LoadingProvider } from 'contexts/loading';
import { ModalProvider } from 'contexts/modal';
import { HeaderProvider } from 'contexts/header';
import { TenantProvider } from 'contexts/tenant';
import { OperatorProvider } from 'contexts/operator';
import { ProductAccountProvider } from 'contexts/productAccount';
import { GroupProvider } from 'contexts/group';
import { PersonProvider } from 'contexts/person';
import { FundProvider } from 'contexts/fund';
import { LegalPersonsProvider } from 'contexts/legalPerson';
import { EnumPayProvider } from 'contexts/enumPaymentPurpose';
import { EnumRelatedBankProvider } from 'contexts/enumRelatedBank';
import { BlockReasonProvider } from 'contexts/blockReason';
import { NaturalPersonsProvider } from 'contexts/naturalPerson';
import { ProductProvider } from 'contexts/product';
import { AccountConfirmation, CodeConfirmation, PasswordRecovery, SignIn } from 'pages/Login';
import PrivateApp from 'pages/PrivateApp';
import { FilterPdfOrCsvProvider } from "contexts/filterPdfAndCsv";

function Provider() {

	return (
		<UserProvider>
			<MyPermissionsProvider>
				<ThemeProvider>
					<LoadingProvider>
						<ModalProvider>
							<HeaderProvider>
								<TenantProvider>
									<FilterPdfOrCsvProvider>
										<OperatorProvider>
											<GroupProvider>
												<ProductProvider>
													<ProductAccountProvider>
														<FundProvider>
															<PersonProvider>
																<NaturalPersonsProvider>
																	<BlockReasonProvider>
																		<EnumRelatedBankProvider>
																			<EnumPayProvider>
																				<LegalPersonsProvider>
																					<Helmet>
																						<title>{window.__RUNTIME_CONFIG__.REACT_APP_MAIN_TITLE} - Backoffice</title>
																						<link rel="icon" href={window.__RUNTIME_CONFIG__.REACT_APP_MAIN_FAVICON} />
																					</Helmet>
																					<Router>
																						<Switch>
																							<Route path='/login/:error' component={SignIn} />
																							<Route path='/forgot-password' component={PasswordRecovery} />
																							<Route path='/account-confirmation' component={AccountConfirmation} />
																							<Route path='/code-confirmation' component={CodeConfirmation} />
																							<Route path='/' component={PrivateApp} />
																						</Switch>
																					</Router>
																				</LegalPersonsProvider>
																			</EnumPayProvider>
																		</EnumRelatedBankProvider>
																	</BlockReasonProvider>
																</NaturalPersonsProvider>
															</PersonProvider>
														</FundProvider>
													</ProductAccountProvider>
												</ProductProvider>
											</GroupProvider>
										</OperatorProvider>
									</FilterPdfOrCsvProvider>
								</TenantProvider>
							</HeaderProvider>
						</ModalProvider>
					</LoadingProvider>
				</ThemeProvider>
			</MyPermissionsProvider>
		</UserProvider>
	);
}

export default Provider;