import TextCustomMask from "components/CustomMaskInput/TextCustomMask";
import Helper from 'helpers/format.helpers';
import CheckboxComponent from "components/CheckBox/Checkbox";
import { DangerAlert } from 'components';
import { FormGroup, FormControlLabel, TextField, Box, Grid } from '@material-ui/core';
import TextFieldComponent from 'components/TextInput/TextField';

function InformationsUsers(props) {
	const {
		operator,
		isEditing,
		changeFieldValue,
		inputBindNumber,
		inputBind,
		setValue
	} = props;
	
	const changeCheckBoxValue = (event) => {
		const value = Boolean(event.target.checked) ? 1 : 0;
		const name = event.target.name;
		setValue(name, value);
	};

	let photo = 'https://dn0a4ma6ta969.cloudfront.net/images/default-user.png';
	
	return (
		<>
			<Box py={3}>
				<Grid container spacing={3}>
					<DangerAlert />
					<div className='uk-margin-remove uk-width-1-1'>
						<div className='wt-user'
							style={{ backgroundImage: `url(${photo})` }}>
						</div>						
					</div>
					<Grid item xs={6}>
						<TextFieldComponent
							id="name"
							name="name"
							label="Nome"
							fullWidth
							placeholder='Nome completo'
							component={TextField}
							{...inputBind("name")}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextFieldComponent
							id="email"
							fullWidth
							name="email"
							label="Email"
							placeholder='Email'
							component={TextField}
							{...inputBind("email")}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="registrationNumber"
							name="registrationNumber"
							label="CPF"
							placeholder="000.000.000-00"
							autoComplete="registrationNumber"
							InputProps={{
								disableUnderline: true,
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.cpfMask },
							}}
							{...inputBindNumber("registrationNumber")}
							disabled={isEditing()}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextFieldComponent
							id="phoneNumber"
							fullWidth
							label="Telefone"
							placeholder="(00) 0000-0000"
							name="phoneNumber"
							component={TextField}
							{...inputBind("phoneNumber")}
							InputProps={{
								disableUnderline: true,
								inputComponent: TextCustomMask,
								inputProps: { mask: Helper.phoneNumberMask },
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextFieldComponent
							id="address"
							fullWidth
							label="Endereço"
							placeholder='Endereço'
							name="address"
							component={TextField}
							{...inputBind("address")}
						/>
					</Grid>
					{!isEditing() &&
						<>
							<Grid item xs={6}>
								<TextFieldComponent
									id="password"
									fullWidth
									label="Senha"
									name="password"
									placeholder='Senha'
									component={TextField}
									InputLabelProps={{ shrink: true }}
									type='password'
									onChange={(event) => changeFieldValue(event, 'password')}
									{...inputBind("password")}
									required={true} />
							</Grid>
							<Grid item xs={6}>
								<TextFieldComponent
									id="passwordConfirmation"
									fullWidth
									name="passwordConfirmation"
									label="Confirmar Senha"
									component={TextField}
									placeholder='Confirme sua senha'
									type='password'
									{...inputBind("passwordConfirmation")}
									required={true}
									onChange={(event) => changeFieldValue(event, 'passwordConfirmation')} />
							</Grid>
						</>}
					<Grid item xs={10}>
						<FormGroup>
							<FormControlLabel
								control={
									<CheckboxComponent
										id="sendNotifications"
										color="primary"
										name="sendNotifications"
										onClick={changeCheckBoxValue}
										inputProps={{ 'aria-label': 'secondary checkbox' }}
										{...inputBind("sendNotifications")}
									/>}
								label="Receber notificações" />

							<FormControlLabel
								control={
									<CheckboxComponent
										id="emailVerified"
										color="primary"
										name="emailVerified"
										onClick={changeCheckBoxValue}
										inputProps={{ 'aria-label': 'secondary checkbox' }}
										{...inputBind("emailVerified")}
									/>}
								label="Email verificado" />

						</FormGroup>						
					</Grid>
				</Grid>
			</Box>
		</>
	);
}
export default InformationsUsers;
