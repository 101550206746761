import { Grid, Button, FormGroup, FormControlLabel, Checkbox, ListItem, Typography } from '@material-ui/core';

function PrintConfiguration({ removedFromPrintList, setModalPrintConfiguration, handleOnChange }) {

	const handlePrint = () => {
		window.print();
	};

	return (
		<>							
			<Typography variant="subtitle1" component="div" htmlFor="economicActivityCodeId" shrink style={{ color: "#5F5F5F", paddingBottom: 12 }}><strong>Informações da Operação</strong></Typography>
			<Grid container alignItems="center" justifyContent="center" style={{ listStyle: "none" }}>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="startDate"
											checked={!removedFromPrintList?.includes("startDate")}
											onChange={handleOnChange} />}
										label="Data de Emissão" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="termInMonths"
											checked={!removedFromPrintList?.includes("termInMonths")}
											onChange={handleOnChange} />}
										label="Prazo" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="monthlyInterest"
											checked={!removedFromPrintList?.includes("monthlyInterest")}
											onChange={handleOnChange} />}
										label="Taxa A.M" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="dueDate"
											checked={!removedFromPrintList?.includes("dueDate")}
											onChange={handleOnChange} />}
										label="Data de Vencimento" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="financeTax"
											checked={!removedFromPrintList?.includes("financeTax")}
											onChange={handleOnChange} />}
										label="IOF" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="yearlyInterest"
											checked={!removedFromPrintList?.includes("yearlyInterest")}
											onChange={handleOnChange} />}
										label="Taxa A.A" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="initialValue"
											checked={!removedFromPrintList?.includes("initialValue")}
											onChange={handleOnChange} />}
										label="Valor do Contrato" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint" >
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="emissionCost"
											checked={!removedFromPrintList?.includes("emissionCost")}
											onChange={handleOnChange} />}
										label="Custo de emissão" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="effectiveMonthlyCost"
											checked={!removedFromPrintList?.includes("effectiveMonthlyCost")}
											onChange={handleOnChange} />}
										label="CET A.M" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="liquidValue"
											checked={!removedFromPrintList?.includes("liquidValue")}
											onChange={handleOnChange} />}
										label="Valor Líquido" />
								</FormGroup>
							</ListItem>
						</Grid>	
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="indexer"
											checked={!removedFromPrintList?.includes("indexer")}
											onChange={handleOnChange} />}
										label="Indexador" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="effectiveYearlyCost"
											checked={!removedFromPrintList?.includes("effectiveYearlyCost")}
											onChange={handleOnChange} />}
										label="CET A.A" />
								</FormGroup>
							</ListItem>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<Typography variant="subtitle1" component="div" htmlFor="economicActivityCodeId" shrink style={{ color: "#5F5F5F", paddingBottom: 12 }}><strong>Informações da Tabela de Pagamento</strong></Typography>
			<Grid container alignItems="center" justifyContent="center" style={{ listStyle: "none" }}>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="installment"
											checked={!removedFromPrintList?.includes("installment")}
											onChange={handleOnChange} />}
										label="Parcela" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="dueDateTable"
											checked={!removedFromPrintList?.includes("dueDateTable")}
											onChange={handleOnChange} />}
										label="Vencimento" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="principalAmountInCents"
											checked={!removedFromPrintList?.includes("principalAmountInCents")}
											onChange={handleOnChange} />}
										label="Saldo Devedor" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="amortization"
											checked={!removedFromPrintList?.includes("amortization")}
											onChange={handleOnChange} />}
										label="Amortização" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="interest"
											checked={!removedFromPrintList?.includes("interest")}
											onChange={handleOnChange} />}
										label="Juros" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="payment"
											checked={!removedFromPrintList?.includes("payment")}
											onChange={handleOnChange} />}
										label="Pagamento" />
								</FormGroup>
							</ListItem>
						</Grid>
						<Grid item sm={4} className="disablePrint">
							<ListItem>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox
											id="valueTotal"
											checked={!removedFromPrintList?.includes("valueTotal")}
											onChange={handleOnChange} />}
										label="Total" />
								</FormGroup>
							</ListItem>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<br />
			<Grid container justifyContent='center' spacing={5}>
				<Grid item>
					<Button color="secondary" variant='contained' onClick={() => { setModalPrintConfiguration(false); window.setTimeout(handlePrint, 10); }}>Imprimir</Button>
				</Grid>
			</Grid></>
	);
};
export default PrintConfiguration;