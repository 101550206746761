import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from "@material-ui/core";
import { ContentContainer, DateRangeSelectionModal } from 'components';
import { fetchDashboardInfo } from '../../services/dashboard';
import moment from 'moment';
import { extractQueryParamsFromSearch, saveQueryParamsToSearch } from 'random/query-params';
import QueryFields from './query-fields';
import { AccountConfirmation } from 'pages/Login';
import ScanList from './List/creditScanList';
import { Link, useHistory } from 'react-router-dom';
import { useHeader } from 'contexts/header';
import LogoScan from './Logo.jpeg';
import YouTube from 'react-youtube';
import { useModal } from 'contexts/modal';

const CreditScan = () => {

	const history = useHistory();
	const { setTitle } = useHeader();
	const { setModalContent } = useModal();

	localStorage.removeItem(AccountConfirmation.EMAIL_FIELD);
	localStorage.removeItem(AccountConfirmation.PASS_FIELD);

	const fetchDashboardDataStatePeriod = (state) => fetchDashboardData(state.initialDate || moment().subtract(7, 'days'), state.finalDate || moment());
	const fetchDashboardData = async (initialDate, finalDate) => {

		const fieldValues = {
			initialDate,
			finalDate
		};
		var data = await fetchDashboardInfo(initialDate, finalDate);
		saveQueryParamsToSearch(history, location, QueryFields, fieldValues);
		data && setState({
			...state,
			data
		});
	};

	const queryParams = extractQueryParamsFromSearch(window.location.search, QueryFields);

	const [state, setState] = useState({
		showDatePicker: false,
		initialDate: queryParams.initialDate || moment().subtract(7, 'days'),
		finalDate: queryParams.finalDate || moment(),
		msgFilter: '',
		data: {}
	});

	useEffect(() => {
		setTitle("Histórico");
		fetchDashboardDataStatePeriod(state);
	}, []);

	useEffect(() => {
		fetchDashboardData(state.initialDate, state.finalDate);
	}, [state.initialDate, state.finalDate]);

	const filterDate = (initialDate, finalDate) => {
		const newState = {
			...state,
			showDatePicker: false,
			initialDate: (initialDate && moment(initialDate)) || null,
			finalDate: (finalDate && moment(finalDate)) || null
		};
		setState(newState);
	};

	const toggleDatePickerVisibility = (show) => {
		const newState = {
			...state,
			showDatePicker: show
		};
		setState(newState);
	};

	const opts = {
		height: '500',
		width: '100%',
		playerVars: {
			autoplay: 1,
		},
	};

	const Image = () => {
		return (
			<div>
				<img onClick={() => {
					setModalContent(<YouTube style={{ padding: '30px 0' }}
						videoId="tRBjhz1EWso"
						containerClassName="embed embed-youtube"
						opts={opts}
					/>);
				}
				} src={LogoScan} style={{ padding: '20px auto', width: '100%', height: 'auto', cursor: 'pointer', borderRadius: '20px' }} />
			</div>
		);
	};

	const { initialDate, finalDate, showDatePicker } = state;

	return (
		<ContentContainer>
			<Image />
			<Box py={1} style={{ paddingTop: 45 }}>
				<Grid container spacing={4}>
					<Grid item>
						<button className='select-date-step wt-button-filter uk-button uk-margin-bottom' onClick={() => toggleDatePickerVisibility(true)}>
							<i className="fa fa-calendar uk-margin-small-right" aria-hidden="true" style={{ fontSize: 16 }} ></i>
							{!initialDate && !finalDate && 'Detalhes dos últimos 7 dias'}
							{initialDate &&
								<Typography component="span" style={{ fontSize: 15, textTransform: 'none', }}>
									{` ${initialDate.format('DD/MM/YYYY')}`}
								</Typography>}
							{finalDate &&
								<Typography component="span" style={{ fontSize: 15, textTransform: 'none', }}>
									{` -  ${finalDate.format('DD/MM/YYYY')}`}
								</Typography>}
						</button>
					</Grid>
					<Grid item >
						<div className='uk-width-auto@m uk-width-1-1'>
							<Link to='/pessoa-fisica/nova' className=' wt-button-filter uk-button uk-margin-bottom'>
								<i className='fa fa-plus uk-margin-small-right'></i>
								<Typography component="span" style={{ textTransform: 'none', }}>Adicionar Pessoa Física</Typography>
							</Link>
						</div>
					</Grid>
					<Grid item >
						<div className='uk-width-auto@m uk-width-1-1'>
							<Link to='/pessoa-juridica/nova' className='wt-button-filter uk-button uk-margin-bottom'>
								<i className='fa fa-plus uk-margin-small-right'></i>
								<Typography component="span" style={{ textTransform: 'none', }}>Adicionar Pessoa Jurídica</Typography>
							</Link>
						</div>
					</Grid>
				</Grid>
			</Box>

			<Grid container spacing={6}>
				<ScanList
					key={`${initialDate}${finalDate}`}
					hideActions={true}
					title="Operações no período"
					initialDate={(initialDate && initialDate.toDate()) || null}
					finalDate={(finalDate && finalDate.toDate()) || null} />
			</Grid>
			{showDatePicker && <DateRangeSelectionModal
				initialDate={(initialDate && initialDate.toDate()) || null}
				finalDate={(finalDate && finalDate.toDate()) || null}
				onFilter={filterDate}
				onCloseModal={() => toggleDatePickerVisibility(false)} />}
		</ContentContainer>
	);
};


export default CreditScan;