export const optionsTypeOfRelationships = [
	{ name: 'Sócio / Avalista', value: 'PartnerGuarantor' },
	{ name: 'Avalista', value: 'Guarantor' },
	{ name: 'Procurador', value: 'Proxy' },
	{ name: 'Procurador / Avalista', value: 'ProxyGuarantor' },
	{ name: 'Sócio', value: 'Partner' },
	{ name: 'Cônjuge', value: 'Spouse' },
	{ name: 'Anuente', value: 'Consenter' },
	{ name: 'Testemunha', value: 'Witness' },
	{ name: 'Cedente', value: 'Assignor' },
	{ name: 'Cessionário', value: 'Assignee' },
	{ name: 'Endossante', value: 'Endorser' },
	{ name: 'Empregador', value: 'Payer' },
	{ name: 'Devedor Solidário', value: 'Debtor' },
	{ name: 'Sócio / Devedor Solidário', value: 'PartnerDebtor' },
	{ name: 'Escriturador', value: 'NotaryAgent' },
	{ name: 'Agente de liquidação', value: 'SettlementAgent' },
];