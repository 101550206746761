import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';


export async function getFiles(relatedId, relatedEntity) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/Upload?RelatedEntity=${relatedEntity}&RelatedId=${relatedId}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);

	}
	catch (err) {

		return Promise.reject(err);
	}
	return result;
}

export async function getSignedUrl(fileName) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/Upload/${fileName}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo", err);
		}
		throw err;
	}
}


export async function  uploadFile(file, url) {

	var fileName = new URL(url).pathname.split('/').reverse()[0];

	var reader = new FileReader();
	reader.readAsArrayBuffer(file);

	const config = {
		headers: { 
			"Content-Type": file.type || "application/octet-stream",
			"Content-Disposition": `inline; filename="${fileName}"`
		},
	};
	
	reader.onload = function(event) {
		return axios.put(url, event.target.result, config).then(response=>{
			console.log(response);
		}).catch(err=> console.log(err)); 
	};
}