import React, {  Fragment, useState } from 'react';

import {
	ContentContainer,
	DangerAlert,
	InputWithLabel,
	PrimaryButton,
	Toast
} from 'components';
import {
	sendVerificationCodeForPasswordRecover,
	passwordRecover
} from '../../../services/user';
import HelpLinkList from '../HelpLinkList';
import LoginHeader from 'pages/Login/Header';
import {
	LoginContainerStyled,
	LoginContentStyled
} from 'assets/style';

function PasswordRecovery (props){

	const [ state, setState ] = useState({
		step: 1,
		email: '',
		verificationCode: '',
		password: '',
		passwordConfirmation: '',
		errors: {}
	});

	const changeFieldValue = (fieldName, event) => {
		setState({
			...state,
			[fieldName]: event.target.value
		});
	};
	//componentDidMount(){
	//	//Helper.checkSendCurrentLocation();
	//}

	const goToNextStep = () => {
		const { step } = state;
		if (step === 1) {
			sendEmailWithVerificationCode();
		} else {
			recoverPassword();
		}
	};

	const sendEmailWithVerificationCode = () => {
		const errors = {};
		const { email } = state;
		if (!email) {
			errors.email = 'Campo "E-mail" é obrigatório';
		}

		setState({...state, errors});

		if (!email) {
			return;
		}
		
		sendVerificationCodeForPasswordRecover(email)
			.then(() => {
				Toast.showSuccessMessage('Código de verificação enviado para o seu e-mail com sucesso', 'Código enviado');
				setState({...state, step: 2});
			}).catch(exception =>
				Toast.showErrorMessage('Ocorreu um erro ao enviar o código de verificação', 'Código não enviado', exception)
			);
	};

	const recoverPassword = () => {
		const errors = {};
		const { verificationCode, password, passwordConfirmation, email } = state;
		if (!verificationCode) {
			errors.verificationCode = 'Campo "Código" é obrigatório';
		}

		if (!password) {
			errors.password = 'Campo "Senha" é obrigatório';
		}

		if (!passwordConfirmation) {
			errors.passwordConfirmation = 'Campo "Confirmação de senha" é obrigatório';
		}

		if (passwordConfirmation && password !== passwordConfirmation) {
			errors.passwordConfirmation = 'A senha e a confirmação são diferentes';
		}

		setState({...state, errors});
		if (Object.keys(errors).length > 0) {			
			return;
		}

		const { history } = props;
		
		passwordRecover(email, verificationCode, password)
			.then(() => {
				Toast.showSuccessMessage('Senha alterada com sucesso', 'Senha alterada');
				history.push('/login/0');
			}).catch(exception =>
				Toast.showErrorMessage('Ocorreu um erro ao alterar a senha', 'Senha não alterada', exception)
			);
	};

	const backToPreviousStep = () => {
		const { history } = props;
		const { step } = state;
		if (step === 1) {
			history.push('/login/0');
		} else {
			setState({ ...state, step: 1 });
		}
	};

	
	const { step, errors, email, verificationCode, password, passwordConfirmation } = state;
	const { passwordRecoveryStatus } = props;

	return (
		<LoginContainerStyled>
			<ContentContainer>
				<LoginContentStyled>
					{/*{passwordRecoveryStatus?.loading && <AbsoluteRequestStatusIndicator request={{loading: true}} /> }*/}
					<LoginHeader title='Recuperar senha' subtitle='Preencha os dados abaixo' />
					<DangerAlert msg={state.msg} />
					<form className='wt-form uk-form-stacked'>
						{step === 1 &&
								<Fragment>
									<p className='uk-text-center uk-section-xsmall'>Primeiro, digite o seu e-mail para receber um código de verificação</p>
									<div className='uk-margin-top'>
										<InputWithLabel label='Digite seu e-mail'
											placeholder='Seu e-mail'
											value={email}
											type='email'
											errorMessage={errors.email}
											onChange={(event)=>changeFieldValue('email', event)} />
									</div>

								</Fragment>
						}
						{step === 2 &&
								<Fragment>
									<p className='uk-text-center uk-section-xsmall'>Agora, informe o código que foi enviado no seu e-mail e a nova senha</p>
									<div className='uk-margin-top'>
										<InputWithLabel label='Código'
											placeholder='Código de verificação'
											errorMessage={errors.verificationCode}
											value={verificationCode}
											onChange={(event)=> changeFieldValue('verificationCode', event)}
											type='number' />
									</div>
									<div className='uk-margin-top'>
										<InputWithLabel label='Nova senha'
											placeholder='Sua nova senha'
											onChange={(event)=>changeFieldValue('password', event)}
											type='password'
											value={password}
											errorMessage={errors.password} />
									</div>
									<div className='uk-margin-top'>
										<InputWithLabel label='Confirmação de senha'
											placeholder='Sua nova senha'
											value={passwordConfirmation}
											errorMessage={errors.passwordConfirmation}
											onChange={(event)=> changeFieldValue('passwordConfirmation', event)}
											type='password' />
									</div>
								</Fragment>
						}
						<div className='uk-margin-top'>
							<PrimaryButton
								type='button'
								onClick={goToNextStep}
								className='uk-width-1-1'>
								{step === 1 ? 'Receber código' : 'Alterar senha'}
							</PrimaryButton>
						</div>
					</form>
					<button
						onClick={backToPreviousStep}
						className='wt-button wt-button-circle wt-button-circle-small uk-margin-small-top'>
						<i className='fas fa-arrow-left'></i>
					</button>
				</LoginContentStyled>
				<HelpLinkList className='wt-login-info' />
			</ContentContainer>
		</LoginContainerStyled>
	);
}

export default PasswordRecovery;