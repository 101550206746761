import React, { useEffect } from 'react';
import { ContentContainer } from 'components';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { Box, Button, Container, Grid, makeStyles, Paper } from '@material-ui/core';
import { AllsIcon, NoneIcon, MyGroupsIcon, MineIcon } from 'assets';
import { getPermissionList } from 'services/permission';
import { useTenant } from 'contexts/tenant';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		textAlign: "right",
		color: theme.palette.text.secondary
	}
}));

const PermissionsList = (props) => {
	const { userId, permissionData, setPermissionData } = props;
	const { selectedTenant } = useTenant();
	const classes = useStyles();
	
	useEffect(() => {
		getPermissionList(userId, selectedTenant, "Group").then((permissions) => {
			setPermissionData(permissions);
		});
	}, []);

	const statusPermission = (level) => {
		switch (level) {
			case "All":
				return <AllsIcon />;
			case "MyGroups":
				return <MyGroupsIcon />;
			case "OnlyMine":
				return <MineIcon />;
			case "None":
				return <NoneIcon />;
			default:
				return <></>;
		}
	};

	const cycle = (level) => {
		switch (level) {
			case "All":
				return "None";
			case "MyGroups":
				return "All";
			case "OnlyMine":
				return "MyGroups";
			default:
				return "OnlyMine";
		}
	};

	const handleItemStatusChange = (resource, type) => () => {
		let permissions = permissionData.map(item => {
			if (item.resource == resource) {
				item[type] = cycle(item[type]);
			}
			return item;
		});
		setPermissionData(permissions);
	};

	const handleResourceStatusChange = (resource) => () => {
		let permissions = permissionData.map(item => {
			if (item.resource == resource) {
				item.readPermission = item.readPermission && cycle(item.readPermission);
				item.createPermission = item.createPermission && cycle(item.createPermission);
				item.updatePermission = item.updatePermission && cycle(item.updatePermission);
				item.deletePermission = item.deletePermission && cycle(item.deletePermission);
			}
			return item;
		});
		setPermissionData(permissions);
	};

	return (
		<ContentContainer>
			<Box >
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<AllsIcon /> TODOS &nbsp;&nbsp; <NoneIcon /> NENHUM &nbsp;&nbsp; <MineIcon /> SÓ OS MEUS &nbsp;&nbsp; <MyGroupsIcon /> MEUS GRUPOS&nbsp;
						</Paper>
					</Grid>
				</Grid>
			</Box>
			<MaterialTable
				title={"Permissões"}
				columns={[
					{
						title: "Recurso", field: 'resourceDisplay', render: ({ resource, resourceDisplay }) =>
							<Button style={{ fontFamily: 'Roboto' }} onClick={handleResourceStatusChange(resource)}>{resourceDisplay}</Button>
					},
					{
						title: "Ler", field: 'readPermission', render: ({ resource, readPermission }) =>
							<Button onClick={handleItemStatusChange(resource, "readPermission")}>{statusPermission(readPermission)}</Button>
					},
					{
						title: "Criar", field: 'createPermission', render: ({ resource, createPermission }) =>
							<Button onClick={handleItemStatusChange(resource, "createPermission")}>{statusPermission(createPermission)}</Button>
					},
					{
						title: "Atualizar", field: 'updatePermission', render: ({ resource, updatePermission }) =>
							<Button onClick={handleItemStatusChange(resource, "updatePermission")}>{statusPermission(updatePermission)}</Button>
					},
					{
						title: "Excluir", field: 'deletePermission', render: ({ resource, deletePermission }) =>
							<Button onClick={handleItemStatusChange(resource, "deletePermission")}>{statusPermission(deletePermission)}</Button>
					}].filter(x => x !== undefined)}
				data={permissionData}
				localization={localizationOptions}
				options={{
					actionsColumnIndex: -1,
					debounceInterval: 500,
					paging: false,
					search: false,
					searchAutoFocus: false
				}}
			/>
		</ContentContainer>
	);
};

export default PermissionsList;
