import React, {useRef, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 450px;
  height: 100px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NumBox = styled.div`
  border: 1px solid #0005;
  border-radius: 8px;
  height: 60px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${({ isFocused }) => isFocused ? '1px 1px 3px  rgba(244, 108, 0, 0.70), -1px -1px 3px rgba(244, 108, 0, 0.65)' : 'none'};
  >span {
    font-weight: 700;
    font-size: 16pt;
  }
`;

const Spacing = styled.div`
  width: 10px;
`;

const HiddenInput = styled.input`
  position: absolute;
  top: -10000px;
  opacity: 0;
`;

const CodeBox = ({ value, index, isFocused, onClick }) => (
	<NumBox isFocused={(isFocused && value.length === index) || (isFocused && index === 5 && value.length === 6)} onClick={onClick}>
		<span>
			{value[index] || ''}
		</span>
	</NumBox>
);

const PassCodeInput = ({ onChange }) => {
	const [code, setCode] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const inputRef = useRef(null);

	const parseValue = (value) => {
		const numberPattern = /\d+/g;

		const onlyNumbers = value.match(numberPattern);

		if (!onlyNumbers) {
			setCode('');
			onChange('');
			return;
		}

		const mask = onlyNumbers.join().slice(0, 6);
		console.log(mask);
		setCode(mask);
		onChange(mask);
	};

	return (
		<Container>
			<CodeBox value={code} index={0} isFocused={isFocused} onClick={() => inputRef.current.focus()} />
			<CodeBox value={code} index={1} isFocused={isFocused} onClick={() => inputRef.current.focus()} />
			<CodeBox value={code} index={2} isFocused={isFocused} onClick={() => inputRef.current.focus()} />
			<Spacing />
			<CodeBox value={code} index={3} isFocused={isFocused} onClick={() => inputRef.current.focus()} />
			<CodeBox value={code} index={4} isFocused={isFocused} onClick={() => inputRef.current.focus()} />
			<CodeBox value={code} index={5} isFocused={isFocused} onClick={() => inputRef.current.focus()} />
			<HiddenInput
				ref={inputRef}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				value={code}
				onChange={(e) => parseValue(e.target.value)}
			/>
		</Container>
	);
};

export default PassCodeInput;