var UserPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID || "us-east-2_ljZORJruL";
var ClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID || "1tmeh7e6eplg27ok1t0cdburfq";
var IdentityPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_IDENTITY_ID || "us-east-2:6f532e04-443f-4ae6-846a-ea2361b1e5bc";
var region = window.__RUNTIME_CONFIG__.REACT_APP_AWS_REGION || "us-east-2";

export default {
	UserPoolId,
	ClientId,
	IdentityPoolId,
	region
};