import axios, { AxiosError } from 'axios';
import { getToken } from "../repository/cognito";
import { ApiConfig } from "../config";
import { Toast } from '../components';
import { pickBy } from 'lodash';
import { NaturalPersonFiltersType, NaturalPersonType } from "../global/types/naturalPerson.types";

export async function getNaturalPersonById(id: string) {

	const token = await getToken();
	const url = ApiConfig.baseURL + `/NaturalPerson/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		await axios.get<NaturalPersonType>(url, config).then(response => {
			if (response.data) {
				response.data.netSalary = (response?.data?.netSalary / 100); // from cents to decimal
				response.data.otherIncome = (response?.data?.otherIncome / 100);// from cents to decimal
				response.data.spouseNetSalary = (response?.data?.spouseNetSalary / 100);// from cents to decimal
				response.data.spouseOtherIncome = (response?.data?.spouseOtherIncome / 100);// from cents to decimal
				response.data?.assets?.map((asset) =>
					asset.estimatedValue = (asset?.estimatedValue / 100)// from cents to decimal
				);
			}
			result = response;
		});


	}
	catch (err) {
		Toast.showErrorMessage("Não foi possível obter o cliente");
	}
	return result;
}

export async function getNaturalPersonsList(filters: NaturalPersonFiltersType, tenant: string) {

	const orderByDirection = filters.orderByDirection ? filters.orderByDirection.toUpperCase() : '';
	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + orderByDirection : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));
	delete params.orderByField;
	delete params.orderByDirection;

	const token = await getToken();
	let url = ApiConfig.baseURL + `/NaturalPerson`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de clientes");
		}
		throw err;

	}
}

export async function getNaturalPersonsPendenciesList(filters: NaturalPersonFiltersType, tenant: string) {

	const orderByDirection = filters.orderByDirection ? filters.orderByDirection.toUpperCase() : '';
	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + orderByDirection : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));
	delete params.orderByField;
	delete params.orderByDirection;
	

	const token = await getToken();
	let url = ApiConfig.baseURL + `/NaturalPerson`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de clientes");
		}
		throw err;

	}
}

export async function editOrCreateNaturalPerson(data: NaturalPersonType, option: 'create' | 'edit', tenant: string) {

	const onlyNumbers = /\D/g;
	if (!data.assets || !data?.assets[0]?.assetType || !data?.assets[0]?.settled) {
		data.assets = null;
	}
	if (data.bankAccounts) {
		data.bankAccounts.forEach(bankAccount => {
			bankAccount.pixKeyTypeValue == "AgencyAndAccount" ? bankAccount.keyPix = null : bankAccount.keyPix = bankAccount.keyPix;
			if(bankAccount.pixKeyTypeValue == "Phone") {
				if(bankAccount?.keyPix?.startsWith("+55")) {
					return bankAccount.keyPix = bankAccount?.keyPix;
				} else {
					return bankAccount.keyPix = "+55" + bankAccount?.keyPix?.replace(onlyNumbers, '');
				}
			}
			return bankAccount;
		});
	}

	data = {
		...data,

		//@ts-ignore
		address: data.address._isEmpty ? null : data.address
	};

	const assets = data.assets?.filter(asset => {
		if (!asset?.assetType && !asset?.settled) {
			return;
		}

		asset.estimatedValue = asset.estimatedValue * 100;
		return asset;
	});

	let result;

	const payload = {
		...data,
		registrationNumber: data?.registrationNumber?.replace(onlyNumbers, ''),
		documentNumber: data?.documentNumber?.replace(onlyNumbers, ''),
		['address.zipCode']: data?.address?.zipCode?.replace(onlyNumbers, ''),
		workplaceCompanyRegistrationNumber: data?.workplaceCompanyRegistrationNumber?.replace(onlyNumbers, ''),
		netSalary: data?.netSalary * 100, // from currency to cents
		otherIncome: data?.otherIncome * 100,// from currency to cents
		assets: assets,
		['commercialAddress.zipCode']: data?.commercialAddress?.zipCode?.replace(onlyNumbers, ''),
		spouseDocumentNumber: data?.spouseDocumentNumber?.replace(onlyNumbers, ''),
		spouseWorkplace: data?.spouseWorkplace?.replace(onlyNumbers, ''),
		spouseNetSalary: data?.spouseNetSalary * 100,// from currency to cents
		spouseOtherIncome: data?.spouseOtherIncome * 100,// from currency to cents
		phone: data?.phone?.replace(onlyNumbers, ''),
		phone2: data?.phone2?.replace(onlyNumbers, ''),
		commercialPhone: data?.commercialPhone?.replace(onlyNumbers, ''),
		commercialPhone2: data?.commercialPhone2?.replace(onlyNumbers, ''),
		spouseCommercialPhone: data?.spouseCommercialPhone?.replace(onlyNumbers, ''),
	};

	const token = await getToken();
	const url = payload.id ?
		ApiConfig.baseURL + `/NaturalPerson/${data.id}` :
		ApiConfig.baseURL + `/NaturalPerson`;

	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(urlWithTenant, payload, config),
			successMessage: 'Cliente cadastrado com sucesso',
			errorMessage: 'Ocorreu um erro no cadastro do cliente'
		},
		edit: {
			function: () => axios.put(urlWithTenant, payload, config),
			successMessage: 'Cliente editado com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar o cliente'
		}
	};

	try {

		result = await request[option].function();
		Toast.showSuccessMessage(request[option].successMessage);
		return result;

	}
	catch (err) {
		const error = err as AxiosError;
		if (error.response) {
			if (error.response?.data.message) {
				const message = error.response.data.message + '</br>' + error?.response?.data?.errors.map((err: Error) => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message);
			} else if (error.response?.data?.errors) {
				Toast.showErrorMessage(error?.response.data?.errors);
			} else {
				Toast.showErrorMessage(error.response.data || request[option].errorMessage);

			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}
		throw err;

	}
}


export async function deleteNaturalPersonById(id: string) {

	const token = await getToken();
	const url = ApiConfig.baseURL + `/NaturalPerson/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.delete(url, config);
		Toast.showSuccessMessage("Cliente excluído com sucesso.");
		return result.data;

	}
	catch (err) {
		const error = err as AxiosError;
		if (error?.response?.data?.errors) {
			Toast.showErrorMessage(error.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao excluir o cliente.");
		}
		throw err;
	}
}