import { IProps } from 'global/interface/types';
import Helper from 'helpers/format.helpers';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getLegalPersonsList } from 'services/legalPerson';
import { useMyPermissions } from './permissions';
import { useTenant } from './tenant';

type GlobalLegalPersonContent = {
	legalPersonsOptionList: []
	setLegalPersonsOptionList: () => void
	getLegalPersonsList: (page: number, size: number, search: string) => void
};

type SearchArgsType = {
	page: number
	size: number
	search: string
};

const LegalPersonsContext = createContext<GlobalLegalPersonContent>({ legalPersonsOptionList: [], setLegalPersonsOptionList: () => { }, getLegalPersonsList: () => { } });

export const LegalPersonsProvider = ({ children }: IProps) => {
	const [legalPersonsOptionList, setLegalPersonsOptionList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState<SearchArgsType>({ page: 0, size: 10, search: "" });
	const { selectedTenant } = useTenant();
	const { getMyPermissionLevel } = useMyPermissions();
	//@ts-ignore
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == "0";

	const hasLegalPersonPerm: boolean = !!getMyPermissionLevel("LegalPerson", "read");

	useEffect(() => {
		if (!hasLegalPersonPerm) return;
		searchLegalPersons(searchArgs.page, searchArgs.size, searchArgs.search, selectedTenant);

	}, [searchArgs, selectedTenant]);


	const searchLegalPersons = (page: number, size: number, search: string, selectedTenant: string | undefined) => {
		return getLegalPersonsList({ page, size, searchString: search }, selectedTenant).then(resp => {
			//@ts-ignore
			setLegalPersonsOptionList(resp?.data.map(n => {
				return {
					name: isRootTenancy ? `${n.name || "Não informado"} (${n.tenantDisplay}) - (${Helper.formatDocumentNumber(n.registrationNumber)})` : n.name, value: n.name, id: n.id,
					registrationNumber: n.registrationNumber, economicActivityCodeId: n.economicActivityCodeId, economicActivityCode: n.economicActivityCode
				};
			}));
		});
	};



	const contextGetLegalPersonsList = (page: number, size: number, search: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || search != searchArgs?.search) {
			//@ts-ignore
			setSearchArgs({ page, size, search });
		}
	};

	return (
		<LegalPersonsContext.Provider value={{
			legalPersonsOptionList: legalPersonsOptionList,
			setLegalPersonsOptionList: () => setLegalPersonsOptionList,
			getLegalPersonsList: (page: number, size: number, search: string) => {
				return contextGetLegalPersonsList(page, size, search);
			},
		}}>
			{children}
		</LegalPersonsContext.Provider>
	);
};

export function useLegalPersons() {
	const context = useContext(LegalPersonsContext);
	const {
		legalPersonsOptionList,
		getLegalPersonsList
	} = context;
	return {
		legalPersonsOptionList,
		getLegalPersonsList
	};
}
