import { Component, useEffect, useState } from 'react';
import { Redirect, useLocation, withRouter } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from "date-fns/locale";
import CreditDashboard from './Dashboard';
import CreditScan from './CreditScan3/creditScan';
import SideMenu from 'components/SideMenu';
import Header from 'components/Header/Header';
import NaturalPersonEdit from './NaturalPerson/CreateEdit/naturalPersonCreateEdit';
import LegalPersonEdit from './LegalPerson/CreateEdit/legalPersonCreateEdit';
import NaturalPersonList from './NaturalPerson/List/naturalPersonList';
import SimulateCCB from './Credit/SimulateCCB/simulateCCB';
import { OperatorList, OperatorCreateEdit } from './Security/Operator';
import { ChangePassword } from './Settings';
import 'random/functions';
import CreditNoteListPage from 'pages/Credit/List/creditNoteList';
import CreditNoteCreateEdit from './Credit/CreateEdit/creditNoteCreateEdit';
import BankAccountList from './BankAccountRequest/List/bankAccountRequestList';
import CTE from './CTE/cte';
import ProductList from './Product/List/productList';
import ProductEdit from 'pages/Product/CreateEdit/productCreateEdit';
import FundList from './Fund/List/fundList';
import FundEdit from './Fund/CreateEdit/fundCreateEdit';
import ModalRenderer from 'components/ModalScan3/ModalRenderer';
import GroupEdit from './Security/Groups/CreateEdit/groupCreateEdit';
import GroupList from './Security/Groups/List/groupsList';
import BankAccountActive from "./BankAccountRequest/Active/bankAccountActive";
import ProductAccountEdit from './ProductAccount/CreateEdit/productAccountCreateEdit';
import ProductAccountList from './ProductAccount/List/productAccountList';
import BankAccoutEdit from './BankAccountRequest/CreateEdit/bankAccountCreateEdit';
import PrivateRoute from 'routes';
import LegalPersonsList from './LegalPerson/List/legalPersonList';
import { useMyPermissions } from 'contexts/permissions';
import DeletedList from 'components/CreditNoteList/DeletedList';

function PrivateApp() {
	const [mobileOpen, setMobileOpen] = useState(false);
	const location = useLocation();
	useEffect(() => {
		localStorage.setItem('selected', location.pathname.replace('/', ''));
	}, []);

	const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

	let mainStyleView = '';
	if (mobileOpen) {
		mainStyleView = 'wt-main';
	} else {
		mainStyleView = 'wt-hide';
	};

	const useCTE = !!+window.__RUNTIME_CONFIG__.REACT_APP_CTE_MENU;
	const useSCAN3 = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCAN3;
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE === '0';
	const { permissions, getMyPermissionLevel } = useMyPermissions();

	const hasCreditPerm = permissions.length == 0 || !!getMyPermissionLevel("CreditNote", "read");
	const redirectTo = "/contas-digitais";

	return (
		<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>

			<div className='uk-offcanvas-content'>
				<Header handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
				<ModalRenderer />
				<main className={mainStyleView}>
					<SideMenu handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
					{useSCAN3 ? <PrivateRoute exact path='/' component={CreditScan} />
						: hasCreditPerm ? <PrivateRoute exact path='/' component={CreditDashboard} /> :
							<Redirect to={redirectTo} />}
					<PrivateRoute path='/pessoa-fisica/editar' component={NaturalPersonEdit} />
					<PrivateRoute exact path={"/pessoa-fisica/nova"} component={NaturalPersonEdit} />

					{/* rendering routes with children, to pass other props to components*/}
					<PrivateRoute exact path={"/pessoa-fisica/todos"}>
						<NaturalPersonList pendencies={false} />
					</PrivateRoute>
					{isRootTenancy ?
						<PrivateRoute exact path={"/pessoa-fisica/pendentes"}>
							<NaturalPersonList pendencies={true} />
						</PrivateRoute>
						: null
					}
					<PrivateRoute path='/pessoa-juridica/editar' component={LegalPersonEdit} />
					<PrivateRoute exact path={"/pessoa-juridica/nova"} component={LegalPersonEdit} />

					{/* rendering routes with children, to pass other props to components*/}
					<PrivateRoute exact path={"/pessoa-juridica/todos"}>
						<LegalPersonsList pendencies={false} />
					</PrivateRoute>
					{isRootTenancy ?
						<PrivateRoute exact path={"/pessoa-juridica/pendentes"}>
							<LegalPersonsList pendencies={true} />
						</PrivateRoute>
						: null
					}

					<PrivateRoute exact path='/ccb/simular' component={SimulateCCB} />
					<PrivateRoute exact path='/ccb/nova' component={CreditNoteCreateEdit} />
					<PrivateRoute exact path='/ccb/editar' component={CreditNoteCreateEdit} />
					<PrivateRoute exact path='/ccb' component={CreditNoteListPage} />
					{isRootTenancy ? <PrivateRoute exact path='/ccb/excluidos'>
						<DeletedList title="Excluídas" />
					</PrivateRoute> : null}
					<PrivateRoute path='/operador/editar' component={OperatorCreateEdit} />
					<PrivateRoute exact path='/operador/novo' component={OperatorCreateEdit} />
					<PrivateRoute path='/operador/editar' component={OperatorCreateEdit} />
					<PrivateRoute exact path='/operador' component={OperatorList} />
					<PrivateRoute path='/change-password' component={ChangePassword} />
					<PrivateRoute exact path='/solicitacao-conta-digital' component={BankAccountList} />
					<PrivateRoute exact path='/solicitacao-conta-digital/nova' component={BankAccoutEdit} />
					<PrivateRoute path='/solicitacao-conta-digital/editar' component={BankAccoutEdit} />
					<PrivateRoute path='/contas-digitais' component={BankAccountActive} />
					{useCTE && <PrivateRoute path='/cte' component={CTE} />}
					<PrivateRoute exact path='/produto' component={ProductList} />
					<PrivateRoute path='/produto/editar' component={ProductEdit} />
					<PrivateRoute path='/produto/novo' component={ProductEdit} />
					<PrivateRoute exact path='/fundo' component={FundList} />
					<PrivateRoute path='/fundo/editar' component={FundEdit} />
					<PrivateRoute exact path={'/fundo/novo'} component={FundEdit} />
					<PrivateRoute exact path='/grupo' component={GroupList} />
					<PrivateRoute path='/grupo/editar' component={GroupEdit} />
					<PrivateRoute exact path={'/grupo/novo'} component={GroupEdit} />
					<PrivateRoute exact path='/produto-conta' component={ProductAccountList} />
					<PrivateRoute path='/produto-conta/editar' component={ProductAccountEdit} />
					<PrivateRoute exact path={'/produto-conta/novo'} component={ProductAccountEdit} />
				</main>
			</div>

		</MuiPickersUtilsProvider >
	);

}

export default withRouter(PrivateApp);
