import React from 'react';

export const DashboardIcon = ({ className }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="path-1-inside-1" fill="white">
				<path d="M4 11H8C9.65685 11 11 9.65685 11 8V4C11 2.34315 9.65685 1 8 1H4C2.34315 1 1 2.34315 1 4V8C1 9.65685 2.34315 11 4 11Z" />
			</mask>
			<path d="M8 9H4V13H8V9ZM3 8V4H-1V8H3ZM9 4V8H13V4H9ZM8 -1H4V3H8V-1ZM13 4C13 1.23858 10.7614 -1 8 -1V3C8.55228 3 9 3.44772 9 4H13ZM4 9C3.44772 9 3 8.55228 3 8H-1C-1 10.7614 1.23858 13 4 13V9ZM8 13C10.7614 13 13 10.7614 13 8H9C9 8.55229 8.55229 9 8 9V13ZM3 4C3 3.44772 3.44772 3 4 3V-1C1.23858 -1 -1 1.23858 -1 4H3Z" fill="white" mask="url(#path-1-inside-1)" />
			<mask id="path-3-inside-2" fill="white">
				<path d="M4 23H8C9.65685 23 11 21.6569 11 20V16C11 14.3431 9.65685 13 8 13H4C2.34315 13 1 14.3431 1 16V20C1 21.6569 2.34315 23 4 23Z" />
			</mask>
			<path d="M8 21H4V25H8V21ZM3 20V16H-1V20H3ZM9 16V20H13V16H9ZM8 11H4V15H8V11ZM13 16C13 13.2386 10.7614 11 8 11V15C8.55228 15 9 15.4477 9 16H13ZM4 21C3.44772 21 3 20.5523 3 20H-1C-1 22.7614 1.23858 25 4 25V21ZM8 25C10.7614 25 13 22.7614 13 20H9C9 20.5523 8.55229 21 8 21V25ZM3 16C3 15.4477 3.44772 15 4 15V11C1.23858 11 -1 13.2386 -1 16H3Z" fill="white" mask="url(#path-3-inside-2)" />
			<mask id="path-5-inside-3" fill="white">
				<path d="M16 11H20C21.6569 11 23 9.65685 23 8V4C23 2.34315 21.6569 1 20 1H16C14.3431 1 13 2.34315 13 4V8C13 9.65685 14.3431 11 16 11Z" />
			</mask>
			<path d="M20 9H16V13H20V9ZM15 8V4H11V8H15ZM21 4V8H25V4H21ZM20 -1H16V3H20V-1ZM25 4C25 1.23858 22.7614 -1 20 -1V3C20.5523 3 21 3.44772 21 4H25ZM16 9C15.4477 9 15 8.55228 15 8H11C11 10.7614 13.2386 13 16 13V9ZM20 13C22.7614 13 25 10.7614 25 8H21C21 8.55229 20.5523 9 20 9V13ZM15 4C15 3.44772 15.4477 3 16 3V-1C13.2386 -1 11 1.23858 11 4H15Z" fill="white" mask="url(#path-5-inside-3)" />
			<mask id="path-7-inside-4" fill="white">
				<path d="M18 13C20.7614 13 23 15.2386 23 18C23 20.7614 20.7614 23 18 23C15.2386 23 13 20.7614 13 18C13 15.2386 15.2386 13 18 13Z" />
			</mask>
			<path d="M25 18C25 14.134 21.866 11 18 11V15C19.6569 15 21 16.3431 21 18H25ZM18 21C16.3431 21 15 19.6569 15 18H11C11 21.866 14.134 25 18 25V21ZM18 25C21.866 25 25 21.866 25 18H21C21 19.6569 19.6569 21 18 21V25ZM15 18C15 16.3431 16.3431 15 18 15V11C14.134 11 11 14.134 11 18H15Z" fill="white" mask="url(#path-7-inside-4)" />
		</svg>

	);
};
