import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import Button from 'components/Button/Button';
import ModalComponent from 'components/Modal/ModalComponent';
import SelectComponent from 'components/Select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from 'helpers/form-helpers';
import { validationSchema, defaultValues } from './warrantyValitionSchema';
import { useForm, useFormState, FieldValues, Controller } from 'react-hook-form';
import TextFieldComponent from 'components/TextInput/TextField';
import { mapDescription, mapTitle, WarrantyItem } from 'global/types/types';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import NumberInput from 'components/NumberInput/NumberInput';
import UF from "global/enums/uf";
import TextCustomMask from 'components/CustomMaskInput/TextCustomMask';
import Helper from 'helpers/format.helpers';

function HandleForm(props: any) {
	const { addItem } = props;
	const { handleSubmit, setValue, control, watch, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues.warrantyType === 'Warranty' ? {warrantyType: 'Warranty'} : defaultValues
	});
	const { errors } = useFormState({ control });
	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema, setValue, control, errors });

	const warrantyType = watch("warrantyType");

	const onSubmit = (item: FieldValues) => {
		addItem(item);
		reset(defaultValues);
	};

	const onError = (values: FieldValues) => {
		console.log(values);
		return false;
	};

	
	return (
		<form
			onSubmit={e => {
				e?.preventDefault();
				e?.stopPropagation();
				return handleSubmit(onSubmit, onError)(e);
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<SelectComponent
						id="warrantyType"
						label="Tipo de garantia"
						{...inputBind("warrantyType")}
						defaultValue=""
						fields={[
							{
								name: 'Selecione',
								value: 'Warranty'
							},
							{
								name: 'Veículo',
								value: 'Vehicle'
							},
							{
								name: 'Imóvel',
								value: 'HomeEquity'
							},
							{
								name: 'Processo Judicial',
								value: 'JudiciaryProcess'
							}
						]}
					/>
				</Grid>
				{warrantyType == "Vehicle" && (
					<>
						<Grid item xs={6}>
							<TextFieldComponent
								id="renavam"
								fullWidth
								type="text"
								label="RENAVAM"
								margin="dense"								
								{...inputBind("renavam")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="typeOfVehicle"
								fullWidth
								type="text"
								label="Tipo"
								margin="dense"								
								{...inputBind("typeOfVehicle")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="chassis"
								fullWidth
								type="text"
								label="Chassi"
								margin="dense"								
								{...inputBind("chassis")}
								required={true}
							/>

						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="board"
								fullWidth
								type="text"
								label="Placa"
								margin="dense"								
								{...inputBind("board")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="supplier"
								fullWidth
								type="text"
								label="Fornecedor"
								margin="dense"								
								{...inputBind("supplier")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="taxNumberOfSupplier"
								label="CNPJ do Fornecedor"
								fullWidth
								InputProps={{
									disableUnderline: true,
									inputComponent: TextCustomMask,
									inputProps: { mask: Helper.cnpjMask },
								}}
								{...inputBind("taxNumberOfSupplier")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="brand"
								fullWidth
								type="text"
								label="Marca"
								margin="dense"								
								{...inputBind("brand")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="color"
								fullWidth
								type="text"
								label="Cor"
								margin="dense"								
								{...inputBind("color")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="model"
								fullWidth
								type="text"
								label="Modelo"
								margin="dense"								
								{...inputBind("model")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="yearOfManufacture"
								fullWidth
								type="number"
								label="Ano de Fabricação"
								{...inputBind("yearOfManufacture")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="yearOfModel"
								fullWidth
								type="number"
								label="Ano do Modelo"
								{...inputBind("yearOfModel")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="typeOfFuel"
								fullWidth
								type="text"
								label="Tipo de Combustível"
								margin="dense"
								multiline={true}
								rows={1}
								{...inputBind("typeOfFuel")}
								required={true}
							/>
						</Grid>
					</>
				)}

				{warrantyType == "HomeEquity" && (
					<>
						<Grid item xs={6}>
							<TextFieldComponent
								id="registryOffice"
								fullWidth
								type="text"
								label="Cartório de Registro"
								margin="dense"
								{...inputBind("registryOffice")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="registrationNumber"
								fullWidth
								type="text"
								label="Matrícula"
								margin="dense"
								{...inputBind("registrationNumber")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<NumberInput								
								id="width"
								label="Largura (em metros)"		
								name="width"
								adornmentText=""
								decimalScale={2}
								{...inputBindNumber("width")}							
							/>
						</Grid>
						<Grid item xs={6}>
							<NumberInput								
								id="length"
								label="Comprimento (em metros)"		
								name="length"
								adornmentText=""
								decimalScale={2}
								{...inputBindNumber("length")}							
							/>							
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="addressName"
								fullWidth
								type="text"
								label="Endereço"
								margin="dense"
								{...inputBind("addressName")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="addressNumber"
								fullWidth
								type="text"
								label="Número"
								margin="dense"
								{...inputBind("addressNumber")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="complementAddress"
								fullWidth
								type="text"
								label="Complemento"
								margin="dense"
								{...inputBind("complementAddress")}
								required={true}
							/>
						</Grid>						
						<Grid item xs={6}>
							<TextFieldComponent
								id="zipCodeAddress"
								fullWidth
								type="text"
								label="CEP"
								margin="dense"
								InputProps={{
									disableUnderline: true,
									inputComponent: TextCustomMask,
									inputProps: { mask: Helper.formatCepForInput},
								}}
								{...inputBind("zipCodeAddress")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<SelectComponent
								id="uf"
								label="UF"
								defaultValue=""
								{...inputBind(`uf`)}
								fields={UF}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="district"
								fullWidth
								type="text"
								label="Bairro"
								margin="dense"
								{...inputBind("district")}
								required={true}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextFieldComponent
								id="propertyFeatures"
								fullWidth
								type="text"																
								margin="dense"
								label={"Características do imóvel"}
								multiline={true}
								rows={4}
								fieldAreaHeight={100}
								{...inputBind("propertyFeatures")}
							/>
						</Grid>						
					</>)}
				{warrantyType == "JudiciaryProcess" && (
					<>
						<Grid item xs={6}>
							<TextFieldComponent
								id="number"
								fullWidth
								type="text"
								label="Nº do processo"
								margin="dense"								
								{...inputBind("number")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="court"
								fullWidth
								type="text"
								label="Vara / Tribunal"
								margin="dense"								
								{...inputBind("court")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<NumberInput								
								id="valueOfCause"
								label="Valor da causa"		
								name="valueOfCause"
								adornmentText="R$ "
								decimalScale={2}
								{...inputBindNumber("valueOfCause")}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="claimant"
								fullWidth
								type="text"
								label="Requerente / Autor"
								margin="dense"								
								{...inputBind("claimant")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="claimed"
								fullWidth
								type="text"
								label="Requerido / Réu"
								margin="dense"								
								{...inputBind("claimed")}
								required={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="typeOfProcess"
								fullWidth
								type="text"
								label="Tipo de processo"
								margin="dense"								
								{...inputBind("typeOfProcess")}
								required={true}
							/>
						</Grid>
					</>)}
			</Grid>
			<br />
			<Grid container justifyContent='center' spacing={10}>
				<Grid item>
					<Button
						variant="contained"
						type="submit"
						children={"Confirmar"}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

function WarrantyTab(props: any) {
	const { getValues, setValue, watch, inputBind } = props;
	const [modalOpen, setModalOpen] = useState(false);
	const toggleModal = () => {
		setModalOpen(!modalOpen);
	};

	const data = (watch("warranty") || []).map((item: WarrantyItem) => {
		var casted = validationSchema.cast(item);
		return casted;
	});

	let actions = [
		{
			icon: 'delete',
			tooltip: 'Excluir documento',
			onClick: (event: any, rowData: WarrantyItem | WarrantyItem[]) => {
				deleteAsset(rowData);
			}
		}
	];

	const deleteAsset = function (rowData: WarrantyItem | WarrantyItem[]) {
		// @ts-expect-error
		var index = rowData?.tableData?.id;
		var current = [...getValues("warranty")];
		current.splice(index, 1);
		setValue("warranty", [...current]);
	};

	const table = <MaterialTable
		title={<Button onClick={() => {
			setModalOpen(!modalOpen);
		}}>+ Garantia</Button>}
		columns={[
			{
				title: 'Tipo de garantia',
				render: (item) => mapTitle(item),
				field: 'warrantyType',
				cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200 },
				editComponent: (props) => {
					return (<TextField
						fullWidth={true}
						value={props.value}
						onChange={(event) => props.onChange(event.target.value)}
					/>);
				}
			},
			{
				title: 'Descrição',
				field: 'description',
				render: (item) => mapDescription(item),
				editComponent: (props) => {
					return (<TextField
						value={props.value}
						onChange={(event) => props.onChange(event)}
					/>);
				}
			}
		]}
		actions={actions}
		options={{
			pageSize: 5,
			pageSizeOptions: [1, 5, 10, 20],
			actionsColumnIndex: -1,
			toolbarButtonAlignment: 'left',
			search: false
		}}
		data={data}
		localization={localizationOptions}
	/>;

	return (
		<>
			<Box mt={2}>
				<Grid container>
					<Grid item xs={12}>
						{table}
					</Grid>
					<Grid item xs={12}>
						<Box mt={2}>
							<TextFieldComponent
								id="collateral"
								fullWidth
								type="text"
								name="collateral"
								component={TextField}
								margin="dense"
								label={"Observações"}
								multiline={true}
								rows={4}
								fieldAreaHeight={100}
								{...inputBind("collateral")}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<ModalComponent
				open={modalOpen}
				onClose={toggleModal}
				title="Adicionar/Alterar Garantia"
				subtitle="Escolha a opção e insira as informações necessárias"
				enableButton={false}
				children={<HandleForm addItem={(item: WarrantyItem) => {
					setValue("warranty", [...data, item]);
					toggleModal();
				}} />}
			/>
		</>
	);
}

export default WarrantyTab;