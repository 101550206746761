import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';
import { pickBy } from 'lodash';

export async function getLegalPersonById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/LegalPerson/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;
	try {

		await axios.get(url, config).then(response => {
			if (response.data) {
				response.data.averageMonthlyRevenue = response.data?.averageMonthlyRevenue;
				response.data.averageGrossProfitMargin = response.data?.averageGrossProfitMargin * 100; // from decimal to pct
				response.data.initialShareCapital = response.data?.initialShareCapital / 100;// from cents do decimal
				response.data.netWorth = response.data?.netWorth / 100;
				response.data.phone = response.data?.phone?.replace('+55', '');
				response.data.purchases = response.data?.purchases / 100;
				response.data.amountRentedFinanced = response.data?.amountRentedFinanced / 100;
				response.data.exportPercentage = response.data?.exportPercentage / 100;
				response.data.costWithSuppliers = response.data?.costWithSuppliers / 100;
				response.data.spouseCommercialPhone = response.data?.spouseCommercialPhone / 100;
				response.data.payrollValue = response.data?.payrollValue / 100;
				response.data.spendingAmountWithTax = response.data?.spendingAmountWithTax / 100;
				response.data.averageSalesPeriod = response.data?.averageSalesPeriod / 100;
				response.data.fosterablePotential = response.data?.fosterablePotential * 100;// from decimal to pct
				response.data.delayedSalePercentage = response.data?.delayedSalePercentage * 100;// from decimal to pct
			}
			result = response;
		});

	}
	catch (err) {
		Toast.showErrorMessage("Não foi possível obter o cliente", err);
	}
	return result;
}

export async function getLegalPersonsList(filters, tenant) {

	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));
	delete params.orderByField;
	delete params.orderByDirection;

	var token = await getToken();
	var url = ApiConfig.baseURL + `/LegalPerson`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};
	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de clientes", err);
		}
		throw err;
	}
}


export async function editOrCreateLegalPerson(data, option, tenant) {
	data = {
		...data,
		address: data.address._isEmpty ? null : data.address
	};
	const onlyNumbers = /\D/g;
	if (data.bankAccounts) {
		data.bankAccounts.forEach(bankAccount => {
			bankAccount.pixKeyTypeValue == "AgencyAndAccount" ? bankAccount.keyPix = null : bankAccount.keyPix = bankAccount.keyPix;
			if(bankAccount.pixKeyTypeValue == "Phone") {
				if(bankAccount?.keyPix?.startsWith("+55")) {
					return bankAccount.keyPix = bankAccount?.keyPix;
				} else {
					return bankAccount.keyPix = "+55" + bankAccount?.keyPix?.replace(onlyNumbers, '');
				}
			}
			return bankAccount;
		});
	}

	const payload = {
		...data,
		registrationNumber: data?.registrationNumber?.toString().replace(onlyNumbers, ''),
		municipalRegistration: data?.municipalRegistration?.toString().replace(onlyNumbers, ''),
		['address.zipCode']: data?.address?.zipCode?.toString().replace(onlyNumbers, ''),
		stateRegistration: data?.stateRegistration?.toString().replace(onlyNumbers, ''),
		phone: data?.phone?.toString().replace(onlyNumbers, ''),
		phone2: data?.phone2?.toString().replace(onlyNumbers, ''),
		averageMonthlyRevenue: data?.averageMonthlyRevenue, // from currency to cents
		averageGrossProfitMargin: data?.averageGrossProfitMargin / 100,// from pct to decimal
		initialShareCapital: data?.initialShareCapital * 100,// from currency to cents
		netWorth: data?.netWorth * 100,
		purchases: data?.purchases * 100,
		amountRentedFinanced: data?.amountRentedFinanced * 100,
		exportPercentage: data?.exportPercentage * 100,
		costWithSuppliers: data?.costWithSuppliers * 100,
		spouseCommercialPhone: data?.spouseCommercialPhone * 100,
		payrollValue: data?.payrollValue * 100,
		spendingAmountWithTax: data?.spendingAmountWithTax * 100,
		averageSalesPeriod: data?.averageSalesPeriod * 100,
		fosterablePotential: data?.fosterablePotential / 100,// from pct to decimal
		delayedSalePercentage: data?.delayedSalePercentage / 100,
	};

	var token = await getToken();

	var url = payload.id ?
		ApiConfig.baseURL + `/LegalPerson/${data.id}` :
		ApiConfig.baseURL + `/LegalPerson`;

	var urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(urlWithTenant, payload, config),
			successMessage: 'Cliente cadastrado com sucesso',
			errorMessage: 'Ocorreu um erro no cadastro do cliente'
		},
		edit: {
			function: () => axios.put(urlWithTenant, payload, config),
			successMessage: 'Cliente editado com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar o cliente'
		}
	};
	let result;

	try {

		result = await request[option].function();

		Toast.showSuccessMessage(request[option].successMessage);

		return result;

	}
	catch (err) {
		if (err.response) {
			if (err.response?.data.message) {
				const message = err.response.data.message + '</br>' + err?.response?.data?.errors.map(err => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message, err);
			} else if (err.response?.data?.errors) {
				Toast.showErrorMessage(err?.response.data?.errors);
			} else if (err.response?.data) {
				Toast.showErrorMessage(err?.response.data);
			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}

		return Promise.reject(err);
	}
}


export async function deleteLegalPersonById(id) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/LegalPerson/${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await (await axios.delete(url, config)).data;
		Toast.showSuccessMessage("Cliente excluído com sucesso.");

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao excluir e empresa");
		}
	}
	return result;
}
