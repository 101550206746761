import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';
import * as fundService from 'services/fund';

export async function postCNAB400(data) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Cnab/Cnab400`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Arquivo CNAB enviado com sucesso");
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo CNAB", err);
		}
		throw err;
	}
}

export async function postCNAB400B(data) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Cnab/Cnab400B`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Arquivo CNAB enviado com sucesso");
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo CNAB", err);
		}
		throw err;
	}
}

export async function postCNAB444(data, fundId) { 
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Cnab/Cnab444?fundId=${fundId}`;	
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Arquivo CNAB enviado com sucesso");
		return result;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo CNAB", err);
		}
		throw err;
	}
}

export async function postCNAB444REAG(data, fundId) { 
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Cnab/Cnab444REAG?fundId=${fundId}`;	
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Arquivo CNAB enviado com sucesso");
		return result;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo CNAB", err);
		}
		throw err;
	}
}

export async function postCNAB500(data, id) {
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Cnab/Cnab500?fundId=${id}`;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Arquivo CNAB enviado com sucesso");
		return result;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo CNAB", err);
		}
		throw err;
	}
}

export async function postCNAB550(data, id) { 
	var token = await getToken();
	var url = ApiConfig.baseURL + `/Cnab/Cnab550?fundId=${id}`;	
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Arquivo CNAB enviado com sucesso");
		return result;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo CNAB", err);
		}
		throw err;
	}
}

export async function liquidationFileWebCred(data) { 
	var token = await getToken();
	var url = ApiConfig.baseURL + `/LiquidationFileWebCred`; 
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, data, config);
		Toast.showSuccessMessage("Relatório de Liquidação enviado com sucesso");
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível enviar o arquivo de Relatório de Liquidação", err);
		}
		throw err;
	}
}
