import React, { useEffect, useState, useCallback } from 'react';
import MaterialTable, { MTableBodyRow } from "material-table";
import { localizationOptions } from "helpers/table.helpers";
import moment from "moment";
import { getBankAccountStatement } from "services/digitalBankAccount";
import Helper from 'helpers/format.helpers';
import { Button, TableCell, TablePagination, TableRow, Typography } from "@material-ui/core";
import PeriodFilter from "pages/BankAccountRequest/Details/AccountStatement/PeriodFilter/Period";
import { subDays } from "date-fns";
import { useParams } from 'react-router';
import { RouteWithIdParamsType } from 'global/types/types';
import { ContentContainer } from 'components';
import { BankAccountTransactionBundleOrTransaction, QueryFiltersType, TransactionType } from "global/types/digitalAccount.types";

function FormatedAccountNumber(account: string) {

	//Ex: account = "457-0001 - 14747-8" é usado o replace para remover o traço e o espaço, e verifica se é apenas números, caso não seja, retorna o valor original.
	let onlyNumber = account?.replaceAll("-", "");
	let removeSpace = onlyNumber?.replaceAll(" ", "");

	if (isNaN(Number(removeSpace))) {
		return account;
	}

	let BCO = onlyNumber.substring(0, 3);
	let Ag = onlyNumber.substring(3, 7);
	let Conta = onlyNumber.substring(9);

	return `${BCO} Ag: ${Ag} Conta:${Conta}`;

}

const AccountStatement = () => {

	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const [rowsPerPage, setRowsPerPage] = useState<number>(Number(localStorage.getItem('rowsPerPage')) || 5);
	const [statement, setStatement] = useState<BankAccountTransactionBundleOrTransaction[]>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const tableRef = React.useRef(null);
	const [period, setPeriod] = useState({ initialDate: subDays(new Date(), 15), finalDate: new Date(), period: 'Últimos 15 dias' });

	const getStatement = useCallback(async (filters: QueryFiltersType) => {
		const response = await getBankAccountStatement(bankAccountId, filters);
		let data = response.balances.reduce((acc: BankAccountTransactionBundleOrTransaction[], value) => {
			acc.push(value);
			return acc.concat(value.transactions);
		}, []);

		setStatement(data);
	}, []);

	useEffect(() => {
		const filters = {
			initialDate: period.initialDate,
			finalDate: period.finalDate,
		};
		//@ts-ignore
		getStatement(filters).then(() => { });
	}, [period]);

	const onRowsPerPageChange = (page: number) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', String(page));
	};

	return (
		<ContentContainer>
			<PeriodFilter onClick={(date) => setPeriod(date)} open={modalOpen} onClose={() => setModalOpen(false)} />
			<Button className='button-filter' onClick={() => setModalOpen(true)} >
				<i className="far fa-calendar-alt"></i>&nbsp;
				<Typography id="filter-text"> Filtrar período </Typography>
			</Button>
			<br />
			<br />
			<MaterialTable
				style={{ paddingLeft: 20, paddingRight: 20, maxWidth: '91vw' }}
				tableRef={tableRef}
				title={`Extrato - ${period.period}`}
				columns={[
					{ title: 'Data da Ocorrência', field: 'issueDate', filtering: false, render: ({ issueDate }) => moment(issueDate).format('DD/MM/YY') },
					{ title: 'Lançamento', field: 'description', filtering: false, render: ({ description, information }: TransactionType) => information ? description + " para " + information : description },
					{ title: 'Descrição', field: 'registrationNumber', render: ({ registrationNumber }) => FormatedAccountNumber(registrationNumber) },
					{ title: 'Valor', field: 'amount', render: ({ amount }) => Helper.formatCurrencyAsIs(amount /100), cellStyle: (_, { movementDisplay }) => ({ color: movementDisplay === 'DEBITO' ? '#F50057' : '#00CD2D' }) },
				]}
				//@ts-ignore
				data={statement}
				onChangeRowsPerPage={onRowsPerPageChange}
				//@ts-ignore
				onRowsPerPageChange={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
					Row: props => {
						return props.data.availableValue > 0 ?
							(
								<TableRow style={{ backgroundColor: '#f7f7f7' }} hover={true}>
									<TableCell colSpan={2} size="medium">{moment(props.data?.issueDate).format('DD/MM/YY')}</TableCell>
									<TableCell colSpan={2} size="medium" align="right"><b>SALDO DO DIA: </b>{Helper.formatCurrencyAsIs(props.data?.availableValue / 100)}</TableCell>
								</TableRow>
							) : <MTableBodyRow {...props} />;
					}
				}}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					search: false
				}}
			/>
		</ContentContainer>
	);
};

export default AccountStatement;