import React from 'react';
import TextFieldComponent from 'components/TextInput/TextField';
import { Grid, Box, TextField } from '@material-ui/core';

function InformationsGroup(props) {
	const {
		isEditing,
		inputBindNumber,
		inputBind,
	} = props;

	return (
		<Box p={0} mt={3}>
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<TextFieldComponent
						id="groupName"
						name="groupName"
						label="Nome do Grupo"
						component={TextField}
						fullWidth
						{...inputBind(`groupName`)}
						disabled={isEditing() == true}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextFieldComponent
						id="precedence"
						name="precedence"
						label="Precedência"
						type="number"
						fullWidth
						{...inputBindNumber("precedence")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						rows={6}
						multiline={true}
						fieldAreaHeight={120}
						id="description"
						name="description"
						label="Descrição"
						component={TextField}
						fullWidth
						{...inputBind(`description`)}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
export default InformationsGroup;
