import axios from 'axios';
import { getToken } from 'repository/cognito';
import { ApiConfig } from 'config';
import { Toast } from '../components';

export async function getGroupList(props) {
	const { size, page, paginationToken, searchString, selectedTenant } = props;

	var token = await getToken();
	var url = ApiConfig.baseURL + `/UserGroup?size=${size}&page=${page}`;

	url = paginationToken ? url + `&paginationToken=${paginationToken}` : url;

	url = selectedTenant ? url + `&tenant=${selectedTenant}` : url;

	url = searchString ? url + `&searchString=${searchString}` : url;
	
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de grupo", err);
		}
		throw err;
	}
}

export async function getUsersInGroupList(props) {
	const { size, page, nextToken, groupNameId, selectedTenant } = props;

	var token = await getToken();
    
	var url = ApiConfig.baseURL + `/UserGroup/GetUsersByGroup/${groupNameId}?size=${size}&page=${page}`;

	url = selectedTenant ? url + `&tenant=${selectedTenant}` : url;

	url = nextToken ? url + `&nextToken=${nextToken}` : url;
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível obter a lista de usuários em grupo", err);
		}
		throw err;
	}
}

export async function postGroupInsertUser(groupName, userId, selectedTenant) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/UserGroup/${groupName}/InsertUser/${userId}`;

	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.post(url, {}, config),
		Toast.showSuccessMessage("Operador associado a um grupo com sucesso");
		return result.data;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Não foi possível associar o operador a um grupo", err);
		}
		throw err;
	}
}

export async function removeUserGroupById(groupName, userId, selectedTenant) {

	var token = await getToken();    
	var url = ApiConfig.baseURL + `/UserGroup/${groupName}/RemoveUser/${userId}`;

	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.delete(url, config);
		Toast.showSuccessMessage("Operador removido do grupo com sucesso.");
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao tentar remover o operador do grupo");
		}
		throw err;

	}
}

export async function deleteGroupById(groupName, selectedTenant) {

	var token = await getToken();
	var url = ApiConfig.baseURL + `/UserGroup/${groupName}`;
	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.delete(url, config);
		Toast.showSuccessMessage("Grupo excluído com sucesso.");
		return result;

	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao tentar excluir o grupo");
		}
		throw err;

	}
}

export async function editOrCreate(data, option, groupId, selectedTenant) {

	var token = await getToken();
	var url = groupId ?
		ApiConfig.baseURL + `/UserGroup/${groupId}` :
		ApiConfig.baseURL + `/UserGroup`;

	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	const request = {
		create: {
			function: () => axios.post(url, data, config),
			successMessage: 'Grupo cadastrado com sucesso',
			errorMessage: 'Ocorreu um erro no cadastrar o grupo'
		},
		edit: {
			function: () => axios.put(url, data, config),
			successMessage: 'Grupo editado com sucesso',
			errorMessage: 'Ocorreu um erro ao atualizar o grupo'
		}
	};

	let result;

	try {

		result = await request[option].function();
		Toast.showSuccessMessage(request[option].successMessage);
		return result;

	}
	catch (err) {
		if (err.response) {
			if (err.response?.data.message) {
				const message = err.response.data.message + '</br>' + err?.response?.data?.errors.map(err => {
					if (err.message) {
						return err.message;
					}
					return err;
				});
				Toast.showErrorMessage(message, err);
			} else if (err.response?.data?.errors) {
				Toast.showErrorMessage(err?.response.data?.errors);
			} else {
				Toast.showErrorMessage(err.response.data || request[option].errorMessage);

			}
		} else {
			Toast.showErrorMessage(request[option].errorMessage);
		}
		throw err;
	}
}

export async function getGroupById(groupName, selectedTenant) {
	
	var token = await getToken();
	var url = ApiConfig.baseURL + `/UserGroup/${groupName}`;
	url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		if (err?.response?.data?.errors) {
			Toast.showErrorMessage(err.response.data.errors);
		} else {
			Toast.showErrorMessage("Ocorreu um erro ao tentar obter as informações do grupo");
		}
		throw err;

	}
}
