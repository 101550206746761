import axios from 'axios';
import { getToken } from "../repository/cognito";
import { ApiConfig } from "../config";
import { Toast } from '../components';
import { pickBy } from 'lodash';

type PersonFiltersType = {
	page: number;
	size: number;
	searchString: string;
};

export async function getPersonsList(filters: PersonFiltersType, tenant: string | undefined) {

	const params = pickBy(filters, v => (v !== undefined && v !== ''));

	const token = await getToken();
	let url = ApiConfig.baseURL + `/Person`;
	if (tenant) url = url + "?tenant=" + tenant;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		return result.data;
	}
	catch (err) {
		Toast.showErrorMessage("Não foi possível obter a lista de clientes");
	}
	return result;
}
