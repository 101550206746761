import { object, date, number, string } from "yup";

function YupFieldSchema(yupSchema: any) {
	return yupSchema.typeError('Valor precisa ser um número').transform((value: any, _: any) => {
		return isNaN(value) ? 0 : value;
	});
}

function YupSchema(yupSchema: string) {

	if (yupSchema != "" || yupSchema != null) {
		if (yupSchema.length < 44) {
			return string().typeError('valor precisa ser um número.').required('Esse campo é obrigatório.')
				.matches(/^[0-9]{44}$/, 'Código de barras: Precisa ter 44 dígitos.');
		} else {
			if (yupSchema.length >= 45) {
				return string().typeError('valor precisa ser um número.').required('Esse campo é obrigatório.')
					.max(48, 'Linha digitável: Precisa ter no máximo 48 dígitos.');
			}
		}
	}
	return string().typeError('valor inválido.').required('Esse campo é obrigatório.');

}

export const BankSlipValidationSchema = (bankSlip: any) => {
	return object().shape({
		barCode: YupSchema(bankSlip),
		paymentDate: date().typeError('Data inválida.').required('Data de pagamento: Precisa ser preenchido.'),
		dueDate: date().typeError('Data inválida.').required('Data de vencimento: Precisa ser preenchido.'),
		amount: YupFieldSchema(number()),
		value: YupFieldSchema(number()),
		discountValue: YupFieldSchema(number()),
		otherDiscountValue: YupFieldSchema(number()),
		totalValue: YupFieldSchema(number()),
		discount: YupFieldSchema(number()),
		sessionId: string().nullable(),
		code: string().nullable()
	});
};

export const defaultValues = {
	barCode: "",
	paymentDate: new Date().toDateString(),
	dueDate: new Date().toDateString(),
	amount: 0,
	value: 0,
	discountValue: 0,
	otherDiscountValue: 0,
	totalValue: 0,
	discount: 0,
	sessionId: "",
	code: ""
};