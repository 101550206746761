import React, { useEffect, useState } from 'react';
import { ContentContainer, Toast } from 'components';
import * as operatorService from '../../../../services/operator';
import { useHeader } from 'contexts/header';
import InformationsUsers from '../FieldsFom/informationsUsers';
import ActionBar from 'components/ActionBar/ActionBar';
import { AppBar, Tabs, Tab, Grid } from '@material-ui/core';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from 'helpers/form-helpers';
import { getValidationSchema, defaultValues } from './validation';
import { useHistory } from 'react-router-dom';
import Groups from '../Tabs/group';
import PermissionsList from '../Tabs/permissions';
import { useLoading } from 'contexts/loading';
import * as permissionServices from 'services/permission';
import { Alert } from '@material-ui/lab';
import { useTenant } from 'contexts/tenant';

const OperatorEdit = (props) => {

	const {selectedTenant} = useTenant();
	const history = useHistory();
	const { loading, addLoader, completeLoader } = useLoading();
	const [operator, setOperator] = useState();
	const { setTitle } = useHeader();
	const [tab, setTab] = useState('Informações');
	const [permissionData, setPermissionData] = useState([]);

	const getOperatorId = () => window?.location?.search?.replace('?id=', '');
	function isEditing() {
		return !!getOperatorId();
	}
	const { handleSubmit, setValue, getValues, control, reset, formState: { isDirty } } = useForm({
		resolver: yupResolver(getValidationSchema(isEditing())),
		defaultValues: defaultValues
	});
	const { errors } = useFormState({ control });
	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema: getValidationSchema(isEditing()), setValue, control, errors });

	useEffect(() => {
		if (isEditing()) {
			fetchOperatorInfoById();
		} else {
			setTitle("Operador - Criar");
		}
	}, []);

	useEffect(() => {
		if (operator) {
			reset(operator);
			getValues();
		}
	}, [operator]);

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const fetchOperatorInfoById = async () => {
		addLoader('fetchOperatorInfoById');
		await operatorService.getOperatorById(getOperatorId(), selectedTenant)
			.then(response => {		
				setOperator(response);				
				response?.name && setTitle("Operador - " + response?.name);
				completeLoader('fetchOperatorInfoById');
			}).catch(err => {
				completeLoader('fetchOperatorInfoById');
			});
	};

	const resetUserPassword = () => {
		addLoader('resetUserPassword');
		operatorService.resetPasswordOperator(getOperatorId(), selectedTenant).then(_ => {
			completeLoader('resetUserPassword');
			Toast.showSuccessMessage(`Senha do operador resetada com sucesso.`, 'Sucesso');
		}).catch(err => { completeLoader('resetUserPassword'); console.log(err); });
	};

	const disableUserMfa = () => {
		addLoader('disableUserMfa');
		operatorService.disableMFA(getOperatorId(), selectedTenant).then(_ => {
			Toast.showSuccessMessage(`MFA desabilitado com sucesso.`, 'Sucesso');
			completeLoader('disableUserMfa');
		}).catch((err) => { console.log(err); completeLoader('disableUserMfa'); });
	};
	const changeFieldValue = (event, fieldName) => {
		const operatorData = operator;
		const newState = {
			...operatorData,
			[fieldName]: event.target.value
		};
		setOperator(newState);
	};

	const saveOperator = async (values) => {
		let option = isEditing() ? "edit" : 'create';

		addLoader('saveOperator');
		await operatorService.editOrCreateOperator(values, option, getOperatorId(), selectedTenant)
			.then(result => {
				completeLoader('saveOperator');
				if (result.data) {
					history.push(`/operador/editar?id=${result.data.username}`);
				} else {
					completeLoader('saveOperator');
					fetchOperatorInfoById();
				}
				return result;
			})
			.catch(err => {
				completeLoader('saveOperator');
				console.log('Error:', err);
			});
	};

	const handleSavePermission = () => {
		addLoader("handleSavePermission");
		permissionServices.postPermissionList(permissionData, getOperatorId(), selectedTenant, "User").then(_ => {
			addLoader("handleSavePermission");
			permissionServices.getPermissionList(getOperatorId(), selectedTenant, "User").then((permissions) => {
				setPermissionData(permissions);
				completeLoader("handleSavePermission");
			});
		}).catch(err => { console.log(err); completeLoader("handleSavePermission"); });
	};

	const actions = isEditing() ? {
		onResetPassword: resetUserPassword,
		onDisableMFA: disableUserMfa,
		onSavePermission: handleSavePermission,
		isDirty: isDirty,
		disabledAll: loading
	} : {
	};

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsUsers {...{ operator, isEditing, saveOperator, changeFieldValue, inputBindNumber, inputBind, setValue }} />
		},
		isEditing() ? {
			name: 'Grupos',
			show: true,
			children: <Groups  {...{ userId: getOperatorId(), isEditing }} />
		} : null,
		isEditing() ? {
			name: 'Permissões',
			show: true,
			children: <PermissionsList  {...{ userId: getOperatorId(), permissionData, setPermissionData }} />
		} : null
	].filter(tab => tab != null);

	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const onSubmit = (values) => {
		saveOperator(values);
	};

	return (
		<ContentContainer>
			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<ActionBar {...actions} />
				{isEditing() && isDirty && <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }} >
					<Alert severity="warning">Existem informações não salvas</Alert>
				</Grid>}
				<AppBar position="relative" color="transparent" elevation={0}>
					<Tabs
						centered={false}
						value={tab}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabs.map((item, index) => (
							item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
						))}
					</Tabs>
				</AppBar>
				{
					tabs.map((item, index) =>
						<TabPanel key={index} value={tab} index={item.name}>
							{item.children}
						</TabPanel>
					)
				}
			</form>
		</ContentContainer>
	);
};

export default OperatorEdit;