import { string, object, date, number, boolean, addMethod, lazy } from 'yup';
import Helper from 'helpers/schema.helpers';

addMethod(string, 'customCPFValidator', function () {
	return this.test({
		name: 'cpfValidation',
		message: 'CPF não é válido.',
		test: (value) => {
			return value ? Helper.cpfValidate(value) : true;
		}
	});
});

addMethod(string, 'customCNPJValidator', function () {
	return this.test({
		name: 'cnpjValidation',
		message: 'CNPJ não é válido.',
		test: (value) => {
			return value ? Helper.cnpjValidate(value) : true;
		}
	});
});

const REQUIRED_FIELDS_STR = window.__RUNTIME_CONFIG__.REACT_APP_REQUIRED_FIELDS_LEGALPERSON;

const REQUIRED_FIELDS = REQUIRED_FIELDS_STR?.split(',');

function isFieldRequired(fieldName) {
	return REQUIRED_FIELDS?.indexOf(fieldName) > -1;
}

function generateRequiredForYupFieldSchema(yupSchema, fieldName, errorMsg) {
	return isFieldRequired(fieldName) ?
		yupSchema.required(errorMsg) :
		yupSchema.nullable();
}

const isNullOrEmpty = (value) => {
	return value == null || value == "";
};

export const validationSchema = object().shape({
	registrationNumber: generateRequiredForYupFieldSchema(string(), "registrationNumber", "CNPJ da empresa é obrigatório"),
	companyName: generateRequiredForYupFieldSchema(string(), "companyName", "Nome da Empresa é obrigatório."),
	name: generateRequiredForYupFieldSchema(string(), "name", "Nome fantasia é obrigatório."),
	typeOfActivity: generateRequiredForYupFieldSchema(string(), "typeOfActivity", "Ramo de atividade é obrigatório."),
	municipalRegistration: generateRequiredForYupFieldSchema(string().typeError("Inscrição municipal é obrigatório."), "municipalRegistration", "Inscrição municipal é obrigatório."),
	stateRegistration: generateRequiredForYupFieldSchema(string().typeError("Inscrição estadual é obrigatório."), "stateRegistration", "Inscrição estadual é obrigatório."),
	openDate: generateRequiredForYupFieldSchema(date().typeError('Data inválida'), "openDate", 'Data inválida'),
	email: generateRequiredForYupFieldSchema(string(), "email", "Email é obrigatório."),
	site: generateRequiredForYupFieldSchema(string().typeError("Site é obrigatório."), "site", "Site é obrigatório."),
	responsibleId: generateRequiredForYupFieldSchema(string().typeError("Responsável é obrigatório."), "responsibleId", "Responsável é obrigatório."),
	address: lazy(value => {
		var nothingIsFiled = isNullOrEmpty(value?.addressName) && isNullOrEmpty(value?.zipCode) && isNullOrEmpty(value?.city)
			&& isNullOrEmpty(value?.uf) && isNullOrEmpty(value?.district) && isNullOrEmpty(value?.number);
		var returnValue = (nothingIsFiled && !isFieldRequired("address") ? object().nullable() : object().shape({
			addressName: generateRequiredForYupFieldSchema(string().typeError('O campo Endereço é obrigatório.'), "address", "Endereço é obrigatório."),
			zipCode: generateRequiredForYupFieldSchema(string().typeError('O campo CEP é obrigatório.'), "address", "CEP é obrigatório."),
			city: generateRequiredForYupFieldSchema(string().typeError('O campo Cidade é obrigatório.'), "address", "Cidade é obrigatório."),
			uf: generateRequiredForYupFieldSchema(string().typeError('O campo UF é obrigatório.'), "address", "UF é obrigatório."),
			district: generateRequiredForYupFieldSchema(string().typeError('O campo Bairro é obrigatório.'), "address", "Bairro é obrigatório."),
			number: generateRequiredForYupFieldSchema(string().typeError('O campo Número é obrigatório.'), "address", "Número é obrigatório."),
			complement: string().nullable()
		})).transform((value, _) => {
			return Object.values(value).every(x => x === null) ? {
				_isEmpty: true
			} : value;
		});
		return returnValue;
	}),
	legalNature: generateRequiredForYupFieldSchema(string().typeError('Natureza Jurídica é obrigatória.'), "legalNature", "Natureza Jurídica é obrigatória."),
	phone: generateRequiredForYupFieldSchema(string(), "phone", "Telefone é obrigatório."),
	phoneExtension: string().nullable(),
	phone2: string().nullable(),
	phone2Extension: string().nullable(),
	economicActivityCodeId: generateRequiredForYupFieldSchema(string().typeError('O campo CNAE é obrigatório.'), "economicActivityCodeId", "CNAE é obrigatório."),
	averageMonthlyRevenue: generateRequiredForYupFieldSchema(number().typeError('Valor precisa ser um número.'), "averageMonthlyRevenue", "Faturamento médio mensal é obrigatório."),
	averageGrossProfitMargin: number().nullable(),
	numberOfEmployees: generateRequiredForYupFieldSchema(number().typeError('O campo Número de Funcionários é obrigatório.'), "numberOfEmployees", "Número de Funcionários é obrigatório."),
	initialShareCapital: number().nullable(),
	netWorth: number().nullable(),
	purchases: generateRequiredForYupFieldSchema(number().typeError('Valor precisa ser um número.'), "purchases", "Faturamento médio mensal é obrigatório."),
	amountRentedFinanced: number().nullable(),
	exportPercentage: number().nullable(),
	costWithSuppliers: number().nullable(),
	payrollValue: number().nullable(),
	spendingAmountWithTax: string().nullable(),
	typeOfResidence: string().nullable(),
	averageSalesPeriod: string().nullable(),
	fosterablePotential: number().nullable(),
	delayedSalePercentage: number().nullable(),
	description: string().nullable(),
	allowAccessToSchedule: boolean().nullable(),
	allowAccessToReceivables: boolean().nullable(),
	allowPartnerApiSubscription: boolean().nullable(),
});


export const defaultValues = {
	registrationNumber: "",
	companyName: "",
	name: "",
	typeOfActivity: "",
	municipalRegistration: "",
	stateRegistration: "",
	openDate: null,
	email: "",
	site: "",
	legalNature: "",
	responsibleId: "",
	address: null,
	phone: "",
	phoneExtension: "",
	phone2: "",
	phone2Extension: "",
	economicActivityCodeId: "",
	averageMonthlyRevenue: null,
	averageGrossProfitMargin: 0,
	initialShareCapital: 0,
	netWorth: 0,
	purchases: null,
	amountRentedFinanced: 0,
	exportPercentage: 0,
	costWithSuppliers: 0,
	numberOfEmployees: null,
	payrollValue: 0,
	spendingAmountWithTax: 0,
	typeOfResidence: "",
	averageSalesPeriod: "",
	fosterablePotential: 0,
	delayedSalePercentage: 0,
	description: "",
	allowAccessToSchedule: false,
	allowAccessToReceivables: false,
	allowPartnerApiSubscription: false,
	assets: [{
		assetType: "",
		settled: ""
	}],
};