import React from 'react';

export const GroupsIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="45" height="37" viewBox="0 0 128 128" preserveAspectRatio="xMidYMid meet">
			<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#ffff" stroke="none">
				<path d="M585 1136 c-68 -29 -88 -126 -46 -221 l20 -45 -29 -15 c-46 -24 -81 -77 -83 -127 l-2 -43 195 0 195 0 3 25 c4 38 -38 112 -79 139 l-37 23 19 44 c26 57 27 143 3 179 -32 49 -100 67 -159 41z m103 -42 c33 -23 41 -52 31 -113 -10 -63 -40 -101 -79 -101 -46 0 -79 55 -82 137 -2 38 2 49 25 69 33 28 73 31 105 8z m66 -294 c19 -19 37 -45 41 -57 l6 -23 -161 0 -161 0 6 23 c9 27 53 76 80 88 11 5 50 8 88 6 62 -2 70 -5 101 -37z" />
				<path d="M302 654 c-48 -25 -65 -65 -60 -139 3 -33 13 -75 22 -93 16 -31 16 -31 -13 -47 -47 -24 -82 -77 -84 -127 l-2 -43 195 0 195 0 3 25 c4 38 -38 112 -80 139 -35 22 -36 25 -22 46 8 12 19 49 25 82 9 48 8 67 -4 96 -28 65 -110 94 -175 61z m115 -48 c24 -21 28 -31 26 -74 -3 -69 -25 -120 -57 -128 -62 -15 -98 26 -104 120 -4 59 -2 64 23 84 36 29 77 28 112 -2z m57 -286 c19 -19 37 -45 41 -57 l6 -23 -161 0 -161 0 6 23 c9 27 53 76 80 88 11 5 50 8 88 6 62 -2 70 -5 101 -37z" />
				<path d="M859 647 c-49 -33 -61 -61 -54 -131 4 -33 13 -73 22 -90 15 -28 14 -31 -3 -41 -40 -22 -83 -73 -94 -112 -23 -75 -25 -74 192 -71 l193 3 3 25 c4 38 -38 112 -79 138 l-36 23 18 52 c36 102 18 181 -47 212 -44 21 -75 19 -115 -8z m118 -43 c23 -23 25 -31 20 -85 -8 -89 -39 -128 -92 -115 -44 11 -78 119 -55 175 22 53 86 66 127 25z m57 -284 c19 -19 37 -45 41 -57 l6 -23 -161 0 -161 0 6 23 c9 27 53 76 80 88 11 5 50 8 88 6 62 -2 70 -5 101 -37z" />
			</g>
		</svg>
	);
};