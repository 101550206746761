import { IProps } from 'global/interface/types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getGroupList } from 'services/group';
import { useMyPermissions } from './permissions';
import { useTenant } from './tenant';

type GlobalGroupContent = {
	groupOptionList: []
	setGroupOptionList: () => void
	getGroupList: (page: number, size: number, searchString: string) => void
};

const GroupContext = createContext<GlobalGroupContent>({ groupOptionList: [], setGroupOptionList: () => { }, getGroupList: () => { } });

export const GroupProvider = ({ children }: IProps) => {
	const [groupOptionList, setGroupOptionList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, searchString: '' });
	const { selectedTenant } = useTenant();
	const { getMyPermissionLevel } = useMyPermissions();

	const hasGroupPerm: boolean = !!getMyPermissionLevel("UserGroup", "read");

	useEffect(() => {
		if (!hasGroupPerm) return;
		searchGroup(searchArgs.page, searchArgs.size, searchArgs.searchString, selectedTenant);
	}, [searchArgs, selectedTenant]);


	function searchGroup(page: number, size: number, searchString: string, selectedTenant: string | undefined) {
		return getGroupList({ page, size, searchString, selectedTenant }).then(response => {
			setGroupOptionList(response?.data);
		});
	};

	const contextGetGroupList = (page: number, size: number, searchString: string) => {
		if (page != searchArgs?.page || size != searchArgs?.size || searchString != searchArgs?.searchString) {
			setSearchArgs({ page, size, searchString });
		}
	};

	return (
		<GroupContext.Provider value={{
			groupOptionList: groupOptionList,
			setGroupOptionList: () => setGroupOptionList,
			getGroupList: (page: number, size: number, searchString: string) => {
				return contextGetGroupList(page, size, searchString);
			},
		}}>
			{children}
		</GroupContext.Provider>
	);
};

export function useGroup() {
	const context = useContext(GroupContext);
	const {
		groupOptionList,
		getGroupList
	} = context;
	return {
		groupOptionList,
		getGroupList
	};
}
