import { string, object, date, boolean } from 'yup';

export const validationSchema = object().shape({
	messageRequired: boolean(),
	message: string().when('messageRequired', {
		is: true,
		then: string().required("Esse campo é obrigatório").typeError("Esse campo é obrigatório"),
		otherwise: string().notRequired().nullable()
	}),
	insertBlock: string().nullable(),
	blockUntil: date().typeError('Data inválida').required(),
});

export const defaultValues = {
	messageRequired: true,
	message: null,
	insertBlock: null,
	blockUntil: new Date().toDateString(),
};
