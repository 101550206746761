import { CognitoConfig } from 'config';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { config as AWSConfig, CognitoIdentityCredentials } from 'aws-sdk';
import authRepository from 'repository/auth';

const cognitoUserPool = new CognitoUserPool(CognitoConfig);
let cognitoUser = null;

export function changePassword(oldPassword, newPassword) {
	return new Promise((resolve, reject) => {
		var cognitoUser = cognitoUserPool.getCurrentUser();

		if (cognitoUser != null) {
			cognitoUser.setAuthenticationFlowType('REFRESH_TOKEN');
			cognitoUser.getSession((sessionError, session) => {
				if (sessionError) {
					authRepository.signOut('1');
					reject(sessionError);
				} else {
					authRepository.setToken(session.idToken.jwtToken, session.idToken.payload.exp);
					cognitoUser.changePassword(oldPassword, newPassword, (changePasswordError, result) => {
						if (changePasswordError) {
							reject(changePasswordError);
						} else {
							resolve(result);
						}
					});
				}
			});
		}
	});
}

export function signIn(email, password) {
	const finalEmail = email.toLocaleLowerCase().trim();

	const authenticationDetails = new AuthenticationDetails({
		Username: finalEmail,
		Password: password,
	});

	const userData = {
		Username: finalEmail,
		Pool: cognitoUserPool
	};

	cognitoUser = new CognitoUser(userData);
	return new Promise((resolve, reject) => {
		authenticateUser(authenticationDetails, resolve, reject);
	});
}
export function authenticateUser(authenticationDetails, resolve, reject) {
	cognitoUser.authenticateUser(authenticationDetails, {
		mfaSetup: function (challengeName, challengeParameters) {
			cognitoUser.associateSoftwareToken(this);
		},
		mfaRequired: function (codeDeliveryDetails) {
			console.log(codeDeliveryDetails);
			// TO DO SMS
			// MFA is required to complete user authentication.
			// Get the code from user and call
			// cognitoUser.sendMFACode(mfaCode, this);
		},
		associateSecretCode: function (secretCode) {
			reject({ associateSecretCode: true, secretCode: secretCode });
		},
		totpRequired: (codeDeliveryDetails) => {
			reject({ validateCode: true });
		},
		onSuccess: result => {
			console.log(result);
			var token = result.idToken.jwtToken;
			updateCredentials(token);
			AWSConfig.getCredentials((err) => {
				if (err) {
					console.log(err);
				} else {
					var creds = {
						accessKeyId: AWSConfig.credentials.accessKeyId,
						secretAccessKey: AWSConfig.credentials.secretAccessKey,
						SessionToken: AWSConfig.credentials.sessionToken,
					};
				}
			});
			resolve(result.idToken);
		},
		onFailure: error => {
			console.log('SIGN_IN - onFailure', error);
			if (error.message && error.message.includes('UserMigration failed with error')) {
				reject({ errorMessage: error.message.replace('UserMigration failed with error', '') });
			} else {
				reject(error);
			}
		},
		newPasswordRequired: () => {
			console.log('SIGN_IN - newPasswordRequired');
			reject({ newPasswordRequired: true });
		}
	});
}


export function continueMFA(code) {
	return new Promise((resolve, reject) => {
		if (cognitoUser != null) {
			cognitoUser.verifySoftwareToken(code, 'SoftwareToken', {
				onSuccess: function (result) {
					var totpMfaSettings = {
						PreferredMfa: true,
						Enabled: true
					};
					cognitoUser.setUserMfaPreference(null, totpMfaSettings, function (err, result) {
						if (err) {
							alert(err);
						}
						console.log('setUserMfaPreference call result ' + result);
					});

					resolve(result.idToken);
				},

				onFailure: function (err) {
					reject(err);
				}
			});
		}
	});
}


export function validateCodeTOTP(code) {
	return new Promise((resolve, reject) => {
		if (cognitoUser != null) {
			cognitoUser.sendMFACode(code, {
				onSuccess: function (result) {
					var token = result.idToken.jwtToken;
					updateCredentials(token);
					AWSConfig.getCredentials((err) => {
						if (err) {
							console.log(err);
						} else {
							var creds = {
								accessKeyId: AWSConfig.credentials.accessKeyId,
								secretAccessKey: AWSConfig.credentials.secretAccessKey,
								SessionToken: AWSConfig.credentials.sessionToken,
							};
						}
					});
					resolve(result.idToken);
				},
				onFailure: function (err) {
					reject(err);
				}
			}, 'SOFTWARE_TOKEN_MFA');
		}
	});
}

export function updateCredentials(token) {
	return new Promise((resolve, reject) => {
		AWSConfig.region = CognitoConfig.region;
		AWSConfig.credentials = new CognitoIdentityCredentials({
			IdentityPoolId: CognitoConfig.IdentityPoolId,
			Logins: {
				[`cognito-idp.${CognitoConfig.region}.amazonaws.com/${CognitoConfig.UserPoolId}`]: token
			}
		});
		AWSConfig.getCredentials((err) => {
			if (err) {
				console.log(err);
				reject(err);
			} else {
				var creds = {
					accessKeyId: AWSConfig.credentials.accessKeyId,
					secretAccessKey: AWSConfig.credentials.secretAccessKey,
					SessionToken: AWSConfig.credentials.sessionToken,
				};
				resolve();
			}
		});
	});

}
export function getUserData() {
	return new Promise((resolve, reject) => {
		if (cognitoUser != null) {
			cognitoUser.getUserData((err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}
	});
}

export function setupMFA() {
	return new Promise((resolve, reject) => {
		if (cognitoUser != null) {
			cognitoUser.associateSoftwareToken({
				associateSecretCode: function (secretCode) {
					reject({ associateSecretCode: true, secretCode: secretCode });
				},

				onFailure: function (err) {
					console.log(err);
				}
			});
		}

	});
}

export function getCurrentUser() {
	return new Promise((resolve, reject) => {
		if (cognitoUser != null) {
			cognitoUser.getSession((err, session) => {
				if (err) {
					reject(err);
				} else {
					resolve(session.idToken);
				}
			});
		} else {
			signOutAndRedirectLogin();
			reject('Cognito user not found');
		}
	});
}

export function confirmNewPassword(email, newPassword) {
	return new Promise((resolve, reject) => {
		cognitoUser.completeNewPasswordChallenge(newPassword, { email }, {
			mfaSetup: function (challengeName, challengeParameters) {
				cognitoUser.associateSoftwareToken(this);
			},
			mfaRequired: function (codeDeliveryDetails) {
				console.log(codeDeliveryDetails);
				// TO DO SMS
				// MFA is required to complete user authentication.
				// Get the code from user and call
				// cognitoUser.sendMFACode(mfaCode, this);
			},
			associateSecretCode: function (secretCode) {
				reject({ associateSecretCode: true, secretCode: secretCode });
			},
			onSuccess: result => {
				console.log(result);
				const authenticationDetails = new AuthenticationDetails({
					Username: email,
					Password: newPassword,
				});

				const userData = {
					Username: email,
					Pool: cognitoUserPool
				};

				cognitoUser = new CognitoUser(userData);
				authenticateUser(authenticationDetails, resolve, reject);
			},
			onFailure: reject
		}, this);
	});
}

export function resendVerificationCode(userName) {
	return new Promise((resolve, reject) => {
		initAWS();
		const cognitoUser = cognitoUserPool.getCurrentUser();

		const callback = {
			onSuccess: function (result) {
				console.log('call result: ' + result);
				resolve(result);
			},
			onFailure: function (err) {
				console.log(err);
				reject(err);
			},
			inputVerificationCode: (data) => {
				console.log(data);
				resolve(data);
			}
		};

		cognitoUser.getSession((err, session) => {
			if (err) {
				console.log('error getting cognito session', { err });
			} else {
				cognitoUser.setSignInUserSession(session);
				cognitoUser.getAttributeVerificationCode("email", callback);
			}
		});
	});
}

export function verifyEmail(confirmationCode) {
	return new Promise((resolve, reject) => {
		initAWS();
		const cognitoUser = cognitoUserPool.getCurrentUser();
		const callback = {
			onSuccess: function (result) {
				console.log('call result: ' + result);
				resolve(result);
			},
			onFailure: function (err) {
				console.log(err);
				reject(err);
			}
		};

		cognitoUser.getSession((err, session) => {
			if (err) {
				console.log('error getting cognito session', { err });
			} else {
				cognitoUser.setSignInUserSession(session);
				cognitoUser.verifyAttribute("email", confirmationCode.trim(), callback);
			}
		});


	});
}

export function sendVerificationCodeForPasswordRecover(email) {
	return new Promise((resolve, reject) => {
		const userData = {
			Username: email.toLocaleLowerCase().trim(),
			Pool: cognitoUserPool
		};
		const cognitoUser = new CognitoUser(userData);
		cognitoUser.forgotPassword({
			onSuccess: resolve,
			onFailure: reject
		});
	});
}

export function passwordRecover(email, verificationCode, password) {
	return new Promise((resolve, reject) => {
		var userData = {
			Username: email.toLocaleLowerCase().trim(),
			Pool: cognitoUserPool
		};

		var cognitoUser = new CognitoUser(userData);
		cognitoUser.confirmPassword(verificationCode, password, {
			onSuccess: resolve,
			onFailure: reject
		});
	});
}

export function signOut() {
	authRepository.signOut();
	const currentUser = cognitoUserPool.getCurrentUser();
	if (currentUser) {
		currentUser.signOut();
	}
}

export function signOutAndRedirectLogin(errorCode = '0') {
	if (window.location.pathname != `/login/${errorCode}`) {
		window.location.href = `/login/${errorCode}`;
		signOut();
	}
}

export function getToken() {
	return new Promise((resolve, reject) => {
		var cognitoUser = cognitoUserPool.getCurrentUser();

		if (cognitoUser != null) {
			cognitoUser.setAuthenticationFlowType('REFRESH_TOKEN');
			cognitoUser.getSession((err, session) => {
				if (err) {
					signOutAndRedirectLogin('1');
					reject(err);
				} else {
					authRepository.setToken(session.idToken.jwtToken, session.idToken.payload.exp);
					resolve(session.idToken.jwtToken);
				}
			});
		} else {
			signOutAndRedirectLogin();
			reject('Cognito user not found');
		}
	});
}

export function initAWS() {
	let user = JSON.parse(localStorage.getItem("user")); AWSConfig.region = CognitoConfig.region; // Region
	AWSConfig.credentials = new CognitoIdentityCredentials({
		IdentityPoolId: CognitoConfig.IdentityPoolId,
		Logins: {
			[`cognito-idp.${CognitoConfig.region}.amazonaws.com/${CognitoConfig.UserPoolId}`]: user.jwtToken
		}
	});
}