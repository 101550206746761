import { IProps } from 'global/interface/types';
import { createContext, useContext, useState, useEffect } from 'react';
import { getEnumList } from 'services/enumList';

type GlobalContent = {
	enumPayPurposeOption: []
	setEnumOptionList: () => void
	getEnumPaymentPurpose: (page: number, size: number, search: string) => void
};

const EnumContext = createContext<GlobalContent>({ enumPayPurposeOption: [], setEnumOptionList: () => { }, getEnumPaymentPurpose: () => { } });

export const EnumPayProvider = ({ children }: IProps) => {
	const [enumOptionList, setEnumOptionList] = useState<[]>([]);
	const [searchArgs, setSearchArgs] = useState({ page: 0, size: 10, search: '' });

	useEffect(() => {
		getEnumPaymentPurpose(searchArgs.page, searchArgs.size, searchArgs.search);
	}, [searchArgs]);

	const getEnumPaymentPurpose = (page: number, size: number, search: string) => {
		getEnumList({ page, size, search }, "PaymentPurpose").then(async resp => {
			setEnumOptionList(resp);
		});
	};

	const contextGetEnumPaymentPurposeList = (page: number, size: number, search: string) => {
		if (page != searchArgs?.page, size != searchArgs?.size, search != searchArgs?.search) {
			setSearchArgs({ page, size, search });
		}
	};

	return (
		<EnumContext.Provider value={{
			enumPayPurposeOption: enumOptionList,
			setEnumOptionList: () => setEnumOptionList,
			getEnumPaymentPurpose: (page: number, size: number, search: string) => {
				return contextGetEnumPaymentPurposeList(page, size, search);
			},

		}}>
			{children}
		</EnumContext.Provider>
	);
};

export function useEnumPayPurpose() {
	const context = useContext(EnumContext);
	const {
		enumPayPurposeOption,
		getEnumPaymentPurpose
	} = context;
	return {
		enumPayPurposeOption,
		getEnumPaymentPurpose
	};
}
