import { IProps } from 'global/interface/types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { updateCredentials } from 'repository/cognito';

type TUser = {
	payload: any;
	authenticated: boolean;
	jwtToken: string;
};

type GlobalUserContext = {
	user: TUser;
	setUser: (user: TUser) => void;
};

const UserContext = createContext<GlobalUserContext>({ user: { payload: {}, authenticated: false, jwtToken: "" }, setUser: () => { } });

export const UserProvider = ({ children }: IProps) => {

	const getUserFromLocalStorage = () => {
		const data = localStorage.getItem("user");
		if (typeof data === 'string') {
			return (JSON.parse(data));
		}
		if (data == null) {
			return { authenticated: false, photo: null, payload: { name: "visitante" } };
		}
		return data;
	};
	const [user, setUser] = useState(getUserFromLocalStorage());

	useEffect(() => {
		if (user) {
			localStorage.setItem("user", JSON.stringify(user));
			updateCredentials(user?.jwtToken).catch((err) => {
				localStorage.removeItem("user");
				setUser(null);
			});
		}
	}, [user]);

	return (
		<UserContext.Provider
			value={{
				user: user,
				setUser: setUser
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export function useUser() {
	const context = useContext(UserContext);
	const {
		user,
		setUser
	} = context;
	return {
		user,
		setUser
	};
}
