import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { validationSchema, defaultValues } from './validation';
import { ContentContainer, Toast, UploadTab } from 'components';
import * as naturalPersonService from '../../../services/naturalPerson';
import { AppBar, Tabs, Tab, Box, Typography, Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel from 'components/TabPanel/TabPanel';
import RiskDatasets from '../RiskDatasets/datasets';
import { useDropzone } from 'react-dropzone';
import AssetTab from 'components/AssetTab/AssetsTab';
import BankAccountsTab from 'components/BankDataTab/BankAccountsTab';
import { OperationsContainer } from 'components/OperationsTab/OperationsContainer';
import { OperationsTab } from 'components/OperationsTab/OperationsTab';
import NaturalCreditDatasets from '../NaturalCreditDatasets';
import { useForm, useFormState } from 'react-hook-form';
import getFormFunctions from 'helpers/form-helpers';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import InformationsNaturalPerson from '../FieldsForm/informationsNaturalPerson';
import ModalAssign from 'components/Assign/ModalAssign';

function NaturalPersonEdit(props) {

	const { selectedTenant } = useTenant();
	const [tab, setTab] = React.useState('Informações');
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [person, setPerson] = useState();
	const [files, setFiles] = useState([]);
	const [assets, setAssets] = useState([]);
	const { control, getValues, handleSubmit, setValue, reset, watch } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues,
	});
	const [bankAccounts, setBankAccounts] = useState([]);
	const { setTitle } = useHeader();
	const [modalAssign, setModalAssign] = useState(false);

	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	const { errors } = useFormState({ control });

	useEffect(() => {
		if (isEditing()) {
			setTitle("Pessoa Física - editar cadastro");
			fetchNaturalPersonInfoById();
		} else {
			setTitle("Pessoa Física - novo cadastro");
		}
	}, []);

	useEffect(() => {
		if (person) {
			reset(person);
			getValues();
		}
	}, [person]);

	const { isFieldRequired, inputBind, inputBindNumber, onChangeNumber } = getFormFunctions({ validationSchema, control, errors, setValue });

	const fetchNaturalPersonInfoById = () => {

		setLoading(true);

		naturalPersonService.getNaturalPersonById(getNaturalPersonId())
			.then(legalPerson => {
				let personTreated = { ...legalPerson.data };
				setLoading(false);
				setFiles(legalPerson?.data?.uploads || []);
				if (personTreated?.assets) setAssets(personTreated.assets);
				if (legalPerson?.data?.bankAccounts) setBankAccounts(legalPerson?.data?.bankAccounts);

				setPerson(personTreated);

			}).catch(err => {
				setLoading(false);
			});
	};

	const saveNaturalPerson = async (values) => {
		setLoading(true);

		let option = isEditing() ? "edit" : 'create';

		values.uploads = files;
		values.assets = assets;
		values.bankAccounts = bankAccounts;
		naturalPersonService.editOrCreateNaturalPerson(values, option, selectedTenant)
			.then(result => {
				setLoading(false);
				if (result.data) {
					history.push(`/pessoa-fisica/editar?id=${result.data}`);
				} else {
					fetchNaturalPersonInfoById();
				}
				return result;
			})
			.catch(error => {
				setLoading(false);
				return;
			});
	};

	const isEditing = () => {
		return !!getNaturalPersonId();
	};

	const getNaturalPersonId = () => props?.location?.search?.replace('?id=', '');

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const useScan3 = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_SCAN3;

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsNaturalPerson {...{ inputBind, inputBindNumber, isEditing, isFieldRequired, onChangeNumber, control, setValue, getValues, watch, naturalPersonData: person }} />
		},		
		{
			name: 'Dados Bancários',
			show: true,
			children: <BankAccountsTab editable={true}  {...{ inputBind, inputBindNumber, isEditing, isFieldRequired, onChangeNumber, bankAccounts, setBankAccounts, errors }} />
		},
		{
			name: 'Documentos',
			show: true,
			children: <UploadTab {...{ files, setFiles, getRootProps, getInputProps }} />
		},
		{
			name: 'Risco',
			show: !!+window.__RUNTIME_CONFIG__.REACT_APP_RISK_TAB && isEditing(),
			children: isEditing() ?
				<RiskDatasets {...{ setLoading, loading, naturalPersonId: getNaturalPersonId() }} />
				: <Box p={3}><Typography variant="h6" gutterBottom>
					Salve o registro para ver os dados
				</Typography>
				</Box>
		},
		{
			name: 'Crédito',
			show: !!+window.__RUNTIME_CONFIG__.REACT_APP_CREDIT_TAB && !useScan3 && isEditing(),
			children: <NaturalCreditDatasets personId={getNaturalPersonId()} {...{ person, setValue, setLoading, loading, inputBind, inputBindNumber, isFieldRequired, control, watch }} />
		},
		{
			name: 'Operações',
			show: !useScan3 && isEditing(),
			children:
				<OperationsContainer personId={getNaturalPersonId()} setLoading={setLoading}>
					<OperationsTab />
				</OperationsContainer>
		}
	];

	const onSubmit = (values) => {
		saveNaturalPerson(values);
	};

	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const handlePrint = () => {
		window.print();
	};

	const handleModalAssign = () => {
		setModalAssign(true);
	};
	const handleCloseModalAssign = () => {
		setModalAssign(false);
	};

	const actions = isEditing() ? {
		onAssign: handleModalAssign,
		onPrint: () => window.setTimeout(handlePrint, 10),
		disabledAll: loading
	} : {
	};

	const style = { borderTopLeftRadius: 10, borderTopRightRadius: 6, borderBottomLeftRadius: 10, borderBottomRightRadius: 6, backgroundColor: 'white', padding: 10, margin: 1 };

	return (
		<>
			<ContentContainer>
				{modalAssign &&
					<ModalAssign recordType={"NaturalPerson"} recordId={getNaturalPersonId()} onCloseModal={handleCloseModalAssign} />
				}

				{loading && (
					<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
				)}
				<form
					onSubmit={handleSubmit(onSubmit, onError)}
				>
					<Grid container direction="row" spacing={4}>
						<Grid item xs={12} lg={6}>
							<ActionBar {...actions} />
						</Grid>
						{isEditing() &&
							<Grid item xs={12} lg={6} className="disablePrint">
								<div className="status-area" style={{ display: 'flex', justifyContent: 'flex-end' }}>
									{person?.createdBy && <small style={style}><strong>Criado em:</strong>&nbsp;{person?.createdAt && new Date(person?.createdAt).toLocaleDateString('pt-BR')}</small>}
									{person?.ownerUser && <small style={style}><strong>Operador:</strong>&nbsp;{person?.ownerUser?.userIdDisplay}</small>}
									{person?.ownerGroup && <small style={style}><strong>Grupo:</strong>&nbsp;{person?.ownerGroup?.groupDisplay}</small>}
								</div>
							</Grid>}

					</Grid>
					<AppBar position="relative" color="transparent" elevation={0}>
						<Tabs
							centered={false}
							value={tab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
						>
							{tabs.map((item, index) => (
								item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
							))}
						</Tabs>
					</AppBar>
					{
						tabs.map((item, index) =>
							<TabPanel key={index} value={tab} index={item.name}>
								{item.children}
							</TabPanel>
						)
					}
				</form>
			</ContentContainer>
		</>
	);
}

export default NaturalPersonEdit;