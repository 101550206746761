import React, { useEffect, useState } from "react";
import AutocompleteWithSearch from "components/AutocompleteSearch/AutocompleteSearch";
import SelectComponent from "components/Select/Select";
import TextFieldComponent from "components/TextInput/TextField";
import accountTypeField from "../accountTypeList";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import TextCustomMask from "components/CustomMaskInput/TextCustomMask";
import Helper from 'helpers/format.helpers';
import { useEnum } from "contexts/enumRelatedBank";
import { FieldValues, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from "helpers/form-helpers";
import * as servicesBeneficiary from "services/digitalBankAccount";
import { CreateBeneficiarySchema, defaultValues } from "../schema/validation";
import { useParams } from "react-router";
import { RouteWithIdParamsType } from "global/types/types";
import { CreateBeneficiaryType } from "global/types/digitalAccount.types";
import { Toast } from "components";
import HasPassCode from "../../Code/codeForm";
import ModalComponent from "components/Modal/ModalComponent";
import { useUser } from "contexts/user";
import { pixTypes } from "../../OptionsFields/options.pixType";
import { IMFAState } from "global/interface/types";

const InformationsFavored = ({ tableRef }: any) => {
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const { user } = useUser();
	const [open, setOpen] = useState(false);
	const [username, setUsername] = useState("");
	const [formValues, setFormValues] = useState<CreateBeneficiaryType>();
	const { control, handleSubmit, setValue, watch, reset } = useForm({
		resolver: yupResolver(CreateBeneficiarySchema),
		defaultValues: defaultValues,
	});
	const { errors } = useFormState({ control });
	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema: CreateBeneficiarySchema, control, errors, setValue });
	const { enumOptionList, getEnumRelatedBank } = useEnum();
	const [labelName, setLabelName] = useState();
	const [customMask, setCustomMask] = useState<'cpf' | 'cnpj'>('cpf');
	const masks = {
		cpf: [...Helper.cpfMask, /\d/],
		cnpj: Helper.cnpjMask,
	};
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	const [registrationNumberValue, setRegistrationNumberValue] = useState("");
	const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
	const cognitoClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID;

	useEffect(() => {
		const subscription = watch((value: any, { name }: any) => {
			if (name === 'registrationNumber') {
				if (!value.registrationNumber) {
					return;
				}
				// @ts-ignore
				const doc = value.registrationNumber.match(/\d+/g).join('');
				if (!doc) {
					return;
				}
				if (doc.length < 12 && customMask !== 'cpf') {
					setCustomMask('cpf');
				}
				if (doc.length > 11 && customMask !== 'cnpj') {
					setCustomMask('cnpj');
				}
			}
			return null;
		});
		return () => subscription.unsubscribe();
	}, [watch, customMask]);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		//@ts-ignore
		if (user?.authenticated == true) setUsername(user['payload']['cognito:username']);
	}, [user]);


	const postFavored = async () => {
		await servicesBeneficiary.generateSessionId(username, modalFormData.password, userPoolId, cognitoClientId).then((sessionId) => {
			let fieldValues = formValues as CreateBeneficiaryType;
			fieldValues.code = modalFormData.code;
			fieldValues.sessionId = sessionId;
			servicesBeneficiary.createBeneficiary(bankAccountId, fieldValues).then(() => {
				tableRef.current.onQueryChange();
				reset();
			}).finally(() => handleClose());
		});
	};

	// @ts-ignore
	const onSubmit = (values: FieldValues) => { handleOpen(); setFormValues(values); };

	const onError = (values: FieldValues) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const pixKey = watch("pixKeyTypeValue") === "NaturalRegistrationNumber" || watch("pixKeyTypeValue") === "LegalRegistrationNumber";
	useEffect(() => {
		if (pixKey) setValue("keyPix", registrationNumberValue);

	}, [pixKey, registrationNumberValue]);

	return (
		<>
			<ModalComponent
				open={open}
				onClose={handleClose}
				buttonText='Confirmar'
				onClick={() => postFavored()}
				title='Cadastrar beneficiário'
				subtitle='Tem certeza que deseja cadastrar este beneficiário?'
				children={<HasPassCode setFormData={(data: IMFAState) => { setModalFormData(data); }} />}
			/>
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<br />
				<Box mb={2} width='50%'>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextFieldComponent
								id="name"
								label="Nome do favorecido"
								name="name"
								fullWidth
								{...inputBindNumber("name")} />
						</Grid>
						<Grid item xs={6}>
							<TextFieldComponent
								id="registrationNumber"
								label={labelName == "CPF" || labelName == "CNPJ" ? labelName : "CPF/CNPJ"}
								name="registrationNumber"
								fullWidth
								InputProps={{
									disableUnderline: true,
									inputComponent: TextCustomMask,
									inputProps: { mask: labelName === "CPF" ? Helper.cpfMask : labelName === "CNPJ" ? Helper.cnpjMask : masks[customMask] },
								}}
								//@ts-ignore
								onChangeField={(ev: React.ChangeEvent<HTMLInputElement>) => {
									const value = ev.target.value?.replace(/\D/g, '');
									setValue("registrationNumber", value);
									setRegistrationNumberValue(value);
									pixKey ? setValue("keyPix", value) : setValue("keyPix", "");
								}}
								{...inputBindNumber("registrationNumber")} />
						</Grid>
						<Grid item xs={6}>
							<SelectComponent
								id="operationTypeValue"
								label="Tipo de Operação"
								{...inputBind("operationTypeValue")}
								defaultValue={false}
								fields={[{ name: "Transferência", value: "Transfer" }, { name: "Pix", value: "Pix" }]}
							/>
						</Grid>
						{watch("operationTypeValue") == "Transfer" &&
							<>
								<Grid item xs={6}>
									{/*@ts-ignore*/}
									<AutocompleteWithSearch
										id="bank"
										displayField="bankDisplay"
										label="Banco"
										watch={watch}
										setValue={setValue}
										params={{
											page: 0,
											size: 10
										}}
										fetch={getEnumRelatedBank}
										options={enumOptionList}
										{...inputBind('bank')}
										onChange={(_: React.ChangeEvent<HTMLInputElement>, options: any) => {
											setValue("bank", options?.value || '');
											//@ts-ignore
											setValue("bankDisplay", options?.name);
										}} />
								</Grid>
								<Grid item xs={6}>
									<SelectComponent
										id="accountType"
										label="Tipo de conta"
										defaultValue={false}
										fields={accountTypeField}
										{...inputBind('accountType')} />
								</Grid>
								<Grid item xs={6}>
									<TextFieldComponent
										id="agency"
										label="Agência (sem o digito)"
										name="agency"
										fullWidth
										{...inputBindNumber("agency")} />
								</Grid>
								<Grid item xs={6}>
									<TextFieldComponent
										id="accountNumber"
										label="Conta (com o digito)"
										name="accountNumber"
										fullWidth
										{...inputBindNumber("accountNumber")} />
								</Grid>
							</>
						}
						{watch("operationTypeValue") === "Pix" && 
							<Grid item xs={6}>
								<SelectComponent
									id="pixKeyTypeValue"
									label="Tipo de chave Pix"
									{...inputBind("pixKeyTypeValue")}
									defaultValue={false}
									fields={pixTypes}
									//@ts-ignore
									onChangeField={(_: React.FormEvent<HTMLFormElement>, values: any) => {
										setLabelName(values.props.children ?? "");
										setValue("keyPix", "");
									}}
								/>
							</Grid>
						}

						{watch("pixKeyTypeValue") === "Email" &&
						watch("operationTypeValue") === "Pix" && 
							<Grid item xs={6}>
								<TextFieldComponent
									id="keyPix"
									label={`Chave Pix (${labelName})`}
									//@ts-ignore
									onChangeField={(event: React.ChangeEvent<HTMLInputElement>) => {
										const value = event?.target?.value;
										setValue("keyPix", value);
									}}
									fullWidth
									{...inputBind("keyPix")}
								/>
							</Grid>
						}
						
						{watch("pixKeyTypeValue") === "Phone" &&
						watch("operationTypeValue") === "Pix" && 
							<Grid item xs={6}>
								<TextFieldComponent
									id="keyPix"
									label={`Chave Pix (${labelName})`}
									//@ts-ignore
									onChangeField={(event: React.ChangeEvent<HTMLInputElement>) => {
										const value = event?.target?.value;
										setValue("keyPix", Helper.formatPhoneNumber(value));
									}}
									fullWidth
									{...inputBind("keyPix")}
								/>
							</Grid>
						}

						{watch("pixKeyTypeValue") === "Automatic" &&
						watch("operationTypeValue") === "Pix" && 
							<Grid item xs={6}>
								<TextFieldComponent
									id="keyPix"
									label={`Chave Pix (${labelName})`}
									//@ts-ignore
									onChangeField={(event: React.ChangeEvent<HTMLInputElement>) => {
										const value = event?.target?.value;
										setValue("keyPix", value);
									}}
									fullWidth
									{...inputBind("keyPix")}
								/>
							</Grid>
						}

						{watch("pixKeyTypeValue") === "AgencyAndAccount" &&
						watch("operationTypeValue") === "Pix" && 
							<Grid item xs={6}>
								{/*@ts-ignore*/}
								<AutocompleteWithSearch
									id="bank"
									displayField="bankDisplay"
									label="Banco"
									watch={watch}
									setValue={setValue}
									params={{
										page: 0,
										size: 10
									}}
									fetch={getEnumRelatedBank}
									options={enumOptionList}
									{...inputBind('bank')}
									onChange={(_: React.ChangeEvent<HTMLInputElement>, options: any) => {
										setValue("bank", options?.value || '');
										//@ts-ignore
										setValue("bankDisplay", options?.name);
									}}
								/>
							</Grid>}

						{watch("pixKeyTypeValue") === "AgencyAndAccount" &&
						watch("operationTypeValue") === "Pix" && 
							<Grid item xs={6}>
								<TextFieldComponent
									id="agency"
									fullWidth
									label="Agência"
									{...inputBind("agency")}
								/>
							</Grid>
						}
						{watch("pixKeyTypeValue") === "AgencyAndAccount" &&
						watch("operationTypeValue") === "Pix" && 
							<Grid item xs={6}>
								<TextFieldComponent
									id="accountNumber"
									fullWidth
									label="Conta"
									{...inputBind("accountNumber")}
								/>
							</Grid>
						}
						<Grid container justifyContent='center' spacing={5}>
							<Grid item>
								<Button className="button-actionBar" type="submit" variant="contained" style={{ backgroundColor: "#5F5F5F" }}>
									<SaveOutlinedIcon style={{ color: "#fff" }} />
									<Typography component="span" style={{ color: "white", textTransform: 'none', }}>
										&nbsp;Salvar
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<br />
			</form>
		</>
	);
};

export default InformationsFavored;