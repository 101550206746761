
export const ProductAccountIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="29" height="31" viewBox="0 0 128.000000 128.000000">
			<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#ffff" stroke="none">
				<path d="M620 1153 c-8 -3 -140 -55 -292 -115 -272 -107 -278 -110 -278 -138 0 -28 15 -40 51 -40 14 0 19 -7 19 -28 0 -36 16 -52 52 -52 l28 0 0 -200 0 -200 -28 0 c-36 0 -52 -16 -52 -52 0 -24 -4 -28 -29 -28 -42 0 -52 -19 -49 -92 l3 -63 288 -3 287 -2 0 -50 c0 -37 5 -55 20 -70 19 -19 33 -20 310 -20 277 0 291 1 310 20 19 19 20 33 20 220 0 245 3 240 -120 240 l-80 0 0 150 0 150 28 0 c36 0 52 16 52 52 0 21 5 28 19 28 36 0 51 12 51 40 0 28 -6 31 -277 138 -306 121 -311 123 -333 115z m303 -146 c209 -82 254 -103 231 -107 -25 -4 -29 -10 -32 -43 l-3 -37 -480 0 c-471 0 -479 0 -479 20 0 19 8 20 378 22 327 3 377 5 377 18 0 13 -54 16 -409 20 l-409 5 269 106 c148 59 276 105 284 102 8 -2 131 -50 273 -106z m-603 -427 l0 -200 -40 0 -40 0 0 200 0 200 40 0 40 0 0 -200z m120 0 l0 -200 -40 0 -40 0 0 200 0 200 40 0 40 0 0 -200z m120 0 l0 -200 -40 0 -40 0 0 200 0 200 40 0 40 0 0 -200z m120 50 c0 -93 -4 -150 -10 -150 -21 0 -50 -41 -50 -70 0 -16 -4 -30 -10 -30 -6 0 -10 73 -10 200 l0 200 40 0 40 0 0 -150z m120 0 l0 -150 -40 0 -40 0 0 150 0 150 40 0 40 0 0 -150z m120 0 l0 -150 -40 0 -40 0 0 150 0 150 40 0 40 0 0 -150z m120 0 l0 -150 -40 0 -40 0 0 150 0 150 40 0 40 0 0 -150z m198 -222 l3 -28 -290 0 -291 0 0 23 c0 13 3 27 7 30 4 4 133 6 287 5 l281 -3 3 -27z m-618 -148 l0 -80 -270 0 -270 0 0 39 c0 39 1 40 38 43 35 3 37 5 40 41 l3 37 230 0 229 0 0 -80z m620 60 c0 -20 -7 -20 -290 -20 -283 0 -290 0 -290 20 0 20 7 20 290 20 283 0 290 0 290 -20z m-2 -167 l-3 -108 -285 0 -285 0 -3 47 -3 47 68 3 c52 2 68 6 68 18 0 12 -15 16 -67 18 l-68 3 0 39 0 40 290 0 291 0 -3 -107z"/>
				<path d="M600 1040 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 11 0 29 9 40 20 11 11 20 29 20 40 0 11 -9 29 -20 40 -11 11 -29 20 -40 20 -11 0 -29 -9 -40 -20z m60 -40 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z"/>
				<path d="M965 890 c-13 -21 11 -31 61 -28 36 2 49 7 49 18 0 19 -99 27 -110 10z"/>
				<path d="M224 287 c-9 -25 0 -62 16 -62 10 0 15 10 15 34 0 36 -21 54 -31 28z"/>
				<path d="M324 287 c-9 -25 0 -62 16 -62 10 0 15 10 15 34 0 36 -21 54 -31 28z"/>
				<path d="M424 287 c-9 -25 0 -62 16 -62 10 0 15 10 15 34 0 36 -21 54 -31 28z"/>
				<path d="M524 287 c-9 -25 0 -62 16 -62 10 0 15 10 15 34 0 36 -21 54 -31 28z"/>
				<path d="M1039 199 c-46 -46 -9 -97 71 -97 57 0 90 21 90 58 0 37 -33 58 -90 58 -38 0 -57 -5 -71 -19z m116 -39 c0 -16 -81 -24 -91 -9 -11 18 7 27 49 25 28 -2 42 -7 42 -16z"/>
				<path d="M845 170 c-13 -21 12 -31 71 -28 44 2 59 7 59 18 0 19 -119 28 -130 10z"/>
			</g>
		</svg>
	);
};


