import { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { PaymentCol } from "assets/style";
import Scan from "assets/images/barcode-scan 1.png";
import BarCode from "assets/images/bar-code 1.png";
import Water from "assets/images/water-drop 1.png";
import { FieldValues, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from "helpers/form-helpers";
import NumberInput from "components/NumberInput/NumberInput";
import TextFieldComponent from "components/TextInput/TextField";
import DatePicker from "components/DatePicker/DatePicker";
import EnterIcon from "assets/icons/EnterIcon";
import * as servicePay from "services/digitalBankAccount";
import { BankSlipType } from 'global/types/digitalAccount.types';
import { useParams } from 'react-router';
import { informationBankSlip, RouteWithIdParamsType } from 'global/types/types';
import { BankSlipValidationSchema as validationSchema, defaultValues } from '../schema/validation';
import { Toast } from 'components';
import ModalComponent from 'components/Modal/ModalComponent';
import HasPassCode from '../../Code/codeForm';
import { useUser } from 'contexts/user';
import { IMFAState } from 'global/interface/types';

function Informations() {
	return (
		<Box mb={2} width='50%'>
			<Grid item >
				<Typography component="div" variant="h6" style={{ color: "#5F5F5F", fontFamily: 'Arial', }}>Pagamentos</Typography>
				<Typography component="div" style={{ color: "#5F5F5F", fontFamily: 'sans-serif', }}>Você poderá pagar os seguintes tipos de conta: </Typography>
			</Grid>
			<br />
			<Grid container spacing={3}>
				<Grid item xs={4}>
					<PaymentCol>
						<img src={Scan} />
						<span>
							BOLETO DE COBRANÇA
						</span>
					</PaymentCol>
				</Grid>
				<Grid item xs={3}>
					<PaymentCol>
						<img src={BarCode} />
						<span>
							TRIBUTOS
						</span>
					</PaymentCol>
				</Grid>
				<Grid item xs={4}>
					<PaymentCol>
						<img src={Water} />
						<span>
							CONTA DE CONSUMO
						</span>
					</PaymentCol>
				</Grid>
			</Grid>
		</Box>
	);
}

const InformationsPayment = ({ tableRef }: any) => {
	const { bankAccountId } = useParams<RouteWithIdParamsType>();
	const { user } = useUser();
	const [step, setStep] = useState(1);
	const [bankSlip, setBankSlip] = useState("");
	const [bankSlipInformation, setBankSlipInformation] = useState<informationBankSlip>();
	const [open, setOpen] = useState(false);
	const [username, setUsername] = useState("");
	const [formValues, setFormValues] = useState<BankSlipType>();
	const { control, handleSubmit, setValue, getValues, watch, reset } = useForm({
		resolver: yupResolver(validationSchema(bankSlip)),
		defaultValues: defaultValues
	});
	const { errors } = useFormState({ control });
	const { inputBind, inputBindNumber } = getFormFunctions({ validationSchema: validationSchema(bankSlip), control, errors, setValue });
	const [modalFormData, setModalFormData] = useState<IMFAState>({
		password: '',
		code: '',
		showPassword: false,
	});
	const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
	const cognitoClientId = window.__RUNTIME_CONFIG__.REACT_APP_USER_CLIENT_ID;

	useEffect(() => {
		setBankSlip(getValues("barCode").replace(/\D/g, ''));
	}, [watch("barCode")]);

	//@ts-ignore
	const bankSlipDataService = (values) => {
		servicePay.getBankSlipData(bankAccountId, values).then((result: any) => {
			setBankSlipInformation(result);
			setStep(2);
		});
	};
	// correction of the mapping of new values

	const formatValueBarcodeOrDigitableLine = (value:number) => value / 100;

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		//@ts-ignore
		if (user?.authenticated == true) setUsername(user['payload']['cognito:username']);
	}, [user]);

	const postPayment = async () => {

		await servicePay.generateSessionId(username, modalFormData.password, userPoolId, cognitoClientId).then((sessionId) => {
			let currentValues = formValues as unknown as FieldValues;
			let fieldValues = {
				...currentValues,
				code: modalFormData.code,
				sessionId: sessionId,
				totalValue: bankSlipInformation?.value,
				amount: bankSlipInformation?.totalValue,
				BarCodeOrDigitableLine: bankSlipInformation?.barCode,
				digitableLine: bankSlipInformation?.digitableLine,
				discountValue: bankSlipInformation?.discountValue,
				discount: bankSlipInformation?.discount,
				maximumValue: bankSlipInformation?.maximumValue,
				minimumValue: bankSlipInformation?.minimumValue, 
				paymentValue: bankSlipInformation?.value,
				fee: bankSlipInformation?.fee,  
				trafficTicket: bankSlipInformation?.trafficTicket, 
				paymentDate: bankSlipInformation?.dueDate, 
				paymentDeadline: bankSlipInformation?.paymentDeadline,
				paymentPurpose: 99999 //Outros
			};
			servicePay.postPay(bankAccountId, fieldValues).then(() => {
				setStep(1);
				tableRef.current.onQueryChange();
				reset();
			}).finally(() => handleClose());
		});
	};

	const onSubmit = async (values: FieldValues) => {

		if (step === 1) {
			await bankSlipDataService(values);
		}

		if (step === 2) {
			handleOpen();
			setFormValues(values);
		}
	};

	const onError = (values: FieldValues) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	return (
		<>
			<ModalComponent
				open={open}
				onClose={handleClose}
				buttonText='Confirmar'
				onClick={() => postPayment()}
				title='Confirmação de pagamento'
				subtitle='Tem certeza que deseja confirmar o pagamento?'
				children={<HasPassCode setFormData={(data: IMFAState) => { setModalFormData(data); }} />}
			/>
			<Informations />
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<Box mb={2} width='50%'>
					<Grid container spacing={3}>
						{step === 1 && (
							<>
								<Grid item xs={10}>
									<TextFieldComponent
										id="barCode"
										fullWidth
										label="Código de barras ou Linha digitável"
										//@ts-ignore
										component={TextField}
										{...inputBind(`barCode`)}
										name="barCode"
									/>
								</Grid>
							</>
						)}
						{step === 2 && (
							<>
								<Grid item xs={6}>
									<NumberInput
										id="totalValue"
										label="Valor do pagamento"
										name="totalValue"
										adornmentText="R$"
										//@ts-ignore
										value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.totalValue)} 
										{...inputBindNumber("totalValue")}
										disabled={true}
									/>
								</Grid>
								<Grid item xs={6}>
									<NumberInput
										id="value"
										label="Valor nominal"
										name="value"
										adornmentText="R$"
										//@ts-ignore
										value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.value)}
										{...inputBindNumber("value")}
										disabled={true}
									/>
								</Grid>
								{bankSlipInformation?.maximumValue !== bankSlipInformation?.totalValue &&
									<Grid item xs={6}>
										<NumberInput
											id="maximumValue"
											label="Valor máximo"
											name="maximumValue"
											adornmentText="R$"
											//@ts-ignore
											value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.maximumValue)}
											{...inputBindNumber("maximumValue")}
											disabled={true}
										/>
									</Grid>
								}
								{bankSlipInformation?.minimumValue !== 0 && 
									<Grid item xs={6}>
										<NumberInput 
											id="minimumValue"
											label="Valor mínimo"
											name="minimumValue"
											adornmentText="R$"
											//@ts-ignore
											value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.minimumValue)}
											{...inputBindNumber("minimumValue")}
											disabled={true}
										/>
									</Grid>
								}
								<Grid item xs={6}>
									<NumberInput
										id="discountValue"
										label="Valor desconto"
										name="discountValue"
										adornmentText="R$"
										//@ts-ignore
										value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.discountValue)}
										{...inputBindNumber("discountValue")}
										disabled={true}
									/>
								</Grid>
								<Grid item xs={6}>
									<NumberInput
										id="discount"
										label="Valores de outros descontos"
										name="discount"
										adornmentText="R$"
										//@ts-ignore
										value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.discount)}
										{...inputBindNumber("discount")}
										disabled={true}
									/>
								</Grid>
								<Grid item xs={6}>
									<NumberInput
										id="free"
										label="Valor encargos"
										name="free"
										adornmentText="R$"
										//@ts-ignore
										value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.free)}
										{...inputBindNumber("free")}
										disabled={true}
									/>
								</Grid>
								<Grid item xs={6}>
									{/* AJUSTAR! */}
									<NumberInput
										id="trafficTicket"
										label="Valor de outros encargos"
										name="trafficTicket"
										adornmentText="R$"
										//@ts-ignore
										value={formatValueBarcodeOrDigitableLine(bankSlipInformation?.trafficTicket)}
										{...inputBindNumber("trafficTicket")}
										disabled={true}
									/>
								</Grid>
								<Grid item xs={6}>
									<DatePicker
										disablePast={false}
										id="dueDate"
										label="Data de pagamento"
										variant="inline"
										fullWidth
										InputLabelProps={{ shrink: true, required: true }}
										{...inputBind("dueDate")} 
										name="dueDate"
										disableFuture={false}
									/>
								</Grid>
								<Grid item xs={6}>
									<DatePicker
										disablePast={true}
										id="paymentDeadline"
										label="Data de vencimento"
										variant="inline"
										fullWidth
										InputLabelProps={{ shrink: true, required: true }}
										{...inputBind("paymentDeadline")}
										name="paymentDeadline"
										disableFuture={false}
									/>
								</Grid>
							</>
						)}
					</Grid>
					<br />
					<Grid container justifyContent='center' spacing={5}>
						<Grid item>
							<Button className="button-actionBar" type="submit" variant="contained" style={{ backgroundColor: "#5F5F5F" }}>
								<EnterIcon />
								<Typography component="span" style={{ color: "white", textTransform: 'none', }}>
									&nbsp;{
										step === 1 ? 'Avançar' : 'Enviar'}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Box>
			</form>
		</>
	);
};

export default InformationsPayment;