import React, { useEffect, useState } from 'react';
import { Grid, Box, TextField, Typography } from '@material-ui/core';
import SelectComponent from 'components/Select/Select';
import TextFieldComponent from 'components/TextInput/TextField';
import TextCustomMask from "components/CustomMaskInput/TextCustomMask";
import { queryZipCodeAndFillForm } from "services/zipCode";
import Helper from 'helpers/format.helpers';
import UF from "global/enums/uf";
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AutocompleteWithSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { usePerson } from 'contexts/person';
import { getLegalPersonById } from 'services/legalPerson';
import { Toast } from 'components';
import { getNaturalPersonById } from 'services/naturalPerson';
import { useProductAccount } from 'contexts/productAccount';

function InformationsBankAccount({
	isEditing,
	inputBind,
	bankAccount,
	setValue,
	watch,
	readOnly,
	setEnvWithProdAccount
}) {

	const { getPersonsList, personOptionsList } = usePerson();
	const { productAccountOptionList, getProductAccountList } = useProductAccount();

	const handleLink = () => {
		const ownerId = watch("ownerId");
		const ownerDiscriminator = watch("ownerDiscriminator");

		if (ownerDiscriminator === "LegalPerson") {
			return `/pessoa-juridica/editar?id=${ownerId}`;
		} else return `/pessoa-fisica/editar?id=${ownerId}`;

	};

	useEffect(() => {

		var errorFn = (error) => {
			setValue("ownerDiscriminator", null);
			setValue("ownerId", null);
			setValue("owner", null);
			setValue("ownerDisplay", null);
			Toast.showErrorMessage("Erro ao selecionar titular: " + error);
		};

		const ownerId = watch("ownerId");
		const ownerDiscriminator = watch("ownerDiscriminator");
		if (ownerDiscriminator === "LegalPerson") {
			getLegalPersonById(ownerId).then(({ data }) => {
				setValue("owner.name", data?.name);
				setValue("owner.address", data?.address);
				if (data?.address == null) {
					return errorFn("Endereço não cadastrado");
				}
				setValue("owner.phone", data?.phone);
				if (data?.phone == null) {
					return errorFn("Telefone não cadastrado");
				}
				setValue("owner.economicActivityCodeId", data?.economicActivityCodeId);
				if (data?.economicActivityCodeId == null) {
					return errorFn("CNAE não cadastrado");
				}
				setValue("owner.numberOfEmployees", data?.numberOfEmployees);
				if (data?.numberOfEmployees == null) {
					return errorFn("Número de funcionários não cadastrado");
				}
				setValue("owner.legalNature", data?.legalNature);
				if (data?.legalNature == null) {
					return errorFn("Natureza jurídica não cadastrada");
				}
				setValue("owner.averageMonthlyRevenue", data?.averageMonthlyRevenue);
				if (data?.averageMonthlyRevenue == null) {
					return errorFn("Faturamento mensal não cadastrado");
				}
			});
		}

		if (ownerDiscriminator === "NaturalPerson") {
			getNaturalPersonById(ownerId).then(({ data }) => {
				setValue("owner.name", data?.name);
				setValue("owner.address", data?.address);
				if (data?.address == null) {
					return errorFn("Endereço não cadastrado");
				}
				setValue("owner.phone", data?.phone);
				if (data?.phone == null) {
					return errorFn("Telefone não cadastrado");
				}
				setValue("owner.averageMonthlyRevenue", data.totalIncome);
			});
		}
	}, [watch("ownerId")]);


	return (
		<><Box py={3}>
			<Grid container spacing={3}>
				{isEditing() && <Grid item xs={4}>
					<TextFieldComponent
						id="tenantDisplay"
						name="tenantDisplay"
						fullWidth
						label="Correspondente"
						InputLabelProps={{
							shrink: false,
						}}
						component={TextField}
						value={bankAccount?.tenantDisplay ?? ""}
						{...inputBind(`tenantDisplay`)}
						disabled={bankAccount ? true : readOnly}
					/>
				</Grid>}
				<Grid item xs={4}>
					<AutocompleteWithSearch
						id="bankAccountProductId"
						name="bankAccountProductId"
						displayField="bankAccountProductIdDisplay"
						fullWidth
						label="Produto"
						watch={watch}
						setValue={setValue}
						rowData={{ bankAccountProductId: bankAccount?.bankAccountProductId, bankAccountProductIdDisplay: bankAccount?.bankAccountProductIdDisplay }}
						params={{
							page: 0,
							size: 10
						}}
						fetch={getProductAccountList}
						options={productAccountOptionList}
						{...inputBind(`bankAccountProductId`)}
						disabled={readOnly}
						onChange={(_, options) => {

							setValue("bankAccountProductId", options?.id || '');
							setValue("bankAccountProductIdDisplay", options?.name || '');
							var relatedPerson = options?.relatedPerson;
							setEnvWithProdAccount(relatedPerson);
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextFieldComponent
						id="partnerName"
						name="partnerName"
						fullWidth
						label="Nome do Parceiro"
						component={TextField}
						{...inputBind(`partnerName`)}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={4}>
					<AutocompleteWithSearch
						id="ownerId"
						name="ownerId"
						fullWidth
						displayField="ownerDisplay"
						label="Titular"
						watch={watch}
						setValue={setValue}
						rowData={{ ownerId: bankAccount?.ownerId, ownerDisplay: bankAccount?.ownerDisplay, ownerDiscriminator: bankAccount?.ownerDiscriminator }}
						params={{
							page: 0,
							size: 10
						}}
						fetch={getPersonsList}
						options={personOptionsList}
						{...inputBind('ownerId')}
						endAdornment={
							watch("ownerId") ? <Link to={handleLink()}>
								<SearchOutlinedIcon position="end" />
							</Link> : ''}
						showEndAdornment={watch("ownerId")}
						onChange={(_, options) => {
							setValue("ownerId", options?.value || '');
							setValue("ownerDisplay", options?.name);
							setValue("ownerDiscriminator", options?.discriminator);
						}}
						disabled={readOnly}
					/>
				</Grid>
				<Grid item xs={4}>
					<SelectComponent
						id="type"
						name="type"
						label="Tipo de Conta"
						fullWidth
						defaultValue=""
						{...inputBind(`type`)}
						fields={[{ name: "Conta Livre Movimentação", value: "Payment" },
							{ name: "Conta Escrow", value: "Escrow" }]}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={4}>
					<SelectComponent
						id="reason"
						name="reason"
						label="Principal motivo para abrir a conta"
						fullWidth
						defaultValue=""
						{...inputBind(`reason`)}
						fields={[
							{ name: "Cartão de débito corporativo", value: "Debit" },
							{ name: "Crédito", value: "Credit" },
							{ name: "Emitir boletos de cobrança", value: "Invoice" },
							{ name: "Receber e enviar transferências e pagar boletos", value: "Transfer" },
							{ name: "Outros", value: "Others" }
						]}
						disabled={readOnly} />
				</Grid>
				{watch("reason") === "Others" &&
					<Grid item xs={4}>
						<TextFieldComponent
							id="otherReason"
							name="otherReason"
							fullWidth
							label="Outro motivo"
							fieldAreaHeight={100}
							component={TextField}
							{...inputBind(`otherReason`)}
							disabled={readOnly}
							required={true} />
					</Grid>}
				<Grid item xs={4}>
					<TextFieldComponent
						id="cardName"
						fullWidth
						label="Insira o nome do seu cartão corporativo"
						name="cardName"
						component={TextField}
						{...inputBind("cardName")}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={4}>
					<TextFieldComponent
						id="quorumMinimumApproval"
						fullWidth
						type='number'
						label="Quórum mínimo de aprovação"
						name="quorumMinimumApproval"
						component={TextField}
						{...inputBind("quorumMinimumApproval")}
					/>
				</Grid>	
				{isEditing() && <Grid item xs={2}>
					<TextFieldComponent
						id="stepDisplay"
						name="stepDisplay"
						fullWidth
						label="Etapa da Preenchimento"
						InputLabelProps={{
							shrink: false,
						}}
						component={TextField}
						{...inputBind(`stepDisplay`)}
						disabled={true} />
				</Grid>}
			</Grid>
		</Box><Box p={0} mt={3}>
			<Typography component="div" variant="h6" gutterBottom style={{ color: "#5F5F5F", textTransform: 'none', }}>Endereço de recibo do cartão</Typography>
			<br />
			<Grid container spacing={4}>
				<Grid item xs={3}>
					<TextFieldComponent
						id="cardReceiptAddress-zipCode"
						fullWidth
						label="CEP"
						name="cardReceiptAddress.zipCode"
						placeholder="00000-000"
						component={TextField}
						InputProps={{
							inputComponent: TextCustomMask,
							inputProps: { mask: Helper.zipCodeMask },
							onChange: (event) => {
								let value = Helper.onlyNumbers(event?.currentTarget?.value);
								if (value.length >= 8) {
									queryZipCodeAndFillForm(value, (fieldName, value) => {
										setValue(`cardReceiptAddress.${fieldName}`, value);
									});
								}
							},
						}}
						{...inputBind("cardReceiptAddress.zipCode")}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={4}>
					<TextFieldComponent
						id="cardReceiptAddress-addressName"
						fullWidth
						label="Endereço"
						name="cardReceiptAddress.addressName"
						component={TextField}
						{...inputBind("cardReceiptAddress.addressName")}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={2}>
					<TextFieldComponent
						id="cardReceiptAddress-number"
						fullWidth
						type="number"
						label="Número"
						name="cardReceiptAddress.name"
						component={TextField}
						{...inputBind("cardReceiptAddress.number")}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={3}>
					<TextFieldComponent
						id="cardReceiptAddress-complement"
						fullWidth
						label="Complemento"
						name="cardReceiptAddress.complement"
						component={TextField}
						{...inputBind("cardReceiptAddress.complement")}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={3}>
					<TextFieldComponent
						id="cardReceiptAddress-district"
						fullWidth
						label="Bairro"
						name="cardReceiptAddress.district"
						component={TextField}
						{...inputBind("cardReceiptAddress.district")}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={4}>
					<TextFieldComponent
						id="cardReceiptAddress-city"
						fullWidth
						label="Cidade"
						name="cardReceiptAddress.city"
						component={TextField}
						{...inputBind("cardReceiptAddress.city")}
						disabled={readOnly} />
				</Grid>
				<Grid item xs={2}>
					<SelectComponent
						id="cardReceiptAddress-uf"
						name="cardReceiptAddress-uf"
						label="UF"
						fullWidth
						defaultValue=""
						{...inputBind(`cardReceiptAddress.uf`)}
						fields={UF}
						disabled={readOnly} />
				</Grid>
			</Grid>
		</Box>
		</>
	);
}
export default InformationsBankAccount;
